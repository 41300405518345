.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.bg-transparent {
  background-color: transparent
}

.bg-black {
  background-color: @color-black
}

.bg-white {
  background-color: @color-white
}

.bg-grey-10 {
  background-color: fade(@color-grey, 10%)
}

.bg-grey-30 {
  background-color: fade(@color-grey, 30%)
}

.bg-grey-40 {
  background-color: fade(@color-grey, 40%)
}

.bg-grey-60 {
  background-color: fade(@color-grey, 60%)
}

.bg-grey-90 {
  background-color: fade(@color-grey, 90%)
}

.bg-grey {
  background-color: @color-grey
}

.bg-grey-metal {
  background-color: @color-grey
}

.bg-grey-divider {
  background-color: fade(@color-grey, 20%)
}

.bg-grey-light {
  background-color: @color-grey-light
}

.bg-grey-header {
  background-color: fade(@color-grey, 90%)
}

.bg-body {
  background-color: @color-body
}

.bg-yellow {
  background-color: @color-yellow
}

.bg-orange {
  background-color: @color-orange
}

.bg-stone {
  background-color: @color-stone
}

.bg-khaki {
  background-color: @color-khaki
}

.bg-green {
  background-color: @color-green
}

.bg-blue {
  background-color: @color-blue
}

.bg-red {
  background-color: @color-red
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-black:hover {
  background-color: @color-black
}

.hover\:bg-white:hover {
  background-color: @color-white
}

.hover\:bg-grey-10:hover {
  background-color: fade(@color-grey, 10%)
}

.hover\:bg-grey-30:hover {
  background-color: fade(@color-grey, 30%)
}

.hover\:bg-grey-40:hover {
  background-color: fade(@color-grey, 40%)
}

.hover\:bg-grey-60:hover {
  background-color: fade(@color-grey, 60%)
}

.hover\:bg-grey-90:hover {
  background-color: fade(@color-grey, 90%)
}

.hover\:bg-grey:hover {
  background-color: @color-grey
}

.hover\:bg-grey-metal:hover {
  background-color: @color-grey
}

.hover\:bg-grey-divider:hover {
  background-color: fade(@color-grey, 20%)
}

.hover\:bg-grey-light:hover {
  background-color: @color-grey-light
}

.hover\:bg-grey-header:hover {
  background-color: fade(@color-grey, 90%)
}

.hover\:bg-body:hover {
  background-color: @color-body
}

.hover\:bg-yellow:hover {
  background-color: @color-yellow
}

.hover\:bg-orange:hover {
  background-color: @color-orange
}

.hover\:bg-stone:hover {
  background-color: @color-stone
}

.hover\:bg-khaki:hover {
  background-color: @color-khaki
}

.hover\:bg-green:hover {
  background-color: @color-green
}

.hover\:bg-blue:hover {
  background-color: @color-blue
}

.hover\:bg-red:hover {
  background-color: @color-red
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.focus\:bg-black:focus {
  background-color: @color-black
}

.focus\:bg-white:focus {
  background-color: @color-white
}

.focus\:bg-grey-10:focus {
  background-color: fade(@color-grey, 10%)
}

.focus\:bg-grey-30:focus {
  background-color: fade(@color-grey, 30%)
}

.focus\:bg-grey-40:focus {
  background-color: fade(@color-grey, 40%)
}

.focus\:bg-grey-60:focus {
  background-color: fade(@color-grey, 60%)
}

.focus\:bg-grey-90:focus {
  background-color: fade(@color-grey, 90%)
}

.focus\:bg-grey:focus {
  background-color: @color-grey
}

.focus\:bg-grey-metal:focus {
  background-color: @color-grey
}

.focus\:bg-grey-divider:focus {
  background-color: fade(@color-grey, 20%)
}

.focus\:bg-grey-light:focus {
  background-color: @color-grey-light
}

.focus\:bg-grey-header:focus {
  background-color: fade(@color-grey, 90%)
}

.focus\:bg-body:focus {
  background-color: @color-body
}

.focus\:bg-yellow:focus {
  background-color: @color-yellow
}

.focus\:bg-orange:focus {
  background-color: @color-orange
}

.focus\:bg-stone:focus {
  background-color: @color-stone
}

.focus\:bg-khaki:focus {
  background-color: @color-khaki
}

.focus\:bg-green:focus {
  background-color: @color-green
}

.focus\:bg-blue:focus {
  background-color: @color-blue
}

.focus\:bg-red:focus {
  background-color: @color-red
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: center
}

.bg-left {
  background-position: left
}

.bg-left-bottom {
  background-position: left bottom
}

.bg-left-top {
  background-position: left top
}

.bg-right {
  background-position: right
}

.bg-right-bottom {
  background-position: right bottom
}

.bg-right-top {
  background-position: right top
}

.bg-top {
  background-position: top
}

.bg-repeat {
  background-repeat: repeat
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-repeat-x {
  background-repeat: repeat-x
}

.bg-repeat-y {
  background-repeat: repeat-y
}

.bg-repeat-round {
  background-repeat: round
}

.bg-repeat-space {
  background-repeat: space
}

.bg-auto {
  background-size: auto
}

.bg-cover {
  background-size: cover
}

.bg-contain {
  background-size: contain
}

.border-collapse {
  border-collapse: collapse
}

.border-separate {
  border-collapse: separate
}

.border-transparent {
  border-color: transparent
}

.border-black {
  border-color: @color-black
}

.border-white {
  border-color: @color-white
}

.border-grey-10 {
  border-color: fade(@color-grey, 10%)
}

.border-grey-30 {
  border-color: fade(@color-grey, 30%)
}

.border-grey-40 {
  border-color: fade(@color-grey, 40%)
}

.border-grey-60 {
  border-color: fade(@color-grey, 60%)
}

.border-grey-90 {
  border-color: fade(@color-grey, 90%)
}

.border-grey {
  border-color: @color-grey
}

.border-grey-metal {
  border-color: @color-grey
}

.border-grey-divider {
  border-color: fade(@color-grey, 20%)
}

.border-grey-light {
  border-color: @color-grey-light
}

.border-grey-header {
  border-color: fade(@color-grey, 90%)
}

.border-body {
  border-color: @color-body
}

.border-yellow {
  border-color: @color-yellow
}

.border-orange {
  border-color: @color-orange
}

.border-stone {
  border-color: @color-stone
}

.border-khaki {
  border-color: @color-khaki
}

.border-green {
  border-color: @color-green
}

.border-blue {
  border-color: @color-blue
}

.border-red {
  border-color: @color-red
}

.hover\:border-transparent:hover {
  border-color: transparent
}

.hover\:border-black:hover {
  border-color: @color-black
}

.hover\:border-white:hover {
  border-color: @color-white
}

.hover\:border-grey-10:hover {
  border-color: fade(@color-grey, 10%)
}

.hover\:border-grey-30:hover {
  border-color: fade(@color-grey, 30%)
}

.hover\:border-grey-40:hover {
  border-color: fade(@color-grey, 40%)
}

.hover\:border-grey-60:hover {
  border-color: fade(@color-grey, 60%)
}

.hover\:border-grey-90:hover {
  border-color: fade(@color-grey, 90%)
}

.hover\:border-grey:hover {
  border-color: @color-grey
}

.hover\:border-grey-metal:hover {
  border-color: @color-grey
}

.hover\:border-grey-divider:hover {
  border-color: fade(@color-grey, 20%)
}

.hover\:border-grey-light:hover {
  border-color: @color-grey-light
}

.hover\:border-grey-header:hover {
  border-color: fade(@color-grey, 90%)
}

.hover\:border-body:hover {
  border-color: @color-body
}

.hover\:border-yellow:hover {
  border-color: @color-yellow
}

.hover\:border-orange:hover {
  border-color: @color-orange
}

.hover\:border-stone:hover {
  border-color: @color-stone
}

.hover\:border-khaki:hover {
  border-color: @color-khaki
}

.hover\:border-green:hover {
  border-color: @color-green
}

.hover\:border-blue:hover {
  border-color: @color-blue
}

.hover\:border-red:hover {
  border-color: @color-red
}

.focus\:border-transparent:focus {
  border-color: transparent
}

.focus\:border-black:focus {
  border-color: @color-black
}

.focus\:border-white:focus {
  border-color: @color-white
}

.focus\:border-grey-10:focus {
  border-color: fade(@color-grey, 10%)
}

.focus\:border-grey-30:focus {
  border-color: fade(@color-grey, 30%)
}

.focus\:border-grey-40:focus {
  border-color: fade(@color-grey, 40%)
}

.focus\:border-grey-60:focus {
  border-color: fade(@color-grey, 60%)
}

.focus\:border-grey-90:focus {
  border-color: fade(@color-grey, 90%)
}

.focus\:border-grey:focus {
  border-color: @color-grey
}

.focus\:border-grey-metal:focus {
  border-color: @color-grey
}

.focus\:border-grey-divider:focus {
  border-color: fade(@color-grey, 20%)
}

.focus\:border-grey-light:focus {
  border-color: @color-grey-light
}

.focus\:border-grey-header:focus {
  border-color: fade(@color-grey, 90%)
}

.focus\:border-body:focus {
  border-color: @color-body
}

.focus\:border-yellow:focus {
  border-color: @color-yellow
}

.focus\:border-orange:focus {
  border-color: @color-orange
}

.focus\:border-stone:focus {
  border-color: @color-stone
}

.focus\:border-khaki:focus {
  border-color: @color-khaki
}

.focus\:border-green:focus {
  border-color: @color-green
}

.focus\:border-blue:focus {
  border-color: @color-blue
}

.focus\:border-red:focus {
  border-color: @color-red
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .125rem
}

.rounded-lg {
  border-radius: .375rem
}

.rounded-full {
  border-radius: 20em
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-t {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem
}

.rounded-r {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem
}

.rounded-b {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem
}

.rounded-l {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem
}

.rounded-t-lg {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem
}

.rounded-r-lg {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.rounded-b-lg {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.rounded-l-lg {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.rounded-t-full {
  border-top-left-radius: 20em;
  border-top-right-radius: 20em
}

.rounded-r-full {
  border-top-right-radius: 20em;
  border-bottom-right-radius: 20em
}

.rounded-b-full {
  border-bottom-right-radius: 20em;
  border-bottom-left-radius: 20em
}

.rounded-l-full {
  border-top-left-radius: 20em;
  border-bottom-left-radius: 20em
}

.rounded-tl-none {
  border-top-left-radius: 0
}

.rounded-tr-none {
  border-top-right-radius: 0
}

.rounded-br-none {
  border-bottom-right-radius: 0
}

.rounded-bl-none {
  border-bottom-left-radius: 0
}

.rounded-tl {
  border-top-left-radius: .125rem
}

.rounded-tr {
  border-top-right-radius: .125rem
}

.rounded-br {
  border-bottom-right-radius: .125rem
}

.rounded-bl {
  border-bottom-left-radius: .125rem
}

.rounded-tl-lg {
  border-top-left-radius: .375rem
}

.rounded-tr-lg {
  border-top-right-radius: .375rem
}

.rounded-br-lg {
  border-bottom-right-radius: .375rem
}

.rounded-bl-lg {
  border-bottom-left-radius: .375rem
}

.rounded-tl-full {
  border-top-left-radius: 20em
}

.rounded-tr-full {
  border-top-right-radius: 20em
}

.rounded-br-full {
  border-bottom-right-radius: 20em
}

.rounded-bl-full {
  border-bottom-left-radius: 20em
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-double {
  border-style: double
}

.border-none {
  border-style: none
}

.border-0 {
  border-width: 0
}

.border-3 {
  border-width: .1875rem
}

.border-6 {
  border-width: .375rem
}

.border {
  border-width: 1px
}

.border-t-0 {
  border-top-width: 0
}

.border-r-0 {
  border-right-width: 0
}

.border-b-0 {
  border-bottom-width: 0
}

.border-l-0 {
  border-left-width: 0
}

.border-t-3 {
  border-top-width: .1875rem
}

.border-r-3 {
  border-right-width: .1875rem
}

.border-b-3 {
  border-bottom-width: .1875rem
}

.border-l-3 {
  border-left-width: .1875rem
}

.border-t-6 {
  border-top-width: .375rem
}

.border-r-6 {
  border-right-width: .375rem
}

.border-b-6 {
  border-bottom-width: .375rem
}

.border-l-6 {
  border-left-width: .375rem
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.cursor-auto {
  cursor: auto
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-wait {
  cursor: wait
}

.cursor-text {
  cursor: text
}

.cursor-move {
  cursor: move
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-row {
  display: table-row
}

.table-cell {
  display: table-cell
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-baseline {
  align-items: baseline
}

.items-stretch {
  align-items: stretch
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end
}

.content-between {
  align-content: space-between
}

.content-around {
  align-content: space-around
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.order-first {
  order: -9999
}

.order-last {
  order: 9999
}

.order-none {
  order: 0
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.float-none {
  float: none
}

.clearfix:after {
  content: "";
  display: table;
  clear: both
}

.font-body {
  font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.hover\:font-normal:hover {
  font-weight: 400
}

.hover\:font-semibold:hover {
  font-weight: 600
}

.focus\:font-normal:focus {
  font-weight: 400
}

.focus\:font-semibold:focus {
  font-weight: 600
}

.h-0 {
  height: 0
}

.h-1 {
  height: 0.5rem
}

.h-2 {
  height: 1rem
}

.h-3 {
  height: 1.5rem
}

.h-4 {
  height: 2rem
}

.h-5 {
  height: 2.5rem
}

.h-6 {
  height: 3rem
}

.h-7 {
  height: 3.5rem
}

.h-8 {
  height: 4rem
}

.h-9 {
  height: 4.5rem
}

.h-10 {
  height: 5rem
}

.h-11 {
  height: 5.5rem
}

.h-12 {
  height: 6rem
}

.h-13 {
  height: 6.5rem
}

.h-14 {
  height: 7rem
}

.h-15 {
  height: 7.5rem
}

.h-16 {
  height: 8rem
}

.h-17 {
  height: 8.5rem
}

.h-19 {
  height: 9rem
}

.h-20 {
  height: 9.5rem
}

.h-22 {
  height: 11rem
}

.h-24 {
  height: 12rem
}

.h-32 {
  height: 16rem
}

.h-40 {
  height: 20rem
}

.h-auto {
  height: auto
}

.h-px {
  height: 1px
}

.h-half {
  height: 0.25rem
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.leading-base {
  line-height: .75rem
}

.leading-sm {
  line-height: 1.125rem
}

.leading-lg {
  line-height: 1.5rem
}

.leading-xl {
  line-height: 1.875rem
}

.leading-2xl {
  line-height: 2.25rem
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.list-decimal {
  list-style-type: decimal
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.5rem
}

.m-2 {
  margin: 1rem
}

.m-3 {
  margin: 1.5rem
}

.m-4 {
  margin: 2rem
}

.m-5 {
  margin: 2.5rem
}

.m-6 {
  margin: 3rem
}

.m-7 {
  margin: 3.5rem
}

.m-8 {
  margin: 4rem
}

.m-9 {
  margin: 4.5rem
}

.m-10 {
  margin: 5rem
}

.m-11 {
  margin: 5.5rem
}

.m-12 {
  margin: 6rem
}

.m-13 {
  margin: 6.5rem
}

.m-14 {
  margin: 7rem
}

.m-15 {
  margin: 7.5rem
}

.m-16 {
  margin: 8rem
}

.m-17 {
  margin: 8.5rem
}

.m-19 {
  margin: 9rem
}

.m-20 {
  margin: 9.5rem
}

.m-22 {
  margin: 11rem
}

.m-24 {
  margin: 12rem
}

.m-32 {
  margin: 16rem
}

.m-40 {
  margin: 20rem
}

.m-auto {
  margin: auto
}

.m-px {
  margin: 1px
}

.m-half {
  margin: 0.25rem
}

.-m-1 {
  margin: -0.5rem
}

.-m-2 {
  margin: -1rem
}

.-m-3 {
  margin: -1.5rem
}

.-m-4 {
  margin: -2rem
}

.-m-5 {
  margin: -2.5rem
}

.-m-6 {
  margin: -3rem
}

.-m-7 {
  margin: -3.5rem
}

.-m-8 {
  margin: -4rem
}

.-m-9 {
  margin: -4.5rem
}

.-m-10 {
  margin: -5rem
}

.-m-11 {
  margin: -5.5rem
}

.-m-12 {
  margin: -6rem
}

.-m-13 {
  margin: -6.5rem
}

.-m-14 {
  margin: -7rem
}

.-m-15 {
  margin: -7.5rem
}

.-m-16 {
  margin: -8rem
}

.-m-17 {
  margin: -8.5rem
}

.-m-19 {
  margin: -9rem
}

.-m-20 {
  margin: -9.5rem
}

.-m-22 {
  margin: -11rem
}

.-m-24 {
  margin: -12rem
}

.-m-32 {
  margin: -16rem
}

.-m-40 {
  margin: -20rem
}

.-m-px {
  margin: -1px
}

.-m-half {
  margin: -0.25rem
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.mx-5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.mx-6 {
  margin-left: 3rem;
  margin-right: 3rem
}

.my-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem
}

.mx-7 {
  margin-left: 3.5rem;
  margin-right: 3.5rem
}

.my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.mx-8 {
  margin-left: 4rem;
  margin-right: 4rem
}

.my-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem
}

.mx-9 {
  margin-left: 4.5rem;
  margin-right: 4.5rem
}

.my-10 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mx-10 {
  margin-left: 5rem;
  margin-right: 5rem
}

.my-11 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem
}

.mx-11 {
  margin-left: 5.5rem;
  margin-right: 5.5rem
}

.my-12 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.mx-12 {
  margin-left: 6rem;
  margin-right: 6rem
}

.my-13 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem
}

.mx-13 {
  margin-left: 6.5rem;
  margin-right: 6.5rem
}

.my-14 {
  margin-top: 7rem;
  margin-bottom: 7rem
}

.mx-14 {
  margin-left: 7rem;
  margin-right: 7rem
}

.my-15 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem
}

.mx-15 {
  margin-left: 7.5rem;
  margin-right: 7.5rem
}

.my-16 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mx-16 {
  margin-left: 8rem;
  margin-right: 8rem
}

.my-17 {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem
}

.mx-17 {
  margin-left: 8.5rem;
  margin-right: 8.5rem
}

.my-19 {
  margin-top: 9rem;
  margin-bottom: 9rem
}

.mx-19 {
  margin-left: 9rem;
  margin-right: 9rem
}

.my-20 {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem
}

.mx-20 {
  margin-left: 9.5rem;
  margin-right: 9.5rem
}

.my-22 {
  margin-top: 11rem;
  margin-bottom: 11rem
}

.mx-22 {
  margin-left: 11rem;
  margin-right: 11rem
}

.my-24 {
  margin-top: 12rem;
  margin-bottom: 12rem
}

.mx-24 {
  margin-left: 12rem;
  margin-right: 12rem
}

.my-32 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

.mx-32 {
  margin-left: 16rem;
  margin-right: 16rem
}

.my-40 {
  margin-top: 20rem;
  margin-bottom: 20rem
}

.mx-40 {
  margin-left: 20rem;
  margin-right: 20rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px
}

.my-half {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-half {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.-my-1 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.-mx-1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-my-2 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

.-mx-2 {
  margin-left: -1rem;
  margin-right: -1rem
}

.-my-3 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem
}

.-mx-3 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.-my-4 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

.-mx-4 {
  margin-left: -2rem;
  margin-right: -2rem
}

.-my-5 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

.-mx-5 {
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.-my-6 {
  margin-top: -3rem;
  margin-bottom: -3rem
}

.-mx-6 {
  margin-left: -3rem;
  margin-right: -3rem
}

.-my-7 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem
}

.-mx-7 {
  margin-left: -3.5rem;
  margin-right: -3.5rem
}

.-my-8 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

.-mx-8 {
  margin-left: -4rem;
  margin-right: -4rem
}

.-my-9 {
  margin-top: -4.5rem;
  margin-bottom: -4.5rem
}

.-mx-9 {
  margin-left: -4.5rem;
  margin-right: -4.5rem
}

.-my-10 {
  margin-top: -5rem;
  margin-bottom: -5rem
}

.-mx-10 {
  margin-left: -5rem;
  margin-right: -5rem
}

.-my-11 {
  margin-top: -5.5rem;
  margin-bottom: -5.5rem
}

.-mx-11 {
  margin-left: -5.5rem;
  margin-right: -5.5rem
}

.-my-12 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

.-mx-12 {
  margin-left: -6rem;
  margin-right: -6rem
}

.-my-13 {
  margin-top: -6.5rem;
  margin-bottom: -6.5rem
}

.-mx-13 {
  margin-left: -6.5rem;
  margin-right: -6.5rem
}

.-my-14 {
  margin-top: -7rem;
  margin-bottom: -7rem
}

.-mx-14 {
  margin-left: -7rem;
  margin-right: -7rem
}

.-my-15 {
  margin-top: -7.5rem;
  margin-bottom: -7.5rem
}

.-mx-15 {
  margin-left: -7.5rem;
  margin-right: -7.5rem
}

.-my-16 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

.-mx-16 {
  margin-left: -8rem;
  margin-right: -8rem
}

.-my-17 {
  margin-top: -8.5rem;
  margin-bottom: -8.5rem
}

.-mx-17 {
  margin-left: -8.5rem;
  margin-right: -8.5rem
}

.-my-19 {
  margin-top: -9rem;
  margin-bottom: -9rem
}

.-mx-19 {
  margin-left: -9rem;
  margin-right: -9rem
}

.-my-20 {
  margin-top: -9.5rem;
  margin-bottom: -9.5rem
}

.-mx-20 {
  margin-left: -9.5rem;
  margin-right: -9.5rem
}

.-my-22 {
  margin-top: -11rem;
  margin-bottom: -11rem
}

.-mx-22 {
  margin-left: -11rem;
  margin-right: -11rem
}

.-my-24 {
  margin-top: -12rem;
  margin-bottom: -12rem
}

.-mx-24 {
  margin-left: -12rem;
  margin-right: -12rem
}

.-my-32 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

.-mx-32 {
  margin-left: -16rem;
  margin-right: -16rem
}

.-my-40 {
  margin-top: -20rem;
  margin-bottom: -20rem
}

.-mx-40 {
  margin-left: -20rem;
  margin-right: -20rem
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

.-my-half {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-mx-half {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.5rem
}

.mr-1 {
  margin-right: 0.5rem
}

.mb-1 {
  margin-bottom: 0.5rem
}

.ml-1 {
  margin-left: 0.5rem
}

.mt-2 {
  margin-top: 1rem
}

.mr-2 {
  margin-right: 1rem
}

.mb-2 {
  margin-bottom: 1rem
}

.ml-2 {
  margin-left: 1rem
}

.mt-3 {
  margin-top: 1.5rem
}

.mr-3 {
  margin-right: 1.5rem
}

.mb-3 {
  margin-bottom: 1.5rem
}

.ml-3 {
  margin-left: 1.5rem
}

.mt-4 {
  margin-top: 2rem
}

.mr-4 {
  margin-right: 2rem
}

.mb-4 {
  margin-bottom: 2rem
}

.ml-4 {
  margin-left: 2rem
}

.mt-5 {
  margin-top: 2.5rem
}

.mr-5 {
  margin-right: 2.5rem
}

.mb-5 {
  margin-bottom: 2.5rem
}

.ml-5 {
  margin-left: 2.5rem
}

.mt-6 {
  margin-top: 3rem
}

.mr-6 {
  margin-right: 3rem
}

.mb-6 {
  margin-bottom: 3rem
}

.ml-6 {
  margin-left: 3rem
}

.mt-7 {
  margin-top: 3.5rem
}

.mr-7 {
  margin-right: 3.5rem
}

.mb-7 {
  margin-bottom: 3.5rem
}

.ml-7 {
  margin-left: 3.5rem
}

.mt-8 {
  margin-top: 4rem
}

.mr-8 {
  margin-right: 4rem
}

.mb-8 {
  margin-bottom: 4rem
}

.ml-8 {
  margin-left: 4rem
}

.mt-9 {
  margin-top: 4.5rem
}

.mr-9 {
  margin-right: 4.5rem
}

.mb-9 {
  margin-bottom: 4.5rem
}

.ml-9 {
  margin-left: 4.5rem
}

.mt-10 {
  margin-top: 5rem
}

.mr-10 {
  margin-right: 5rem
}

.mb-10 {
  margin-bottom: 5rem
}

.ml-10 {
  margin-left: 5rem
}

.mt-11 {
  margin-top: 5.5rem
}

.mr-11 {
  margin-right: 5.5rem
}

.mb-11 {
  margin-bottom: 5.5rem
}

.ml-11 {
  margin-left: 5.5rem
}

.mt-12 {
  margin-top: 6rem
}

.mr-12 {
  margin-right: 6rem
}

.mb-12 {
  margin-bottom: 6rem
}

.ml-12 {
  margin-left: 6rem
}

.mt-13 {
  margin-top: 6.5rem
}

.mr-13 {
  margin-right: 6.5rem
}

.mb-13 {
  margin-bottom: 6.5rem
}

.ml-13 {
  margin-left: 6.5rem
}

.mt-14 {
  margin-top: 7rem
}

.mr-14 {
  margin-right: 7rem
}

.mb-14 {
  margin-bottom: 7rem
}

.ml-14 {
  margin-left: 7rem
}

.mt-15 {
  margin-top: 7.5rem
}

.mr-15 {
  margin-right: 7.5rem
}

.mb-15 {
  margin-bottom: 7.5rem
}

.ml-15 {
  margin-left: 7.5rem
}

.mt-16 {
  margin-top: 8rem
}

.mr-16 {
  margin-right: 8rem
}

.mb-16 {
  margin-bottom: 8rem
}

.ml-16 {
  margin-left: 8rem
}

.mt-17 {
  margin-top: 8.5rem
}

.mr-17 {
  margin-right: 8.5rem
}

.mb-17 {
  margin-bottom: 8.5rem
}

.ml-17 {
  margin-left: 8.5rem
}

.mt-19 {
  margin-top: 9rem
}

.mr-19 {
  margin-right: 9rem
}

.mb-19 {
  margin-bottom: 9rem
}

.ml-19 {
  margin-left: 9rem
}

.mt-20 {
  margin-top: 9.5rem
}

.mr-20 {
  margin-right: 9.5rem
}

.mb-20 {
  margin-bottom: 9.5rem
}

.ml-20 {
  margin-left: 9.5rem
}

.mt-22 {
  margin-top: 11rem
}

.mr-22 {
  margin-right: 11rem
}

.mb-22 {
  margin-bottom: 11rem
}

.ml-22 {
  margin-left: 11rem
}

.mt-24 {
  margin-top: 12rem
}

.mr-24 {
  margin-right: 12rem
}

.mb-24 {
  margin-bottom: 12rem
}

.ml-24 {
  margin-left: 12rem
}

.mt-32 {
  margin-top: 16rem
}

.mr-32 {
  margin-right: 16rem
}

.mb-32 {
  margin-bottom: 16rem
}

.ml-32 {
  margin-left: 16rem
}

.mt-40 {
  margin-top: 20rem
}

.mr-40 {
  margin-right: 20rem
}

.mb-40 {
  margin-bottom: 20rem
}

.ml-40 {
  margin-left: 20rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.mt-px {
  margin-top: 1px
}

.mr-px {
  margin-right: 1px
}

.mb-px {
  margin-bottom: 1px
}

.ml-px {
  margin-left: 1px
}

.mt-half {
  margin-top: 0.25rem
}

.mr-half {
  margin-right: 0.25rem
}

.mb-half {
  margin-bottom: 0.25rem
}

.ml-half {
  margin-left: 0.25rem
}

.-mt-1 {
  margin-top: -0.5rem
}

.-mr-1 {
  margin-right: -0.5rem
}

.-mb-1 {
  margin-bottom: -0.5rem
}

.-ml-1 {
  margin-left: -0.5rem
}

.-mt-2 {
  margin-top: -1rem
}

.-mr-2 {
  margin-right: -1rem
}

.-mb-2 {
  margin-bottom: -1rem
}

.-ml-2 {
  margin-left: -1rem
}

.-mt-3 {
  margin-top: -1.5rem
}

.-mr-3 {
  margin-right: -1.5rem
}

.-mb-3 {
  margin-bottom: -1.5rem
}

.-ml-3 {
  margin-left: -1.5rem
}

.-mt-4 {
  margin-top: -2rem
}

.-mr-4 {
  margin-right: -2rem
}

.-mb-4 {
  margin-bottom: -2rem
}

.-ml-4 {
  margin-left: -2rem
}

.-mt-5 {
  margin-top: -2.5rem
}

.-mr-5 {
  margin-right: -2.5rem
}

.-mb-5 {
  margin-bottom: -2.5rem
}

.-ml-5 {
  margin-left: -2.5rem
}

.-mt-6 {
  margin-top: -3rem
}

.-mr-6 {
  margin-right: -3rem
}

.-mb-6 {
  margin-bottom: -3rem
}

.-ml-6 {
  margin-left: -3rem
}

.-mt-7 {
  margin-top: -3.5rem
}

.-mr-7 {
  margin-right: -3.5rem
}

.-mb-7 {
  margin-bottom: -3.5rem
}

.-ml-7 {
  margin-left: -3.5rem
}

.-mt-8 {
  margin-top: -4rem
}

.-mr-8 {
  margin-right: -4rem
}

.-mb-8 {
  margin-bottom: -4rem
}

.-ml-8 {
  margin-left: -4rem
}

.-mt-9 {
  margin-top: -4.5rem
}

.-mr-9 {
  margin-right: -4.5rem
}

.-mb-9 {
  margin-bottom: -4.5rem
}

.-ml-9 {
  margin-left: -4.5rem
}

.-mt-10 {
  margin-top: -5rem
}

.-mr-10 {
  margin-right: -5rem
}

.-mb-10 {
  margin-bottom: -5rem
}

.-ml-10 {
  margin-left: -5rem
}

.-mt-11 {
  margin-top: -5.5rem
}

.-mr-11 {
  margin-right: -5.5rem
}

.-mb-11 {
  margin-bottom: -5.5rem
}

.-ml-11 {
  margin-left: -5.5rem
}

.-mt-12 {
  margin-top: -6rem
}

.-mr-12 {
  margin-right: -6rem
}

.-mb-12 {
  margin-bottom: -6rem
}

.-ml-12 {
  margin-left: -6rem
}

.-mt-13 {
  margin-top: -6.5rem
}

.-mr-13 {
  margin-right: -6.5rem
}

.-mb-13 {
  margin-bottom: -6.5rem
}

.-ml-13 {
  margin-left: -6.5rem
}

.-mt-14 {
  margin-top: -7rem
}

.-mr-14 {
  margin-right: -7rem
}

.-mb-14 {
  margin-bottom: -7rem
}

.-ml-14 {
  margin-left: -7rem
}

.-mt-15 {
  margin-top: -7.5rem
}

.-mr-15 {
  margin-right: -7.5rem
}

.-mb-15 {
  margin-bottom: -7.5rem
}

.-ml-15 {
  margin-left: -7.5rem
}

.-mt-16 {
  margin-top: -8rem
}

.-mr-16 {
  margin-right: -8rem
}

.-mb-16 {
  margin-bottom: -8rem
}

.-ml-16 {
  margin-left: -8rem
}

.-mt-17 {
  margin-top: -8.5rem
}

.-mr-17 {
  margin-right: -8.5rem
}

.-mb-17 {
  margin-bottom: -8.5rem
}

.-ml-17 {
  margin-left: -8.5rem
}

.-mt-19 {
  margin-top: -9rem
}

.-mr-19 {
  margin-right: -9rem
}

.-mb-19 {
  margin-bottom: -9rem
}

.-ml-19 {
  margin-left: -9rem
}

.-mt-20 {
  margin-top: -9.5rem
}

.-mr-20 {
  margin-right: -9.5rem
}

.-mb-20 {
  margin-bottom: -9.5rem
}

.-ml-20 {
  margin-left: -9.5rem
}

.-mt-22 {
  margin-top: -11rem
}

.-mr-22 {
  margin-right: -11rem
}

.-mb-22 {
  margin-bottom: -11rem
}

.-ml-22 {
  margin-left: -11rem
}

.-mt-24 {
  margin-top: -12rem
}

.-mr-24 {
  margin-right: -12rem
}

.-mb-24 {
  margin-bottom: -12rem
}

.-ml-24 {
  margin-left: -12rem
}

.-mt-32 {
  margin-top: -16rem
}

.-mr-32 {
  margin-right: -16rem
}

.-mb-32 {
  margin-bottom: -16rem
}

.-ml-32 {
  margin-left: -16rem
}

.-mt-40 {
  margin-top: -20rem
}

.-mr-40 {
  margin-right: -20rem
}

.-mb-40 {
  margin-bottom: -20rem
}

.-ml-40 {
  margin-left: -20rem
}

.-mt-px {
  margin-top: -1px
}

.-mr-px {
  margin-right: -1px
}

.-mb-px {
  margin-bottom: -1px
}

.-ml-px {
  margin-left: -1px
}

.-mt-half {
  margin-top: -0.25rem
}

.-mr-half {
  margin-right: -0.25rem
}

.-mb-half {
  margin-bottom: -0.25rem
}

.-ml-half {
  margin-left: -0.25rem
}

.max-h-90 {
  max-height: 90%
}

.max-h-full {
  max-height: 100%
}

.max-h-screen {
  max-height: 100vh
}

.max-w-400 {
  max-width: 25rem
}

.max-w-500 {
  max-width: 31.25rem
}

.max-w-600 {
  max-width: 37.5rem
}

.max-w-700 {
  max-width: 43.75em
}

.max-w-800 {
  max-width: 50rem
}

.max-w-1000 {
  max-width: 62.5rem
}

.max-w-1200 {
  max-width: 75rem
}

.max-w-1280 {
  max-width: 80rem
}

.max-w-1400 {
  max-width: 87.5rem
}

.max-w-1600 {
  max-width: 100rem
}

.max-w-1800 {
  max-width: 112.5rem
}

.max-w-2000 {
  max-width: 125rem
}

.min-h-0 {
  min-height: 0
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0
}

.min-w-full {
  min-width: 100%
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.object-fill {
  object-fit: fill
}

.object-none {
  object-fit: none
}

.object-scale-down {
  object-fit: scale-down
}

.object-bottom {
  object-position: bottom
}

.object-center {
  object-position: center
}

.object-left {
  object-position: left
}

.object-left-bottom {
  object-position: left bottom
}

.object-left-top {
  object-position: left top
}

.object-right {
  object-position: right
}

.object-right-bottom {
  object-position: right bottom
}

.object-right-top {
  object-position: right top
}

.object-top {
  object-position: top
}

.opacity-0 {
  opacity: 0
}

.opacity-20 {
  opacity: .2
}

.opacity-30 {
  opacity: .3
}

.opacity-40 {
  opacity: .4
}

.opacity-50 {
  opacity: .5
}

.opacity-60 {
  opacity: .6
}

.opacity-80 {
  opacity: .8
}

.opacity-100 {
  opacity: 1
}

.hover\:opacity-0:hover {
  opacity: 0
}

.hover\:opacity-20:hover {
  opacity: .2
}

.hover\:opacity-30:hover {
  opacity: .3
}

.hover\:opacity-40:hover {
  opacity: .4
}

.hover\:opacity-50:hover {
  opacity: .5
}

.hover\:opacity-60:hover {
  opacity: .6
}

.hover\:opacity-80:hover {
  opacity: .8
}

.hover\:opacity-100:hover {
  opacity: 1
}

.focus\:opacity-0:focus {
  opacity: 0
}

.focus\:opacity-20:focus {
  opacity: .2
}

.focus\:opacity-30:focus {
  opacity: .3
}

.focus\:opacity-40:focus {
  opacity: .4
}

.focus\:opacity-50:focus {
  opacity: .5
}

.focus\:opacity-60:focus {
  opacity: .6
}

.focus\:opacity-80:focus {
  opacity: .8
}

.focus\:opacity-100:focus {
  opacity: 1
}

.outline-none {
  outline: 0
}

.focus\:outline-none:focus {
  outline: 0
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.5rem
}

.p-2 {
  padding: 1rem
}

.p-3 {
  padding: 1.5rem
}

.p-4 {
  padding: 2rem
}

.p-5 {
  padding: 2.5rem
}

.p-6 {
  padding: 3rem
}

.p-7 {
  padding: 3.5rem
}

.p-8 {
  padding: 4rem
}

.p-9 {
  padding: 4.5rem
}

.p-10 {
  padding: 5rem
}

.p-11 {
  padding: 5.5rem
}

.p-12 {
  padding: 6rem
}

.p-13 {
  padding: 6.5rem
}

.p-14 {
  padding: 7rem
}

.p-15 {
  padding: 7.5rem
}

.p-16 {
  padding: 8rem
}

.p-17 {
  padding: 8.5rem
}

.p-19 {
  padding: 9rem
}

.p-20 {
  padding: 9.5rem
}

.p-22 {
  padding: 11rem
}

.p-24 {
  padding: 12rem
}

.p-32 {
  padding: 16rem
}

.p-40 {
  padding: 20rem
}

.p-px {
  padding: 1px
}

.p-half {
  padding: 0.25rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem
}

.py-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem
}

.px-7 {
  padding-left: 3.5rem;
  padding-right: 3.5rem
}

.py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-8 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem
}

.px-9 {
  padding-left: 4.5rem;
  padding-right: 4.5rem
}

.py-10 {
  padding-top: 5rem;
  padding-bottom: 5rem
}

.px-10 {
  padding-left: 5rem;
  padding-right: 5rem
}

.py-11 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem
}

.px-11 {
  padding-left: 5.5rem;
  padding-right: 5.5rem
}

.py-12 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.px-12 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-13 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem
}

.px-13 {
  padding-left: 6.5rem;
  padding-right: 6.5rem
}

.py-14 {
  padding-top: 7rem;
  padding-bottom: 7rem
}

.px-14 {
  padding-left: 7rem;
  padding-right: 7rem
}

.py-15 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem
}

.px-15 {
  padding-left: 7.5rem;
  padding-right: 7.5rem
}

.py-16 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

.px-16 {
  padding-left: 8rem;
  padding-right: 8rem
}

.py-17 {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem
}

.px-17 {
  padding-left: 8.5rem;
  padding-right: 8.5rem
}

.py-19 {
  padding-top: 9rem;
  padding-bottom: 9rem
}

.px-19 {
  padding-left: 9rem;
  padding-right: 9rem
}

.py-20 {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem
}

.px-20 {
  padding-left: 9.5rem;
  padding-right: 9.5rem
}

.py-22 {
  padding-top: 11rem;
  padding-bottom: 11rem
}

.px-22 {
  padding-left: 11rem;
  padding-right: 11rem
}

.py-24 {
  padding-top: 12rem;
  padding-bottom: 12rem
}

.px-24 {
  padding-left: 12rem;
  padding-right: 12rem
}

.py-32 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

.px-32 {
  padding-left: 16rem;
  padding-right: 16rem
}

.py-40 {
  padding-top: 20rem;
  padding-bottom: 20rem
}

.px-40 {
  padding-left: 20rem;
  padding-right: 20rem
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

.px-px {
  padding-left: 1px;
  padding-right: 1px
}

.py-half {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-half {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.5rem
}

.pr-1 {
  padding-right: 0.5rem
}

.pb-1 {
  padding-bottom: 0.5rem
}

.pl-1 {
  padding-left: 0.5rem
}

.pt-2 {
  padding-top: 1rem
}

.pr-2 {
  padding-right: 1rem
}

.pb-2 {
  padding-bottom: 1rem
}

.pl-2 {
  padding-left: 1rem
}

.pt-3 {
  padding-top: 1.5rem
}

.pr-3 {
  padding-right: 1.5rem
}

.pb-3 {
  padding-bottom: 1.5rem
}

.pl-3 {
  padding-left: 1.5rem
}

.pt-4 {
  padding-top: 2rem
}

.pr-4 {
  padding-right: 2rem
}

.pb-4 {
  padding-bottom: 2rem
}

.pl-4 {
  padding-left: 2rem
}

.pt-5 {
  padding-top: 2.5rem
}

.pr-5 {
  padding-right: 2.5rem
}

.pb-5 {
  padding-bottom: 2.5rem
}

.pl-5 {
  padding-left: 2.5rem
}

.pt-6 {
  padding-top: 3rem
}

.pr-6 {
  padding-right: 3rem
}

.pb-6 {
  padding-bottom: 3rem
}

.pl-6 {
  padding-left: 3rem
}

.pt-7 {
  padding-top: 3.5rem
}

.pr-7 {
  padding-right: 3.5rem
}

.pb-7 {
  padding-bottom: 3.5rem
}

.pl-7 {
  padding-left: 3.5rem
}

.pt-8 {
  padding-top: 4rem
}

.pr-8 {
  padding-right: 4rem
}

.pb-8 {
  padding-bottom: 4rem
}

.pl-8 {
  padding-left: 4rem
}

.pt-9 {
  padding-top: 4.5rem
}

.pr-9 {
  padding-right: 4.5rem
}

.pb-9 {
  padding-bottom: 4.5rem
}

.pl-9 {
  padding-left: 4.5rem
}

.pt-10 {
  padding-top: 5rem
}

.pr-10 {
  padding-right: 5rem
}

.pb-10 {
  padding-bottom: 5rem
}

.pl-10 {
  padding-left: 5rem
}

.pt-11 {
  padding-top: 5.5rem
}

.pr-11 {
  padding-right: 5.5rem
}

.pb-11 {
  padding-bottom: 5.5rem
}

.pl-11 {
  padding-left: 5.5rem
}

.pt-12 {
  padding-top: 6rem
}

.pr-12 {
  padding-right: 6rem
}

.pb-12 {
  padding-bottom: 6rem
}

.pl-12 {
  padding-left: 6rem
}

.pt-13 {
  padding-top: 6.5rem
}

.pr-13 {
  padding-right: 6.5rem
}

.pb-13 {
  padding-bottom: 6.5rem
}

.pl-13 {
  padding-left: 6.5rem
}

.pt-14 {
  padding-top: 7rem
}

.pr-14 {
  padding-right: 7rem
}

.pb-14 {
  padding-bottom: 7rem
}

.pl-14 {
  padding-left: 7rem
}

.pt-15 {
  padding-top: 7.5rem
}

.pr-15 {
  padding-right: 7.5rem
}

.pb-15 {
  padding-bottom: 7.5rem
}

.pl-15 {
  padding-left: 7.5rem
}

.pt-16 {
  padding-top: 8rem
}

.pr-16 {
  padding-right: 8rem
}

.pb-16 {
  padding-bottom: 8rem
}

.pl-16 {
  padding-left: 8rem
}

.pt-17 {
  padding-top: 8.5rem
}

.pr-17 {
  padding-right: 8.5rem
}

.pb-17 {
  padding-bottom: 8.5rem
}

.pl-17 {
  padding-left: 8.5rem
}

.pt-19 {
  padding-top: 9rem
}

.pr-19 {
  padding-right: 9rem
}

.pb-19 {
  padding-bottom: 9rem
}

.pl-19 {
  padding-left: 9rem
}

.pt-20 {
  padding-top: 9.5rem
}

.pr-20 {
  padding-right: 9.5rem
}

.pb-20 {
  padding-bottom: 9.5rem
}

.pl-20 {
  padding-left: 9.5rem
}

.pt-22 {
  padding-top: 11rem
}

.pr-22 {
  padding-right: 11rem
}

.pb-22 {
  padding-bottom: 11rem
}

.pl-22 {
  padding-left: 11rem
}

.pt-24 {
  padding-top: 12rem
}

.pr-24 {
  padding-right: 12rem
}

.pb-24 {
  padding-bottom: 12rem
}

.pl-24 {
  padding-left: 12rem
}

.pt-32 {
  padding-top: 16rem
}

.pr-32 {
  padding-right: 16rem
}

.pb-32 {
  padding-bottom: 16rem
}

.pl-32 {
  padding-left: 16rem
}

.pt-40 {
  padding-top: 20rem
}

.pr-40 {
  padding-right: 20rem
}

.pb-40 {
  padding-bottom: 20rem
}

.pl-40 {
  padding-left: 20rem
}

.pt-px {
  padding-top: 1px
}

.pr-px {
  padding-right: 1px
}

.pb-px {
  padding-bottom: 1px
}

.pl-px {
  padding-left: 1px
}

.pt-half {
  padding-top: 0.25rem
}

.pr-half {
  padding-right: 0.25rem
}

.pb-half {
  padding-bottom: 0.25rem
}

.pl-half {
  padding-left: 0.25rem
}

.placeholder-transparent::placeholder {
  color: transparent
}

.placeholder-black::placeholder {
  color: @color-black
}

.placeholder-white::placeholder {
  color: @color-white
}

.placeholder-grey-10::placeholder {
  color: fade(@color-grey, 10%)
}

.placeholder-grey-30::placeholder {
  color: fade(@color-grey, 30%)
}

.placeholder-grey-40::placeholder {
  color: fade(@color-grey, 40%)
}

.placeholder-grey-60::placeholder {
  color: fade(@color-grey, 60%)
}

.placeholder-grey-90::placeholder {
  color: fade(@color-grey, 90%)
}

.placeholder-grey::placeholder {
  color: @color-grey
}

.placeholder-grey-metal::placeholder {
  color: @color-grey
}

.placeholder-grey-divider::placeholder {
  color: fade(@color-grey, 20%)
}

.placeholder-grey-light::placeholder {
  color: @color-grey-light
}

.placeholder-grey-header::placeholder {
  color: fade(@color-grey, 90%)
}

.placeholder-body::placeholder {
  color: @color-body
}

.placeholder-yellow::placeholder {
  color: @color-yellow
}

.placeholder-orange::placeholder {
  color: @color-orange
}

.placeholder-stone::placeholder {
  color: @color-stone
}

.placeholder-khaki::placeholder {
  color: @color-khaki
}

.placeholder-green::placeholder {
  color: @color-green
}

.placeholder-blue::placeholder {
  color: @color-blue
}

.placeholder-red::placeholder {
  color: @color-red
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent
}

.focus\:placeholder-black:focus::placeholder {
  color: @color-black
}

.focus\:placeholder-white:focus::placeholder {
  color: @color-white
}

.focus\:placeholder-grey-10:focus::placeholder {
  color: fade(@color-grey, 10%)
}

.focus\:placeholder-grey-30:focus::placeholder {
  color: fade(@color-grey, 30%)
}

.focus\:placeholder-grey-40:focus::placeholder {
  color: fade(@color-grey, 40%)
}

.focus\:placeholder-grey-60:focus::placeholder {
  color: fade(@color-grey, 60%)
}

.focus\:placeholder-grey-90:focus::placeholder {
  color: fade(@color-grey, 90%)
}

.focus\:placeholder-grey:focus::placeholder {
  color: @color-grey
}

.focus\:placeholder-grey-metal:focus::placeholder {
  color: @color-grey
}

.focus\:placeholder-grey-divider:focus::placeholder {
  color: fade(@color-grey, 20%)
}

.focus\:placeholder-grey-light:focus::placeholder {
  color: @color-grey-light
}

.focus\:placeholder-grey-header:focus::placeholder {
  color: fade(@color-grey, 90%)
}

.focus\:placeholder-body:focus::placeholder {
  color: @color-body
}

.focus\:placeholder-yellow:focus::placeholder {
  color: @color-yellow
}

.focus\:placeholder-orange:focus::placeholder {
  color: @color-orange
}

.focus\:placeholder-stone:focus::placeholder {
  color: @color-stone
}

.focus\:placeholder-khaki:focus::placeholder {
  color: @color-khaki
}

.focus\:placeholder-green:focus::placeholder {
  color: @color-green
}

.focus\:placeholder-blue:focus::placeholder {
  color: @color-blue
}

.focus\:placeholder-red:focus::placeholder {
  color: @color-red
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.inset-y-auto {
  top: auto;
  bottom: auto
}

.inset-x-auto {
  right: auto;
  left: auto
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.top-auto {
  top: auto
}

.right-auto {
  right: auto
}

.bottom-auto {
  bottom: auto
}

.left-auto {
  left: auto
}

.resize-none {
  resize: none
}

.resize-y {
  resize: vertical
}

.resize-x {
  resize: horizontal
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.shadow-none {
  box-shadow: none
}

.hover\:shadow:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.hover\:shadow-none:hover {
  box-shadow: none
}

.focus\:shadow:focus {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.focus\:shadow-none:focus {
  box-shadow: none
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.table-auto {
  table-layout: auto
}

.table-fixed {
  table-layout: fixed
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-transparent {
  color: transparent
}

.text-black {
  color: @color-black
}

.text-white {
  color: @color-white
}

.text-grey-10 {
  color: fade(@color-grey, 10%)
}

.text-grey-30 {
  color: fade(@color-grey, 30%)
}

.text-grey-40 {
  color: fade(@color-grey, 40%)
}

.text-grey-60 {
  color: fade(@color-grey, 60%)
}

.text-grey-90 {
  color: fade(@color-grey, 90%)
}

.text-grey {
  color: @color-grey
}

.text-grey-metal {
  color: @color-grey
}

.text-grey-divider {
  color: fade(@color-grey, 20%)
}

.text-grey-light {
  color: @color-grey-light
}

.text-grey-header {
  color: fade(@color-grey, 90%)
}

.text-body {
  color: @color-body
}

.text-yellow {
  color: @color-yellow
}

.text-orange {
  color: @color-orange
}

.text-stone {
  color: @color-stone
}

.text-khaki {
  color: @color-khaki
}

.text-green {
  color: @color-green
}

.text-blue {
  color: @color-blue
}

.text-red {
  color: @color-red
}

.hover\:text-transparent:hover {
  color: transparent
}

.hover\:text-black:hover {
  color: @color-black
}

.hover\:text-white:hover {
  color: @color-white
}

.hover\:text-grey-10:hover {
  color: fade(@color-grey, 10%)
}

.hover\:text-grey-30:hover {
  color: fade(@color-grey, 30%)
}

.hover\:text-grey-40:hover {
  color: fade(@color-grey, 40%)
}

.hover\:text-grey-60:hover {
  color: fade(@color-grey, 60%)
}

.hover\:text-grey-90:hover {
  color: fade(@color-grey, 90%)
}

.hover\:text-grey:hover {
  color: @color-grey
}

.hover\:text-grey-metal:hover {
  color: @color-grey
}

.hover\:text-grey-divider:hover {
  color: fade(@color-grey, 20%)
}

.hover\:text-grey-light:hover {
  color: @color-grey-light
}

.hover\:text-grey-header:hover {
  color: fade(@color-grey, 90%)
}

.hover\:text-body:hover {
  color: @color-body
}

.hover\:text-yellow:hover {
  color: @color-yellow
}

.hover\:text-orange:hover {
  color: @color-orange
}

.hover\:text-stone:hover {
  color: @color-stone
}

.hover\:text-khaki:hover {
  color: @color-khaki
}

.hover\:text-green:hover {
  color: @color-green
}

.hover\:text-blue:hover {
  color: @color-blue
}

.hover\:text-red:hover {
  color: @color-red
}

.focus\:text-transparent:focus {
  color: transparent
}

.focus\:text-black:focus {
  color: @color-black
}

.focus\:text-white:focus {
  color: @color-white
}

.focus\:text-grey-10:focus {
  color: fade(@color-grey, 10%)
}

.focus\:text-grey-30:focus {
  color: fade(@color-grey, 30%)
}

.focus\:text-grey-40:focus {
  color: fade(@color-grey, 40%)
}

.focus\:text-grey-60:focus {
  color: fade(@color-grey, 60%)
}

.focus\:text-grey-90:focus {
  color: fade(@color-grey, 90%)
}

.focus\:text-grey:focus {
  color: @color-grey
}

.focus\:text-grey-metal:focus {
  color: @color-grey
}

.focus\:text-grey-divider:focus {
  color: fade(@color-grey, 20%)
}

.focus\:text-grey-light:focus {
  color: @color-grey-light
}

.focus\:text-grey-header:focus {
  color: fade(@color-grey, 90%)
}

.focus\:text-body:focus {
  color: @color-body
}

.focus\:text-yellow:focus {
  color: @color-yellow
}

.focus\:text-orange:focus {
  color: @color-orange
}

.focus\:text-stone:focus {
  color: @color-stone
}

.focus\:text-khaki:focus {
  color: @color-khaki
}

.focus\:text-green:focus {
  color: @color-green
}

.focus\:text-blue:focus {
  color: @color-blue
}

.focus\:text-red:focus {
  color: @color-red
}

.text-base {
  font-size: .6875rem
}

.text-md {
  font-size: 0.9375rem
}

.text-lg {
  font-size: 1rem
}

.text-xl {
  font-size: 1.375rem
}

.text-2xl {
  font-size: 2.0625rem
}

.italic {
  font-style: italic
}

.not-italic {
  font-style: normal
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.no-underline {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.hover\:line-through:hover {
  text-decoration: line-through
}

.hover\:no-underline:hover {
  text-decoration: none
}

.focus\:underline:focus {
  text-decoration: underline
}

.focus\:line-through:focus {
  text-decoration: line-through
}

.focus\:no-underline:focus {
  text-decoration: none
}

.tracking-tighter {
  letter-spacing: -0.05em
}

.tracking-tight {
  letter-spacing: -0.025em
}

.tracking-normal {
  letter-spacing: 0
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-widest {
  letter-spacing: 0.1em
}

.align-baseline {
  vertical-align: baseline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.align-text-top {
  vertical-align: text-top
}

.align-text-bottom {
  vertical-align: text-bottom
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-normal {
  white-space: normal
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.w-0 {
  width: 0
}

.w-1 {
  width: 0.5rem
}

.w-2 {
  width: 1rem
}

.w-3 {
  width: 1.5rem
}

.w-4 {
  width: 2rem
}

.w-5 {
  width: 2.5rem
}

.w-6 {
  width: 3rem
}

.w-7 {
  width: 3.5rem
}

.w-8 {
  width: 4rem
}

.w-9 {
  width: 4.5rem
}

.w-10 {
  width: 5rem
}

.w-11 {
  width: 5.5rem
}

.w-12 {
  width: 6rem
}

.w-13 {
  width: 6.5rem
}

.w-14 {
  width: 7rem
}

.w-15 {
  width: 7.5rem
}

.w-16 {
  width: 8rem
}

.w-17 {
  width: 8.5rem
}

.w-19 {
  width: 9rem
}

.w-20 {
  width: 9.5rem
}

.w-22 {
  width: 11rem
}

.w-24 {
  width: 12rem
}

.w-32 {
  width: 16rem
}

.w-40 {
  width: 20rem
}

.w-auto {
  width: auto
}

.w-px {
  width: 1px
}

.w-half {
  width: 0.25rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-3\/6 {
  width: 50%
}

.w-4\/6 {
  width: 66.666667%
}

.w-5\/6 {
  width: 83.333333%
}

.w-1\/12 {
  width: 8.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-3\/12 {
  width: 25%
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-9\/12 {
  width: 75%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-auto {
  z-index: auto
}

@media (min-width: @s-break) {
  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:bg-transparent {
    background-color: transparent
  }

  .sm\:bg-black {
    background-color: @color-black
  }

  .sm\:bg-white {
    background-color: @color-white
  }

  .sm\:bg-grey-10 {
    background-color: fade(@color-grey, 10%)
  }

  .sm\:bg-grey-30 {
    background-color: fade(@color-grey, 30%)
  }

  .sm\:bg-grey-40 {
    background-color: fade(@color-grey, 40%)
  }

  .sm\:bg-grey-60 {
    background-color: fade(@color-grey, 60%)
  }

  .sm\:bg-grey-90 {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:bg-grey {
    background-color: @color-grey
  }

  .sm\:bg-grey-metal {
    background-color: @color-grey
  }

  .sm\:bg-grey-divider {
    background-color: fade(@color-grey, 20%)
  }

  .sm\:bg-grey-light {
    background-color: @color-grey-light
  }

  .sm\:bg-grey-header {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:bg-body {
    background-color: @color-body
  }

  .sm\:bg-yellow {
    background-color: @color-yellow
  }

  .sm\:bg-orange {
    background-color: @color-orange
  }

  .sm\:bg-stone {
    background-color: @color-stone
  }

  .sm\:bg-khaki {
    background-color: @color-khaki
  }

  .sm\:bg-green {
    background-color: @color-green
  }

  .sm\:bg-blue {
    background-color: @color-blue
  }

  .sm\:bg-red {
    background-color: @color-red
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .sm\:hover\:bg-black:hover {
    background-color: @color-black
  }

  .sm\:hover\:bg-white:hover {
    background-color: @color-white
  }

  .sm\:hover\:bg-grey-10:hover {
    background-color: fade(@color-grey, 10%)
  }

  .sm\:hover\:bg-grey-30:hover {
    background-color: fade(@color-grey, 30%)
  }

  .sm\:hover\:bg-grey-40:hover {
    background-color: fade(@color-grey, 40%)
  }

  .sm\:hover\:bg-grey-60:hover {
    background-color: fade(@color-grey, 60%)
  }

  .sm\:hover\:bg-grey-90:hover {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:hover\:bg-grey:hover {
    background-color: @color-grey
  }

  .sm\:hover\:bg-grey-metal:hover {
    background-color: @color-grey
  }

  .sm\:hover\:bg-grey-divider:hover {
    background-color: fade(@color-grey, 20%)
  }

  .sm\:hover\:bg-grey-light:hover {
    background-color: @color-grey-light
  }

  .sm\:hover\:bg-grey-header:hover {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:hover\:bg-body:hover {
    background-color: @color-body
  }

  .sm\:hover\:bg-yellow:hover {
    background-color: @color-yellow
  }

  .sm\:hover\:bg-orange:hover {
    background-color: @color-orange
  }

  .sm\:hover\:bg-stone:hover {
    background-color: @color-stone
  }

  .sm\:hover\:bg-khaki:hover {
    background-color: @color-khaki
  }

  .sm\:hover\:bg-green:hover {
    background-color: @color-green
  }

  .sm\:hover\:bg-blue:hover {
    background-color: @color-blue
  }

  .sm\:hover\:bg-red:hover {
    background-color: @color-red
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .sm\:focus\:bg-black:focus {
    background-color: @color-black
  }

  .sm\:focus\:bg-white:focus {
    background-color: @color-white
  }

  .sm\:focus\:bg-grey-10:focus {
    background-color: fade(@color-grey, 10%)
  }

  .sm\:focus\:bg-grey-30:focus {
    background-color: fade(@color-grey, 30%)
  }

  .sm\:focus\:bg-grey-40:focus {
    background-color: fade(@color-grey, 40%)
  }

  .sm\:focus\:bg-grey-60:focus {
    background-color: fade(@color-grey, 60%)
  }

  .sm\:focus\:bg-grey-90:focus {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:focus\:bg-grey:focus {
    background-color: @color-grey
  }

  .sm\:focus\:bg-grey-metal:focus {
    background-color: @color-grey
  }

  .sm\:focus\:bg-grey-divider:focus {
    background-color: fade(@color-grey, 20%)
  }

  .sm\:focus\:bg-grey-light:focus {
    background-color: @color-grey-light
  }

  .sm\:focus\:bg-grey-header:focus {
    background-color: fade(@color-grey, 90%)
  }

  .sm\:focus\:bg-body:focus {
    background-color: @color-body
  }

  .sm\:focus\:bg-yellow:focus {
    background-color: @color-yellow
  }

  .sm\:focus\:bg-orange:focus {
    background-color: @color-orange
  }

  .sm\:focus\:bg-stone:focus {
    background-color: @color-stone
  }

  .sm\:focus\:bg-khaki:focus {
    background-color: @color-khaki
  }

  .sm\:focus\:bg-green:focus {
    background-color: @color-green
  }

  .sm\:focus\:bg-blue:focus {
    background-color: @color-blue
  }

  .sm\:focus\:bg-red:focus {
    background-color: @color-red
  }

  .sm\:bg-bottom {
    background-position: bottom
  }

  .sm\:bg-center {
    background-position: center
  }

  .sm\:bg-left {
    background-position: left
  }

  .sm\:bg-left-bottom {
    background-position: left bottom
  }

  .sm\:bg-left-top {
    background-position: left top
  }

  .sm\:bg-right {
    background-position: right
  }

  .sm\:bg-right-bottom {
    background-position: right bottom
  }

  .sm\:bg-right-top {
    background-position: right top
  }

  .sm\:bg-top {
    background-position: top
  }

  .sm\:bg-repeat {
    background-repeat: repeat
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .sm\:bg-repeat-round {
    background-repeat: round
  }

  .sm\:bg-repeat-space {
    background-repeat: space
  }

  .sm\:bg-auto {
    background-size: auto
  }

  .sm\:bg-cover {
    background-size: cover
  }

  .sm\:bg-contain {
    background-size: contain
  }

  .sm\:border-collapse {
    border-collapse: collapse
  }

  .sm\:border-separate {
    border-collapse: separate
  }

  .sm\:border-transparent {
    border-color: transparent
  }

  .sm\:border-black {
    border-color: @color-black
  }

  .sm\:border-white {
    border-color: @color-white
  }

  .sm\:border-grey-10 {
    border-color: fade(@color-grey, 10%)
  }

  .sm\:border-grey-30 {
    border-color: fade(@color-grey, 30%)
  }

  .sm\:border-grey-40 {
    border-color: fade(@color-grey, 40%)
  }

  .sm\:border-grey-60 {
    border-color: fade(@color-grey, 60%)
  }

  .sm\:border-grey-90 {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:border-grey {
    border-color: @color-grey
  }

  .sm\:border-grey-metal {
    border-color: @color-grey
  }

  .sm\:border-grey-divider {
    border-color: fade(@color-grey, 20%)
  }

  .sm\:border-grey-light {
    border-color: @color-grey-light
  }

  .sm\:border-grey-header {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:border-body {
    border-color: @color-body
  }

  .sm\:border-yellow {
    border-color: @color-yellow
  }

  .sm\:border-orange {
    border-color: @color-orange
  }

  .sm\:border-stone {
    border-color: @color-stone
  }

  .sm\:border-khaki {
    border-color: @color-khaki
  }

  .sm\:border-green {
    border-color: @color-green
  }

  .sm\:border-blue {
    border-color: @color-blue
  }

  .sm\:border-red {
    border-color: @color-red
  }

  .sm\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .sm\:hover\:border-black:hover {
    border-color: @color-black
  }

  .sm\:hover\:border-white:hover {
    border-color: @color-white
  }

  .sm\:hover\:border-grey-10:hover {
    border-color: fade(@color-grey, 10%)
  }

  .sm\:hover\:border-grey-30:hover {
    border-color: fade(@color-grey, 30%)
  }

  .sm\:hover\:border-grey-40:hover {
    border-color: fade(@color-grey, 40%)
  }

  .sm\:hover\:border-grey-60:hover {
    border-color: fade(@color-grey, 60%)
  }

  .sm\:hover\:border-grey-90:hover {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:hover\:border-grey:hover {
    border-color: @color-grey
  }

  .sm\:hover\:border-grey-metal:hover {
    border-color: @color-grey
  }

  .sm\:hover\:border-grey-divider:hover {
    border-color: fade(@color-grey, 20%)
  }

  .sm\:hover\:border-grey-light:hover {
    border-color: @color-grey-light
  }

  .sm\:hover\:border-grey-header:hover {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:hover\:border-body:hover {
    border-color: @color-body
  }

  .sm\:hover\:border-yellow:hover {
    border-color: @color-yellow
  }

  .sm\:hover\:border-orange:hover {
    border-color: @color-orange
  }

  .sm\:hover\:border-stone:hover {
    border-color: @color-stone
  }

  .sm\:hover\:border-khaki:hover {
    border-color: @color-khaki
  }

  .sm\:hover\:border-green:hover {
    border-color: @color-green
  }

  .sm\:hover\:border-blue:hover {
    border-color: @color-blue
  }

  .sm\:hover\:border-red:hover {
    border-color: @color-red
  }

  .sm\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .sm\:focus\:border-black:focus {
    border-color: @color-black
  }

  .sm\:focus\:border-white:focus {
    border-color: @color-white
  }

  .sm\:focus\:border-grey-10:focus {
    border-color: fade(@color-grey, 10%)
  }

  .sm\:focus\:border-grey-30:focus {
    border-color: fade(@color-grey, 30%)
  }

  .sm\:focus\:border-grey-40:focus {
    border-color: fade(@color-grey, 40%)
  }

  .sm\:focus\:border-grey-60:focus {
    border-color: fade(@color-grey, 60%)
  }

  .sm\:focus\:border-grey-90:focus {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:focus\:border-grey:focus {
    border-color: @color-grey
  }

  .sm\:focus\:border-grey-metal:focus {
    border-color: @color-grey
  }

  .sm\:focus\:border-grey-divider:focus {
    border-color: fade(@color-grey, 20%)
  }

  .sm\:focus\:border-grey-light:focus {
    border-color: @color-grey-light
  }

  .sm\:focus\:border-grey-header:focus {
    border-color: fade(@color-grey, 90%)
  }

  .sm\:focus\:border-body:focus {
    border-color: @color-body
  }

  .sm\:focus\:border-yellow:focus {
    border-color: @color-yellow
  }

  .sm\:focus\:border-orange:focus {
    border-color: @color-orange
  }

  .sm\:focus\:border-stone:focus {
    border-color: @color-stone
  }

  .sm\:focus\:border-khaki:focus {
    border-color: @color-khaki
  }

  .sm\:focus\:border-green:focus {
    border-color: @color-green
  }

  .sm\:focus\:border-blue:focus {
    border-color: @color-blue
  }

  .sm\:focus\:border-red:focus {
    border-color: @color-red
  }

  .sm\:rounded-none {
    border-radius: 0
  }

  .sm\:rounded {
    border-radius: .125rem
  }

  .sm\:rounded-lg {
    border-radius: .375rem
  }

  .sm\:rounded-full {
    border-radius: 20em
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-t {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem
  }

  .sm\:rounded-r {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
  }

  .sm\:rounded-b {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .sm\:rounded-l {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 20em;
    border-top-right-radius: 20em
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .sm\:rounded-tl {
    border-top-left-radius: .125rem
  }

  .sm\:rounded-tr {
    border-top-right-radius: .125rem
  }

  .sm\:rounded-br {
    border-bottom-right-radius: .125rem
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: .125rem
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: .375rem
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: .375rem
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: .375rem
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .375rem
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 20em
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 20em
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 20em
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 20em
  }

  .sm\:border-solid {
    border-style: solid
  }

  .sm\:border-dashed {
    border-style: dashed
  }

  .sm\:border-dotted {
    border-style: dotted
  }

  .sm\:border-double {
    border-style: double
  }

  .sm\:border-none {
    border-style: none
  }

  .sm\:border-0 {
    border-width: 0
  }

  .sm\:border-3 {
    border-width: .1875rem
  }

  .sm\:border-6 {
    border-width: .375rem
  }

  .sm\:border {
    border-width: 1px
  }

  .sm\:border-t-0 {
    border-top-width: 0
  }

  .sm\:border-r-0 {
    border-right-width: 0
  }

  .sm\:border-b-0 {
    border-bottom-width: 0
  }

  .sm\:border-l-0 {
    border-left-width: 0
  }

  .sm\:border-t-3 {
    border-top-width: .1875rem
  }

  .sm\:border-r-3 {
    border-right-width: .1875rem
  }

  .sm\:border-b-3 {
    border-bottom-width: .1875rem
  }

  .sm\:border-l-3 {
    border-left-width: .1875rem
  }

  .sm\:border-t-6 {
    border-top-width: .375rem
  }

  .sm\:border-r-6 {
    border-right-width: .375rem
  }

  .sm\:border-b-6 {
    border-bottom-width: .375rem
  }

  .sm\:border-l-6 {
    border-left-width: .375rem
  }

  .sm\:border-t {
    border-top-width: 1px
  }

  .sm\:border-r {
    border-right-width: 1px
  }

  .sm\:border-b {
    border-bottom-width: 1px
  }

  .sm\:border-l {
    border-left-width: 1px
  }

  .sm\:cursor-auto {
    cursor: auto
  }

  .sm\:cursor-default {
    cursor: default
  }

  .sm\:cursor-pointer {
    cursor: pointer
  }

  .sm\:cursor-wait {
    cursor: wait
  }

  .sm\:cursor-text {
    cursor: text
  }

  .sm\:cursor-move {
    cursor: move
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:flex {
    display: flex
  }

  .sm\:inline-flex {
    display: inline-flex
  }

  .sm\:table {
    display: table
  }

  .sm\:table-row {
    display: table-row
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .sm\:items-start {
    align-items: flex-start
  }

  .sm\:items-end {
    align-items: flex-end
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:items-baseline {
    align-items: baseline
  }

  .sm\:items-stretch {
    align-items: stretch
  }

  .sm\:self-auto {
    align-self: auto
  }

  .sm\:self-start {
    align-self: flex-start
  }

  .sm\:self-end {
    align-self: flex-end
  }

  .sm\:self-center {
    align-self: center
  }

  .sm\:self-stretch {
    align-self: stretch
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-center {
    justify-content: center
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:justify-around {
    justify-content: space-around
  }

  .sm\:content-center {
    align-content: center
  }

  .sm\:content-start {
    align-content: flex-start
  }

  .sm\:content-end {
    align-content: flex-end
  }

  .sm\:content-between {
    align-content: space-between
  }

  .sm\:content-around {
    align-content: space-around
  }

  .sm\:flex-1 {
    flex: 1 1 0%
  }

  .sm\:flex-auto {
    flex: 1 1 auto
  }

  .sm\:flex-initial {
    flex: 0 1 auto
  }

  .sm\:flex-none {
    flex: none
  }

  .sm\:flex-grow-0 {
    flex-grow: 0
  }

  .sm\:flex-grow {
    flex-grow: 1
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0
  }

  .sm\:flex-shrink {
    flex-shrink: 1
  }

  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:order-3 {
    order: 3
  }

  .sm\:order-4 {
    order: 4
  }

  .sm\:order-5 {
    order: 5
  }

  .sm\:order-6 {
    order: 6
  }

  .sm\:order-7 {
    order: 7
  }

  .sm\:order-8 {
    order: 8
  }

  .sm\:order-9 {
    order: 9
  }

  .sm\:order-10 {
    order: 10
  }

  .sm\:order-11 {
    order: 11
  }

  .sm\:order-12 {
    order: 12
  }

  .sm\:order-first {
    order: -9999
  }

  .sm\:order-last {
    order: 9999
  }

  .sm\:order-none {
    order: 0
  }

  .sm\:float-right {
    float: right
  }

  .sm\:float-left {
    float: left
  }

  .sm\:float-none {
    float: none
  }

  .sm\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .sm\:font-body {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
  }

  .sm\:font-normal {
    font-weight: 400
  }

  .sm\:font-semibold {
    font-weight: 600
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .sm\:h-0 {
    height: 0
  }

  .sm\:h-1 {
    height: 0.5rem
  }

  .sm\:h-2 {
    height: 1rem
  }

  .sm\:h-3 {
    height: 1.5rem
  }

  .sm\:h-4 {
    height: 2rem
  }

  .sm\:h-5 {
    height: 2.5rem
  }

  .sm\:h-6 {
    height: 3rem
  }

  .sm\:h-7 {
    height: 3.5rem
  }

  .sm\:h-8 {
    height: 4rem
  }

  .sm\:h-9 {
    height: 4.5rem
  }

  .sm\:h-10 {
    height: 5rem
  }

  .sm\:h-11 {
    height: 5.5rem
  }

  .sm\:h-12 {
    height: 6rem
  }

  .sm\:h-13 {
    height: 6.5rem
  }

  .sm\:h-14 {
    height: 7rem
  }

  .sm\:h-15 {
    height: 7.5rem
  }

  .sm\:h-16 {
    height: 8rem
  }

  .sm\:h-17 {
    height: 8.5rem
  }

  .sm\:h-19 {
    height: 9rem
  }

  .sm\:h-20 {
    height: 9.5rem
  }

  .sm\:h-22 {
    height: 11rem
  }

  .sm\:h-24 {
    height: 12rem
  }

  .sm\:h-32 {
    height: 16rem
  }

  .sm\:h-40 {
    height: 20rem
  }

  .sm\:h-auto {
    height: auto
  }

  .sm\:h-px {
    height: 1px
  }

  .sm\:h-half {
    height: 0.25rem
  }

  .sm\:h-full {
    height: 100%
  }

  .sm\:h-screen {
    height: 100vh
  }

  .sm\:leading-base {
    line-height: .75rem
  }

  .sm\:leading-sm {
    line-height: 1.125rem
  }

  .sm\:leading-lg {
    line-height: 1.5rem
  }

  .sm\:leading-xl {
    line-height: 1.875rem
  }

  .sm\:leading-2xl {
    line-height: 2.25rem
  }

  .sm\:list-none {
    list-style-type: none
  }

  .sm\:list-disc {
    list-style-type: disc
  }

  .sm\:list-decimal {
    list-style-type: decimal
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:m-1 {
    margin: 0.5rem
  }

  .sm\:m-2 {
    margin: 1rem
  }

  .sm\:m-3 {
    margin: 1.5rem
  }

  .sm\:m-4 {
    margin: 2rem
  }

  .sm\:m-5 {
    margin: 2.5rem
  }

  .sm\:m-6 {
    margin: 3rem
  }

  .sm\:m-7 {
    margin: 3.5rem
  }

  .sm\:m-8 {
    margin: 4rem
  }

  .sm\:m-9 {
    margin: 4.5rem
  }

  .sm\:m-10 {
    margin: 5rem
  }

  .sm\:m-11 {
    margin: 5.5rem
  }

  .sm\:m-12 {
    margin: 6rem
  }

  .sm\:m-13 {
    margin: 6.5rem
  }

  .sm\:m-14 {
    margin: 7rem
  }

  .sm\:m-15 {
    margin: 7.5rem
  }

  .sm\:m-16 {
    margin: 8rem
  }

  .sm\:m-17 {
    margin: 8.5rem
  }

  .sm\:m-19 {
    margin: 9rem
  }

  .sm\:m-20 {
    margin: 9.5rem
  }

  .sm\:m-22 {
    margin: 11rem
  }

  .sm\:m-24 {
    margin: 12rem
  }

  .sm\:m-32 {
    margin: 16rem
  }

  .sm\:m-40 {
    margin: 20rem
  }

  .sm\:m-auto {
    margin: auto
  }

  .sm\:m-px {
    margin: 1px
  }

  .sm\:m-half {
    margin: 0.25rem
  }

  .sm\:-m-1 {
    margin: -0.5rem
  }

  .sm\:-m-2 {
    margin: -1rem
  }

  .sm\:-m-3 {
    margin: -1.5rem
  }

  .sm\:-m-4 {
    margin: -2rem
  }

  .sm\:-m-5 {
    margin: -2.5rem
  }

  .sm\:-m-6 {
    margin: -3rem
  }

  .sm\:-m-7 {
    margin: -3.5rem
  }

  .sm\:-m-8 {
    margin: -4rem
  }

  .sm\:-m-9 {
    margin: -4.5rem
  }

  .sm\:-m-10 {
    margin: -5rem
  }

  .sm\:-m-11 {
    margin: -5.5rem
  }

  .sm\:-m-12 {
    margin: -6rem
  }

  .sm\:-m-13 {
    margin: -6.5rem
  }

  .sm\:-m-14 {
    margin: -7rem
  }

  .sm\:-m-15 {
    margin: -7.5rem
  }

  .sm\:-m-16 {
    margin: -8rem
  }

  .sm\:-m-17 {
    margin: -8.5rem
  }

  .sm\:-m-19 {
    margin: -9rem
  }

  .sm\:-m-20 {
    margin: -9.5rem
  }

  .sm\:-m-22 {
    margin: -11rem
  }

  .sm\:-m-24 {
    margin: -12rem
  }

  .sm\:-m-32 {
    margin: -16rem
  }

  .sm\:-m-40 {
    margin: -20rem
  }

  .sm\:-m-px {
    margin: -1px
  }

  .sm\:-m-half {
    margin: -0.25rem
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .sm\:my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .sm\:mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .sm\:my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm\:mx-2 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .sm\:my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .sm\:my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm\:mx-4 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .sm\:my-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .sm\:mx-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .sm\:my-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .sm\:mx-6 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .sm\:my-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .sm\:mx-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .sm\:my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm\:mx-8 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .sm\:my-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
  }

  .sm\:mx-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .sm\:my-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .sm\:mx-10 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .sm\:my-11 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem
  }

  .sm\:mx-11 {
    margin-left: 5.5rem;
    margin-right: 5.5rem
  }

  .sm\:my-12 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm\:mx-12 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .sm\:my-13 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem
  }

  .sm\:mx-13 {
    margin-left: 6.5rem;
    margin-right: 6.5rem
  }

  .sm\:my-14 {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .sm\:mx-14 {
    margin-left: 7rem;
    margin-right: 7rem
  }

  .sm\:my-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem
  }

  .sm\:mx-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem
  }

  .sm\:my-16 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm\:mx-16 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .sm\:my-17 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem
  }

  .sm\:mx-17 {
    margin-left: 8.5rem;
    margin-right: 8.5rem
  }

  .sm\:my-19 {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .sm\:mx-19 {
    margin-left: 9rem;
    margin-right: 9rem
  }

  .sm\:my-20 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem
  }

  .sm\:mx-20 {
    margin-left: 9.5rem;
    margin-right: 9.5rem
  }

  .sm\:my-22 {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .sm\:mx-22 {
    margin-left: 11rem;
    margin-right: 11rem
  }

  .sm\:my-24 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .sm\:mx-24 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .sm\:my-32 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .sm\:mx-32 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .sm\:my-40 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .sm\:mx-40 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .sm\:my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .sm\:mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .sm\:-my-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .sm\:-mx-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:-my-2 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .sm\:-mx-2 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .sm\:-my-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .sm\:-mx-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .sm\:-my-4 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .sm\:-mx-4 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .sm\:-my-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .sm\:-mx-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .sm\:-my-6 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .sm\:-mx-6 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .sm\:-my-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .sm\:-mx-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .sm\:-my-8 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .sm\:-mx-8 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .sm\:-my-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem
  }

  .sm\:-mx-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem
  }

  .sm\:-my-10 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .sm\:-mx-10 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .sm\:-my-11 {
    margin-top: -5.5rem;
    margin-bottom: -5.5rem
  }

  .sm\:-mx-11 {
    margin-left: -5.5rem;
    margin-right: -5.5rem
  }

  .sm\:-my-12 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .sm\:-mx-12 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .sm\:-my-13 {
    margin-top: -6.5rem;
    margin-bottom: -6.5rem
  }

  .sm\:-mx-13 {
    margin-left: -6.5rem;
    margin-right: -6.5rem
  }

  .sm\:-my-14 {
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  .sm\:-mx-14 {
    margin-left: -7rem;
    margin-right: -7rem
  }

  .sm\:-my-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem
  }

  .sm\:-mx-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem
  }

  .sm\:-my-16 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .sm\:-mx-16 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .sm\:-my-17 {
    margin-top: -8.5rem;
    margin-bottom: -8.5rem
  }

  .sm\:-mx-17 {
    margin-left: -8.5rem;
    margin-right: -8.5rem
  }

  .sm\:-my-19 {
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  .sm\:-mx-19 {
    margin-left: -9rem;
    margin-right: -9rem
  }

  .sm\:-my-20 {
    margin-top: -9.5rem;
    margin-bottom: -9.5rem
  }

  .sm\:-mx-20 {
    margin-left: -9.5rem;
    margin-right: -9.5rem
  }

  .sm\:-my-22 {
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  .sm\:-mx-22 {
    margin-left: -11rem;
    margin-right: -11rem
  }

  .sm\:-my-24 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .sm\:-mx-24 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .sm\:-my-32 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .sm\:-mx-32 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .sm\:-my-40 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .sm\:-mx-40 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .sm\:-my-half {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .sm\:-mx-half {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mr-0 {
    margin-right: 0
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:ml-0 {
    margin-left: 0
  }

  .sm\:mt-1 {
    margin-top: 0.5rem
  }

  .sm\:mr-1 {
    margin-right: 0.5rem
  }

  .sm\:mb-1 {
    margin-bottom: 0.5rem
  }

  .sm\:ml-1 {
    margin-left: 0.5rem
  }

  .sm\:mt-2 {
    margin-top: 1rem
  }

  .sm\:mr-2 {
    margin-right: 1rem
  }

  .sm\:mb-2 {
    margin-bottom: 1rem
  }

  .sm\:ml-2 {
    margin-left: 1rem
  }

  .sm\:mt-3 {
    margin-top: 1.5rem
  }

  .sm\:mr-3 {
    margin-right: 1.5rem
  }

  .sm\:mb-3 {
    margin-bottom: 1.5rem
  }

  .sm\:ml-3 {
    margin-left: 1.5rem
  }

  .sm\:mt-4 {
    margin-top: 2rem
  }

  .sm\:mr-4 {
    margin-right: 2rem
  }

  .sm\:mb-4 {
    margin-bottom: 2rem
  }

  .sm\:ml-4 {
    margin-left: 2rem
  }

  .sm\:mt-5 {
    margin-top: 2.5rem
  }

  .sm\:mr-5 {
    margin-right: 2.5rem
  }

  .sm\:mb-5 {
    margin-bottom: 2.5rem
  }

  .sm\:ml-5 {
    margin-left: 2.5rem
  }

  .sm\:mt-6 {
    margin-top: 3rem
  }

  .sm\:mr-6 {
    margin-right: 3rem
  }

  .sm\:mb-6 {
    margin-bottom: 3rem
  }

  .sm\:ml-6 {
    margin-left: 3rem
  }

  .sm\:mt-7 {
    margin-top: 3.5rem
  }

  .sm\:mr-7 {
    margin-right: 3.5rem
  }

  .sm\:mb-7 {
    margin-bottom: 3.5rem
  }

  .sm\:ml-7 {
    margin-left: 3.5rem
  }

  .sm\:mt-8 {
    margin-top: 4rem
  }

  .sm\:mr-8 {
    margin-right: 4rem
  }

  .sm\:mb-8 {
    margin-bottom: 4rem
  }

  .sm\:ml-8 {
    margin-left: 4rem
  }

  .sm\:mt-9 {
    margin-top: 4.5rem
  }

  .sm\:mr-9 {
    margin-right: 4.5rem
  }

  .sm\:mb-9 {
    margin-bottom: 4.5rem
  }

  .sm\:ml-9 {
    margin-left: 4.5rem
  }

  .sm\:mt-10 {
    margin-top: 5rem
  }

  .sm\:mr-10 {
    margin-right: 5rem
  }

  .sm\:mb-10 {
    margin-bottom: 5rem
  }

  .sm\:ml-10 {
    margin-left: 5rem
  }

  .sm\:mt-11 {
    margin-top: 5.5rem
  }

  .sm\:mr-11 {
    margin-right: 5.5rem
  }

  .sm\:mb-11 {
    margin-bottom: 5.5rem
  }

  .sm\:ml-11 {
    margin-left: 5.5rem
  }

  .sm\:mt-12 {
    margin-top: 6rem
  }

  .sm\:mr-12 {
    margin-right: 6rem
  }

  .sm\:mb-12 {
    margin-bottom: 6rem
  }

  .sm\:ml-12 {
    margin-left: 6rem
  }

  .sm\:mt-13 {
    margin-top: 6.5rem
  }

  .sm\:mr-13 {
    margin-right: 6.5rem
  }

  .sm\:mb-13 {
    margin-bottom: 6.5rem
  }

  .sm\:ml-13 {
    margin-left: 6.5rem
  }

  .sm\:mt-14 {
    margin-top: 7rem
  }

  .sm\:mr-14 {
    margin-right: 7rem
  }

  .sm\:mb-14 {
    margin-bottom: 7rem
  }

  .sm\:ml-14 {
    margin-left: 7rem
  }

  .sm\:mt-15 {
    margin-top: 7.5rem
  }

  .sm\:mr-15 {
    margin-right: 7.5rem
  }

  .sm\:mb-15 {
    margin-bottom: 7.5rem
  }

  .sm\:ml-15 {
    margin-left: 7.5rem
  }

  .sm\:mt-16 {
    margin-top: 8rem
  }

  .sm\:mr-16 {
    margin-right: 8rem
  }

  .sm\:mb-16 {
    margin-bottom: 8rem
  }

  .sm\:ml-16 {
    margin-left: 8rem
  }

  .sm\:mt-17 {
    margin-top: 8.5rem
  }

  .sm\:mr-17 {
    margin-right: 8.5rem
  }

  .sm\:mb-17 {
    margin-bottom: 8.5rem
  }

  .sm\:ml-17 {
    margin-left: 8.5rem
  }

  .sm\:mt-19 {
    margin-top: 9rem
  }

  .sm\:mr-19 {
    margin-right: 9rem
  }

  .sm\:mb-19 {
    margin-bottom: 9rem
  }

  .sm\:ml-19 {
    margin-left: 9rem
  }

  .sm\:mt-20 {
    margin-top: 9.5rem
  }

  .sm\:mr-20 {
    margin-right: 9.5rem
  }

  .sm\:mb-20 {
    margin-bottom: 9.5rem
  }

  .sm\:ml-20 {
    margin-left: 9.5rem
  }

  .sm\:mt-22 {
    margin-top: 11rem
  }

  .sm\:mr-22 {
    margin-right: 11rem
  }

  .sm\:mb-22 {
    margin-bottom: 11rem
  }

  .sm\:ml-22 {
    margin-left: 11rem
  }

  .sm\:mt-24 {
    margin-top: 12rem
  }

  .sm\:mr-24 {
    margin-right: 12rem
  }

  .sm\:mb-24 {
    margin-bottom: 12rem
  }

  .sm\:ml-24 {
    margin-left: 12rem
  }

  .sm\:mt-32 {
    margin-top: 16rem
  }

  .sm\:mr-32 {
    margin-right: 16rem
  }

  .sm\:mb-32 {
    margin-bottom: 16rem
  }

  .sm\:ml-32 {
    margin-left: 16rem
  }

  .sm\:mt-40 {
    margin-top: 20rem
  }

  .sm\:mr-40 {
    margin-right: 20rem
  }

  .sm\:mb-40 {
    margin-bottom: 20rem
  }

  .sm\:ml-40 {
    margin-left: 20rem
  }

  .sm\:mt-auto {
    margin-top: auto
  }

  .sm\:mr-auto {
    margin-right: auto
  }

  .sm\:mb-auto {
    margin-bottom: auto
  }

  .sm\:ml-auto {
    margin-left: auto
  }

  .sm\:mt-px {
    margin-top: 1px
  }

  .sm\:mr-px {
    margin-right: 1px
  }

  .sm\:mb-px {
    margin-bottom: 1px
  }

  .sm\:ml-px {
    margin-left: 1px
  }

  .sm\:mt-half {
    margin-top: 0.25rem
  }

  .sm\:mr-half {
    margin-right: 0.25rem
  }

  .sm\:mb-half {
    margin-bottom: 0.25rem
  }

  .sm\:ml-half {
    margin-left: 0.25rem
  }

  .sm\:-mt-1 {
    margin-top: -0.5rem
  }

  .sm\:-mr-1 {
    margin-right: -0.5rem
  }

  .sm\:-mb-1 {
    margin-bottom: -0.5rem
  }

  .sm\:-ml-1 {
    margin-left: -0.5rem
  }

  .sm\:-mt-2 {
    margin-top: -1rem
  }

  .sm\:-mr-2 {
    margin-right: -1rem
  }

  .sm\:-mb-2 {
    margin-bottom: -1rem
  }

  .sm\:-ml-2 {
    margin-left: -1rem
  }

  .sm\:-mt-3 {
    margin-top: -1.5rem
  }

  .sm\:-mr-3 {
    margin-right: -1.5rem
  }

  .sm\:-mb-3 {
    margin-bottom: -1.5rem
  }

  .sm\:-ml-3 {
    margin-left: -1.5rem
  }

  .sm\:-mt-4 {
    margin-top: -2rem
  }

  .sm\:-mr-4 {
    margin-right: -2rem
  }

  .sm\:-mb-4 {
    margin-bottom: -2rem
  }

  .sm\:-ml-4 {
    margin-left: -2rem
  }

  .sm\:-mt-5 {
    margin-top: -2.5rem
  }

  .sm\:-mr-5 {
    margin-right: -2.5rem
  }

  .sm\:-mb-5 {
    margin-bottom: -2.5rem
  }

  .sm\:-ml-5 {
    margin-left: -2.5rem
  }

  .sm\:-mt-6 {
    margin-top: -3rem
  }

  .sm\:-mr-6 {
    margin-right: -3rem
  }

  .sm\:-mb-6 {
    margin-bottom: -3rem
  }

  .sm\:-ml-6 {
    margin-left: -3rem
  }

  .sm\:-mt-7 {
    margin-top: -3.5rem
  }

  .sm\:-mr-7 {
    margin-right: -3.5rem
  }

  .sm\:-mb-7 {
    margin-bottom: -3.5rem
  }

  .sm\:-ml-7 {
    margin-left: -3.5rem
  }

  .sm\:-mt-8 {
    margin-top: -4rem
  }

  .sm\:-mr-8 {
    margin-right: -4rem
  }

  .sm\:-mb-8 {
    margin-bottom: -4rem
  }

  .sm\:-ml-8 {
    margin-left: -4rem
  }

  .sm\:-mt-9 {
    margin-top: -4.5rem
  }

  .sm\:-mr-9 {
    margin-right: -4.5rem
  }

  .sm\:-mb-9 {
    margin-bottom: -4.5rem
  }

  .sm\:-ml-9 {
    margin-left: -4.5rem
  }

  .sm\:-mt-10 {
    margin-top: -5rem
  }

  .sm\:-mr-10 {
    margin-right: -5rem
  }

  .sm\:-mb-10 {
    margin-bottom: -5rem
  }

  .sm\:-ml-10 {
    margin-left: -5rem
  }

  .sm\:-mt-11 {
    margin-top: -5.5rem
  }

  .sm\:-mr-11 {
    margin-right: -5.5rem
  }

  .sm\:-mb-11 {
    margin-bottom: -5.5rem
  }

  .sm\:-ml-11 {
    margin-left: -5.5rem
  }

  .sm\:-mt-12 {
    margin-top: -6rem
  }

  .sm\:-mr-12 {
    margin-right: -6rem
  }

  .sm\:-mb-12 {
    margin-bottom: -6rem
  }

  .sm\:-ml-12 {
    margin-left: -6rem
  }

  .sm\:-mt-13 {
    margin-top: -6.5rem
  }

  .sm\:-mr-13 {
    margin-right: -6.5rem
  }

  .sm\:-mb-13 {
    margin-bottom: -6.5rem
  }

  .sm\:-ml-13 {
    margin-left: -6.5rem
  }

  .sm\:-mt-14 {
    margin-top: -7rem
  }

  .sm\:-mr-14 {
    margin-right: -7rem
  }

  .sm\:-mb-14 {
    margin-bottom: -7rem
  }

  .sm\:-ml-14 {
    margin-left: -7rem
  }

  .sm\:-mt-15 {
    margin-top: -7.5rem
  }

  .sm\:-mr-15 {
    margin-right: -7.5rem
  }

  .sm\:-mb-15 {
    margin-bottom: -7.5rem
  }

  .sm\:-ml-15 {
    margin-left: -7.5rem
  }

  .sm\:-mt-16 {
    margin-top: -8rem
  }

  .sm\:-mr-16 {
    margin-right: -8rem
  }

  .sm\:-mb-16 {
    margin-bottom: -8rem
  }

  .sm\:-ml-16 {
    margin-left: -8rem
  }

  .sm\:-mt-17 {
    margin-top: -8.5rem
  }

  .sm\:-mr-17 {
    margin-right: -8.5rem
  }

  .sm\:-mb-17 {
    margin-bottom: -8.5rem
  }

  .sm\:-ml-17 {
    margin-left: -8.5rem
  }

  .sm\:-mt-19 {
    margin-top: -9rem
  }

  .sm\:-mr-19 {
    margin-right: -9rem
  }

  .sm\:-mb-19 {
    margin-bottom: -9rem
  }

  .sm\:-ml-19 {
    margin-left: -9rem
  }

  .sm\:-mt-20 {
    margin-top: -9.5rem
  }

  .sm\:-mr-20 {
    margin-right: -9.5rem
  }

  .sm\:-mb-20 {
    margin-bottom: -9.5rem
  }

  .sm\:-ml-20 {
    margin-left: -9.5rem
  }

  .sm\:-mt-22 {
    margin-top: -11rem
  }

  .sm\:-mr-22 {
    margin-right: -11rem
  }

  .sm\:-mb-22 {
    margin-bottom: -11rem
  }

  .sm\:-ml-22 {
    margin-left: -11rem
  }

  .sm\:-mt-24 {
    margin-top: -12rem
  }

  .sm\:-mr-24 {
    margin-right: -12rem
  }

  .sm\:-mb-24 {
    margin-bottom: -12rem
  }

  .sm\:-ml-24 {
    margin-left: -12rem
  }

  .sm\:-mt-32 {
    margin-top: -16rem
  }

  .sm\:-mr-32 {
    margin-right: -16rem
  }

  .sm\:-mb-32 {
    margin-bottom: -16rem
  }

  .sm\:-ml-32 {
    margin-left: -16rem
  }

  .sm\:-mt-40 {
    margin-top: -20rem
  }

  .sm\:-mr-40 {
    margin-right: -20rem
  }

  .sm\:-mb-40 {
    margin-bottom: -20rem
  }

  .sm\:-ml-40 {
    margin-left: -20rem
  }

  .sm\:-mt-px {
    margin-top: -1px
  }

  .sm\:-mr-px {
    margin-right: -1px
  }

  .sm\:-mb-px {
    margin-bottom: -1px
  }

  .sm\:-ml-px {
    margin-left: -1px
  }

  .sm\:-mt-half {
    margin-top: -0.25rem
  }

  .sm\:-mr-half {
    margin-right: -0.25rem
  }

  .sm\:-mb-half {
    margin-bottom: -0.25rem
  }

  .sm\:-ml-half {
    margin-left: -0.25rem
  }

  .sm\:max-h-90 {
    max-height: 90%
  }

  .sm\:max-h-full {
    max-height: 100%
  }

  .sm\:max-h-screen {
    max-height: 100vh
  }

  .sm\:max-w-400 {
    max-width: 25rem
  }

  .sm\:max-w-500 {
    max-width: 31.25rem
  }

  .sm\:max-w-600 {
    max-width: 37.5rem
  }

  .sm\:max-w-700 {
    max-width: 43.75em
  }

  .sm\:max-w-800 {
    max-width: 50rem
  }

  .sm\:max-w-1000 {
    max-width: 62.5rem
  }

  .sm\:max-w-1200 {
    max-width: 75rem
  }

  .sm\:max-w-1280 {
    max-width: 80rem
  }

  .sm\:max-w-1400 {
    max-width: 87.5rem
  }

  .sm\:max-w-1600 {
    max-width: 100rem
  }

  .sm\:max-w-1800 {
    max-width: 112.5rem
  }

  .sm\:max-w-2000 {
    max-width: 125rem
  }

  .sm\:min-h-0 {
    min-height: 0
  }

  .sm\:min-h-full {
    min-height: 100%
  }

  .sm\:min-h-screen {
    min-height: 100vh
  }

  .sm\:min-w-0 {
    min-width: 0
  }

  .sm\:min-w-full {
    min-width: 100%
  }

  .sm\:object-contain {
    object-fit: contain
  }

  .sm\:object-cover {
    object-fit: cover
  }

  .sm\:object-fill {
    object-fit: fill
  }

  .sm\:object-none {
    object-fit: none
  }

  .sm\:object-scale-down {
    object-fit: scale-down
  }

  .sm\:object-bottom {
    object-position: bottom
  }

  .sm\:object-center {
    object-position: center
  }

  .sm\:object-left {
    object-position: left
  }

  .sm\:object-left-bottom {
    object-position: left bottom
  }

  .sm\:object-left-top {
    object-position: left top
  }

  .sm\:object-right {
    object-position: right
  }

  .sm\:object-right-bottom {
    object-position: right bottom
  }

  .sm\:object-right-top {
    object-position: right top
  }

  .sm\:object-top {
    object-position: top
  }

  .sm\:opacity-0 {
    opacity: 0
  }

  .sm\:opacity-20 {
    opacity: .2
  }

  .sm\:opacity-30 {
    opacity: .3
  }

  .sm\:opacity-40 {
    opacity: .4
  }

  .sm\:opacity-50 {
    opacity: .5
  }

  .sm\:opacity-60 {
    opacity: .6
  }

  .sm\:opacity-80 {
    opacity: .8
  }

  .sm\:opacity-100 {
    opacity: 1
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0
  }

  .sm\:hover\:opacity-20:hover {
    opacity: .2
  }

  .sm\:hover\:opacity-30:hover {
    opacity: .3
  }

  .sm\:hover\:opacity-40:hover {
    opacity: .4
  }

  .sm\:hover\:opacity-50:hover {
    opacity: .5
  }

  .sm\:hover\:opacity-60:hover {
    opacity: .6
  }

  .sm\:hover\:opacity-80:hover {
    opacity: .8
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0
  }

  .sm\:focus\:opacity-20:focus {
    opacity: .2
  }

  .sm\:focus\:opacity-30:focus {
    opacity: .3
  }

  .sm\:focus\:opacity-40:focus {
    opacity: .4
  }

  .sm\:focus\:opacity-50:focus {
    opacity: .5
  }

  .sm\:focus\:opacity-60:focus {
    opacity: .6
  }

  .sm\:focus\:opacity-80:focus {
    opacity: .8
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1
  }

  .sm\:outline-none {
    outline: 0
  }

  .sm\:focus\:outline-none:focus {
    outline: 0
  }

  .sm\:overflow-auto {
    overflow: auto
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:overflow-visible {
    overflow: visible
  }

  .sm\:overflow-scroll {
    overflow: scroll
  }

  .sm\:overflow-x-auto {
    overflow-x: auto
  }

  .sm\:overflow-y-auto {
    overflow-y: auto
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden
  }

  .sm\:overflow-x-visible {
    overflow-x: visible
  }

  .sm\:overflow-y-visible {
    overflow-y: visible
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .sm\:p-0 {
    padding: 0
  }

  .sm\:p-1 {
    padding: 0.5rem
  }

  .sm\:p-2 {
    padding: 1rem
  }

  .sm\:p-3 {
    padding: 1.5rem
  }

  .sm\:p-4 {
    padding: 2rem
  }

  .sm\:p-5 {
    padding: 2.5rem
  }

  .sm\:p-6 {
    padding: 3rem
  }

  .sm\:p-7 {
    padding: 3.5rem
  }

  .sm\:p-8 {
    padding: 4rem
  }

  .sm\:p-9 {
    padding: 4.5rem
  }

  .sm\:p-10 {
    padding: 5rem
  }

  .sm\:p-11 {
    padding: 5.5rem
  }

  .sm\:p-12 {
    padding: 6rem
  }

  .sm\:p-13 {
    padding: 6.5rem
  }

  .sm\:p-14 {
    padding: 7rem
  }

  .sm\:p-15 {
    padding: 7.5rem
  }

  .sm\:p-16 {
    padding: 8rem
  }

  .sm\:p-17 {
    padding: 8.5rem
  }

  .sm\:p-19 {
    padding: 9rem
  }

  .sm\:p-20 {
    padding: 9.5rem
  }

  .sm\:p-22 {
    padding: 11rem
  }

  .sm\:p-24 {
    padding: 12rem
  }

  .sm\:p-32 {
    padding: 16rem
  }

  .sm\:p-40 {
    padding: 20rem
  }

  .sm\:p-px {
    padding: 1px
  }

  .sm\:p-half {
    padding: 0.25rem
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .sm\:px-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .sm\:px-2 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .sm\:py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .sm\:px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm\:px-4 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:py-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .sm\:px-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:py-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .sm\:px-6 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .sm\:py-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .sm\:px-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .sm\:py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm\:px-8 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .sm\:py-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
  }

  .sm\:px-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
  }

  .sm\:py-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .sm\:px-10 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .sm\:py-11 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem
  }

  .sm\:px-11 {
    padding-left: 5.5rem;
    padding-right: 5.5rem
  }

  .sm\:py-12 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:px-12 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .sm\:py-13 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem
  }

  .sm\:px-13 {
    padding-left: 6.5rem;
    padding-right: 6.5rem
  }

  .sm\:py-14 {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .sm\:px-14 {
    padding-left: 7rem;
    padding-right: 7rem
  }

  .sm\:py-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
  }

  .sm\:px-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .sm\:py-16 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:px-16 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .sm\:py-17 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem
  }

  .sm\:px-17 {
    padding-left: 8.5rem;
    padding-right: 8.5rem
  }

  .sm\:py-19 {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .sm\:px-19 {
    padding-left: 9rem;
    padding-right: 9rem
  }

  .sm\:py-20 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem
  }

  .sm\:px-20 {
    padding-left: 9.5rem;
    padding-right: 9.5rem
  }

  .sm\:py-22 {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .sm\:px-22 {
    padding-left: 11rem;
    padding-right: 11rem
  }

  .sm\:py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .sm\:px-24 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .sm\:py-32 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .sm\:px-32 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .sm\:py-40 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .sm\:px-40 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .sm\:py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pl-0 {
    padding-left: 0
  }

  .sm\:pt-1 {
    padding-top: 0.5rem
  }

  .sm\:pr-1 {
    padding-right: 0.5rem
  }

  .sm\:pb-1 {
    padding-bottom: 0.5rem
  }

  .sm\:pl-1 {
    padding-left: 0.5rem
  }

  .sm\:pt-2 {
    padding-top: 1rem
  }

  .sm\:pr-2 {
    padding-right: 1rem
  }

  .sm\:pb-2 {
    padding-bottom: 1rem
  }

  .sm\:pl-2 {
    padding-left: 1rem
  }

  .sm\:pt-3 {
    padding-top: 1.5rem
  }

  .sm\:pr-3 {
    padding-right: 1.5rem
  }

  .sm\:pb-3 {
    padding-bottom: 1.5rem
  }

  .sm\:pl-3 {
    padding-left: 1.5rem
  }

  .sm\:pt-4 {
    padding-top: 2rem
  }

  .sm\:pr-4 {
    padding-right: 2rem
  }

  .sm\:pb-4 {
    padding-bottom: 2rem
  }

  .sm\:pl-4 {
    padding-left: 2rem
  }

  .sm\:pt-5 {
    padding-top: 2.5rem
  }

  .sm\:pr-5 {
    padding-right: 2.5rem
  }

  .sm\:pb-5 {
    padding-bottom: 2.5rem
  }

  .sm\:pl-5 {
    padding-left: 2.5rem
  }

  .sm\:pt-6 {
    padding-top: 3rem
  }

  .sm\:pr-6 {
    padding-right: 3rem
  }

  .sm\:pb-6 {
    padding-bottom: 3rem
  }

  .sm\:pl-6 {
    padding-left: 3rem
  }

  .sm\:pt-7 {
    padding-top: 3.5rem
  }

  .sm\:pr-7 {
    padding-right: 3.5rem
  }

  .sm\:pb-7 {
    padding-bottom: 3.5rem
  }

  .sm\:pl-7 {
    padding-left: 3.5rem
  }

  .sm\:pt-8 {
    padding-top: 4rem
  }

  .sm\:pr-8 {
    padding-right: 4rem
  }

  .sm\:pb-8 {
    padding-bottom: 4rem
  }

  .sm\:pl-8 {
    padding-left: 4rem
  }

  .sm\:pt-9 {
    padding-top: 4.5rem
  }

  .sm\:pr-9 {
    padding-right: 4.5rem
  }

  .sm\:pb-9 {
    padding-bottom: 4.5rem
  }

  .sm\:pl-9 {
    padding-left: 4.5rem
  }

  .sm\:pt-10 {
    padding-top: 5rem
  }

  .sm\:pr-10 {
    padding-right: 5rem
  }

  .sm\:pb-10 {
    padding-bottom: 5rem
  }

  .sm\:pl-10 {
    padding-left: 5rem
  }

  .sm\:pt-11 {
    padding-top: 5.5rem
  }

  .sm\:pr-11 {
    padding-right: 5.5rem
  }

  .sm\:pb-11 {
    padding-bottom: 5.5rem
  }

  .sm\:pl-11 {
    padding-left: 5.5rem
  }

  .sm\:pt-12 {
    padding-top: 6rem
  }

  .sm\:pr-12 {
    padding-right: 6rem
  }

  .sm\:pb-12 {
    padding-bottom: 6rem
  }

  .sm\:pl-12 {
    padding-left: 6rem
  }

  .sm\:pt-13 {
    padding-top: 6.5rem
  }

  .sm\:pr-13 {
    padding-right: 6.5rem
  }

  .sm\:pb-13 {
    padding-bottom: 6.5rem
  }

  .sm\:pl-13 {
    padding-left: 6.5rem
  }

  .sm\:pt-14 {
    padding-top: 7rem
  }

  .sm\:pr-14 {
    padding-right: 7rem
  }

  .sm\:pb-14 {
    padding-bottom: 7rem
  }

  .sm\:pl-14 {
    padding-left: 7rem
  }

  .sm\:pt-15 {
    padding-top: 7.5rem
  }

  .sm\:pr-15 {
    padding-right: 7.5rem
  }

  .sm\:pb-15 {
    padding-bottom: 7.5rem
  }

  .sm\:pl-15 {
    padding-left: 7.5rem
  }

  .sm\:pt-16 {
    padding-top: 8rem
  }

  .sm\:pr-16 {
    padding-right: 8rem
  }

  .sm\:pb-16 {
    padding-bottom: 8rem
  }

  .sm\:pl-16 {
    padding-left: 8rem
  }

  .sm\:pt-17 {
    padding-top: 8.5rem
  }

  .sm\:pr-17 {
    padding-right: 8.5rem
  }

  .sm\:pb-17 {
    padding-bottom: 8.5rem
  }

  .sm\:pl-17 {
    padding-left: 8.5rem
  }

  .sm\:pt-19 {
    padding-top: 9rem
  }

  .sm\:pr-19 {
    padding-right: 9rem
  }

  .sm\:pb-19 {
    padding-bottom: 9rem
  }

  .sm\:pl-19 {
    padding-left: 9rem
  }

  .sm\:pt-20 {
    padding-top: 9.5rem
  }

  .sm\:pr-20 {
    padding-right: 9.5rem
  }

  .sm\:pb-20 {
    padding-bottom: 9.5rem
  }

  .sm\:pl-20 {
    padding-left: 9.5rem
  }

  .sm\:pt-22 {
    padding-top: 11rem
  }

  .sm\:pr-22 {
    padding-right: 11rem
  }

  .sm\:pb-22 {
    padding-bottom: 11rem
  }

  .sm\:pl-22 {
    padding-left: 11rem
  }

  .sm\:pt-24 {
    padding-top: 12rem
  }

  .sm\:pr-24 {
    padding-right: 12rem
  }

  .sm\:pb-24 {
    padding-bottom: 12rem
  }

  .sm\:pl-24 {
    padding-left: 12rem
  }

  .sm\:pt-32 {
    padding-top: 16rem
  }

  .sm\:pr-32 {
    padding-right: 16rem
  }

  .sm\:pb-32 {
    padding-bottom: 16rem
  }

  .sm\:pl-32 {
    padding-left: 16rem
  }

  .sm\:pt-40 {
    padding-top: 20rem
  }

  .sm\:pr-40 {
    padding-right: 20rem
  }

  .sm\:pb-40 {
    padding-bottom: 20rem
  }

  .sm\:pl-40 {
    padding-left: 20rem
  }

  .sm\:pt-px {
    padding-top: 1px
  }

  .sm\:pr-px {
    padding-right: 1px
  }

  .sm\:pb-px {
    padding-bottom: 1px
  }

  .sm\:pl-px {
    padding-left: 1px
  }

  .sm\:pt-half {
    padding-top: 0.25rem
  }

  .sm\:pr-half {
    padding-right: 0.25rem
  }

  .sm\:pb-half {
    padding-bottom: 0.25rem
  }

  .sm\:pl-half {
    padding-left: 0.25rem
  }

  .sm\:placeholder-transparent::placeholder {
    color: transparent
  }

  .sm\:placeholder-black::placeholder {
    color: @color-black
  }

  .sm\:placeholder-white::placeholder {
    color: @color-white
  }

  .sm\:placeholder-grey-10::placeholder {
    color: fade(@color-grey, 10%)
  }

  .sm\:placeholder-grey-30::placeholder {
    color: fade(@color-grey, 30%)
  }

  .sm\:placeholder-grey-40::placeholder {
    color: fade(@color-grey, 40%)
  }

  .sm\:placeholder-grey-60::placeholder {
    color: fade(@color-grey, 60%)
  }

  .sm\:placeholder-grey-90::placeholder {
    color: fade(@color-grey, 90%)
  }

  .sm\:placeholder-grey::placeholder {
    color: @color-grey
  }

  .sm\:placeholder-grey-metal::placeholder {
    color: @color-grey
  }

  .sm\:placeholder-grey-divider::placeholder {
    color: fade(@color-grey, 20%)
  }

  .sm\:placeholder-grey-light::placeholder {
    color: @color-grey-light
  }

  .sm\:placeholder-grey-header::placeholder {
    color: fade(@color-grey, 90%)
  }

  .sm\:placeholder-body::placeholder {
    color: @color-body
  }

  .sm\:placeholder-yellow::placeholder {
    color: @color-yellow
  }

  .sm\:placeholder-orange::placeholder {
    color: @color-orange
  }

  .sm\:placeholder-stone::placeholder {
    color: @color-stone
  }

  .sm\:placeholder-khaki::placeholder {
    color: @color-khaki
  }

  .sm\:placeholder-green::placeholder {
    color: @color-green
  }

  .sm\:placeholder-blue::placeholder {
    color: @color-blue
  }

  .sm\:placeholder-red::placeholder {
    color: @color-red
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    color: @color-black
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    color: @color-white
  }

  .sm\:focus\:placeholder-grey-10:focus::placeholder {
    color: fade(@color-grey, 10%)
  }

  .sm\:focus\:placeholder-grey-30:focus::placeholder {
    color: fade(@color-grey, 30%)
  }

  .sm\:focus\:placeholder-grey-40:focus::placeholder {
    color: fade(@color-grey, 40%)
  }

  .sm\:focus\:placeholder-grey-60:focus::placeholder {
    color: fade(@color-grey, 60%)
  }

  .sm\:focus\:placeholder-grey-90:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .sm\:focus\:placeholder-grey:focus::placeholder {
    color: @color-grey
  }

  .sm\:focus\:placeholder-grey-metal:focus::placeholder {
    color: @color-grey
  }

  .sm\:focus\:placeholder-grey-divider:focus::placeholder {
    color: fade(@color-grey, 20%)
  }

  .sm\:focus\:placeholder-grey-light:focus::placeholder {
    color: @color-grey-light
  }

  .sm\:focus\:placeholder-grey-header:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .sm\:focus\:placeholder-body:focus::placeholder {
    color: @color-body
  }

  .sm\:focus\:placeholder-yellow:focus::placeholder {
    color: @color-yellow
  }

  .sm\:focus\:placeholder-orange:focus::placeholder {
    color: @color-orange
  }

  .sm\:focus\:placeholder-stone:focus::placeholder {
    color: @color-stone
  }

  .sm\:focus\:placeholder-khaki:focus::placeholder {
    color: @color-khaki
  }

  .sm\:focus\:placeholder-green:focus::placeholder {
    color: @color-green
  }

  .sm\:focus\:placeholder-blue:focus::placeholder {
    color: @color-blue
  }

  .sm\:focus\:placeholder-red:focus::placeholder {
    color: @color-red
  }

  .sm\:pointer-events-none {
    pointer-events: none
  }

  .sm\:pointer-events-auto {
    pointer-events: auto
  }

  .sm\:static {
    position: static
  }

  .sm\:fixed {
    position: fixed
  }

  .sm\:absolute {
    position: absolute
  }

  .sm\:relative {
    position: relative
  }

  .sm\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .sm\:inset-x-0 {
    right: 0;
    left: 0
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .sm\:inset-x-auto {
    right: auto;
    left: auto
  }

  .sm\:top-0 {
    top: 0
  }

  .sm\:right-0 {
    right: 0
  }

  .sm\:bottom-0 {
    bottom: 0
  }

  .sm\:left-0 {
    left: 0
  }

  .sm\:top-auto {
    top: auto
  }

  .sm\:right-auto {
    right: auto
  }

  .sm\:bottom-auto {
    bottom: auto
  }

  .sm\:left-auto {
    left: auto
  }

  .sm\:resize-none {
    resize: none
  }

  .sm\:resize-y {
    resize: vertical
  }

  .sm\:resize-x {
    resize: horizontal
  }

  .sm\:resize {
    resize: both
  }

  .sm\:shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow-none {
    box-shadow: none
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .sm\:fill-current {
    fill: currentColor
  }

  .sm\:stroke-current {
    stroke: currentColor
  }

  .sm\:table-auto {
    table-layout: auto
  }

  .sm\:table-fixed {
    table-layout: fixed
  }

  .sm\:text-left {
    text-align: left
  }

  .sm\:text-center {
    text-align: center
  }

  .sm\:text-right {
    text-align: right
  }

  .sm\:text-justify {
    text-align: justify
  }

  .sm\:text-transparent {
    color: transparent
  }

  .sm\:text-black {
    color: @color-black
  }

  .sm\:text-white {
    color: @color-white
  }

  .sm\:text-grey-10 {
    color: fade(@color-grey, 10%)
  }

  .sm\:text-grey-30 {
    color: fade(@color-grey, 30%)
  }

  .sm\:text-grey-40 {
    color: fade(@color-grey, 40%)
  }

  .sm\:text-grey-60 {
    color: fade(@color-grey, 60%)
  }

  .sm\:text-grey-90 {
    color: fade(@color-grey, 90%)
  }

  .sm\:text-grey {
    color: @color-grey
  }

  .sm\:text-grey-metal {
    color: @color-grey
  }

  .sm\:text-grey-divider {
    color: fade(@color-grey, 20%)
  }

  .sm\:text-grey-light {
    color: @color-grey-light
  }

  .sm\:text-grey-header {
    color: fade(@color-grey, 90%)
  }

  .sm\:text-body {
    color: @color-body
  }

  .sm\:text-yellow {
    color: @color-yellow
  }

  .sm\:text-orange {
    color: @color-orange
  }

  .sm\:text-stone {
    color: @color-stone
  }

  .sm\:text-khaki {
    color: @color-khaki
  }

  .sm\:text-green {
    color: @color-green
  }

  .sm\:text-blue {
    color: @color-blue
  }

  .sm\:text-red {
    color: @color-red
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent
  }

  .sm\:hover\:text-black:hover {
    color: @color-black
  }

  .sm\:hover\:text-white:hover {
    color: @color-white
  }

  .sm\:hover\:text-grey-10:hover {
    color: fade(@color-grey, 10%)
  }

  .sm\:hover\:text-grey-30:hover {
    color: fade(@color-grey, 30%)
  }

  .sm\:hover\:text-grey-40:hover {
    color: fade(@color-grey, 40%)
  }

  .sm\:hover\:text-grey-60:hover {
    color: fade(@color-grey, 60%)
  }

  .sm\:hover\:text-grey-90:hover {
    color: fade(@color-grey, 90%)
  }

  .sm\:hover\:text-grey:hover {
    color: @color-grey
  }

  .sm\:hover\:text-grey-metal:hover {
    color: @color-grey
  }

  .sm\:hover\:text-grey-divider:hover {
    color: fade(@color-grey, 20%)
  }

  .sm\:hover\:text-grey-light:hover {
    color: @color-grey-light
  }

  .sm\:hover\:text-grey-header:hover {
    color: fade(@color-grey, 90%)
  }

  .sm\:hover\:text-body:hover {
    color: @color-body
  }

  .sm\:hover\:text-yellow:hover {
    color: @color-yellow
  }

  .sm\:hover\:text-orange:hover {
    color: @color-orange
  }

  .sm\:hover\:text-stone:hover {
    color: @color-stone
  }

  .sm\:hover\:text-khaki:hover {
    color: @color-khaki
  }

  .sm\:hover\:text-green:hover {
    color: @color-green
  }

  .sm\:hover\:text-blue:hover {
    color: @color-blue
  }

  .sm\:hover\:text-red:hover {
    color: @color-red
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent
  }

  .sm\:focus\:text-black:focus {
    color: @color-black
  }

  .sm\:focus\:text-white:focus {
    color: @color-white
  }

  .sm\:focus\:text-grey-10:focus {
    color: fade(@color-grey, 10%)
  }

  .sm\:focus\:text-grey-30:focus {
    color: fade(@color-grey, 30%)
  }

  .sm\:focus\:text-grey-40:focus {
    color: fade(@color-grey, 40%)
  }

  .sm\:focus\:text-grey-60:focus {
    color: fade(@color-grey, 60%)
  }

  .sm\:focus\:text-grey-90:focus {
    color: fade(@color-grey, 90%)
  }

  .sm\:focus\:text-grey:focus {
    color: @color-grey
  }

  .sm\:focus\:text-grey-metal:focus {
    color: @color-grey
  }

  .sm\:focus\:text-grey-divider:focus {
    color: fade(@color-grey, 20%)
  }

  .sm\:focus\:text-grey-light:focus {
    color: @color-grey-light
  }

  .sm\:focus\:text-grey-header:focus {
    color: fade(@color-grey, 90%)
  }

  .sm\:focus\:text-body:focus {
    color: @color-body
  }

  .sm\:focus\:text-yellow:focus {
    color: @color-yellow
  }

  .sm\:focus\:text-orange:focus {
    color: @color-orange
  }

  .sm\:focus\:text-stone:focus {
    color: @color-stone
  }

  .sm\:focus\:text-khaki:focus {
    color: @color-khaki
  }

  .sm\:focus\:text-green:focus {
    color: @color-green
  }

  .sm\:focus\:text-blue:focus {
    color: @color-blue
  }

  .sm\:focus\:text-red:focus {
    color: @color-red
  }

  .sm\:text-base {
    font-size: .6875rem
  }

  .sm\:text-md {
    font-size: 0.9375rem
  }

  .sm\:text-lg {
    font-size: 1rem
  }

  .sm\:text-xl {
    font-size: 1.375rem
  }

  .sm\:text-2xl {
    font-size: 2.0625rem
  }

  .sm\:italic {
    font-style: italic
  }

  .sm\:not-italic {
    font-style: normal
  }

  .sm\:uppercase {
    text-transform: uppercase
  }

  .sm\:lowercase {
    text-transform: lowercase
  }

  .sm\:capitalize {
    text-transform: capitalize
  }

  .sm\:normal-case {
    text-transform: none
  }

  .sm\:underline {
    text-decoration: underline
  }

  .sm\:line-through {
    text-decoration: line-through
  }

  .sm\:no-underline {
    text-decoration: none
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em
  }

  .sm\:tracking-normal {
    letter-spacing: 0
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em
  }

  .sm\:align-baseline {
    vertical-align: baseline
  }

  .sm\:align-top {
    vertical-align: top
  }

  .sm\:align-middle {
    vertical-align: middle
  }

  .sm\:align-bottom {
    vertical-align: bottom
  }

  .sm\:align-text-top {
    vertical-align: text-top
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom
  }

  .sm\:visible {
    visibility: visible
  }

  .sm\:invisible {
    visibility: hidden
  }

  .sm\:whitespace-normal {
    white-space: normal
  }

  .sm\:whitespace-no-wrap {
    white-space: nowrap
  }

  .sm\:whitespace-pre {
    white-space: pre
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .sm\:w-0 {
    width: 0
  }

  .sm\:w-1 {
    width: 0.5rem
  }

  .sm\:w-2 {
    width: 1rem
  }

  .sm\:w-3 {
    width: 1.5rem
  }

  .sm\:w-4 {
    width: 2rem
  }

  .sm\:w-5 {
    width: 2.5rem
  }

  .sm\:w-6 {
    width: 3rem
  }

  .sm\:w-7 {
    width: 3.5rem
  }

  .sm\:w-8 {
    width: 4rem
  }

  .sm\:w-9 {
    width: 4.5rem
  }

  .sm\:w-10 {
    width: 5rem
  }

  .sm\:w-11 {
    width: 5.5rem
  }

  .sm\:w-12 {
    width: 6rem
  }

  .sm\:w-13 {
    width: 6.5rem
  }

  .sm\:w-14 {
    width: 7rem
  }

  .sm\:w-15 {
    width: 7.5rem
  }

  .sm\:w-16 {
    width: 8rem
  }

  .sm\:w-17 {
    width: 8.5rem
  }

  .sm\:w-19 {
    width: 9rem
  }

  .sm\:w-20 {
    width: 9.5rem
  }

  .sm\:w-22 {
    width: 11rem
  }

  .sm\:w-24 {
    width: 12rem
  }

  .sm\:w-32 {
    width: 16rem
  }

  .sm\:w-40 {
    width: 20rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-px {
    width: 1px
  }

  .sm\:w-half {
    width: 0.25rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-3\/6 {
    width: 50%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-7\/12 {
    width: 58.333333%
  }

  .sm\:w-8\/12 {
    width: 66.666667%
  }

  .sm\:w-9\/12 {
    width: 75%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:w-screen {
    width: 100vw
  }

  .sm\:z-0 {
    z-index: 0
  }

  .sm\:z-10 {
    z-index: 10
  }

  .sm\:z-20 {
    z-index: 20
  }

  .sm\:z-30 {
    z-index: 30
  }

  .sm\:z-40 {
    z-index: 40
  }

  .sm\:z-50 {
    z-index: 50
  }

  .sm\:z-auto {
    z-index: auto
  }
}

@media (min-width: @m-break) {
  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:bg-transparent {
    background-color: transparent
  }

  .md\:bg-black {
    background-color: @color-black
  }

  .md\:bg-white {
    background-color: @color-white
  }

  .md\:bg-grey-10 {
    background-color: fade(@color-grey, 10%)
  }

  .md\:bg-grey-30 {
    background-color: fade(@color-grey, 30%)
  }

  .md\:bg-grey-40 {
    background-color: fade(@color-grey, 40%)
  }

  .md\:bg-grey-60 {
    background-color: fade(@color-grey, 60%)
  }

  .md\:bg-grey-90 {
    background-color: fade(@color-grey, 90%)
  }

  .md\:bg-grey {
    background-color: @color-grey
  }

  .md\:bg-grey-metal {
    background-color: @color-grey
  }

  .md\:bg-grey-divider {
    background-color: fade(@color-grey, 20%)
  }

  .md\:bg-grey-light {
    background-color: @color-grey-light
  }

  .md\:bg-grey-header {
    background-color: fade(@color-grey, 90%)
  }

  .md\:bg-body {
    background-color: @color-body
  }

  .md\:bg-yellow {
    background-color: @color-yellow
  }

  .md\:bg-orange {
    background-color: @color-orange
  }

  .md\:bg-stone {
    background-color: @color-stone
  }

  .md\:bg-khaki {
    background-color: @color-khaki
  }

  .md\:bg-green {
    background-color: @color-green
  }

  .md\:bg-blue {
    background-color: @color-blue
  }

  .md\:bg-red {
    background-color: @color-red
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .md\:hover\:bg-black:hover {
    background-color: @color-black
  }

  .md\:hover\:bg-white:hover {
    background-color: @color-white
  }

  .md\:hover\:bg-grey-10:hover {
    background-color: fade(@color-grey, 10%)
  }

  .md\:hover\:bg-grey-30:hover {
    background-color: fade(@color-grey, 30%)
  }

  .md\:hover\:bg-grey-40:hover {
    background-color: fade(@color-grey, 40%)
  }

  .md\:hover\:bg-grey-60:hover {
    background-color: fade(@color-grey, 60%)
  }

  .md\:hover\:bg-grey-90:hover {
    background-color: fade(@color-grey, 90%)
  }

  .md\:hover\:bg-grey:hover {
    background-color: @color-grey
  }

  .md\:hover\:bg-grey-metal:hover {
    background-color: @color-grey
  }

  .md\:hover\:bg-grey-divider:hover {
    background-color: fade(@color-grey, 20%)
  }

  .md\:hover\:bg-grey-light:hover {
    background-color: @color-grey-light
  }

  .md\:hover\:bg-grey-header:hover {
    background-color: fade(@color-grey, 90%)
  }

  .md\:hover\:bg-body:hover {
    background-color: @color-body
  }

  .md\:hover\:bg-yellow:hover {
    background-color: @color-yellow
  }

  .md\:hover\:bg-orange:hover {
    background-color: @color-orange
  }

  .md\:hover\:bg-stone:hover {
    background-color: @color-stone
  }

  .md\:hover\:bg-khaki:hover {
    background-color: @color-khaki
  }

  .md\:hover\:bg-green:hover {
    background-color: @color-green
  }

  .md\:hover\:bg-blue:hover {
    background-color: @color-blue
  }

  .md\:hover\:bg-red:hover {
    background-color: @color-red
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .md\:focus\:bg-black:focus {
    background-color: @color-black
  }

  .md\:focus\:bg-white:focus {
    background-color: @color-white
  }

  .md\:focus\:bg-grey-10:focus {
    background-color: fade(@color-grey, 10%)
  }

  .md\:focus\:bg-grey-30:focus {
    background-color: fade(@color-grey, 30%)
  }

  .md\:focus\:bg-grey-40:focus {
    background-color: fade(@color-grey, 40%)
  }

  .md\:focus\:bg-grey-60:focus {
    background-color: fade(@color-grey, 60%)
  }

  .md\:focus\:bg-grey-90:focus {
    background-color: fade(@color-grey, 90%)
  }

  .md\:focus\:bg-grey:focus {
    background-color: @color-grey
  }

  .md\:focus\:bg-grey-metal:focus {
    background-color: @color-grey
  }

  .md\:focus\:bg-grey-divider:focus {
    background-color: fade(@color-grey, 20%)
  }

  .md\:focus\:bg-grey-light:focus {
    background-color: @color-grey-light
  }

  .md\:focus\:bg-grey-header:focus {
    background-color: fade(@color-grey, 90%)
  }

  .md\:focus\:bg-body:focus {
    background-color: @color-body
  }

  .md\:focus\:bg-yellow:focus {
    background-color: @color-yellow
  }

  .md\:focus\:bg-orange:focus {
    background-color: @color-orange
  }

  .md\:focus\:bg-stone:focus {
    background-color: @color-stone
  }

  .md\:focus\:bg-khaki:focus {
    background-color: @color-khaki
  }

  .md\:focus\:bg-green:focus {
    background-color: @color-green
  }

  .md\:focus\:bg-blue:focus {
    background-color: @color-blue
  }

  .md\:focus\:bg-red:focus {
    background-color: @color-red
  }

  .md\:bg-bottom {
    background-position: bottom
  }

  .md\:bg-center {
    background-position: center
  }

  .md\:bg-left {
    background-position: left
  }

  .md\:bg-left-bottom {
    background-position: left bottom
  }

  .md\:bg-left-top {
    background-position: left top
  }

  .md\:bg-right {
    background-position: right
  }

  .md\:bg-right-bottom {
    background-position: right bottom
  }

  .md\:bg-right-top {
    background-position: right top
  }

  .md\:bg-top {
    background-position: top
  }

  .md\:bg-repeat {
    background-repeat: repeat
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .md\:bg-repeat-round {
    background-repeat: round
  }

  .md\:bg-repeat-space {
    background-repeat: space
  }

  .md\:bg-auto {
    background-size: auto
  }

  .md\:bg-cover {
    background-size: cover
  }

  .md\:bg-contain {
    background-size: contain
  }

  .md\:border-collapse {
    border-collapse: collapse
  }

  .md\:border-separate {
    border-collapse: separate
  }

  .md\:border-transparent {
    border-color: transparent
  }

  .md\:border-black {
    border-color: @color-black
  }

  .md\:border-white {
    border-color: @color-white
  }

  .md\:border-grey-10 {
    border-color: fade(@color-grey, 10%)
  }

  .md\:border-grey-30 {
    border-color: fade(@color-grey, 30%)
  }

  .md\:border-grey-40 {
    border-color: fade(@color-grey, 40%)
  }

  .md\:border-grey-60 {
    border-color: fade(@color-grey, 60%)
  }

  .md\:border-grey-90 {
    border-color: fade(@color-grey, 90%)
  }

  .md\:border-grey {
    border-color: @color-grey
  }

  .md\:border-grey-metal {
    border-color: @color-grey
  }

  .md\:border-grey-divider {
    border-color: fade(@color-grey, 20%)
  }

  .md\:border-grey-light {
    border-color: @color-grey-light
  }

  .md\:border-grey-header {
    border-color: fade(@color-grey, 90%)
  }

  .md\:border-body {
    border-color: @color-body
  }

  .md\:border-yellow {
    border-color: @color-yellow
  }

  .md\:border-orange {
    border-color: @color-orange
  }

  .md\:border-stone {
    border-color: @color-stone
  }

  .md\:border-khaki {
    border-color: @color-khaki
  }

  .md\:border-green {
    border-color: @color-green
  }

  .md\:border-blue {
    border-color: @color-blue
  }

  .md\:border-red {
    border-color: @color-red
  }

  .md\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .md\:hover\:border-black:hover {
    border-color: @color-black
  }

  .md\:hover\:border-white:hover {
    border-color: @color-white
  }

  .md\:hover\:border-grey-10:hover {
    border-color: fade(@color-grey, 10%)
  }

  .md\:hover\:border-grey-30:hover {
    border-color: fade(@color-grey, 30%)
  }

  .md\:hover\:border-grey-40:hover {
    border-color: fade(@color-grey, 40%)
  }

  .md\:hover\:border-grey-60:hover {
    border-color: fade(@color-grey, 60%)
  }

  .md\:hover\:border-grey-90:hover {
    border-color: fade(@color-grey, 90%)
  }

  .md\:hover\:border-grey:hover {
    border-color: @color-grey
  }

  .md\:hover\:border-grey-metal:hover {
    border-color: @color-grey
  }

  .md\:hover\:border-grey-divider:hover {
    border-color: fade(@color-grey, 20%)
  }

  .md\:hover\:border-grey-light:hover {
    border-color: @color-grey-light
  }

  .md\:hover\:border-grey-header:hover {
    border-color: fade(@color-grey, 90%)
  }

  .md\:hover\:border-body:hover {
    border-color: @color-body
  }

  .md\:hover\:border-yellow:hover {
    border-color: @color-yellow
  }

  .md\:hover\:border-orange:hover {
    border-color: @color-orange
  }

  .md\:hover\:border-stone:hover {
    border-color: @color-stone
  }

  .md\:hover\:border-khaki:hover {
    border-color: @color-khaki
  }

  .md\:hover\:border-green:hover {
    border-color: @color-green
  }

  .md\:hover\:border-blue:hover {
    border-color: @color-blue
  }

  .md\:hover\:border-red:hover {
    border-color: @color-red
  }

  .md\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .md\:focus\:border-black:focus {
    border-color: @color-black
  }

  .md\:focus\:border-white:focus {
    border-color: @color-white
  }

  .md\:focus\:border-grey-10:focus {
    border-color: fade(@color-grey, 10%)
  }

  .md\:focus\:border-grey-30:focus {
    border-color: fade(@color-grey, 30%)
  }

  .md\:focus\:border-grey-40:focus {
    border-color: fade(@color-grey, 40%)
  }

  .md\:focus\:border-grey-60:focus {
    border-color: fade(@color-grey, 60%)
  }

  .md\:focus\:border-grey-90:focus {
    border-color: fade(@color-grey, 90%)
  }

  .md\:focus\:border-grey:focus {
    border-color: @color-grey
  }

  .md\:focus\:border-grey-metal:focus {
    border-color: @color-grey
  }

  .md\:focus\:border-grey-divider:focus {
    border-color: fade(@color-grey, 20%)
  }

  .md\:focus\:border-grey-light:focus {
    border-color: @color-grey-light
  }

  .md\:focus\:border-grey-header:focus {
    border-color: fade(@color-grey, 90%)
  }

  .md\:focus\:border-body:focus {
    border-color: @color-body
  }

  .md\:focus\:border-yellow:focus {
    border-color: @color-yellow
  }

  .md\:focus\:border-orange:focus {
    border-color: @color-orange
  }

  .md\:focus\:border-stone:focus {
    border-color: @color-stone
  }

  .md\:focus\:border-khaki:focus {
    border-color: @color-khaki
  }

  .md\:focus\:border-green:focus {
    border-color: @color-green
  }

  .md\:focus\:border-blue:focus {
    border-color: @color-blue
  }

  .md\:focus\:border-red:focus {
    border-color: @color-red
  }

  .md\:rounded-none {
    border-radius: 0
  }

  .md\:rounded {
    border-radius: .125rem
  }

  .md\:rounded-lg {
    border-radius: .375rem
  }

  .md\:rounded-full {
    border-radius: 20em
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-t {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem
  }

  .md\:rounded-r {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
  }

  .md\:rounded-b {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .md\:rounded-l {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
  }

  .md\:rounded-r-lg {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .md\:rounded-t-full {
    border-top-left-radius: 20em;
    border-top-right-radius: 20em
  }

  .md\:rounded-r-full {
    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .md\:rounded-l-full {
    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .md\:rounded-tl {
    border-top-left-radius: .125rem
  }

  .md\:rounded-tr {
    border-top-right-radius: .125rem
  }

  .md\:rounded-br {
    border-bottom-right-radius: .125rem
  }

  .md\:rounded-bl {
    border-bottom-left-radius: .125rem
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: .375rem
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: .375rem
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: .375rem
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: .375rem
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 20em
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 20em
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 20em
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 20em
  }

  .md\:border-solid {
    border-style: solid
  }

  .md\:border-dashed {
    border-style: dashed
  }

  .md\:border-dotted {
    border-style: dotted
  }

  .md\:border-double {
    border-style: double
  }

  .md\:border-none {
    border-style: none
  }

  .md\:border-0 {
    border-width: 0
  }

  .md\:border-3 {
    border-width: .1875rem
  }

  .md\:border-6 {
    border-width: .375rem
  }

  .md\:border {
    border-width: 1px
  }

  .md\:border-t-0 {
    border-top-width: 0
  }

  .md\:border-r-0 {
    border-right-width: 0
  }

  .md\:border-b-0 {
    border-bottom-width: 0
  }

  .md\:border-l-0 {
    border-left-width: 0
  }

  .md\:border-t-3 {
    border-top-width: .1875rem
  }

  .md\:border-r-3 {
    border-right-width: .1875rem
  }

  .md\:border-b-3 {
    border-bottom-width: .1875rem
  }

  .md\:border-l-3 {
    border-left-width: .1875rem
  }

  .md\:border-t-6 {
    border-top-width: .375rem
  }

  .md\:border-r-6 {
    border-right-width: .375rem
  }

  .md\:border-b-6 {
    border-bottom-width: .375rem
  }

  .md\:border-l-6 {
    border-left-width: .375rem
  }

  .md\:border-t {
    border-top-width: 1px
  }

  .md\:border-r {
    border-right-width: 1px
  }

  .md\:border-b {
    border-bottom-width: 1px
  }

  .md\:border-l {
    border-left-width: 1px
  }

  .md\:cursor-auto {
    cursor: auto
  }

  .md\:cursor-default {
    cursor: default
  }

  .md\:cursor-pointer {
    cursor: pointer
  }

  .md\:cursor-wait {
    cursor: wait
  }

  .md\:cursor-text {
    cursor: text
  }

  .md\:cursor-move {
    cursor: move
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed
  }

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:table {
    display: table
  }

  .md\:table-row {
    display: table-row
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:items-center {
    align-items: center
  }

  .md\:items-baseline {
    align-items: baseline
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:self-auto {
    align-self: auto
  }

  .md\:self-start {
    align-self: flex-start
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:self-center {
    align-self: center
  }

  .md\:self-stretch {
    align-self: stretch
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:justify-around {
    justify-content: space-around
  }

  .md\:content-center {
    align-content: center
  }

  .md\:content-start {
    align-content: flex-start
  }

  .md\:content-end {
    align-content: flex-end
  }

  .md\:content-between {
    align-content: space-between
  }

  .md\:content-around {
    align-content: space-around
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-auto {
    flex: 1 1 auto
  }

  .md\:flex-initial {
    flex: 0 1 auto
  }

  .md\:flex-none {
    flex: none
  }

  .md\:flex-grow-0 {
    flex-grow: 0
  }

  .md\:flex-grow {
    flex-grow: 1
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:flex-shrink {
    flex-shrink: 1
  }

  .md\:order-1 {
    order: 1
  }

  .md\:order-2 {
    order: 2
  }

  .md\:order-3 {
    order: 3
  }

  .md\:order-4 {
    order: 4
  }

  .md\:order-5 {
    order: 5
  }

  .md\:order-6 {
    order: 6
  }

  .md\:order-7 {
    order: 7
  }

  .md\:order-8 {
    order: 8
  }

  .md\:order-9 {
    order: 9
  }

  .md\:order-10 {
    order: 10
  }

  .md\:order-11 {
    order: 11
  }

  .md\:order-12 {
    order: 12
  }

  .md\:order-first {
    order: -9999
  }

  .md\:order-last {
    order: 9999
  }

  .md\:order-none {
    order: 0
  }

  .md\:float-right {
    float: right
  }

  .md\:float-left {
    float: left
  }

  .md\:float-none {
    float: none
  }

  .md\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .md\:font-body {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
  }

  .md\:font-normal {
    font-weight: 400
  }

  .md\:font-semibold {
    font-weight: 600
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .md\:h-0 {
    height: 0
  }

  .md\:h-1 {
    height: 0.5rem
  }

  .md\:h-2 {
    height: 1rem
  }

  .md\:h-3 {
    height: 1.5rem
  }

  .md\:h-4 {
    height: 2rem
  }

  .md\:h-5 {
    height: 2.5rem
  }

  .md\:h-6 {
    height: 3rem
  }

  .md\:h-7 {
    height: 3.5rem
  }

  .md\:h-8 {
    height: 4rem
  }

  .md\:h-9 {
    height: 4.5rem
  }

  .md\:h-10 {
    height: 5rem
  }

  .md\:h-11 {
    height: 5.5rem
  }

  .md\:h-12 {
    height: 6rem
  }

  .md\:h-13 {
    height: 6.5rem
  }

  .md\:h-14 {
    height: 7rem
  }

  .md\:h-15 {
    height: 7.5rem
  }

  .md\:h-16 {
    height: 8rem
  }

  .md\:h-17 {
    height: 8.5rem
  }

  .md\:h-19 {
    height: 9rem
  }

  .md\:h-20 {
    height: 9.5rem
  }

  .md\:h-22 {
    height: 11rem
  }

  .md\:h-24 {
    height: 12rem
  }

  .md\:h-32 {
    height: 16rem
  }

  .md\:h-40 {
    height: 20rem
  }

  .md\:h-auto {
    height: auto
  }

  .md\:h-px {
    height: 1px
  }

  .md\:h-half {
    height: 0.25rem
  }

  .md\:h-full {
    height: 100%
  }

  .md\:h-screen {
    height: 100vh
  }

  .md\:leading-base {
    line-height: .75rem
  }

  .md\:leading-sm {
    line-height: 1.125rem
  }

  .md\:leading-lg {
    line-height: 1.5rem
  }

  .md\:leading-xl {
    line-height: 1.875rem
  }

  .md\:leading-2xl {
    line-height: 2.25rem
  }

  .md\:list-none {
    list-style-type: none
  }

  .md\:list-disc {
    list-style-type: disc
  }

  .md\:list-decimal {
    list-style-type: decimal
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:m-1 {
    margin: 0.5rem
  }

  .md\:m-2 {
    margin: 1rem
  }

  .md\:m-3 {
    margin: 1.5rem
  }

  .md\:m-4 {
    margin: 2rem
  }

  .md\:m-5 {
    margin: 2.5rem
  }

  .md\:m-6 {
    margin: 3rem
  }

  .md\:m-7 {
    margin: 3.5rem
  }

  .md\:m-8 {
    margin: 4rem
  }

  .md\:m-9 {
    margin: 4.5rem
  }

  .md\:m-10 {
    margin: 5rem
  }

  .md\:m-11 {
    margin: 5.5rem
  }

  .md\:m-12 {
    margin: 6rem
  }

  .md\:m-13 {
    margin: 6.5rem
  }

  .md\:m-14 {
    margin: 7rem
  }

  .md\:m-15 {
    margin: 7.5rem
  }

  .md\:m-16 {
    margin: 8rem
  }

  .md\:m-17 {
    margin: 8.5rem
  }

  .md\:m-19 {
    margin: 9rem
  }

  .md\:m-20 {
    margin: 9.5rem
  }

  .md\:m-22 {
    margin: 11rem
  }

  .md\:m-24 {
    margin: 12rem
  }

  .md\:m-32 {
    margin: 16rem
  }

  .md\:m-40 {
    margin: 20rem
  }

  .md\:m-auto {
    margin: auto
  }

  .md\:m-px {
    margin: 1px
  }

  .md\:m-half {
    margin: 0.25rem
  }

  .md\:-m-1 {
    margin: -0.5rem
  }

  .md\:-m-2 {
    margin: -1rem
  }

  .md\:-m-3 {
    margin: -1.5rem
  }

  .md\:-m-4 {
    margin: -2rem
  }

  .md\:-m-5 {
    margin: -2.5rem
  }

  .md\:-m-6 {
    margin: -3rem
  }

  .md\:-m-7 {
    margin: -3.5rem
  }

  .md\:-m-8 {
    margin: -4rem
  }

  .md\:-m-9 {
    margin: -4.5rem
  }

  .md\:-m-10 {
    margin: -5rem
  }

  .md\:-m-11 {
    margin: -5.5rem
  }

  .md\:-m-12 {
    margin: -6rem
  }

  .md\:-m-13 {
    margin: -6.5rem
  }

  .md\:-m-14 {
    margin: -7rem
  }

  .md\:-m-15 {
    margin: -7.5rem
  }

  .md\:-m-16 {
    margin: -8rem
  }

  .md\:-m-17 {
    margin: -8.5rem
  }

  .md\:-m-19 {
    margin: -9rem
  }

  .md\:-m-20 {
    margin: -9.5rem
  }

  .md\:-m-22 {
    margin: -11rem
  }

  .md\:-m-24 {
    margin: -12rem
  }

  .md\:-m-32 {
    margin: -16rem
  }

  .md\:-m-40 {
    margin: -20rem
  }

  .md\:-m-px {
    margin: -1px
  }

  .md\:-m-half {
    margin: -0.25rem
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .md\:mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .md\:mx-2 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .md\:my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .md\:mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .md\:my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:mx-4 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:my-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .md\:mx-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .md\:my-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md\:mx-6 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .md\:my-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .md\:mx-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .md\:my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md\:mx-8 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .md\:my-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
  }

  .md\:mx-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .md\:my-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .md\:mx-10 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .md\:my-11 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem
  }

  .md\:mx-11 {
    margin-left: 5.5rem;
    margin-right: 5.5rem
  }

  .md\:my-12 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md\:mx-12 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .md\:my-13 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem
  }

  .md\:mx-13 {
    margin-left: 6.5rem;
    margin-right: 6.5rem
  }

  .md\:my-14 {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .md\:mx-14 {
    margin-left: 7rem;
    margin-right: 7rem
  }

  .md\:my-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem
  }

  .md\:mx-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem
  }

  .md\:my-16 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md\:mx-16 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .md\:my-17 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem
  }

  .md\:mx-17 {
    margin-left: 8.5rem;
    margin-right: 8.5rem
  }

  .md\:my-19 {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .md\:mx-19 {
    margin-left: 9rem;
    margin-right: 9rem
  }

  .md\:my-20 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem
  }

  .md\:mx-20 {
    margin-left: 9.5rem;
    margin-right: 9.5rem
  }

  .md\:my-22 {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .md\:mx-22 {
    margin-left: 11rem;
    margin-right: 11rem
  }

  .md\:my-24 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .md\:mx-24 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .md\:my-32 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .md\:mx-32 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .md\:my-40 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .md\:mx-40 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .md\:my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .md\:mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:-my-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .md\:-mx-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .md\:-my-2 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .md\:-mx-2 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .md\:-my-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .md\:-mx-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .md\:-my-4 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .md\:-mx-4 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .md\:-my-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .md\:-mx-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .md\:-my-6 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .md\:-mx-6 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .md\:-my-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .md\:-mx-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .md\:-my-8 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .md\:-mx-8 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .md\:-my-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem
  }

  .md\:-mx-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem
  }

  .md\:-my-10 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .md\:-mx-10 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .md\:-my-11 {
    margin-top: -5.5rem;
    margin-bottom: -5.5rem
  }

  .md\:-mx-11 {
    margin-left: -5.5rem;
    margin-right: -5.5rem
  }

  .md\:-my-12 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .md\:-mx-12 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:-my-13 {
    margin-top: -6.5rem;
    margin-bottom: -6.5rem
  }

  .md\:-mx-13 {
    margin-left: -6.5rem;
    margin-right: -6.5rem
  }

  .md\:-my-14 {
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  .md\:-mx-14 {
    margin-left: -7rem;
    margin-right: -7rem
  }

  .md\:-my-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem
  }

  .md\:-mx-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem
  }

  .md\:-my-16 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .md\:-mx-16 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .md\:-my-17 {
    margin-top: -8.5rem;
    margin-bottom: -8.5rem
  }

  .md\:-mx-17 {
    margin-left: -8.5rem;
    margin-right: -8.5rem
  }

  .md\:-my-19 {
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  .md\:-mx-19 {
    margin-left: -9rem;
    margin-right: -9rem
  }

  .md\:-my-20 {
    margin-top: -9.5rem;
    margin-bottom: -9.5rem
  }

  .md\:-mx-20 {
    margin-left: -9.5rem;
    margin-right: -9.5rem
  }

  .md\:-my-22 {
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  .md\:-mx-22 {
    margin-left: -11rem;
    margin-right: -11rem
  }

  .md\:-my-24 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .md\:-mx-24 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .md\:-my-32 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .md\:-mx-32 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .md\:-my-40 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .md\:-mx-40 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .md\:-my-half {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .md\:-mx-half {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:ml-0 {
    margin-left: 0
  }

  .md\:mt-1 {
    margin-top: 0.5rem
  }

  .md\:mr-1 {
    margin-right: 0.5rem
  }

  .md\:mb-1 {
    margin-bottom: 0.5rem
  }

  .md\:ml-1 {
    margin-left: 0.5rem
  }

  .md\:mt-2 {
    margin-top: 1rem
  }

  .md\:mr-2 {
    margin-right: 1rem
  }

  .md\:mb-2 {
    margin-bottom: 1rem
  }

  .md\:ml-2 {
    margin-left: 1rem
  }

  .md\:mt-3 {
    margin-top: 1.5rem
  }

  .md\:mr-3 {
    margin-right: 1.5rem
  }

  .md\:mb-3 {
    margin-bottom: 1.5rem
  }

  .md\:ml-3 {
    margin-left: 1.5rem
  }

  .md\:mt-4 {
    margin-top: 2rem
  }

  .md\:mr-4 {
    margin-right: 2rem
  }

  .md\:mb-4 {
    margin-bottom: 2rem
  }

  .md\:ml-4 {
    margin-left: 2rem
  }

  .md\:mt-5 {
    margin-top: 2.5rem
  }

  .md\:mr-5 {
    margin-right: 2.5rem
  }

  .md\:mb-5 {
    margin-bottom: 2.5rem
  }

  .md\:ml-5 {
    margin-left: 2.5rem
  }

  .md\:mt-6 {
    margin-top: 3rem
  }

  .md\:mr-6 {
    margin-right: 3rem
  }

  .md\:mb-6 {
    margin-bottom: 3rem
  }

  .md\:ml-6 {
    margin-left: 3rem
  }

  .md\:mt-7 {
    margin-top: 3.5rem
  }

  .md\:mr-7 {
    margin-right: 3.5rem
  }

  .md\:mb-7 {
    margin-bottom: 3.5rem
  }

  .md\:ml-7 {
    margin-left: 3.5rem
  }

  .md\:mt-8 {
    margin-top: 4rem
  }

  .md\:mr-8 {
    margin-right: 4rem
  }

  .md\:mb-8 {
    margin-bottom: 4rem
  }

  .md\:ml-8 {
    margin-left: 4rem
  }

  .md\:mt-9 {
    margin-top: 4.5rem
  }

  .md\:mr-9 {
    margin-right: 4.5rem
  }

  .md\:mb-9 {
    margin-bottom: 4.5rem
  }

  .md\:ml-9 {
    margin-left: 4.5rem
  }

  .md\:mt-10 {
    margin-top: 5rem
  }

  .md\:mr-10 {
    margin-right: 5rem
  }

  .md\:mb-10 {
    margin-bottom: 5rem
  }

  .md\:ml-10 {
    margin-left: 5rem
  }

  .md\:mt-11 {
    margin-top: 5.5rem
  }

  .md\:mr-11 {
    margin-right: 5.5rem
  }

  .md\:mb-11 {
    margin-bottom: 5.5rem
  }

  .md\:ml-11 {
    margin-left: 5.5rem
  }

  .md\:mt-12 {
    margin-top: 6rem
  }

  .md\:mr-12 {
    margin-right: 6rem
  }

  .md\:mb-12 {
    margin-bottom: 6rem
  }

  .md\:ml-12 {
    margin-left: 6rem
  }

  .md\:mt-13 {
    margin-top: 6.5rem
  }

  .md\:mr-13 {
    margin-right: 6.5rem
  }

  .md\:mb-13 {
    margin-bottom: 6.5rem
  }

  .md\:ml-13 {
    margin-left: 6.5rem
  }

  .md\:mt-14 {
    margin-top: 7rem
  }

  .md\:mr-14 {
    margin-right: 7rem
  }

  .md\:mb-14 {
    margin-bottom: 7rem
  }

  .md\:ml-14 {
    margin-left: 7rem
  }

  .md\:mt-15 {
    margin-top: 7.5rem
  }

  .md\:mr-15 {
    margin-right: 7.5rem
  }

  .md\:mb-15 {
    margin-bottom: 7.5rem
  }

  .md\:ml-15 {
    margin-left: 7.5rem
  }

  .md\:mt-16 {
    margin-top: 8rem
  }

  .md\:mr-16 {
    margin-right: 8rem
  }

  .md\:mb-16 {
    margin-bottom: 8rem
  }

  .md\:ml-16 {
    margin-left: 8rem
  }

  .md\:mt-17 {
    margin-top: 8.5rem
  }

  .md\:mr-17 {
    margin-right: 8.5rem
  }

  .md\:mb-17 {
    margin-bottom: 8.5rem
  }

  .md\:ml-17 {
    margin-left: 8.5rem
  }

  .md\:mt-19 {
    margin-top: 9rem
  }

  .md\:mr-19 {
    margin-right: 9rem
  }

  .md\:mb-19 {
    margin-bottom: 9rem
  }

  .md\:ml-19 {
    margin-left: 9rem
  }

  .md\:mt-20 {
    margin-top: 9.5rem
  }

  .md\:mr-20 {
    margin-right: 9.5rem
  }

  .md\:mb-20 {
    margin-bottom: 9.5rem
  }

  .md\:ml-20 {
    margin-left: 9.5rem
  }

  .md\:mt-22 {
    margin-top: 11rem
  }

  .md\:mr-22 {
    margin-right: 11rem
  }

  .md\:mb-22 {
    margin-bottom: 11rem
  }

  .md\:ml-22 {
    margin-left: 11rem
  }

  .md\:mt-24 {
    margin-top: 12rem
  }

  .md\:mr-24 {
    margin-right: 12rem
  }

  .md\:mb-24 {
    margin-bottom: 12rem
  }

  .md\:ml-24 {
    margin-left: 12rem
  }

  .md\:mt-32 {
    margin-top: 16rem
  }

  .md\:mr-32 {
    margin-right: 16rem
  }

  .md\:mb-32 {
    margin-bottom: 16rem
  }

  .md\:ml-32 {
    margin-left: 16rem
  }

  .md\:mt-40 {
    margin-top: 20rem
  }

  .md\:mr-40 {
    margin-right: 20rem
  }

  .md\:mb-40 {
    margin-bottom: 20rem
  }

  .md\:ml-40 {
    margin-left: 20rem
  }

  .md\:mt-auto {
    margin-top: auto
  }

  .md\:mr-auto {
    margin-right: auto
  }

  .md\:mb-auto {
    margin-bottom: auto
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:mt-px {
    margin-top: 1px
  }

  .md\:mr-px {
    margin-right: 1px
  }

  .md\:mb-px {
    margin-bottom: 1px
  }

  .md\:ml-px {
    margin-left: 1px
  }

  .md\:mt-half {
    margin-top: 0.25rem
  }

  .md\:mr-half {
    margin-right: 0.25rem
  }

  .md\:mb-half {
    margin-bottom: 0.25rem
  }

  .md\:ml-half {
    margin-left: 0.25rem
  }

  .md\:-mt-1 {
    margin-top: -0.5rem
  }

  .md\:-mr-1 {
    margin-right: -0.5rem
  }

  .md\:-mb-1 {
    margin-bottom: -0.5rem
  }

  .md\:-ml-1 {
    margin-left: -0.5rem
  }

  .md\:-mt-2 {
    margin-top: -1rem
  }

  .md\:-mr-2 {
    margin-right: -1rem
  }

  .md\:-mb-2 {
    margin-bottom: -1rem
  }

  .md\:-ml-2 {
    margin-left: -1rem
  }

  .md\:-mt-3 {
    margin-top: -1.5rem
  }

  .md\:-mr-3 {
    margin-right: -1.5rem
  }

  .md\:-mb-3 {
    margin-bottom: -1.5rem
  }

  .md\:-ml-3 {
    margin-left: -1.5rem
  }

  .md\:-mt-4 {
    margin-top: -2rem
  }

  .md\:-mr-4 {
    margin-right: -2rem
  }

  .md\:-mb-4 {
    margin-bottom: -2rem
  }

  .md\:-ml-4 {
    margin-left: -2rem
  }

  .md\:-mt-5 {
    margin-top: -2.5rem
  }

  .md\:-mr-5 {
    margin-right: -2.5rem
  }

  .md\:-mb-5 {
    margin-bottom: -2.5rem
  }

  .md\:-ml-5 {
    margin-left: -2.5rem
  }

  .md\:-mt-6 {
    margin-top: -3rem
  }

  .md\:-mr-6 {
    margin-right: -3rem
  }

  .md\:-mb-6 {
    margin-bottom: -3rem
  }

  .md\:-ml-6 {
    margin-left: -3rem
  }

  .md\:-mt-7 {
    margin-top: -3.5rem
  }

  .md\:-mr-7 {
    margin-right: -3.5rem
  }

  .md\:-mb-7 {
    margin-bottom: -3.5rem
  }

  .md\:-ml-7 {
    margin-left: -3.5rem
  }

  .md\:-mt-8 {
    margin-top: -4rem
  }

  .md\:-mr-8 {
    margin-right: -4rem
  }

  .md\:-mb-8 {
    margin-bottom: -4rem
  }

  .md\:-ml-8 {
    margin-left: -4rem
  }

  .md\:-mt-9 {
    margin-top: -4.5rem
  }

  .md\:-mr-9 {
    margin-right: -4.5rem
  }

  .md\:-mb-9 {
    margin-bottom: -4.5rem
  }

  .md\:-ml-9 {
    margin-left: -4.5rem
  }

  .md\:-mt-10 {
    margin-top: -5rem
  }

  .md\:-mr-10 {
    margin-right: -5rem
  }

  .md\:-mb-10 {
    margin-bottom: -5rem
  }

  .md\:-ml-10 {
    margin-left: -5rem
  }

  .md\:-mt-11 {
    margin-top: -5.5rem
  }

  .md\:-mr-11 {
    margin-right: -5.5rem
  }

  .md\:-mb-11 {
    margin-bottom: -5.5rem
  }

  .md\:-ml-11 {
    margin-left: -5.5rem
  }

  .md\:-mt-12 {
    margin-top: -6rem
  }

  .md\:-mr-12 {
    margin-right: -6rem
  }

  .md\:-mb-12 {
    margin-bottom: -6rem
  }

  .md\:-ml-12 {
    margin-left: -6rem
  }

  .md\:-mt-13 {
    margin-top: -6.5rem
  }

  .md\:-mr-13 {
    margin-right: -6.5rem
  }

  .md\:-mb-13 {
    margin-bottom: -6.5rem
  }

  .md\:-ml-13 {
    margin-left: -6.5rem
  }

  .md\:-mt-14 {
    margin-top: -7rem
  }

  .md\:-mr-14 {
    margin-right: -7rem
  }

  .md\:-mb-14 {
    margin-bottom: -7rem
  }

  .md\:-ml-14 {
    margin-left: -7rem
  }

  .md\:-mt-15 {
    margin-top: -7.5rem
  }

  .md\:-mr-15 {
    margin-right: -7.5rem
  }

  .md\:-mb-15 {
    margin-bottom: -7.5rem
  }

  .md\:-ml-15 {
    margin-left: -7.5rem
  }

  .md\:-mt-16 {
    margin-top: -8rem
  }

  .md\:-mr-16 {
    margin-right: -8rem
  }

  .md\:-mb-16 {
    margin-bottom: -8rem
  }

  .md\:-ml-16 {
    margin-left: -8rem
  }

  .md\:-mt-17 {
    margin-top: -8.5rem
  }

  .md\:-mr-17 {
    margin-right: -8.5rem
  }

  .md\:-mb-17 {
    margin-bottom: -8.5rem
  }

  .md\:-ml-17 {
    margin-left: -8.5rem
  }

  .md\:-mt-19 {
    margin-top: -9rem
  }

  .md\:-mr-19 {
    margin-right: -9rem
  }

  .md\:-mb-19 {
    margin-bottom: -9rem
  }

  .md\:-ml-19 {
    margin-left: -9rem
  }

  .md\:-mt-20 {
    margin-top: -9.5rem
  }

  .md\:-mr-20 {
    margin-right: -9.5rem
  }

  .md\:-mb-20 {
    margin-bottom: -9.5rem
  }

  .md\:-ml-20 {
    margin-left: -9.5rem
  }

  .md\:-mt-22 {
    margin-top: -11rem
  }

  .md\:-mr-22 {
    margin-right: -11rem
  }

  .md\:-mb-22 {
    margin-bottom: -11rem
  }

  .md\:-ml-22 {
    margin-left: -11rem
  }

  .md\:-mt-24 {
    margin-top: -12rem
  }

  .md\:-mr-24 {
    margin-right: -12rem
  }

  .md\:-mb-24 {
    margin-bottom: -12rem
  }

  .md\:-ml-24 {
    margin-left: -12rem
  }

  .md\:-mt-32 {
    margin-top: -16rem
  }

  .md\:-mr-32 {
    margin-right: -16rem
  }

  .md\:-mb-32 {
    margin-bottom: -16rem
  }

  .md\:-ml-32 {
    margin-left: -16rem
  }

  .md\:-mt-40 {
    margin-top: -20rem
  }

  .md\:-mr-40 {
    margin-right: -20rem
  }

  .md\:-mb-40 {
    margin-bottom: -20rem
  }

  .md\:-ml-40 {
    margin-left: -20rem
  }

  .md\:-mt-px {
    margin-top: -1px
  }

  .md\:-mr-px {
    margin-right: -1px
  }

  .md\:-mb-px {
    margin-bottom: -1px
  }

  .md\:-ml-px {
    margin-left: -1px
  }

  .md\:-mt-half {
    margin-top: -0.25rem
  }

  .md\:-mr-half {
    margin-right: -0.25rem
  }

  .md\:-mb-half {
    margin-bottom: -0.25rem
  }

  .md\:-ml-half {
    margin-left: -0.25rem
  }

  .md\:max-h-90 {
    max-height: 90%
  }

  .md\:max-h-full {
    max-height: 100%
  }

  .md\:max-h-screen {
    max-height: 100vh
  }

  .md\:max-w-400 {
    max-width: 25rem
  }

  .md\:max-w-500 {
    max-width: 31.25rem
  }

  .md\:max-w-600 {
    max-width: 37.5rem
  }

  .md\:max-w-700 {
    max-width: 43.75em
  }

  .md\:max-w-800 {
    max-width: 50rem
  }

  .md\:max-w-1000 {
    max-width: 62.5rem
  }

  .md\:max-w-1200 {
    max-width: 75rem
  }

  .md\:max-w-1280 {
    max-width: 80rem
  }

  .md\:max-w-1400 {
    max-width: 87.5rem
  }

  .md\:max-w-1600 {
    max-width: 100rem
  }

  .md\:max-w-1800 {
    max-width: 112.5rem
  }

  .md\:max-w-2000 {
    max-width: 125rem
  }

  .md\:min-h-0 {
    min-height: 0
  }

  .md\:min-h-full {
    min-height: 100%
  }

  .md\:min-h-screen {
    min-height: 100vh
  }

  .md\:min-w-0 {
    min-width: 0
  }

  .md\:min-w-full {
    min-width: 100%
  }

  .md\:object-contain {
    object-fit: contain
  }

  .md\:object-cover {
    object-fit: cover
  }

  .md\:object-fill {
    object-fit: fill
  }

  .md\:object-none {
    object-fit: none
  }

  .md\:object-scale-down {
    object-fit: scale-down
  }

  .md\:object-bottom {
    object-position: bottom
  }

  .md\:object-center {
    object-position: center
  }

  .md\:object-left {
    object-position: left
  }

  .md\:object-left-bottom {
    object-position: left bottom
  }

  .md\:object-left-top {
    object-position: left top
  }

  .md\:object-right {
    object-position: right
  }

  .md\:object-right-bottom {
    object-position: right bottom
  }

  .md\:object-right-top {
    object-position: right top
  }

  .md\:object-top {
    object-position: top
  }

  .md\:opacity-0 {
    opacity: 0
  }

  .md\:opacity-20 {
    opacity: .2
  }

  .md\:opacity-30 {
    opacity: .3
  }

  .md\:opacity-40 {
    opacity: .4
  }

  .md\:opacity-50 {
    opacity: .5
  }

  .md\:opacity-60 {
    opacity: .6
  }

  .md\:opacity-80 {
    opacity: .8
  }

  .md\:opacity-100 {
    opacity: 1
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0
  }

  .md\:hover\:opacity-20:hover {
    opacity: .2
  }

  .md\:hover\:opacity-30:hover {
    opacity: .3
  }

  .md\:hover\:opacity-40:hover {
    opacity: .4
  }

  .md\:hover\:opacity-50:hover {
    opacity: .5
  }

  .md\:hover\:opacity-60:hover {
    opacity: .6
  }

  .md\:hover\:opacity-80:hover {
    opacity: .8
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0
  }

  .md\:focus\:opacity-20:focus {
    opacity: .2
  }

  .md\:focus\:opacity-30:focus {
    opacity: .3
  }

  .md\:focus\:opacity-40:focus {
    opacity: .4
  }

  .md\:focus\:opacity-50:focus {
    opacity: .5
  }

  .md\:focus\:opacity-60:focus {
    opacity: .6
  }

  .md\:focus\:opacity-80:focus {
    opacity: .8
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1
  }

  .md\:outline-none {
    outline: 0
  }

  .md\:focus\:outline-none:focus {
    outline: 0
  }

  .md\:overflow-auto {
    overflow: auto
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:overflow-visible {
    overflow: visible
  }

  .md\:overflow-scroll {
    overflow: scroll
  }

  .md\:overflow-x-auto {
    overflow-x: auto
  }

  .md\:overflow-y-auto {
    overflow-y: auto
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden
  }

  .md\:overflow-x-visible {
    overflow-x: visible
  }

  .md\:overflow-y-visible {
    overflow-y: visible
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-1 {
    padding: 0.5rem
  }

  .md\:p-2 {
    padding: 1rem
  }

  .md\:p-3 {
    padding: 1.5rem
  }

  .md\:p-4 {
    padding: 2rem
  }

  .md\:p-5 {
    padding: 2.5rem
  }

  .md\:p-6 {
    padding: 3rem
  }

  .md\:p-7 {
    padding: 3.5rem
  }

  .md\:p-8 {
    padding: 4rem
  }

  .md\:p-9 {
    padding: 4.5rem
  }

  .md\:p-10 {
    padding: 5rem
  }

  .md\:p-11 {
    padding: 5.5rem
  }

  .md\:p-12 {
    padding: 6rem
  }

  .md\:p-13 {
    padding: 6.5rem
  }

  .md\:p-14 {
    padding: 7rem
  }

  .md\:p-15 {
    padding: 7.5rem
  }

  .md\:p-16 {
    padding: 8rem
  }

  .md\:p-17 {
    padding: 8.5rem
  }

  .md\:p-19 {
    padding: 9rem
  }

  .md\:p-20 {
    padding: 9.5rem
  }

  .md\:p-22 {
    padding: 11rem
  }

  .md\:p-24 {
    padding: 12rem
  }

  .md\:p-32 {
    padding: 16rem
  }

  .md\:p-40 {
    padding: 20rem
  }

  .md\:p-px {
    padding: 1px
  }

  .md\:p-half {
    padding: 0.25rem
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:px-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:px-2 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .md\:px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md\:px-4 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:py-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .md\:px-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .md\:py-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md\:px-6 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .md\:py-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .md\:px-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .md\:py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md\:px-8 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:py-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
  }

  .md\:px-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
  }

  .md\:py-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md\:px-10 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .md\:py-11 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem
  }

  .md\:px-11 {
    padding-left: 5.5rem;
    padding-right: 5.5rem
  }

  .md\:py-12 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:px-12 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:py-13 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem
  }

  .md\:px-13 {
    padding-left: 6.5rem;
    padding-right: 6.5rem
  }

  .md\:py-14 {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .md\:px-14 {
    padding-left: 7rem;
    padding-right: 7rem
  }

  .md\:py-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
  }

  .md\:px-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .md\:py-16 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md\:px-16 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:py-17 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem
  }

  .md\:px-17 {
    padding-left: 8.5rem;
    padding-right: 8.5rem
  }

  .md\:py-19 {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .md\:px-19 {
    padding-left: 9rem;
    padding-right: 9rem
  }

  .md\:py-20 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem
  }

  .md\:px-20 {
    padding-left: 9.5rem;
    padding-right: 9.5rem
  }

  .md\:py-22 {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .md\:px-22 {
    padding-left: 11rem;
    padding-right: 11rem
  }

  .md\:py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .md\:px-24 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .md\:py-32 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .md\:px-32 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .md\:py-40 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .md\:px-40 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .md\:py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .md\:px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pt-1 {
    padding-top: 0.5rem
  }

  .md\:pr-1 {
    padding-right: 0.5rem
  }

  .md\:pb-1 {
    padding-bottom: 0.5rem
  }

  .md\:pl-1 {
    padding-left: 0.5rem
  }

  .md\:pt-2 {
    padding-top: 1rem
  }

  .md\:pr-2 {
    padding-right: 1rem
  }

  .md\:pb-2 {
    padding-bottom: 1rem
  }

  .md\:pl-2 {
    padding-left: 1rem
  }

  .md\:pt-3 {
    padding-top: 1.5rem
  }

  .md\:pr-3 {
    padding-right: 1.5rem
  }

  .md\:pb-3 {
    padding-bottom: 1.5rem
  }

  .md\:pl-3 {
    padding-left: 1.5rem
  }

  .md\:pt-4 {
    padding-top: 2rem
  }

  .md\:pr-4 {
    padding-right: 2rem
  }

  .md\:pb-4 {
    padding-bottom: 2rem
  }

  .md\:pl-4 {
    padding-left: 2rem
  }

  .md\:pt-5 {
    padding-top: 2.5rem
  }

  .md\:pr-5 {
    padding-right: 2.5rem
  }

  .md\:pb-5 {
    padding-bottom: 2.5rem
  }

  .md\:pl-5 {
    padding-left: 2.5rem
  }

  .md\:pt-6 {
    padding-top: 3rem
  }

  .md\:pr-6 {
    padding-right: 3rem
  }

  .md\:pb-6 {
    padding-bottom: 3rem
  }

  .md\:pl-6 {
    padding-left: 3rem
  }

  .md\:pt-7 {
    padding-top: 3.5rem
  }

  .md\:pr-7 {
    padding-right: 3.5rem
  }

  .md\:pb-7 {
    padding-bottom: 3.5rem
  }

  .md\:pl-7 {
    padding-left: 3.5rem
  }

  .md\:pt-8 {
    padding-top: 4rem
  }

  .md\:pr-8 {
    padding-right: 4rem
  }

  .md\:pb-8 {
    padding-bottom: 4rem
  }

  .md\:pl-8 {
    padding-left: 4rem
  }

  .md\:pt-9 {
    padding-top: 4.5rem
  }

  .md\:pr-9 {
    padding-right: 4.5rem
  }

  .md\:pb-9 {
    padding-bottom: 4.5rem
  }

  .md\:pl-9 {
    padding-left: 4.5rem
  }

  .md\:pt-10 {
    padding-top: 5rem
  }

  .md\:pr-10 {
    padding-right: 5rem
  }

  .md\:pb-10 {
    padding-bottom: 5rem
  }

  .md\:pl-10 {
    padding-left: 5rem
  }

  .md\:pt-11 {
    padding-top: 5.5rem
  }

  .md\:pr-11 {
    padding-right: 5.5rem
  }

  .md\:pb-11 {
    padding-bottom: 5.5rem
  }

  .md\:pl-11 {
    padding-left: 5.5rem
  }

  .md\:pt-12 {
    padding-top: 6rem
  }

  .md\:pr-12 {
    padding-right: 6rem
  }

  .md\:pb-12 {
    padding-bottom: 6rem
  }

  .md\:pl-12 {
    padding-left: 6rem
  }

  .md\:pt-13 {
    padding-top: 6.5rem
  }

  .md\:pr-13 {
    padding-right: 6.5rem
  }

  .md\:pb-13 {
    padding-bottom: 6.5rem
  }

  .md\:pl-13 {
    padding-left: 6.5rem
  }

  .md\:pt-14 {
    padding-top: 7rem
  }

  .md\:pr-14 {
    padding-right: 7rem
  }

  .md\:pb-14 {
    padding-bottom: 7rem
  }

  .md\:pl-14 {
    padding-left: 7rem
  }

  .md\:pt-15 {
    padding-top: 7.5rem
  }

  .md\:pr-15 {
    padding-right: 7.5rem
  }

  .md\:pb-15 {
    padding-bottom: 7.5rem
  }

  .md\:pl-15 {
    padding-left: 7.5rem
  }

  .md\:pt-16 {
    padding-top: 8rem
  }

  .md\:pr-16 {
    padding-right: 8rem
  }

  .md\:pb-16 {
    padding-bottom: 8rem
  }

  .md\:pl-16 {
    padding-left: 8rem
  }

  .md\:pt-17 {
    padding-top: 8.5rem
  }

  .md\:pr-17 {
    padding-right: 8.5rem
  }

  .md\:pb-17 {
    padding-bottom: 8.5rem
  }

  .md\:pl-17 {
    padding-left: 8.5rem
  }

  .md\:pt-19 {
    padding-top: 9rem
  }

  .md\:pr-19 {
    padding-right: 9rem
  }

  .md\:pb-19 {
    padding-bottom: 9rem
  }

  .md\:pl-19 {
    padding-left: 9rem
  }

  .md\:pt-20 {
    padding-top: 9.5rem
  }

  .md\:pr-20 {
    padding-right: 9.5rem
  }

  .md\:pb-20 {
    padding-bottom: 9.5rem
  }

  .md\:pl-20 {
    padding-left: 9.5rem
  }

  .md\:pt-22 {
    padding-top: 11rem
  }

  .md\:pr-22 {
    padding-right: 11rem
  }

  .md\:pb-22 {
    padding-bottom: 11rem
  }

  .md\:pl-22 {
    padding-left: 11rem
  }

  .md\:pt-24 {
    padding-top: 12rem
  }

  .md\:pr-24 {
    padding-right: 12rem
  }

  .md\:pb-24 {
    padding-bottom: 12rem
  }

  .md\:pl-24 {
    padding-left: 12rem
  }

  .md\:pt-32 {
    padding-top: 16rem
  }

  .md\:pr-32 {
    padding-right: 16rem
  }

  .md\:pb-32 {
    padding-bottom: 16rem
  }

  .md\:pl-32 {
    padding-left: 16rem
  }

  .md\:pt-40 {
    padding-top: 20rem
  }

  .md\:pr-40 {
    padding-right: 20rem
  }

  .md\:pb-40 {
    padding-bottom: 20rem
  }

  .md\:pl-40 {
    padding-left: 20rem
  }

  .md\:pt-px {
    padding-top: 1px
  }

  .md\:pr-px {
    padding-right: 1px
  }

  .md\:pb-px {
    padding-bottom: 1px
  }

  .md\:pl-px {
    padding-left: 1px
  }

  .md\:pt-half {
    padding-top: 0.25rem
  }

  .md\:pr-half {
    padding-right: 0.25rem
  }

  .md\:pb-half {
    padding-bottom: 0.25rem
  }

  .md\:pl-half {
    padding-left: 0.25rem
  }

  .md\:placeholder-transparent::placeholder {
    color: transparent
  }

  .md\:placeholder-black::placeholder {
    color: @color-black
  }

  .md\:placeholder-white::placeholder {
    color: @color-white
  }

  .md\:placeholder-grey-10::placeholder {
    color: fade(@color-grey, 10%)
  }

  .md\:placeholder-grey-30::placeholder {
    color: fade(@color-grey, 30%)
  }

  .md\:placeholder-grey-40::placeholder {
    color: fade(@color-grey, 40%)
  }

  .md\:placeholder-grey-60::placeholder {
    color: fade(@color-grey, 60%)
  }

  .md\:placeholder-grey-90::placeholder {
    color: fade(@color-grey, 90%)
  }

  .md\:placeholder-grey::placeholder {
    color: @color-grey
  }

  .md\:placeholder-grey-metal::placeholder {
    color: @color-grey
  }

  .md\:placeholder-grey-divider::placeholder {
    color: fade(@color-grey, 20%)
  }

  .md\:placeholder-grey-light::placeholder {
    color: @color-grey-light
  }

  .md\:placeholder-grey-header::placeholder {
    color: fade(@color-grey, 90%)
  }

  .md\:placeholder-body::placeholder {
    color: @color-body
  }

  .md\:placeholder-yellow::placeholder {
    color: @color-yellow
  }

  .md\:placeholder-orange::placeholder {
    color: @color-orange
  }

  .md\:placeholder-stone::placeholder {
    color: @color-stone
  }

  .md\:placeholder-khaki::placeholder {
    color: @color-khaki
  }

  .md\:placeholder-green::placeholder {
    color: @color-green
  }

  .md\:placeholder-blue::placeholder {
    color: @color-blue
  }

  .md\:placeholder-red::placeholder {
    color: @color-red
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    color: @color-black
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    color: @color-white
  }

  .md\:focus\:placeholder-grey-10:focus::placeholder {
    color: fade(@color-grey, 10%)
  }

  .md\:focus\:placeholder-grey-30:focus::placeholder {
    color: fade(@color-grey, 30%)
  }

  .md\:focus\:placeholder-grey-40:focus::placeholder {
    color: fade(@color-grey, 40%)
  }

  .md\:focus\:placeholder-grey-60:focus::placeholder {
    color: fade(@color-grey, 60%)
  }

  .md\:focus\:placeholder-grey-90:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .md\:focus\:placeholder-grey:focus::placeholder {
    color: @color-grey
  }

  .md\:focus\:placeholder-grey-metal:focus::placeholder {
    color: @color-grey
  }

  .md\:focus\:placeholder-grey-divider:focus::placeholder {
    color: fade(@color-grey, 20%)
  }

  .md\:focus\:placeholder-grey-light:focus::placeholder {
    color: @color-grey-light
  }

  .md\:focus\:placeholder-grey-header:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .md\:focus\:placeholder-body:focus::placeholder {
    color: @color-body
  }

  .md\:focus\:placeholder-yellow:focus::placeholder {
    color: @color-yellow
  }

  .md\:focus\:placeholder-orange:focus::placeholder {
    color: @color-orange
  }

  .md\:focus\:placeholder-stone:focus::placeholder {
    color: @color-stone
  }

  .md\:focus\:placeholder-khaki:focus::placeholder {
    color: @color-khaki
  }

  .md\:focus\:placeholder-green:focus::placeholder {
    color: @color-green
  }

  .md\:focus\:placeholder-blue:focus::placeholder {
    color: @color-blue
  }

  .md\:focus\:placeholder-red:focus::placeholder {
    color: @color-red
  }

  .md\:pointer-events-none {
    pointer-events: none
  }

  .md\:pointer-events-auto {
    pointer-events: auto
  }

  .md\:static {
    position: static
  }

  .md\:fixed {
    position: fixed
  }

  .md\:absolute {
    position: absolute
  }

  .md\:relative {
    position: relative
  }

  .md\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .md\:inset-x-0 {
    right: 0;
    left: 0
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .md\:inset-x-auto {
    right: auto;
    left: auto
  }

  .md\:top-0 {
    top: 0
  }

  .md\:right-0 {
    right: 0
  }

  .md\:bottom-0 {
    bottom: 0
  }

  .md\:left-0 {
    left: 0
  }

  .md\:top-auto {
    top: auto
  }

  .md\:right-auto {
    right: auto
  }

  .md\:bottom-auto {
    bottom: auto
  }

  .md\:left-auto {
    left: auto
  }

  .md\:resize-none {
    resize: none
  }

  .md\:resize-y {
    resize: vertical
  }

  .md\:resize-x {
    resize: horizontal
  }

  .md\:resize {
    resize: both
  }

  .md\:shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:shadow-none {
    box-shadow: none
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .md\:fill-current {
    fill: currentColor
  }

  .md\:stroke-current {
    stroke: currentColor
  }

  .md\:table-auto {
    table-layout: auto
  }

  .md\:table-fixed {
    table-layout: fixed
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-center {
    text-align: center
  }

  .md\:text-right {
    text-align: right
  }

  .md\:text-justify {
    text-align: justify
  }

  .md\:text-transparent {
    color: transparent
  }

  .md\:text-black {
    color: @color-black
  }

  .md\:text-white {
    color: @color-white
  }

  .md\:text-grey-10 {
    color: fade(@color-grey, 10%)
  }

  .md\:text-grey-30 {
    color: fade(@color-grey, 30%)
  }

  .md\:text-grey-40 {
    color: fade(@color-grey, 40%)
  }

  .md\:text-grey-60 {
    color: fade(@color-grey, 60%)
  }

  .md\:text-grey-90 {
    color: fade(@color-grey, 90%)
  }

  .md\:text-grey {
    color: @color-grey
  }

  .md\:text-grey-metal {
    color: @color-grey
  }

  .md\:text-grey-divider {
    color: fade(@color-grey, 20%)
  }

  .md\:text-grey-light {
    color: @color-grey-light
  }

  .md\:text-grey-header {
    color: fade(@color-grey, 90%)
  }

  .md\:text-body {
    color: @color-body
  }

  .md\:text-yellow {
    color: @color-yellow
  }

  .md\:text-orange {
    color: @color-orange
  }

  .md\:text-stone {
    color: @color-stone
  }

  .md\:text-khaki {
    color: @color-khaki
  }

  .md\:text-green {
    color: @color-green
  }

  .md\:text-blue {
    color: @color-blue
  }

  .md\:text-red {
    color: @color-red
  }

  .md\:hover\:text-transparent:hover {
    color: transparent
  }

  .md\:hover\:text-black:hover {
    color: @color-black
  }

  .md\:hover\:text-white:hover {
    color: @color-white
  }

  .md\:hover\:text-grey-10:hover {
    color: fade(@color-grey, 10%)
  }

  .md\:hover\:text-grey-30:hover {
    color: fade(@color-grey, 30%)
  }

  .md\:hover\:text-grey-40:hover {
    color: fade(@color-grey, 40%)
  }

  .md\:hover\:text-grey-60:hover {
    color: fade(@color-grey, 60%)
  }

  .md\:hover\:text-grey-90:hover {
    color: fade(@color-grey, 90%)
  }

  .md\:hover\:text-grey:hover {
    color: @color-grey
  }

  .md\:hover\:text-grey-metal:hover {
    color: @color-grey
  }

  .md\:hover\:text-grey-divider:hover {
    color: fade(@color-grey, 20%)
  }

  .md\:hover\:text-grey-light:hover {
    color: @color-grey-light
  }

  .md\:hover\:text-grey-header:hover {
    color: fade(@color-grey, 90%)
  }

  .md\:hover\:text-body:hover {
    color: @color-body
  }

  .md\:hover\:text-yellow:hover {
    color: @color-yellow
  }

  .md\:hover\:text-orange:hover {
    color: @color-orange
  }

  .md\:hover\:text-stone:hover {
    color: @color-stone
  }

  .md\:hover\:text-khaki:hover {
    color: @color-khaki
  }

  .md\:hover\:text-green:hover {
    color: @color-green
  }

  .md\:hover\:text-blue:hover {
    color: @color-blue
  }

  .md\:hover\:text-red:hover {
    color: @color-red
  }

  .md\:focus\:text-transparent:focus {
    color: transparent
  }

  .md\:focus\:text-black:focus {
    color: @color-black
  }

  .md\:focus\:text-white:focus {
    color: @color-white
  }

  .md\:focus\:text-grey-10:focus {
    color: fade(@color-grey, 10%)
  }

  .md\:focus\:text-grey-30:focus {
    color: fade(@color-grey, 30%)
  }

  .md\:focus\:text-grey-40:focus {
    color: fade(@color-grey, 40%)
  }

  .md\:focus\:text-grey-60:focus {
    color: fade(@color-grey, 60%)
  }

  .md\:focus\:text-grey-90:focus {
    color: fade(@color-grey, 90%)
  }

  .md\:focus\:text-grey:focus {
    color: @color-grey
  }

  .md\:focus\:text-grey-metal:focus {
    color: @color-grey
  }

  .md\:focus\:text-grey-divider:focus {
    color: fade(@color-grey, 20%)
  }

  .md\:focus\:text-grey-light:focus {
    color: @color-grey-light
  }

  .md\:focus\:text-grey-header:focus {
    color: fade(@color-grey, 90%)
  }

  .md\:focus\:text-body:focus {
    color: @color-body
  }

  .md\:focus\:text-yellow:focus {
    color: @color-yellow
  }

  .md\:focus\:text-orange:focus {
    color: @color-orange
  }

  .md\:focus\:text-stone:focus {
    color: @color-stone
  }

  .md\:focus\:text-khaki:focus {
    color: @color-khaki
  }

  .md\:focus\:text-green:focus {
    color: @color-green
  }

  .md\:focus\:text-blue:focus {
    color: @color-blue
  }

  .md\:focus\:text-red:focus {
    color: @color-red
  }

  .md\:text-base {
    font-size: .6875rem
  }

  .md\:text-md {
    font-size: 0.9375rem
  }

  .md\:text-lg {
    font-size: 1rem
  }

  .md\:text-xl {
    font-size: 1.375rem
  }

  .md\:text-2xl {
    font-size: 2.0625rem
  }

  .md\:italic {
    font-style: italic
  }

  .md\:not-italic {
    font-style: normal
  }

  .md\:uppercase {
    text-transform: uppercase
  }

  .md\:lowercase {
    text-transform: lowercase
  }

  .md\:capitalize {
    text-transform: capitalize
  }

  .md\:normal-case {
    text-transform: none
  }

  .md\:underline {
    text-decoration: underline
  }

  .md\:line-through {
    text-decoration: line-through
  }

  .md\:no-underline {
    text-decoration: none
  }

  .md\:hover\:underline:hover {
    text-decoration: underline
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none
  }

  .md\:focus\:underline:focus {
    text-decoration: underline
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em
  }

  .md\:tracking-normal {
    letter-spacing: 0
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em
  }

  .md\:align-baseline {
    vertical-align: baseline
  }

  .md\:align-top {
    vertical-align: top
  }

  .md\:align-middle {
    vertical-align: middle
  }

  .md\:align-bottom {
    vertical-align: bottom
  }

  .md\:align-text-top {
    vertical-align: text-top
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom
  }

  .md\:visible {
    visibility: visible
  }

  .md\:invisible {
    visibility: hidden
  }

  .md\:whitespace-normal {
    white-space: normal
  }

  .md\:whitespace-no-wrap {
    white-space: nowrap
  }

  .md\:whitespace-pre {
    white-space: pre
  }

  .md\:whitespace-pre-line {
    white-space: pre-line
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .md\:w-0 {
    width: 0
  }

  .md\:w-1 {
    width: 0.5rem
  }

  .md\:w-2 {
    width: 1rem
  }

  .md\:w-3 {
    width: 1.5rem
  }

  .md\:w-4 {
    width: 2rem
  }

  .md\:w-5 {
    width: 2.5rem
  }

  .md\:w-6 {
    width: 3rem
  }

  .md\:w-7 {
    width: 3.5rem
  }

  .md\:w-8 {
    width: 4rem
  }

  .md\:w-9 {
    width: 4.5rem
  }

  .md\:w-10 {
    width: 5rem
  }

  .md\:w-11 {
    width: 5.5rem
  }

  .md\:w-12 {
    width: 6rem
  }

  .md\:w-13 {
    width: 6.5rem
  }

  .md\:w-14 {
    width: 7rem
  }

  .md\:w-15 {
    width: 7.5rem
  }

  .md\:w-16 {
    width: 8rem
  }

  .md\:w-17 {
    width: 8.5rem
  }

  .md\:w-19 {
    width: 9rem
  }

  .md\:w-20 {
    width: 9.5rem
  }

  .md\:w-22 {
    width: 11rem
  }

  .md\:w-24 {
    width: 12rem
  }

  .md\:w-32 {
    width: 16rem
  }

  .md\:w-40 {
    width: 20rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-px {
    width: 1px
  }

  .md\:w-half {
    width: 0.25rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-2\/4 {
    width: 50%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-1\/6 {
    width: 16.666667%
  }

  .md\:w-2\/6 {
    width: 33.333333%
  }

  .md\:w-3\/6 {
    width: 50%
  }

  .md\:w-4\/6 {
    width: 66.666667%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-1\/12 {
    width: 8.333333%
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.333333%
  }

  .md\:w-11\/12 {
    width: 91.666667%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:w-screen {
    width: 100vw
  }

  .md\:z-0 {
    z-index: 0
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:z-20 {
    z-index: 20
  }

  .md\:z-30 {
    z-index: 30
  }

  .md\:z-40 {
    z-index: 40
  }

  .md\:z-50 {
    z-index: 50
  }

  .md\:z-auto {
    z-index: auto
  }
}

@media (min-width: @l-break) {
  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:bg-transparent {
    background-color: transparent
  }

  .lg\:bg-black {
    background-color: @color-black
  }

  .lg\:bg-white {
    background-color: @color-white
  }

  .lg\:bg-grey-10 {
    background-color: fade(@color-grey, 10%)
  }

  .lg\:bg-grey-30 {
    background-color: fade(@color-grey, 30%)
  }

  .lg\:bg-grey-40 {
    background-color: fade(@color-grey, 40%)
  }

  .lg\:bg-grey-60 {
    background-color: fade(@color-grey, 60%)
  }

  .lg\:bg-grey-90 {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:bg-grey {
    background-color: @color-grey
  }

  .lg\:bg-grey-metal {
    background-color: @color-grey
  }

  .lg\:bg-grey-divider {
    background-color: fade(@color-grey, 20%)
  }

  .lg\:bg-grey-light {
    background-color: @color-grey-light
  }

  .lg\:bg-grey-header {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:bg-body {
    background-color: @color-body
  }

  .lg\:bg-yellow {
    background-color: @color-yellow
  }

  .lg\:bg-orange {
    background-color: @color-orange
  }

  .lg\:bg-stone {
    background-color: @color-stone
  }

  .lg\:bg-khaki {
    background-color: @color-khaki
  }

  .lg\:bg-green {
    background-color: @color-green
  }

  .lg\:bg-blue {
    background-color: @color-blue
  }

  .lg\:bg-red {
    background-color: @color-red
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .lg\:hover\:bg-black:hover {
    background-color: @color-black
  }

  .lg\:hover\:bg-white:hover {
    background-color: @color-white
  }

  .lg\:hover\:bg-grey-10:hover {
    background-color: fade(@color-grey, 10%)
  }

  .lg\:hover\:bg-grey-30:hover {
    background-color: fade(@color-grey, 30%)
  }

  .lg\:hover\:bg-grey-40:hover {
    background-color: fade(@color-grey, 40%)
  }

  .lg\:hover\:bg-grey-60:hover {
    background-color: fade(@color-grey, 60%)
  }

  .lg\:hover\:bg-grey-90:hover {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:hover\:bg-grey:hover {
    background-color: @color-grey
  }

  .lg\:hover\:bg-grey-metal:hover {
    background-color: @color-grey
  }

  .lg\:hover\:bg-grey-divider:hover {
    background-color: fade(@color-grey, 20%)
  }

  .lg\:hover\:bg-grey-light:hover {
    background-color: @color-grey-light
  }

  .lg\:hover\:bg-grey-header:hover {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:hover\:bg-body:hover {
    background-color: @color-body
  }

  .lg\:hover\:bg-yellow:hover {
    background-color: @color-yellow
  }

  .lg\:hover\:bg-orange:hover {
    background-color: @color-orange
  }

  .lg\:hover\:bg-stone:hover {
    background-color: @color-stone
  }

  .lg\:hover\:bg-khaki:hover {
    background-color: @color-khaki
  }

  .lg\:hover\:bg-green:hover {
    background-color: @color-green
  }

  .lg\:hover\:bg-blue:hover {
    background-color: @color-blue
  }

  .lg\:hover\:bg-red:hover {
    background-color: @color-red
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .lg\:focus\:bg-black:focus {
    background-color: @color-black
  }

  .lg\:focus\:bg-white:focus {
    background-color: @color-white
  }

  .lg\:focus\:bg-grey-10:focus {
    background-color: fade(@color-grey, 10%)
  }

  .lg\:focus\:bg-grey-30:focus {
    background-color: fade(@color-grey, 30%)
  }

  .lg\:focus\:bg-grey-40:focus {
    background-color: fade(@color-grey, 40%)
  }

  .lg\:focus\:bg-grey-60:focus {
    background-color: fade(@color-grey, 60%)
  }

  .lg\:focus\:bg-grey-90:focus {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:focus\:bg-grey:focus {
    background-color: @color-grey
  }

  .lg\:focus\:bg-grey-metal:focus {
    background-color: @color-grey
  }

  .lg\:focus\:bg-grey-divider:focus {
    background-color: fade(@color-grey, 20%)
  }

  .lg\:focus\:bg-grey-light:focus {
    background-color: @color-grey-light
  }

  .lg\:focus\:bg-grey-header:focus {
    background-color: fade(@color-grey, 90%)
  }

  .lg\:focus\:bg-body:focus {
    background-color: @color-body
  }

  .lg\:focus\:bg-yellow:focus {
    background-color: @color-yellow
  }

  .lg\:focus\:bg-orange:focus {
    background-color: @color-orange
  }

  .lg\:focus\:bg-stone:focus {
    background-color: @color-stone
  }

  .lg\:focus\:bg-khaki:focus {
    background-color: @color-khaki
  }

  .lg\:focus\:bg-green:focus {
    background-color: @color-green
  }

  .lg\:focus\:bg-blue:focus {
    background-color: @color-blue
  }

  .lg\:focus\:bg-red:focus {
    background-color: @color-red
  }

  .lg\:bg-bottom {
    background-position: bottom
  }

  .lg\:bg-center {
    background-position: center
  }

  .lg\:bg-left {
    background-position: left
  }

  .lg\:bg-left-bottom {
    background-position: left bottom
  }

  .lg\:bg-left-top {
    background-position: left top
  }

  .lg\:bg-right {
    background-position: right
  }

  .lg\:bg-right-bottom {
    background-position: right bottom
  }

  .lg\:bg-right-top {
    background-position: right top
  }

  .lg\:bg-top {
    background-position: top
  }

  .lg\:bg-repeat {
    background-repeat: repeat
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .lg\:bg-repeat-round {
    background-repeat: round
  }

  .lg\:bg-repeat-space {
    background-repeat: space
  }

  .lg\:bg-auto {
    background-size: auto
  }

  .lg\:bg-cover {
    background-size: cover
  }

  .lg\:bg-contain {
    background-size: contain
  }

  .lg\:border-collapse {
    border-collapse: collapse
  }

  .lg\:border-separate {
    border-collapse: separate
  }

  .lg\:border-transparent {
    border-color: transparent
  }

  .lg\:border-black {
    border-color: @color-black
  }

  .lg\:border-white {
    border-color: @color-white
  }

  .lg\:border-grey-10 {
    border-color: fade(@color-grey, 10%)
  }

  .lg\:border-grey-30 {
    border-color: fade(@color-grey, 30%)
  }

  .lg\:border-grey-40 {
    border-color: fade(@color-grey, 40%)
  }

  .lg\:border-grey-60 {
    border-color: fade(@color-grey, 60%)
  }

  .lg\:border-grey-90 {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:border-grey {
    border-color: @color-grey
  }

  .lg\:border-grey-metal {
    border-color: @color-grey
  }

  .lg\:border-grey-divider {
    border-color: fade(@color-grey, 20%)
  }

  .lg\:border-grey-light {
    border-color: @color-grey-light
  }

  .lg\:border-grey-header {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:border-body {
    border-color: @color-body
  }

  .lg\:border-yellow {
    border-color: @color-yellow
  }

  .lg\:border-orange {
    border-color: @color-orange
  }

  .lg\:border-stone {
    border-color: @color-stone
  }

  .lg\:border-khaki {
    border-color: @color-khaki
  }

  .lg\:border-green {
    border-color: @color-green
  }

  .lg\:border-blue {
    border-color: @color-blue
  }

  .lg\:border-red {
    border-color: @color-red
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .lg\:hover\:border-black:hover {
    border-color: @color-black
  }

  .lg\:hover\:border-white:hover {
    border-color: @color-white
  }

  .lg\:hover\:border-grey-10:hover {
    border-color: fade(@color-grey, 10%)
  }

  .lg\:hover\:border-grey-30:hover {
    border-color: fade(@color-grey, 30%)
  }

  .lg\:hover\:border-grey-40:hover {
    border-color: fade(@color-grey, 40%)
  }

  .lg\:hover\:border-grey-60:hover {
    border-color: fade(@color-grey, 60%)
  }

  .lg\:hover\:border-grey-90:hover {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:hover\:border-grey:hover {
    border-color: @color-grey
  }

  .lg\:hover\:border-grey-metal:hover {
    border-color: @color-grey
  }

  .lg\:hover\:border-grey-divider:hover {
    border-color: fade(@color-grey, 20%)
  }

  .lg\:hover\:border-grey-light:hover {
    border-color: @color-grey-light
  }

  .lg\:hover\:border-grey-header:hover {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:hover\:border-body:hover {
    border-color: @color-body
  }

  .lg\:hover\:border-yellow:hover {
    border-color: @color-yellow
  }

  .lg\:hover\:border-orange:hover {
    border-color: @color-orange
  }

  .lg\:hover\:border-stone:hover {
    border-color: @color-stone
  }

  .lg\:hover\:border-khaki:hover {
    border-color: @color-khaki
  }

  .lg\:hover\:border-green:hover {
    border-color: @color-green
  }

  .lg\:hover\:border-blue:hover {
    border-color: @color-blue
  }

  .lg\:hover\:border-red:hover {
    border-color: @color-red
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .lg\:focus\:border-black:focus {
    border-color: @color-black
  }

  .lg\:focus\:border-white:focus {
    border-color: @color-white
  }

  .lg\:focus\:border-grey-10:focus {
    border-color: fade(@color-grey, 10%)
  }

  .lg\:focus\:border-grey-30:focus {
    border-color: fade(@color-grey, 30%)
  }

  .lg\:focus\:border-grey-40:focus {
    border-color: fade(@color-grey, 40%)
  }

  .lg\:focus\:border-grey-60:focus {
    border-color: fade(@color-grey, 60%)
  }

  .lg\:focus\:border-grey-90:focus {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:focus\:border-grey:focus {
    border-color: @color-grey
  }

  .lg\:focus\:border-grey-metal:focus {
    border-color: @color-grey
  }

  .lg\:focus\:border-grey-divider:focus {
    border-color: fade(@color-grey, 20%)
  }

  .lg\:focus\:border-grey-light:focus {
    border-color: @color-grey-light
  }

  .lg\:focus\:border-grey-header:focus {
    border-color: fade(@color-grey, 90%)
  }

  .lg\:focus\:border-body:focus {
    border-color: @color-body
  }

  .lg\:focus\:border-yellow:focus {
    border-color: @color-yellow
  }

  .lg\:focus\:border-orange:focus {
    border-color: @color-orange
  }

  .lg\:focus\:border-stone:focus {
    border-color: @color-stone
  }

  .lg\:focus\:border-khaki:focus {
    border-color: @color-khaki
  }

  .lg\:focus\:border-green:focus {
    border-color: @color-green
  }

  .lg\:focus\:border-blue:focus {
    border-color: @color-blue
  }

  .lg\:focus\:border-red:focus {
    border-color: @color-red
  }

  .lg\:rounded-none {
    border-radius: 0
  }

  .lg\:rounded {
    border-radius: .125rem
  }

  .lg\:rounded-lg {
    border-radius: .375rem
  }

  .lg\:rounded-full {
    border-radius: 20em
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-t {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem
  }

  .lg\:rounded-r {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
  }

  .lg\:rounded-b {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .lg\:rounded-l {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 20em;
    border-top-right-radius: 20em
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .lg\:rounded-tl {
    border-top-left-radius: .125rem
  }

  .lg\:rounded-tr {
    border-top-right-radius: .125rem
  }

  .lg\:rounded-br {
    border-bottom-right-radius: .125rem
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: .125rem
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: .375rem
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: .375rem
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: .375rem
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .375rem
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 20em
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 20em
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 20em
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 20em
  }

  .lg\:border-solid {
    border-style: solid
  }

  .lg\:border-dashed {
    border-style: dashed
  }

  .lg\:border-dotted {
    border-style: dotted
  }

  .lg\:border-double {
    border-style: double
  }

  .lg\:border-none {
    border-style: none
  }

  .lg\:border-0 {
    border-width: 0
  }

  .lg\:border-3 {
    border-width: .1875rem
  }

  .lg\:border-6 {
    border-width: .375rem
  }

  .lg\:border {
    border-width: 1px
  }

  .lg\:border-t-0 {
    border-top-width: 0
  }

  .lg\:border-r-0 {
    border-right-width: 0
  }

  .lg\:border-b-0 {
    border-bottom-width: 0
  }

  .lg\:border-l-0 {
    border-left-width: 0
  }

  .lg\:border-t-3 {
    border-top-width: .1875rem
  }

  .lg\:border-r-3 {
    border-right-width: .1875rem
  }

  .lg\:border-b-3 {
    border-bottom-width: .1875rem
  }

  .lg\:border-l-3 {
    border-left-width: .1875rem
  }

  .lg\:border-t-6 {
    border-top-width: .375rem
  }

  .lg\:border-r-6 {
    border-right-width: .375rem
  }

  .lg\:border-b-6 {
    border-bottom-width: .375rem
  }

  .lg\:border-l-6 {
    border-left-width: .375rem
  }

  .lg\:border-t {
    border-top-width: 1px
  }

  .lg\:border-r {
    border-right-width: 1px
  }

  .lg\:border-b {
    border-bottom-width: 1px
  }

  .lg\:border-l {
    border-left-width: 1px
  }

  .lg\:cursor-auto {
    cursor: auto
  }

  .lg\:cursor-default {
    cursor: default
  }

  .lg\:cursor-pointer {
    cursor: pointer
  }

  .lg\:cursor-wait {
    cursor: wait
  }

  .lg\:cursor-text {
    cursor: text
  }

  .lg\:cursor-move {
    cursor: move
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:inline {
    display: inline
  }

  .lg\:flex {
    display: flex
  }

  .lg\:inline-flex {
    display: inline-flex
  }

  .lg\:table {
    display: table
  }

  .lg\:table-row {
    display: table-row
  }

  .lg\:table-cell {
    display: table-cell
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .lg\:flex-wrap {
    flex-wrap: wrap
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-end {
    align-items: flex-end
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:items-baseline {
    align-items: baseline
  }

  .lg\:items-stretch {
    align-items: stretch
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:self-start {
    align-self: flex-start
  }

  .lg\:self-end {
    align-self: flex-end
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:self-stretch {
    align-self: stretch
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:justify-center {
    justify-content: center
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:justify-around {
    justify-content: space-around
  }

  .lg\:content-center {
    align-content: center
  }

  .lg\:content-start {
    align-content: flex-start
  }

  .lg\:content-end {
    align-content: flex-end
  }

  .lg\:content-between {
    align-content: space-between
  }

  .lg\:content-around {
    align-content: space-around
  }

  .lg\:flex-1 {
    flex: 1 1 0%
  }

  .lg\:flex-auto {
    flex: 1 1 auto
  }

  .lg\:flex-initial {
    flex: 0 1 auto
  }

  .lg\:flex-none {
    flex: none
  }

  .lg\:flex-grow-0 {
    flex-grow: 0
  }

  .lg\:flex-grow {
    flex-grow: 1
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:flex-shrink {
    flex-shrink: 1
  }

  .lg\:order-1 {
    order: 1
  }

  .lg\:order-2 {
    order: 2
  }

  .lg\:order-3 {
    order: 3
  }

  .lg\:order-4 {
    order: 4
  }

  .lg\:order-5 {
    order: 5
  }

  .lg\:order-6 {
    order: 6
  }

  .lg\:order-7 {
    order: 7
  }

  .lg\:order-8 {
    order: 8
  }

  .lg\:order-9 {
    order: 9
  }

  .lg\:order-10 {
    order: 10
  }

  .lg\:order-11 {
    order: 11
  }

  .lg\:order-12 {
    order: 12
  }

  .lg\:order-first {
    order: -9999
  }

  .lg\:order-last {
    order: 9999
  }

  .lg\:order-none {
    order: 0
  }

  .lg\:float-right {
    float: right
  }

  .lg\:float-left {
    float: left
  }

  .lg\:float-none {
    float: none
  }

  .lg\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .lg\:font-body {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
  }

  .lg\:font-normal {
    font-weight: 400
  }

  .lg\:font-semibold {
    font-weight: 600
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .lg\:h-0 {
    height: 0
  }

  .lg\:h-1 {
    height: 0.5rem
  }

  .lg\:h-2 {
    height: 1rem
  }

  .lg\:h-3 {
    height: 1.5rem
  }

  .lg\:h-4 {
    height: 2rem
  }

  .lg\:h-5 {
    height: 2.5rem
  }

  .lg\:h-6 {
    height: 3rem
  }

  .lg\:h-7 {
    height: 3.5rem
  }

  .lg\:h-8 {
    height: 4rem
  }

  .lg\:h-9 {
    height: 4.5rem
  }

  .lg\:h-10 {
    height: 5rem
  }

  .lg\:h-11 {
    height: 5.5rem
  }

  .lg\:h-12 {
    height: 6rem
  }

  .lg\:h-13 {
    height: 6.5rem
  }

  .lg\:h-14 {
    height: 7rem
  }

  .lg\:h-15 {
    height: 7.5rem
  }

  .lg\:h-16 {
    height: 8rem
  }

  .lg\:h-17 {
    height: 8.5rem
  }

  .lg\:h-19 {
    height: 9rem
  }

  .lg\:h-20 {
    height: 9.5rem
  }

  .lg\:h-22 {
    height: 11rem
  }

  .lg\:h-24 {
    height: 12rem
  }

  .lg\:h-32 {
    height: 16rem
  }

  .lg\:h-40 {
    height: 20rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:h-px {
    height: 1px
  }

  .lg\:h-half {
    height: 0.25rem
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:h-screen {
    height: 100vh
  }

  .lg\:leading-base {
    line-height: .75rem
  }

  .lg\:leading-sm {
    line-height: 1.125rem
  }

  .lg\:leading-lg {
    line-height: 1.5rem
  }

  .lg\:leading-xl {
    line-height: 1.875rem
  }

  .lg\:leading-2xl {
    line-height: 2.25rem
  }

  .lg\:list-none {
    list-style-type: none
  }

  .lg\:list-disc {
    list-style-type: disc
  }

  .lg\:list-decimal {
    list-style-type: decimal
  }

  .lg\:m-0 {
    margin: 0
  }

  .lg\:m-1 {
    margin: 0.5rem
  }

  .lg\:m-2 {
    margin: 1rem
  }

  .lg\:m-3 {
    margin: 1.5rem
  }

  .lg\:m-4 {
    margin: 2rem
  }

  .lg\:m-5 {
    margin: 2.5rem
  }

  .lg\:m-6 {
    margin: 3rem
  }

  .lg\:m-7 {
    margin: 3.5rem
  }

  .lg\:m-8 {
    margin: 4rem
  }

  .lg\:m-9 {
    margin: 4.5rem
  }

  .lg\:m-10 {
    margin: 5rem
  }

  .lg\:m-11 {
    margin: 5.5rem
  }

  .lg\:m-12 {
    margin: 6rem
  }

  .lg\:m-13 {
    margin: 6.5rem
  }

  .lg\:m-14 {
    margin: 7rem
  }

  .lg\:m-15 {
    margin: 7.5rem
  }

  .lg\:m-16 {
    margin: 8rem
  }

  .lg\:m-17 {
    margin: 8.5rem
  }

  .lg\:m-19 {
    margin: 9rem
  }

  .lg\:m-20 {
    margin: 9.5rem
  }

  .lg\:m-22 {
    margin: 11rem
  }

  .lg\:m-24 {
    margin: 12rem
  }

  .lg\:m-32 {
    margin: 16rem
  }

  .lg\:m-40 {
    margin: 20rem
  }

  .lg\:m-auto {
    margin: auto
  }

  .lg\:m-px {
    margin: 1px
  }

  .lg\:m-half {
    margin: 0.25rem
  }

  .lg\:-m-1 {
    margin: -0.5rem
  }

  .lg\:-m-2 {
    margin: -1rem
  }

  .lg\:-m-3 {
    margin: -1.5rem
  }

  .lg\:-m-4 {
    margin: -2rem
  }

  .lg\:-m-5 {
    margin: -2.5rem
  }

  .lg\:-m-6 {
    margin: -3rem
  }

  .lg\:-m-7 {
    margin: -3.5rem
  }

  .lg\:-m-8 {
    margin: -4rem
  }

  .lg\:-m-9 {
    margin: -4.5rem
  }

  .lg\:-m-10 {
    margin: -5rem
  }

  .lg\:-m-11 {
    margin: -5.5rem
  }

  .lg\:-m-12 {
    margin: -6rem
  }

  .lg\:-m-13 {
    margin: -6.5rem
  }

  .lg\:-m-14 {
    margin: -7rem
  }

  .lg\:-m-15 {
    margin: -7.5rem
  }

  .lg\:-m-16 {
    margin: -8rem
  }

  .lg\:-m-17 {
    margin: -8.5rem
  }

  .lg\:-m-19 {
    margin: -9rem
  }

  .lg\:-m-20 {
    margin: -9.5rem
  }

  .lg\:-m-22 {
    margin: -11rem
  }

  .lg\:-m-24 {
    margin: -12rem
  }

  .lg\:-m-32 {
    margin: -16rem
  }

  .lg\:-m-40 {
    margin: -20rem
  }

  .lg\:-m-px {
    margin: -1px
  }

  .lg\:-m-half {
    margin: -0.25rem
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg\:my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .lg\:mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .lg\:my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .lg\:mx-2 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .lg\:my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .lg\:mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .lg\:my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg\:mx-4 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .lg\:my-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .lg\:mx-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .lg\:my-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .lg\:mx-6 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .lg\:my-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .lg\:mx-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .lg\:my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg\:mx-8 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .lg\:my-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
  }

  .lg\:mx-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .lg\:my-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .lg\:mx-10 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .lg\:my-11 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem
  }

  .lg\:mx-11 {
    margin-left: 5.5rem;
    margin-right: 5.5rem
  }

  .lg\:my-12 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg\:mx-12 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .lg\:my-13 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem
  }

  .lg\:mx-13 {
    margin-left: 6.5rem;
    margin-right: 6.5rem
  }

  .lg\:my-14 {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .lg\:mx-14 {
    margin-left: 7rem;
    margin-right: 7rem
  }

  .lg\:my-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem
  }

  .lg\:mx-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem
  }

  .lg\:my-16 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg\:mx-16 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .lg\:my-17 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem
  }

  .lg\:mx-17 {
    margin-left: 8.5rem;
    margin-right: 8.5rem
  }

  .lg\:my-19 {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .lg\:mx-19 {
    margin-left: 9rem;
    margin-right: 9rem
  }

  .lg\:my-20 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem
  }

  .lg\:mx-20 {
    margin-left: 9.5rem;
    margin-right: 9.5rem
  }

  .lg\:my-22 {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .lg\:mx-22 {
    margin-left: 11rem;
    margin-right: 11rem
  }

  .lg\:my-24 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .lg\:mx-24 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .lg\:my-32 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .lg\:mx-32 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .lg\:my-40 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .lg\:mx-40 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .lg\:my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .lg\:mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .lg\:-my-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .lg\:-mx-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:-my-2 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:-mx-2 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .lg\:-my-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .lg\:-mx-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .lg\:-my-4 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .lg\:-mx-4 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:-my-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .lg\:-mx-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .lg\:-my-6 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .lg\:-mx-6 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .lg\:-my-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .lg\:-mx-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .lg\:-my-8 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .lg\:-mx-8 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .lg\:-my-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem
  }

  .lg\:-mx-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem
  }

  .lg\:-my-10 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .lg\:-mx-10 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .lg\:-my-11 {
    margin-top: -5.5rem;
    margin-bottom: -5.5rem
  }

  .lg\:-mx-11 {
    margin-left: -5.5rem;
    margin-right: -5.5rem
  }

  .lg\:-my-12 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .lg\:-mx-12 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .lg\:-my-13 {
    margin-top: -6.5rem;
    margin-bottom: -6.5rem
  }

  .lg\:-mx-13 {
    margin-left: -6.5rem;
    margin-right: -6.5rem
  }

  .lg\:-my-14 {
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  .lg\:-mx-14 {
    margin-left: -7rem;
    margin-right: -7rem
  }

  .lg\:-my-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem
  }

  .lg\:-mx-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem
  }

  .lg\:-my-16 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .lg\:-mx-16 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .lg\:-my-17 {
    margin-top: -8.5rem;
    margin-bottom: -8.5rem
  }

  .lg\:-mx-17 {
    margin-left: -8.5rem;
    margin-right: -8.5rem
  }

  .lg\:-my-19 {
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  .lg\:-mx-19 {
    margin-left: -9rem;
    margin-right: -9rem
  }

  .lg\:-my-20 {
    margin-top: -9.5rem;
    margin-bottom: -9.5rem
  }

  .lg\:-mx-20 {
    margin-left: -9.5rem;
    margin-right: -9.5rem
  }

  .lg\:-my-22 {
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  .lg\:-mx-22 {
    margin-left: -11rem;
    margin-right: -11rem
  }

  .lg\:-my-24 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .lg\:-mx-24 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .lg\:-my-32 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .lg\:-mx-32 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .lg\:-my-40 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .lg\:-mx-40 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .lg\:-my-half {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .lg\:-mx-half {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mr-0 {
    margin-right: 0
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:ml-0 {
    margin-left: 0
  }

  .lg\:mt-1 {
    margin-top: 0.5rem
  }

  .lg\:mr-1 {
    margin-right: 0.5rem
  }

  .lg\:mb-1 {
    margin-bottom: 0.5rem
  }

  .lg\:ml-1 {
    margin-left: 0.5rem
  }

  .lg\:mt-2 {
    margin-top: 1rem
  }

  .lg\:mr-2 {
    margin-right: 1rem
  }

  .lg\:mb-2 {
    margin-bottom: 1rem
  }

  .lg\:ml-2 {
    margin-left: 1rem
  }

  .lg\:mt-3 {
    margin-top: 1.5rem
  }

  .lg\:mr-3 {
    margin-right: 1.5rem
  }

  .lg\:mb-3 {
    margin-bottom: 1.5rem
  }

  .lg\:ml-3 {
    margin-left: 1.5rem
  }

  .lg\:mt-4 {
    margin-top: 2rem
  }

  .lg\:mr-4 {
    margin-right: 2rem
  }

  .lg\:mb-4 {
    margin-bottom: 2rem
  }

  .lg\:ml-4 {
    margin-left: 2rem
  }

  .lg\:mt-5 {
    margin-top: 2.5rem
  }

  .lg\:mr-5 {
    margin-right: 2.5rem
  }

  .lg\:mb-5 {
    margin-bottom: 2.5rem
  }

  .lg\:ml-5 {
    margin-left: 2.5rem
  }

  .lg\:mt-6 {
    margin-top: 3rem
  }

  .lg\:mr-6 {
    margin-right: 3rem
  }

  .lg\:mb-6 {
    margin-bottom: 3rem
  }

  .lg\:ml-6 {
    margin-left: 3rem
  }

  .lg\:mt-7 {
    margin-top: 3.5rem
  }

  .lg\:mr-7 {
    margin-right: 3.5rem
  }

  .lg\:mb-7 {
    margin-bottom: 3.5rem
  }

  .lg\:ml-7 {
    margin-left: 3.5rem
  }

  .lg\:mt-8 {
    margin-top: 4rem
  }

  .lg\:mr-8 {
    margin-right: 4rem
  }

  .lg\:mb-8 {
    margin-bottom: 4rem
  }

  .lg\:ml-8 {
    margin-left: 4rem
  }

  .lg\:mt-9 {
    margin-top: 4.5rem
  }

  .lg\:mr-9 {
    margin-right: 4.5rem
  }

  .lg\:mb-9 {
    margin-bottom: 4.5rem
  }

  .lg\:ml-9 {
    margin-left: 4.5rem
  }

  .lg\:mt-10 {
    margin-top: 5rem
  }

  .lg\:mr-10 {
    margin-right: 5rem
  }

  .lg\:mb-10 {
    margin-bottom: 5rem
  }

  .lg\:ml-10 {
    margin-left: 5rem
  }

  .lg\:mt-11 {
    margin-top: 5.5rem
  }

  .lg\:mr-11 {
    margin-right: 5.5rem
  }

  .lg\:mb-11 {
    margin-bottom: 5.5rem
  }

  .lg\:ml-11 {
    margin-left: 5.5rem
  }

  .lg\:mt-12 {
    margin-top: 6rem
  }

  .lg\:mr-12 {
    margin-right: 6rem
  }

  .lg\:mb-12 {
    margin-bottom: 6rem
  }

  .lg\:ml-12 {
    margin-left: 6rem
  }

  .lg\:mt-13 {
    margin-top: 6.5rem
  }

  .lg\:mr-13 {
    margin-right: 6.5rem
  }

  .lg\:mb-13 {
    margin-bottom: 6.5rem
  }

  .lg\:ml-13 {
    margin-left: 6.5rem
  }

  .lg\:mt-14 {
    margin-top: 7rem
  }

  .lg\:mr-14 {
    margin-right: 7rem
  }

  .lg\:mb-14 {
    margin-bottom: 7rem
  }

  .lg\:ml-14 {
    margin-left: 7rem
  }

  .lg\:mt-15 {
    margin-top: 7.5rem
  }

  .lg\:mr-15 {
    margin-right: 7.5rem
  }

  .lg\:mb-15 {
    margin-bottom: 7.5rem
  }

  .lg\:ml-15 {
    margin-left: 7.5rem
  }

  .lg\:mt-16 {
    margin-top: 8rem
  }

  .lg\:mr-16 {
    margin-right: 8rem
  }

  .lg\:mb-16 {
    margin-bottom: 8rem
  }

  .lg\:ml-16 {
    margin-left: 8rem
  }

  .lg\:mt-17 {
    margin-top: 8.5rem
  }

  .lg\:mr-17 {
    margin-right: 8.5rem
  }

  .lg\:mb-17 {
    margin-bottom: 8.5rem
  }

  .lg\:ml-17 {
    margin-left: 8.5rem
  }

  .lg\:mt-19 {
    margin-top: 9rem
  }

  .lg\:mr-19 {
    margin-right: 9rem
  }

  .lg\:mb-19 {
    margin-bottom: 9rem
  }

  .lg\:ml-19 {
    margin-left: 9rem
  }

  .lg\:mt-20 {
    margin-top: 9.5rem
  }

  .lg\:mr-20 {
    margin-right: 9.5rem
  }

  .lg\:mb-20 {
    margin-bottom: 9.5rem
  }

  .lg\:ml-20 {
    margin-left: 9.5rem
  }

  .lg\:mt-22 {
    margin-top: 11rem
  }

  .lg\:mr-22 {
    margin-right: 11rem
  }

  .lg\:mb-22 {
    margin-bottom: 11rem
  }

  .lg\:ml-22 {
    margin-left: 11rem
  }

  .lg\:mt-24 {
    margin-top: 12rem
  }

  .lg\:mr-24 {
    margin-right: 12rem
  }

  .lg\:mb-24 {
    margin-bottom: 12rem
  }

  .lg\:ml-24 {
    margin-left: 12rem
  }

  .lg\:mt-32 {
    margin-top: 16rem
  }

  .lg\:mr-32 {
    margin-right: 16rem
  }

  .lg\:mb-32 {
    margin-bottom: 16rem
  }

  .lg\:ml-32 {
    margin-left: 16rem
  }

  .lg\:mt-40 {
    margin-top: 20rem
  }

  .lg\:mr-40 {
    margin-right: 20rem
  }

  .lg\:mb-40 {
    margin-bottom: 20rem
  }

  .lg\:ml-40 {
    margin-left: 20rem
  }

  .lg\:mt-auto {
    margin-top: auto
  }

  .lg\:mr-auto {
    margin-right: auto
  }

  .lg\:mb-auto {
    margin-bottom: auto
  }

  .lg\:ml-auto {
    margin-left: auto
  }

  .lg\:mt-px {
    margin-top: 1px
  }

  .lg\:mr-px {
    margin-right: 1px
  }

  .lg\:mb-px {
    margin-bottom: 1px
  }

  .lg\:ml-px {
    margin-left: 1px
  }

  .lg\:mt-half {
    margin-top: 0.25rem
  }

  .lg\:mr-half {
    margin-right: 0.25rem
  }

  .lg\:mb-half {
    margin-bottom: 0.25rem
  }

  .lg\:ml-half {
    margin-left: 0.25rem
  }

  .lg\:-mt-1 {
    margin-top: -0.5rem
  }

  .lg\:-mr-1 {
    margin-right: -0.5rem
  }

  .lg\:-mb-1 {
    margin-bottom: -0.5rem
  }

  .lg\:-ml-1 {
    margin-left: -0.5rem
  }

  .lg\:-mt-2 {
    margin-top: -1rem
  }

  .lg\:-mr-2 {
    margin-right: -1rem
  }

  .lg\:-mb-2 {
    margin-bottom: -1rem
  }

  .lg\:-ml-2 {
    margin-left: -1rem
  }

  .lg\:-mt-3 {
    margin-top: -1.5rem
  }

  .lg\:-mr-3 {
    margin-right: -1.5rem
  }

  .lg\:-mb-3 {
    margin-bottom: -1.5rem
  }

  .lg\:-ml-3 {
    margin-left: -1.5rem
  }

  .lg\:-mt-4 {
    margin-top: -2rem
  }

  .lg\:-mr-4 {
    margin-right: -2rem
  }

  .lg\:-mb-4 {
    margin-bottom: -2rem
  }

  .lg\:-ml-4 {
    margin-left: -2rem
  }

  .lg\:-mt-5 {
    margin-top: -2.5rem
  }

  .lg\:-mr-5 {
    margin-right: -2.5rem
  }

  .lg\:-mb-5 {
    margin-bottom: -2.5rem
  }

  .lg\:-ml-5 {
    margin-left: -2.5rem
  }

  .lg\:-mt-6 {
    margin-top: -3rem
  }

  .lg\:-mr-6 {
    margin-right: -3rem
  }

  .lg\:-mb-6 {
    margin-bottom: -3rem
  }

  .lg\:-ml-6 {
    margin-left: -3rem
  }

  .lg\:-mt-7 {
    margin-top: -3.5rem
  }

  .lg\:-mr-7 {
    margin-right: -3.5rem
  }

  .lg\:-mb-7 {
    margin-bottom: -3.5rem
  }

  .lg\:-ml-7 {
    margin-left: -3.5rem
  }

  .lg\:-mt-8 {
    margin-top: -4rem
  }

  .lg\:-mr-8 {
    margin-right: -4rem
  }

  .lg\:-mb-8 {
    margin-bottom: -4rem
  }

  .lg\:-ml-8 {
    margin-left: -4rem
  }

  .lg\:-mt-9 {
    margin-top: -4.5rem
  }

  .lg\:-mr-9 {
    margin-right: -4.5rem
  }

  .lg\:-mb-9 {
    margin-bottom: -4.5rem
  }

  .lg\:-ml-9 {
    margin-left: -4.5rem
  }

  .lg\:-mt-10 {
    margin-top: -5rem
  }

  .lg\:-mr-10 {
    margin-right: -5rem
  }

  .lg\:-mb-10 {
    margin-bottom: -5rem
  }

  .lg\:-ml-10 {
    margin-left: -5rem
  }

  .lg\:-mt-11 {
    margin-top: -5.5rem
  }

  .lg\:-mr-11 {
    margin-right: -5.5rem
  }

  .lg\:-mb-11 {
    margin-bottom: -5.5rem
  }

  .lg\:-ml-11 {
    margin-left: -5.5rem
  }

  .lg\:-mt-12 {
    margin-top: -6rem
  }

  .lg\:-mr-12 {
    margin-right: -6rem
  }

  .lg\:-mb-12 {
    margin-bottom: -6rem
  }

  .lg\:-ml-12 {
    margin-left: -6rem
  }

  .lg\:-mt-13 {
    margin-top: -6.5rem
  }

  .lg\:-mr-13 {
    margin-right: -6.5rem
  }

  .lg\:-mb-13 {
    margin-bottom: -6.5rem
  }

  .lg\:-ml-13 {
    margin-left: -6.5rem
  }

  .lg\:-mt-14 {
    margin-top: -7rem
  }

  .lg\:-mr-14 {
    margin-right: -7rem
  }

  .lg\:-mb-14 {
    margin-bottom: -7rem
  }

  .lg\:-ml-14 {
    margin-left: -7rem
  }

  .lg\:-mt-15 {
    margin-top: -7.5rem
  }

  .lg\:-mr-15 {
    margin-right: -7.5rem
  }

  .lg\:-mb-15 {
    margin-bottom: -7.5rem
  }

  .lg\:-ml-15 {
    margin-left: -7.5rem
  }

  .lg\:-mt-16 {
    margin-top: -8rem
  }

  .lg\:-mr-16 {
    margin-right: -8rem
  }

  .lg\:-mb-16 {
    margin-bottom: -8rem
  }

  .lg\:-ml-16 {
    margin-left: -8rem
  }

  .lg\:-mt-17 {
    margin-top: -8.5rem
  }

  .lg\:-mr-17 {
    margin-right: -8.5rem
  }

  .lg\:-mb-17 {
    margin-bottom: -8.5rem
  }

  .lg\:-ml-17 {
    margin-left: -8.5rem
  }

  .lg\:-mt-19 {
    margin-top: -9rem
  }

  .lg\:-mr-19 {
    margin-right: -9rem
  }

  .lg\:-mb-19 {
    margin-bottom: -9rem
  }

  .lg\:-ml-19 {
    margin-left: -9rem
  }

  .lg\:-mt-20 {
    margin-top: -9.5rem
  }

  .lg\:-mr-20 {
    margin-right: -9.5rem
  }

  .lg\:-mb-20 {
    margin-bottom: -9.5rem
  }

  .lg\:-ml-20 {
    margin-left: -9.5rem
  }

  .lg\:-mt-22 {
    margin-top: -11rem
  }

  .lg\:-mr-22 {
    margin-right: -11rem
  }

  .lg\:-mb-22 {
    margin-bottom: -11rem
  }

  .lg\:-ml-22 {
    margin-left: -11rem
  }

  .lg\:-mt-24 {
    margin-top: -12rem
  }

  .lg\:-mr-24 {
    margin-right: -12rem
  }

  .lg\:-mb-24 {
    margin-bottom: -12rem
  }

  .lg\:-ml-24 {
    margin-left: -12rem
  }

  .lg\:-mt-32 {
    margin-top: -16rem
  }

  .lg\:-mr-32 {
    margin-right: -16rem
  }

  .lg\:-mb-32 {
    margin-bottom: -16rem
  }

  .lg\:-ml-32 {
    margin-left: -16rem
  }

  .lg\:-mt-40 {
    margin-top: -20rem
  }

  .lg\:-mr-40 {
    margin-right: -20rem
  }

  .lg\:-mb-40 {
    margin-bottom: -20rem
  }

  .lg\:-ml-40 {
    margin-left: -20rem
  }

  .lg\:-mt-px {
    margin-top: -1px
  }

  .lg\:-mr-px {
    margin-right: -1px
  }

  .lg\:-mb-px {
    margin-bottom: -1px
  }

  .lg\:-ml-px {
    margin-left: -1px
  }

  .lg\:-mt-half {
    margin-top: -0.25rem
  }

  .lg\:-mr-half {
    margin-right: -0.25rem
  }

  .lg\:-mb-half {
    margin-bottom: -0.25rem
  }

  .lg\:-ml-half {
    margin-left: -0.25rem
  }

  .lg\:max-h-90 {
    max-height: 90%
  }

  .lg\:max-h-full {
    max-height: 100%
  }

  .lg\:max-h-screen {
    max-height: 100vh
  }

  .lg\:max-w-400 {
    max-width: 25rem
  }

  .lg\:max-w-500 {
    max-width: 31.25rem
  }

  .lg\:max-w-600 {
    max-width: 37.5rem
  }

  .lg\:max-w-700 {
    max-width: 43.75em
  }

  .lg\:max-w-800 {
    max-width: 50rem
  }

  .lg\:max-w-1000 {
    max-width: 62.5rem
  }

  .lg\:max-w-1200 {
    max-width: 75rem
  }

  .lg\:max-w-1280 {
    max-width: 80rem
  }

  .lg\:max-w-1400 {
    max-width: 87.5rem
  }

  .lg\:max-w-1600 {
    max-width: 100rem
  }

  .lg\:max-w-1800 {
    max-width: 112.5rem
  }

  .lg\:max-w-2000 {
    max-width: 125rem
  }

  .lg\:min-h-0 {
    min-height: 0
  }

  .lg\:min-h-full {
    min-height: 100%
  }

  .lg\:min-h-screen {
    min-height: 100vh
  }

  .lg\:min-w-0 {
    min-width: 0
  }

  .lg\:min-w-full {
    min-width: 100%
  }

  .lg\:object-contain {
    object-fit: contain
  }

  .lg\:object-cover {
    object-fit: cover
  }

  .lg\:object-fill {
    object-fit: fill
  }

  .lg\:object-none {
    object-fit: none
  }

  .lg\:object-scale-down {
    object-fit: scale-down
  }

  .lg\:object-bottom {
    object-position: bottom
  }

  .lg\:object-center {
    object-position: center
  }

  .lg\:object-left {
    object-position: left
  }

  .lg\:object-left-bottom {
    object-position: left bottom
  }

  .lg\:object-left-top {
    object-position: left top
  }

  .lg\:object-right {
    object-position: right
  }

  .lg\:object-right-bottom {
    object-position: right bottom
  }

  .lg\:object-right-top {
    object-position: right top
  }

  .lg\:object-top {
    object-position: top
  }

  .lg\:opacity-0 {
    opacity: 0
  }

  .lg\:opacity-20 {
    opacity: .2
  }

  .lg\:opacity-30 {
    opacity: .3
  }

  .lg\:opacity-40 {
    opacity: .4
  }

  .lg\:opacity-50 {
    opacity: .5
  }

  .lg\:opacity-60 {
    opacity: .6
  }

  .lg\:opacity-80 {
    opacity: .8
  }

  .lg\:opacity-100 {
    opacity: 1
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0
  }

  .lg\:hover\:opacity-20:hover {
    opacity: .2
  }

  .lg\:hover\:opacity-30:hover {
    opacity: .3
  }

  .lg\:hover\:opacity-40:hover {
    opacity: .4
  }

  .lg\:hover\:opacity-50:hover {
    opacity: .5
  }

  .lg\:hover\:opacity-60:hover {
    opacity: .6
  }

  .lg\:hover\:opacity-80:hover {
    opacity: .8
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0
  }

  .lg\:focus\:opacity-20:focus {
    opacity: .2
  }

  .lg\:focus\:opacity-30:focus {
    opacity: .3
  }

  .lg\:focus\:opacity-40:focus {
    opacity: .4
  }

  .lg\:focus\:opacity-50:focus {
    opacity: .5
  }

  .lg\:focus\:opacity-60:focus {
    opacity: .6
  }

  .lg\:focus\:opacity-80:focus {
    opacity: .8
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1
  }

  .lg\:outline-none {
    outline: 0
  }

  .lg\:focus\:outline-none:focus {
    outline: 0
  }

  .lg\:overflow-auto {
    overflow: auto
  }

  .lg\:overflow-hidden {
    overflow: hidden
  }

  .lg\:overflow-visible {
    overflow: visible
  }

  .lg\:overflow-scroll {
    overflow: scroll
  }

  .lg\:overflow-x-auto {
    overflow-x: auto
  }

  .lg\:overflow-y-auto {
    overflow-y: auto
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden
  }

  .lg\:overflow-x-visible {
    overflow-x: visible
  }

  .lg\:overflow-y-visible {
    overflow-y: visible
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-1 {
    padding: 0.5rem
  }

  .lg\:p-2 {
    padding: 1rem
  }

  .lg\:p-3 {
    padding: 1.5rem
  }

  .lg\:p-4 {
    padding: 2rem
  }

  .lg\:p-5 {
    padding: 2.5rem
  }

  .lg\:p-6 {
    padding: 3rem
  }

  .lg\:p-7 {
    padding: 3.5rem
  }

  .lg\:p-8 {
    padding: 4rem
  }

  .lg\:p-9 {
    padding: 4.5rem
  }

  .lg\:p-10 {
    padding: 5rem
  }

  .lg\:p-11 {
    padding: 5.5rem
  }

  .lg\:p-12 {
    padding: 6rem
  }

  .lg\:p-13 {
    padding: 6.5rem
  }

  .lg\:p-14 {
    padding: 7rem
  }

  .lg\:p-15 {
    padding: 7.5rem
  }

  .lg\:p-16 {
    padding: 8rem
  }

  .lg\:p-17 {
    padding: 8.5rem
  }

  .lg\:p-19 {
    padding: 9rem
  }

  .lg\:p-20 {
    padding: 9.5rem
  }

  .lg\:p-22 {
    padding: 11rem
  }

  .lg\:p-24 {
    padding: 12rem
  }

  .lg\:p-32 {
    padding: 16rem
  }

  .lg\:p-40 {
    padding: 20rem
  }

  .lg\:p-px {
    padding: 1px
  }

  .lg\:p-half {
    padding: 0.25rem
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:px-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .lg\:py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg\:px-2 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .lg\:px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .lg\:py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-4 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .lg\:px-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:py-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg\:px-6 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .lg\:py-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .lg\:px-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .lg\:py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:px-8 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg\:py-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
  }

  .lg\:px-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
  }

  .lg\:py-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:px-10 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .lg\:py-11 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem
  }

  .lg\:px-11 {
    padding-left: 5.5rem;
    padding-right: 5.5rem
  }

  .lg\:py-12 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg\:px-12 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:py-13 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem
  }

  .lg\:px-13 {
    padding-left: 6.5rem;
    padding-right: 6.5rem
  }

  .lg\:py-14 {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .lg\:px-14 {
    padding-left: 7rem;
    padding-right: 7rem
  }

  .lg\:py-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
  }

  .lg\:px-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .lg\:py-16 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:px-16 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:py-17 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem
  }

  .lg\:px-17 {
    padding-left: 8.5rem;
    padding-right: 8.5rem
  }

  .lg\:py-19 {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .lg\:px-19 {
    padding-left: 9rem;
    padding-right: 9rem
  }

  .lg\:py-20 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem
  }

  .lg\:px-20 {
    padding-left: 9.5rem;
    padding-right: 9.5rem
  }

  .lg\:py-22 {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .lg\:px-22 {
    padding-left: 11rem;
    padding-right: 11rem
  }

  .lg\:py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .lg\:px-24 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .lg\:py-32 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:px-32 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .lg\:py-40 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .lg\:px-40 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .lg\:py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .lg\:px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  .lg\:pt-1 {
    padding-top: 0.5rem
  }

  .lg\:pr-1 {
    padding-right: 0.5rem
  }

  .lg\:pb-1 {
    padding-bottom: 0.5rem
  }

  .lg\:pl-1 {
    padding-left: 0.5rem
  }

  .lg\:pt-2 {
    padding-top: 1rem
  }

  .lg\:pr-2 {
    padding-right: 1rem
  }

  .lg\:pb-2 {
    padding-bottom: 1rem
  }

  .lg\:pl-2 {
    padding-left: 1rem
  }

  .lg\:pt-3 {
    padding-top: 1.5rem
  }

  .lg\:pr-3 {
    padding-right: 1.5rem
  }

  .lg\:pb-3 {
    padding-bottom: 1.5rem
  }

  .lg\:pl-3 {
    padding-left: 1.5rem
  }

  .lg\:pt-4 {
    padding-top: 2rem
  }

  .lg\:pr-4 {
    padding-right: 2rem
  }

  .lg\:pb-4 {
    padding-bottom: 2rem
  }

  .lg\:pl-4 {
    padding-left: 2rem
  }

  .lg\:pt-5 {
    padding-top: 2.5rem
  }

  .lg\:pr-5 {
    padding-right: 2.5rem
  }

  .lg\:pb-5 {
    padding-bottom: 2.5rem
  }

  .lg\:pl-5 {
    padding-left: 2.5rem
  }

  .lg\:pt-6 {
    padding-top: 3rem
  }

  .lg\:pr-6 {
    padding-right: 3rem
  }

  .lg\:pb-6 {
    padding-bottom: 3rem
  }

  .lg\:pl-6 {
    padding-left: 3rem
  }

  .lg\:pt-7 {
    padding-top: 3.5rem
  }

  .lg\:pr-7 {
    padding-right: 3.5rem
  }

  .lg\:pb-7 {
    padding-bottom: 3.5rem
  }

  .lg\:pl-7 {
    padding-left: 3.5rem
  }

  .lg\:pt-8 {
    padding-top: 4rem
  }

  .lg\:pr-8 {
    padding-right: 4rem
  }

  .lg\:pb-8 {
    padding-bottom: 4rem
  }

  .lg\:pl-8 {
    padding-left: 4rem
  }

  .lg\:pt-9 {
    padding-top: 4.5rem
  }

  .lg\:pr-9 {
    padding-right: 4.5rem
  }

  .lg\:pb-9 {
    padding-bottom: 4.5rem
  }

  .lg\:pl-9 {
    padding-left: 4.5rem
  }

  .lg\:pt-10 {
    padding-top: 5rem
  }

  .lg\:pr-10 {
    padding-right: 5rem
  }

  .lg\:pb-10 {
    padding-bottom: 5rem
  }

  .lg\:pl-10 {
    padding-left: 5rem
  }

  .lg\:pt-11 {
    padding-top: 5.5rem
  }

  .lg\:pr-11 {
    padding-right: 5.5rem
  }

  .lg\:pb-11 {
    padding-bottom: 5.5rem
  }

  .lg\:pl-11 {
    padding-left: 5.5rem
  }

  .lg\:pt-12 {
    padding-top: 6rem
  }

  .lg\:pr-12 {
    padding-right: 6rem
  }

  .lg\:pb-12 {
    padding-bottom: 6rem
  }

  .lg\:pl-12 {
    padding-left: 6rem
  }

  .lg\:pt-13 {
    padding-top: 6.5rem
  }

  .lg\:pr-13 {
    padding-right: 6.5rem
  }

  .lg\:pb-13 {
    padding-bottom: 6.5rem
  }

  .lg\:pl-13 {
    padding-left: 6.5rem
  }

  .lg\:pt-14 {
    padding-top: 7rem
  }

  .lg\:pr-14 {
    padding-right: 7rem
  }

  .lg\:pb-14 {
    padding-bottom: 7rem
  }

  .lg\:pl-14 {
    padding-left: 7rem
  }

  .lg\:pt-15 {
    padding-top: 7.5rem
  }

  .lg\:pr-15 {
    padding-right: 7.5rem
  }

  .lg\:pb-15 {
    padding-bottom: 7.5rem
  }

  .lg\:pl-15 {
    padding-left: 7.5rem
  }

  .lg\:pt-16 {
    padding-top: 8rem
  }

  .lg\:pr-16 {
    padding-right: 8rem
  }

  .lg\:pb-16 {
    padding-bottom: 8rem
  }

  .lg\:pl-16 {
    padding-left: 8rem
  }

  .lg\:pt-17 {
    padding-top: 8.5rem
  }

  .lg\:pr-17 {
    padding-right: 8.5rem
  }

  .lg\:pb-17 {
    padding-bottom: 8.5rem
  }

  .lg\:pl-17 {
    padding-left: 8.5rem
  }

  .lg\:pt-19 {
    padding-top: 9rem
  }

  .lg\:pr-19 {
    padding-right: 9rem
  }

  .lg\:pb-19 {
    padding-bottom: 9rem
  }

  .lg\:pl-19 {
    padding-left: 9rem
  }

  .lg\:pt-20 {
    padding-top: 9.5rem
  }

  .lg\:pr-20 {
    padding-right: 9.5rem
  }

  .lg\:pb-20 {
    padding-bottom: 9.5rem
  }

  .lg\:pl-20 {
    padding-left: 9.5rem
  }

  .lg\:pt-22 {
    padding-top: 11rem
  }

  .lg\:pr-22 {
    padding-right: 11rem
  }

  .lg\:pb-22 {
    padding-bottom: 11rem
  }

  .lg\:pl-22 {
    padding-left: 11rem
  }

  .lg\:pt-24 {
    padding-top: 12rem
  }

  .lg\:pr-24 {
    padding-right: 12rem
  }

  .lg\:pb-24 {
    padding-bottom: 12rem
  }

  .lg\:pl-24 {
    padding-left: 12rem
  }

  .lg\:pt-32 {
    padding-top: 16rem
  }

  .lg\:pr-32 {
    padding-right: 16rem
  }

  .lg\:pb-32 {
    padding-bottom: 16rem
  }

  .lg\:pl-32 {
    padding-left: 16rem
  }

  .lg\:pt-40 {
    padding-top: 20rem
  }

  .lg\:pr-40 {
    padding-right: 20rem
  }

  .lg\:pb-40 {
    padding-bottom: 20rem
  }

  .lg\:pl-40 {
    padding-left: 20rem
  }

  .lg\:pt-px {
    padding-top: 1px
  }

  .lg\:pr-px {
    padding-right: 1px
  }

  .lg\:pb-px {
    padding-bottom: 1px
  }

  .lg\:pl-px {
    padding-left: 1px
  }

  .lg\:pt-half {
    padding-top: 0.25rem
  }

  .lg\:pr-half {
    padding-right: 0.25rem
  }

  .lg\:pb-half {
    padding-bottom: 0.25rem
  }

  .lg\:pl-half {
    padding-left: 0.25rem
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent
  }

  .lg\:placeholder-black::placeholder {
    color: @color-black
  }

  .lg\:placeholder-white::placeholder {
    color: @color-white
  }

  .lg\:placeholder-grey-10::placeholder {
    color: fade(@color-grey, 10%)
  }

  .lg\:placeholder-grey-30::placeholder {
    color: fade(@color-grey, 30%)
  }

  .lg\:placeholder-grey-40::placeholder {
    color: fade(@color-grey, 40%)
  }

  .lg\:placeholder-grey-60::placeholder {
    color: fade(@color-grey, 60%)
  }

  .lg\:placeholder-grey-90::placeholder {
    color: fade(@color-grey, 90%)
  }

  .lg\:placeholder-grey::placeholder {
    color: @color-grey
  }

  .lg\:placeholder-grey-metal::placeholder {
    color: @color-grey
  }

  .lg\:placeholder-grey-divider::placeholder {
    color: fade(@color-grey, 20%)
  }

  .lg\:placeholder-grey-light::placeholder {
    color: @color-grey-light
  }

  .lg\:placeholder-grey-header::placeholder {
    color: fade(@color-grey, 90%)
  }

  .lg\:placeholder-body::placeholder {
    color: @color-body
  }

  .lg\:placeholder-yellow::placeholder {
    color: @color-yellow
  }

  .lg\:placeholder-orange::placeholder {
    color: @color-orange
  }

  .lg\:placeholder-stone::placeholder {
    color: @color-stone
  }

  .lg\:placeholder-khaki::placeholder {
    color: @color-khaki
  }

  .lg\:placeholder-green::placeholder {
    color: @color-green
  }

  .lg\:placeholder-blue::placeholder {
    color: @color-blue
  }

  .lg\:placeholder-red::placeholder {
    color: @color-red
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    color: @color-black
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    color: @color-white
  }

  .lg\:focus\:placeholder-grey-10:focus::placeholder {
    color: fade(@color-grey, 10%)
  }

  .lg\:focus\:placeholder-grey-30:focus::placeholder {
    color: fade(@color-grey, 30%)
  }

  .lg\:focus\:placeholder-grey-40:focus::placeholder {
    color: fade(@color-grey, 40%)
  }

  .lg\:focus\:placeholder-grey-60:focus::placeholder {
    color: fade(@color-grey, 60%)
  }

  .lg\:focus\:placeholder-grey-90:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .lg\:focus\:placeholder-grey:focus::placeholder {
    color: @color-grey
  }

  .lg\:focus\:placeholder-grey-metal:focus::placeholder {
    color: @color-grey
  }

  .lg\:focus\:placeholder-grey-divider:focus::placeholder {
    color: fade(@color-grey, 20%)
  }

  .lg\:focus\:placeholder-grey-light:focus::placeholder {
    color: @color-grey-light
  }

  .lg\:focus\:placeholder-grey-header:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .lg\:focus\:placeholder-body:focus::placeholder {
    color: @color-body
  }

  .lg\:focus\:placeholder-yellow:focus::placeholder {
    color: @color-yellow
  }

  .lg\:focus\:placeholder-orange:focus::placeholder {
    color: @color-orange
  }

  .lg\:focus\:placeholder-stone:focus::placeholder {
    color: @color-stone
  }

  .lg\:focus\:placeholder-khaki:focus::placeholder {
    color: @color-khaki
  }

  .lg\:focus\:placeholder-green:focus::placeholder {
    color: @color-green
  }

  .lg\:focus\:placeholder-blue:focus::placeholder {
    color: @color-blue
  }

  .lg\:focus\:placeholder-red:focus::placeholder {
    color: @color-red
  }

  .lg\:pointer-events-none {
    pointer-events: none
  }

  .lg\:pointer-events-auto {
    pointer-events: auto
  }

  .lg\:static {
    position: static
  }

  .lg\:fixed {
    position: fixed
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .lg\:inset-x-0 {
    right: 0;
    left: 0
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .lg\:inset-x-auto {
    right: auto;
    left: auto
  }

  .lg\:top-0 {
    top: 0
  }

  .lg\:right-0 {
    right: 0
  }

  .lg\:bottom-0 {
    bottom: 0
  }

  .lg\:left-0 {
    left: 0
  }

  .lg\:top-auto {
    top: auto
  }

  .lg\:right-auto {
    right: auto
  }

  .lg\:bottom-auto {
    bottom: auto
  }

  .lg\:left-auto {
    left: auto
  }

  .lg\:resize-none {
    resize: none
  }

  .lg\:resize-y {
    resize: vertical
  }

  .lg\:resize-x {
    resize: horizontal
  }

  .lg\:resize {
    resize: both
  }

  .lg\:shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow-none {
    box-shadow: none
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .lg\:fill-current {
    fill: currentColor
  }

  .lg\:stroke-current {
    stroke: currentColor
  }

  .lg\:table-auto {
    table-layout: auto
  }

  .lg\:table-fixed {
    table-layout: fixed
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-center {
    text-align: center
  }

  .lg\:text-right {
    text-align: right
  }

  .lg\:text-justify {
    text-align: justify
  }

  .lg\:text-transparent {
    color: transparent
  }

  .lg\:text-black {
    color: @color-black
  }

  .lg\:text-white {
    color: @color-white
  }

  .lg\:text-grey-10 {
    color: fade(@color-grey, 10%)
  }

  .lg\:text-grey-30 {
    color: fade(@color-grey, 30%)
  }

  .lg\:text-grey-40 {
    color: fade(@color-grey, 40%)
  }

  .lg\:text-grey-60 {
    color: fade(@color-grey, 60%)
  }

  .lg\:text-grey-90 {
    color: fade(@color-grey, 90%)
  }

  .lg\:text-grey {
    color: @color-grey
  }

  .lg\:text-grey-metal {
    color: @color-grey
  }

  .lg\:text-grey-divider {
    color: fade(@color-grey, 20%)
  }

  .lg\:text-grey-light {
    color: @color-grey-light
  }

  .lg\:text-grey-header {
    color: fade(@color-grey, 90%)
  }

  .lg\:text-body {
    color: @color-body
  }

  .lg\:text-yellow {
    color: @color-yellow
  }

  .lg\:text-orange {
    color: @color-orange
  }

  .lg\:text-stone {
    color: @color-stone
  }

  .lg\:text-khaki {
    color: @color-khaki
  }

  .lg\:text-green {
    color: @color-green
  }

  .lg\:text-blue {
    color: @color-blue
  }

  .lg\:text-red {
    color: @color-red
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent
  }

  .lg\:hover\:text-black:hover {
    color: @color-black
  }

  .lg\:hover\:text-white:hover {
    color: @color-white
  }

  .lg\:hover\:text-grey-10:hover {
    color: fade(@color-grey, 10%)
  }

  .lg\:hover\:text-grey-30:hover {
    color: fade(@color-grey, 30%)
  }

  .lg\:hover\:text-grey-40:hover {
    color: fade(@color-grey, 40%)
  }

  .lg\:hover\:text-grey-60:hover {
    color: fade(@color-grey, 60%)
  }

  .lg\:hover\:text-grey-90:hover {
    color: fade(@color-grey, 90%)
  }

  .lg\:hover\:text-grey:hover {
    color: @color-grey
  }

  .lg\:hover\:text-grey-metal:hover {
    color: @color-grey
  }

  .lg\:hover\:text-grey-divider:hover {
    color: fade(@color-grey, 20%)
  }

  .lg\:hover\:text-grey-light:hover {
    color: @color-grey-light
  }

  .lg\:hover\:text-grey-header:hover {
    color: fade(@color-grey, 90%)
  }

  .lg\:hover\:text-body:hover {
    color: @color-body
  }

  .lg\:hover\:text-yellow:hover {
    color: @color-yellow
  }

  .lg\:hover\:text-orange:hover {
    color: @color-orange
  }

  .lg\:hover\:text-stone:hover {
    color: @color-stone
  }

  .lg\:hover\:text-khaki:hover {
    color: @color-khaki
  }

  .lg\:hover\:text-green:hover {
    color: @color-green
  }

  .lg\:hover\:text-blue:hover {
    color: @color-blue
  }

  .lg\:hover\:text-red:hover {
    color: @color-red
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent
  }

  .lg\:focus\:text-black:focus {
    color: @color-black
  }

  .lg\:focus\:text-white:focus {
    color: @color-white
  }

  .lg\:focus\:text-grey-10:focus {
    color: fade(@color-grey, 10%)
  }

  .lg\:focus\:text-grey-30:focus {
    color: fade(@color-grey, 30%)
  }

  .lg\:focus\:text-grey-40:focus {
    color: fade(@color-grey, 40%)
  }

  .lg\:focus\:text-grey-60:focus {
    color: fade(@color-grey, 60%)
  }

  .lg\:focus\:text-grey-90:focus {
    color: fade(@color-grey, 90%)
  }

  .lg\:focus\:text-grey:focus {
    color: @color-grey
  }

  .lg\:focus\:text-grey-metal:focus {
    color: @color-grey
  }

  .lg\:focus\:text-grey-divider:focus {
    color: fade(@color-grey, 20%)
  }

  .lg\:focus\:text-grey-light:focus {
    color: @color-grey-light
  }

  .lg\:focus\:text-grey-header:focus {
    color: fade(@color-grey, 90%)
  }

  .lg\:focus\:text-body:focus {
    color: @color-body
  }

  .lg\:focus\:text-yellow:focus {
    color: @color-yellow
  }

  .lg\:focus\:text-orange:focus {
    color: @color-orange
  }

  .lg\:focus\:text-stone:focus {
    color: @color-stone
  }

  .lg\:focus\:text-khaki:focus {
    color: @color-khaki
  }

  .lg\:focus\:text-green:focus {
    color: @color-green
  }

  .lg\:focus\:text-blue:focus {
    color: @color-blue
  }

  .lg\:focus\:text-red:focus {
    color: @color-red
  }

  .lg\:text-base {
    font-size: .6875rem
  }

  .lg\:text-md {
    font-size: 0.9375rem
  }

  .lg\:text-lg {
    font-size: 1rem
  }

  .lg\:text-xl {
    font-size: 1.375rem
  }

  .lg\:text-2xl {
    font-size: 2.0625rem
  }

  .lg\:italic {
    font-style: italic
  }

  .lg\:not-italic {
    font-style: normal
  }

  .lg\:uppercase {
    text-transform: uppercase
  }

  .lg\:lowercase {
    text-transform: lowercase
  }

  .lg\:capitalize {
    text-transform: capitalize
  }

  .lg\:normal-case {
    text-transform: none
  }

  .lg\:underline {
    text-decoration: underline
  }

  .lg\:line-through {
    text-decoration: line-through
  }

  .lg\:no-underline {
    text-decoration: none
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em
  }

  .lg\:tracking-normal {
    letter-spacing: 0
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em
  }

  .lg\:align-baseline {
    vertical-align: baseline
  }

  .lg\:align-top {
    vertical-align: top
  }

  .lg\:align-middle {
    vertical-align: middle
  }

  .lg\:align-bottom {
    vertical-align: bottom
  }

  .lg\:align-text-top {
    vertical-align: text-top
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom
  }

  .lg\:visible {
    visibility: visible
  }

  .lg\:invisible {
    visibility: hidden
  }

  .lg\:whitespace-normal {
    white-space: normal
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap
  }

  .lg\:whitespace-pre {
    white-space: pre
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .lg\:w-0 {
    width: 0
  }

  .lg\:w-1 {
    width: 0.5rem
  }

  .lg\:w-2 {
    width: 1rem
  }

  .lg\:w-3 {
    width: 1.5rem
  }

  .lg\:w-4 {
    width: 2rem
  }

  .lg\:w-5 {
    width: 2.5rem
  }

  .lg\:w-6 {
    width: 3rem
  }

  .lg\:w-7 {
    width: 3.5rem
  }

  .lg\:w-8 {
    width: 4rem
  }

  .lg\:w-9 {
    width: 4.5rem
  }

  .lg\:w-10 {
    width: 5rem
  }

  .lg\:w-11 {
    width: 5.5rem
  }

  .lg\:w-12 {
    width: 6rem
  }

  .lg\:w-13 {
    width: 6.5rem
  }

  .lg\:w-14 {
    width: 7rem
  }

  .lg\:w-15 {
    width: 7.5rem
  }

  .lg\:w-16 {
    width: 8rem
  }

  .lg\:w-17 {
    width: 8.5rem
  }

  .lg\:w-19 {
    width: 9rem
  }

  .lg\:w-20 {
    width: 9.5rem
  }

  .lg\:w-22 {
    width: 11rem
  }

  .lg\:w-24 {
    width: 12rem
  }

  .lg\:w-32 {
    width: 16rem
  }

  .lg\:w-40 {
    width: 20rem
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-px {
    width: 1px
  }

  .lg\:w-half {
    width: 0.25rem
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-2\/3 {
    width: 66.666667%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-2\/4 {
    width: 50%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-2\/5 {
    width: 40%
  }

  .lg\:w-3\/5 {
    width: 60%
  }

  .lg\:w-4\/5 {
    width: 80%
  }

  .lg\:w-1\/6 {
    width: 16.666667%
  }

  .lg\:w-2\/6 {
    width: 33.333333%
  }

  .lg\:w-3\/6 {
    width: 50%
  }

  .lg\:w-4\/6 {
    width: 66.666667%
  }

  .lg\:w-5\/6 {
    width: 83.333333%
  }

  .lg\:w-1\/12 {
    width: 8.333333%
  }

  .lg\:w-2\/12 {
    width: 16.666667%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-5\/12 {
    width: 41.666667%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-7\/12 {
    width: 58.333333%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-10\/12 {
    width: 83.333333%
  }

  .lg\:w-11\/12 {
    width: 91.666667%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-screen {
    width: 100vw
  }

  .lg\:z-0 {
    z-index: 0
  }

  .lg\:z-10 {
    z-index: 10
  }

  .lg\:z-20 {
    z-index: 20
  }

  .lg\:z-30 {
    z-index: 30
  }

  .lg\:z-40 {
    z-index: 40
  }

  .lg\:z-50 {
    z-index: 50
  }

  .lg\:z-auto {
    z-index: auto
  }
}

@media (min-width: @xl-break) {
  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:bg-transparent {
    background-color: transparent
  }

  .xl\:bg-black {
    background-color: @color-black
  }

  .xl\:bg-white {
    background-color: @color-white
  }

  .xl\:bg-grey-10 {
    background-color: fade(@color-grey, 10%)
  }

  .xl\:bg-grey-30 {
    background-color: fade(@color-grey, 30%)
  }

  .xl\:bg-grey-40 {
    background-color: fade(@color-grey, 40%)
  }

  .xl\:bg-grey-60 {
    background-color: fade(@color-grey, 60%)
  }

  .xl\:bg-grey-90 {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:bg-grey {
    background-color: @color-grey
  }

  .xl\:bg-grey-metal {
    background-color: @color-grey
  }

  .xl\:bg-grey-divider {
    background-color: fade(@color-grey, 20%)
  }

  .xl\:bg-grey-light {
    background-color: @color-grey-light
  }

  .xl\:bg-grey-header {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:bg-body {
    background-color: @color-body
  }

  .xl\:bg-yellow {
    background-color: @color-yellow
  }

  .xl\:bg-orange {
    background-color: @color-orange
  }

  .xl\:bg-stone {
    background-color: @color-stone
  }

  .xl\:bg-khaki {
    background-color: @color-khaki
  }

  .xl\:bg-green {
    background-color: @color-green
  }

  .xl\:bg-blue {
    background-color: @color-blue
  }

  .xl\:bg-red {
    background-color: @color-red
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .xl\:hover\:bg-black:hover {
    background-color: @color-black
  }

  .xl\:hover\:bg-white:hover {
    background-color: @color-white
  }

  .xl\:hover\:bg-grey-10:hover {
    background-color: fade(@color-grey, 10%)
  }

  .xl\:hover\:bg-grey-30:hover {
    background-color: fade(@color-grey, 30%)
  }

  .xl\:hover\:bg-grey-40:hover {
    background-color: fade(@color-grey, 40%)
  }

  .xl\:hover\:bg-grey-60:hover {
    background-color: fade(@color-grey, 60%)
  }

  .xl\:hover\:bg-grey-90:hover {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:hover\:bg-grey:hover {
    background-color: @color-grey
  }

  .xl\:hover\:bg-grey-metal:hover {
    background-color: @color-grey
  }

  .xl\:hover\:bg-grey-divider:hover {
    background-color: fade(@color-grey, 20%)
  }

  .xl\:hover\:bg-grey-light:hover {
    background-color: @color-grey-light
  }

  .xl\:hover\:bg-grey-header:hover {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:hover\:bg-body:hover {
    background-color: @color-body
  }

  .xl\:hover\:bg-yellow:hover {
    background-color: @color-yellow
  }

  .xl\:hover\:bg-orange:hover {
    background-color: @color-orange
  }

  .xl\:hover\:bg-stone:hover {
    background-color: @color-stone
  }

  .xl\:hover\:bg-khaki:hover {
    background-color: @color-khaki
  }

  .xl\:hover\:bg-green:hover {
    background-color: @color-green
  }

  .xl\:hover\:bg-blue:hover {
    background-color: @color-blue
  }

  .xl\:hover\:bg-red:hover {
    background-color: @color-red
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .xl\:focus\:bg-black:focus {
    background-color: @color-black
  }

  .xl\:focus\:bg-white:focus {
    background-color: @color-white
  }

  .xl\:focus\:bg-grey-10:focus {
    background-color: fade(@color-grey, 10%)
  }

  .xl\:focus\:bg-grey-30:focus {
    background-color: fade(@color-grey, 30%)
  }

  .xl\:focus\:bg-grey-40:focus {
    background-color: fade(@color-grey, 40%)
  }

  .xl\:focus\:bg-grey-60:focus {
    background-color: fade(@color-grey, 60%)
  }

  .xl\:focus\:bg-grey-90:focus {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:focus\:bg-grey:focus {
    background-color: @color-grey
  }

  .xl\:focus\:bg-grey-metal:focus {
    background-color: @color-grey
  }

  .xl\:focus\:bg-grey-divider:focus {
    background-color: fade(@color-grey, 20%)
  }

  .xl\:focus\:bg-grey-light:focus {
    background-color: @color-grey-light
  }

  .xl\:focus\:bg-grey-header:focus {
    background-color: fade(@color-grey, 90%)
  }

  .xl\:focus\:bg-body:focus {
    background-color: @color-body
  }

  .xl\:focus\:bg-yellow:focus {
    background-color: @color-yellow
  }

  .xl\:focus\:bg-orange:focus {
    background-color: @color-orange
  }

  .xl\:focus\:bg-stone:focus {
    background-color: @color-stone
  }

  .xl\:focus\:bg-khaki:focus {
    background-color: @color-khaki
  }

  .xl\:focus\:bg-green:focus {
    background-color: @color-green
  }

  .xl\:focus\:bg-blue:focus {
    background-color: @color-blue
  }

  .xl\:focus\:bg-red:focus {
    background-color: @color-red
  }

  .xl\:bg-bottom {
    background-position: bottom
  }

  .xl\:bg-center {
    background-position: center
  }

  .xl\:bg-left {
    background-position: left
  }

  .xl\:bg-left-bottom {
    background-position: left bottom
  }

  .xl\:bg-left-top {
    background-position: left top
  }

  .xl\:bg-right {
    background-position: right
  }

  .xl\:bg-right-bottom {
    background-position: right bottom
  }

  .xl\:bg-right-top {
    background-position: right top
  }

  .xl\:bg-top {
    background-position: top
  }

  .xl\:bg-repeat {
    background-repeat: repeat
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .xl\:bg-repeat-round {
    background-repeat: round
  }

  .xl\:bg-repeat-space {
    background-repeat: space
  }

  .xl\:bg-auto {
    background-size: auto
  }

  .xl\:bg-cover {
    background-size: cover
  }

  .xl\:bg-contain {
    background-size: contain
  }

  .xl\:border-collapse {
    border-collapse: collapse
  }

  .xl\:border-separate {
    border-collapse: separate
  }

  .xl\:border-transparent {
    border-color: transparent
  }

  .xl\:border-black {
    border-color: @color-black
  }

  .xl\:border-white {
    border-color: @color-white
  }

  .xl\:border-grey-10 {
    border-color: fade(@color-grey, 10%)
  }

  .xl\:border-grey-30 {
    border-color: fade(@color-grey, 30%)
  }

  .xl\:border-grey-40 {
    border-color: fade(@color-grey, 40%)
  }

  .xl\:border-grey-60 {
    border-color: fade(@color-grey, 60%)
  }

  .xl\:border-grey-90 {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:border-grey {
    border-color: @color-grey
  }

  .xl\:border-grey-metal {
    border-color: @color-grey
  }

  .xl\:border-grey-divider {
    border-color: fade(@color-grey, 20%)
  }

  .xl\:border-grey-light {
    border-color: @color-grey-light
  }

  .xl\:border-grey-header {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:border-body {
    border-color: @color-body
  }

  .xl\:border-yellow {
    border-color: @color-yellow
  }

  .xl\:border-orange {
    border-color: @color-orange
  }

  .xl\:border-stone {
    border-color: @color-stone
  }

  .xl\:border-khaki {
    border-color: @color-khaki
  }

  .xl\:border-green {
    border-color: @color-green
  }

  .xl\:border-blue {
    border-color: @color-blue
  }

  .xl\:border-red {
    border-color: @color-red
  }

  .xl\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .xl\:hover\:border-black:hover {
    border-color: @color-black
  }

  .xl\:hover\:border-white:hover {
    border-color: @color-white
  }

  .xl\:hover\:border-grey-10:hover {
    border-color: fade(@color-grey, 10%)
  }

  .xl\:hover\:border-grey-30:hover {
    border-color: fade(@color-grey, 30%)
  }

  .xl\:hover\:border-grey-40:hover {
    border-color: fade(@color-grey, 40%)
  }

  .xl\:hover\:border-grey-60:hover {
    border-color: fade(@color-grey, 60%)
  }

  .xl\:hover\:border-grey-90:hover {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:hover\:border-grey:hover {
    border-color: @color-grey
  }

  .xl\:hover\:border-grey-metal:hover {
    border-color: @color-grey
  }

  .xl\:hover\:border-grey-divider:hover {
    border-color: fade(@color-grey, 20%)
  }

  .xl\:hover\:border-grey-light:hover {
    border-color: @color-grey-light
  }

  .xl\:hover\:border-grey-header:hover {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:hover\:border-body:hover {
    border-color: @color-body
  }

  .xl\:hover\:border-yellow:hover {
    border-color: @color-yellow
  }

  .xl\:hover\:border-orange:hover {
    border-color: @color-orange
  }

  .xl\:hover\:border-stone:hover {
    border-color: @color-stone
  }

  .xl\:hover\:border-khaki:hover {
    border-color: @color-khaki
  }

  .xl\:hover\:border-green:hover {
    border-color: @color-green
  }

  .xl\:hover\:border-blue:hover {
    border-color: @color-blue
  }

  .xl\:hover\:border-red:hover {
    border-color: @color-red
  }

  .xl\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .xl\:focus\:border-black:focus {
    border-color: @color-black
  }

  .xl\:focus\:border-white:focus {
    border-color: @color-white
  }

  .xl\:focus\:border-grey-10:focus {
    border-color: fade(@color-grey, 10%)
  }

  .xl\:focus\:border-grey-30:focus {
    border-color: fade(@color-grey, 30%)
  }

  .xl\:focus\:border-grey-40:focus {
    border-color: fade(@color-grey, 40%)
  }

  .xl\:focus\:border-grey-60:focus {
    border-color: fade(@color-grey, 60%)
  }

  .xl\:focus\:border-grey-90:focus {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:focus\:border-grey:focus {
    border-color: @color-grey
  }

  .xl\:focus\:border-grey-metal:focus {
    border-color: @color-grey
  }

  .xl\:focus\:border-grey-divider:focus {
    border-color: fade(@color-grey, 20%)
  }

  .xl\:focus\:border-grey-light:focus {
    border-color: @color-grey-light
  }

  .xl\:focus\:border-grey-header:focus {
    border-color: fade(@color-grey, 90%)
  }

  .xl\:focus\:border-body:focus {
    border-color: @color-body
  }

  .xl\:focus\:border-yellow:focus {
    border-color: @color-yellow
  }

  .xl\:focus\:border-orange:focus {
    border-color: @color-orange
  }

  .xl\:focus\:border-stone:focus {
    border-color: @color-stone
  }

  .xl\:focus\:border-khaki:focus {
    border-color: @color-khaki
  }

  .xl\:focus\:border-green:focus {
    border-color: @color-green
  }

  .xl\:focus\:border-blue:focus {
    border-color: @color-blue
  }

  .xl\:focus\:border-red:focus {
    border-color: @color-red
  }

  .xl\:rounded-none {
    border-radius: 0
  }

  .xl\:rounded {
    border-radius: .125rem
  }

  .xl\:rounded-lg {
    border-radius: .375rem
  }

  .xl\:rounded-full {
    border-radius: 20em
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-t {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem
  }

  .xl\:rounded-r {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
  }

  .xl\:rounded-b {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .xl\:rounded-l {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 20em;
    border-top-right-radius: 20em
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .xl\:rounded-tl {
    border-top-left-radius: .125rem
  }

  .xl\:rounded-tr {
    border-top-right-radius: .125rem
  }

  .xl\:rounded-br {
    border-bottom-right-radius: .125rem
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: .125rem
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: .375rem
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: .375rem
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: .375rem
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .375rem
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 20em
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 20em
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 20em
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 20em
  }

  .xl\:border-solid {
    border-style: solid
  }

  .xl\:border-dashed {
    border-style: dashed
  }

  .xl\:border-dotted {
    border-style: dotted
  }

  .xl\:border-double {
    border-style: double
  }

  .xl\:border-none {
    border-style: none
  }

  .xl\:border-0 {
    border-width: 0
  }

  .xl\:border-3 {
    border-width: .1875rem
  }

  .xl\:border-6 {
    border-width: .375rem
  }

  .xl\:border {
    border-width: 1px
  }

  .xl\:border-t-0 {
    border-top-width: 0
  }

  .xl\:border-r-0 {
    border-right-width: 0
  }

  .xl\:border-b-0 {
    border-bottom-width: 0
  }

  .xl\:border-l-0 {
    border-left-width: 0
  }

  .xl\:border-t-3 {
    border-top-width: .1875rem
  }

  .xl\:border-r-3 {
    border-right-width: .1875rem
  }

  .xl\:border-b-3 {
    border-bottom-width: .1875rem
  }

  .xl\:border-l-3 {
    border-left-width: .1875rem
  }

  .xl\:border-t-6 {
    border-top-width: .375rem
  }

  .xl\:border-r-6 {
    border-right-width: .375rem
  }

  .xl\:border-b-6 {
    border-bottom-width: .375rem
  }

  .xl\:border-l-6 {
    border-left-width: .375rem
  }

  .xl\:border-t {
    border-top-width: 1px
  }

  .xl\:border-r {
    border-right-width: 1px
  }

  .xl\:border-b {
    border-bottom-width: 1px
  }

  .xl\:border-l {
    border-left-width: 1px
  }

  .xl\:cursor-auto {
    cursor: auto
  }

  .xl\:cursor-default {
    cursor: default
  }

  .xl\:cursor-pointer {
    cursor: pointer
  }

  .xl\:cursor-wait {
    cursor: wait
  }

  .xl\:cursor-text {
    cursor: text
  }

  .xl\:cursor-move {
    cursor: move
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .xl\:block {
    display: block
  }

  .xl\:inline-block {
    display: inline-block
  }

  .xl\:inline {
    display: inline
  }

  .xl\:flex {
    display: flex
  }

  .xl\:inline-flex {
    display: inline-flex
  }

  .xl\:table {
    display: table
  }

  .xl\:table-row {
    display: table-row
  }

  .xl\:table-cell {
    display: table-cell
  }

  .xl\:hidden {
    display: none
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .xl\:flex-col {
    flex-direction: column
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .xl\:flex-wrap {
    flex-wrap: wrap
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .xl\:items-start {
    align-items: flex-start
  }

  .xl\:items-end {
    align-items: flex-end
  }

  .xl\:items-center {
    align-items: center
  }

  .xl\:items-baseline {
    align-items: baseline
  }

  .xl\:items-stretch {
    align-items: stretch
  }

  .xl\:self-auto {
    align-self: auto
  }

  .xl\:self-start {
    align-self: flex-start
  }

  .xl\:self-end {
    align-self: flex-end
  }

  .xl\:self-center {
    align-self: center
  }

  .xl\:self-stretch {
    align-self: stretch
  }

  .xl\:justify-start {
    justify-content: flex-start
  }

  .xl\:justify-end {
    justify-content: flex-end
  }

  .xl\:justify-center {
    justify-content: center
  }

  .xl\:justify-between {
    justify-content: space-between
  }

  .xl\:justify-around {
    justify-content: space-around
  }

  .xl\:content-center {
    align-content: center
  }

  .xl\:content-start {
    align-content: flex-start
  }

  .xl\:content-end {
    align-content: flex-end
  }

  .xl\:content-between {
    align-content: space-between
  }

  .xl\:content-around {
    align-content: space-around
  }

  .xl\:flex-1 {
    flex: 1 1 0%
  }

  .xl\:flex-auto {
    flex: 1 1 auto
  }

  .xl\:flex-initial {
    flex: 0 1 auto
  }

  .xl\:flex-none {
    flex: none
  }

  .xl\:flex-grow-0 {
    flex-grow: 0
  }

  .xl\:flex-grow {
    flex-grow: 1
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .xl\:flex-shrink {
    flex-shrink: 1
  }

  .xl\:order-1 {
    order: 1
  }

  .xl\:order-2 {
    order: 2
  }

  .xl\:order-3 {
    order: 3
  }

  .xl\:order-4 {
    order: 4
  }

  .xl\:order-5 {
    order: 5
  }

  .xl\:order-6 {
    order: 6
  }

  .xl\:order-7 {
    order: 7
  }

  .xl\:order-8 {
    order: 8
  }

  .xl\:order-9 {
    order: 9
  }

  .xl\:order-10 {
    order: 10
  }

  .xl\:order-11 {
    order: 11
  }

  .xl\:order-12 {
    order: 12
  }

  .xl\:order-first {
    order: -9999
  }

  .xl\:order-last {
    order: 9999
  }

  .xl\:order-none {
    order: 0
  }

  .xl\:float-right {
    float: right
  }

  .xl\:float-left {
    float: left
  }

  .xl\:float-none {
    float: none
  }

  .xl\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .xl\:font-body {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
  }

  .xl\:font-normal {
    font-weight: 400
  }

  .xl\:font-semibold {
    font-weight: 600
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .xl\:h-0 {
    height: 0
  }

  .xl\:h-1 {
    height: 0.5rem
  }

  .xl\:h-2 {
    height: 1rem
  }

  .xl\:h-3 {
    height: 1.5rem
  }

  .xl\:h-4 {
    height: 2rem
  }

  .xl\:h-5 {
    height: 2.5rem
  }

  .xl\:h-6 {
    height: 3rem
  }

  .xl\:h-7 {
    height: 3.5rem
  }

  .xl\:h-8 {
    height: 4rem
  }

  .xl\:h-9 {
    height: 4.5rem
  }

  .xl\:h-10 {
    height: 5rem
  }

  .xl\:h-11 {
    height: 5.5rem
  }

  .xl\:h-12 {
    height: 6rem
  }

  .xl\:h-13 {
    height: 6.5rem
  }

  .xl\:h-14 {
    height: 7rem
  }

  .xl\:h-15 {
    height: 7.5rem
  }

  .xl\:h-16 {
    height: 8rem
  }

  .xl\:h-17 {
    height: 8.5rem
  }

  .xl\:h-19 {
    height: 9rem
  }

  .xl\:h-20 {
    height: 9.5rem
  }

  .xl\:h-22 {
    height: 11rem
  }

  .xl\:h-24 {
    height: 12rem
  }

  .xl\:h-32 {
    height: 16rem
  }

  .xl\:h-40 {
    height: 20rem
  }

  .xl\:h-auto {
    height: auto
  }

  .xl\:h-px {
    height: 1px
  }

  .xl\:h-half {
    height: 0.25rem
  }

  .xl\:h-full {
    height: 100%
  }

  .xl\:h-screen {
    height: 100vh
  }

  .xl\:leading-base {
    line-height: .75rem
  }

  .xl\:leading-sm {
    line-height: 1.125rem
  }

  .xl\:leading-lg {
    line-height: 1.5rem
  }

  .xl\:leading-xl {
    line-height: 1.875rem
  }

  .xl\:leading-2xl {
    line-height: 2.25rem
  }

  .xl\:list-none {
    list-style-type: none
  }

  .xl\:list-disc {
    list-style-type: disc
  }

  .xl\:list-decimal {
    list-style-type: decimal
  }

  .xl\:m-0 {
    margin: 0
  }

  .xl\:m-1 {
    margin: 0.5rem
  }

  .xl\:m-2 {
    margin: 1rem
  }

  .xl\:m-3 {
    margin: 1.5rem
  }

  .xl\:m-4 {
    margin: 2rem
  }

  .xl\:m-5 {
    margin: 2.5rem
  }

  .xl\:m-6 {
    margin: 3rem
  }

  .xl\:m-7 {
    margin: 3.5rem
  }

  .xl\:m-8 {
    margin: 4rem
  }

  .xl\:m-9 {
    margin: 4.5rem
  }

  .xl\:m-10 {
    margin: 5rem
  }

  .xl\:m-11 {
    margin: 5.5rem
  }

  .xl\:m-12 {
    margin: 6rem
  }

  .xl\:m-13 {
    margin: 6.5rem
  }

  .xl\:m-14 {
    margin: 7rem
  }

  .xl\:m-15 {
    margin: 7.5rem
  }

  .xl\:m-16 {
    margin: 8rem
  }

  .xl\:m-17 {
    margin: 8.5rem
  }

  .xl\:m-19 {
    margin: 9rem
  }

  .xl\:m-20 {
    margin: 9.5rem
  }

  .xl\:m-22 {
    margin: 11rem
  }

  .xl\:m-24 {
    margin: 12rem
  }

  .xl\:m-32 {
    margin: 16rem
  }

  .xl\:m-40 {
    margin: 20rem
  }

  .xl\:m-auto {
    margin: auto
  }

  .xl\:m-px {
    margin: 1px
  }

  .xl\:m-half {
    margin: 0.25rem
  }

  .xl\:-m-1 {
    margin: -0.5rem
  }

  .xl\:-m-2 {
    margin: -1rem
  }

  .xl\:-m-3 {
    margin: -1.5rem
  }

  .xl\:-m-4 {
    margin: -2rem
  }

  .xl\:-m-5 {
    margin: -2.5rem
  }

  .xl\:-m-6 {
    margin: -3rem
  }

  .xl\:-m-7 {
    margin: -3.5rem
  }

  .xl\:-m-8 {
    margin: -4rem
  }

  .xl\:-m-9 {
    margin: -4.5rem
  }

  .xl\:-m-10 {
    margin: -5rem
  }

  .xl\:-m-11 {
    margin: -5.5rem
  }

  .xl\:-m-12 {
    margin: -6rem
  }

  .xl\:-m-13 {
    margin: -6.5rem
  }

  .xl\:-m-14 {
    margin: -7rem
  }

  .xl\:-m-15 {
    margin: -7.5rem
  }

  .xl\:-m-16 {
    margin: -8rem
  }

  .xl\:-m-17 {
    margin: -8.5rem
  }

  .xl\:-m-19 {
    margin: -9rem
  }

  .xl\:-m-20 {
    margin: -9.5rem
  }

  .xl\:-m-22 {
    margin: -11rem
  }

  .xl\:-m-24 {
    margin: -12rem
  }

  .xl\:-m-32 {
    margin: -16rem
  }

  .xl\:-m-40 {
    margin: -20rem
  }

  .xl\:-m-px {
    margin: -1px
  }

  .xl\:-m-half {
    margin: -0.25rem
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .xl\:my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xl\:mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xl\:my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xl\:mx-2 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .xl\:my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .xl\:mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .xl\:my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xl\:mx-4 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xl\:my-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .xl\:mx-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .xl\:my-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .xl\:mx-6 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .xl\:my-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .xl\:mx-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .xl\:my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xl\:mx-8 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xl\:my-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
  }

  .xl\:mx-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .xl\:my-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .xl\:mx-10 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .xl\:my-11 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem
  }

  .xl\:mx-11 {
    margin-left: 5.5rem;
    margin-right: 5.5rem
  }

  .xl\:my-12 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xl\:mx-12 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xl\:my-13 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem
  }

  .xl\:mx-13 {
    margin-left: 6.5rem;
    margin-right: 6.5rem
  }

  .xl\:my-14 {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .xl\:mx-14 {
    margin-left: 7rem;
    margin-right: 7rem
  }

  .xl\:my-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem
  }

  .xl\:mx-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem
  }

  .xl\:my-16 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xl\:mx-16 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xl\:my-17 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem
  }

  .xl\:mx-17 {
    margin-left: 8.5rem;
    margin-right: 8.5rem
  }

  .xl\:my-19 {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .xl\:mx-19 {
    margin-left: 9rem;
    margin-right: 9rem
  }

  .xl\:my-20 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem
  }

  .xl\:mx-20 {
    margin-left: 9.5rem;
    margin-right: 9.5rem
  }

  .xl\:my-22 {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .xl\:mx-22 {
    margin-left: 11rem;
    margin-right: 11rem
  }

  .xl\:my-24 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .xl\:mx-24 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .xl\:my-32 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xl\:mx-32 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xl\:my-40 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .xl\:mx-40 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .xl\:my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xl\:mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xl\:-my-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xl\:-mx-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xl\:-my-2 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xl\:-mx-2 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xl\:-my-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .xl\:-mx-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .xl\:-my-4 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xl\:-mx-4 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xl\:-my-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .xl\:-mx-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .xl\:-my-6 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .xl\:-mx-6 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .xl\:-my-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .xl\:-mx-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .xl\:-my-8 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xl\:-mx-8 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xl\:-my-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem
  }

  .xl\:-mx-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem
  }

  .xl\:-my-10 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .xl\:-mx-10 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .xl\:-my-11 {
    margin-top: -5.5rem;
    margin-bottom: -5.5rem
  }

  .xl\:-mx-11 {
    margin-left: -5.5rem;
    margin-right: -5.5rem
  }

  .xl\:-my-12 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xl\:-mx-12 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xl\:-my-13 {
    margin-top: -6.5rem;
    margin-bottom: -6.5rem
  }

  .xl\:-mx-13 {
    margin-left: -6.5rem;
    margin-right: -6.5rem
  }

  .xl\:-my-14 {
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  .xl\:-mx-14 {
    margin-left: -7rem;
    margin-right: -7rem
  }

  .xl\:-my-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem
  }

  .xl\:-mx-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem
  }

  .xl\:-my-16 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xl\:-mx-16 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xl\:-my-17 {
    margin-top: -8.5rem;
    margin-bottom: -8.5rem
  }

  .xl\:-mx-17 {
    margin-left: -8.5rem;
    margin-right: -8.5rem
  }

  .xl\:-my-19 {
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  .xl\:-mx-19 {
    margin-left: -9rem;
    margin-right: -9rem
  }

  .xl\:-my-20 {
    margin-top: -9.5rem;
    margin-bottom: -9.5rem
  }

  .xl\:-mx-20 {
    margin-left: -9.5rem;
    margin-right: -9.5rem
  }

  .xl\:-my-22 {
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  .xl\:-mx-22 {
    margin-left: -11rem;
    margin-right: -11rem
  }

  .xl\:-my-24 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .xl\:-mx-24 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .xl\:-my-32 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xl\:-mx-32 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xl\:-my-40 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .xl\:-mx-40 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .xl\:-my-half {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xl\:-mx-half {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xl\:mt-0 {
    margin-top: 0
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:ml-0 {
    margin-left: 0
  }

  .xl\:mt-1 {
    margin-top: 0.5rem
  }

  .xl\:mr-1 {
    margin-right: 0.5rem
  }

  .xl\:mb-1 {
    margin-bottom: 0.5rem
  }

  .xl\:ml-1 {
    margin-left: 0.5rem
  }

  .xl\:mt-2 {
    margin-top: 1rem
  }

  .xl\:mr-2 {
    margin-right: 1rem
  }

  .xl\:mb-2 {
    margin-bottom: 1rem
  }

  .xl\:ml-2 {
    margin-left: 1rem
  }

  .xl\:mt-3 {
    margin-top: 1.5rem
  }

  .xl\:mr-3 {
    margin-right: 1.5rem
  }

  .xl\:mb-3 {
    margin-bottom: 1.5rem
  }

  .xl\:ml-3 {
    margin-left: 1.5rem
  }

  .xl\:mt-4 {
    margin-top: 2rem
  }

  .xl\:mr-4 {
    margin-right: 2rem
  }

  .xl\:mb-4 {
    margin-bottom: 2rem
  }

  .xl\:ml-4 {
    margin-left: 2rem
  }

  .xl\:mt-5 {
    margin-top: 2.5rem
  }

  .xl\:mr-5 {
    margin-right: 2.5rem
  }

  .xl\:mb-5 {
    margin-bottom: 2.5rem
  }

  .xl\:ml-5 {
    margin-left: 2.5rem
  }

  .xl\:mt-6 {
    margin-top: 3rem
  }

  .xl\:mr-6 {
    margin-right: 3rem
  }

  .xl\:mb-6 {
    margin-bottom: 3rem
  }

  .xl\:ml-6 {
    margin-left: 3rem
  }

  .xl\:mt-7 {
    margin-top: 3.5rem
  }

  .xl\:mr-7 {
    margin-right: 3.5rem
  }

  .xl\:mb-7 {
    margin-bottom: 3.5rem
  }

  .xl\:ml-7 {
    margin-left: 3.5rem
  }

  .xl\:mt-8 {
    margin-top: 4rem
  }

  .xl\:mr-8 {
    margin-right: 4rem
  }

  .xl\:mb-8 {
    margin-bottom: 4rem
  }

  .xl\:ml-8 {
    margin-left: 4rem
  }

  .xl\:mt-9 {
    margin-top: 4.5rem
  }

  .xl\:mr-9 {
    margin-right: 4.5rem
  }

  .xl\:mb-9 {
    margin-bottom: 4.5rem
  }

  .xl\:ml-9 {
    margin-left: 4.5rem
  }

  .xl\:mt-10 {
    margin-top: 5rem
  }

  .xl\:mr-10 {
    margin-right: 5rem
  }

  .xl\:mb-10 {
    margin-bottom: 5rem
  }

  .xl\:ml-10 {
    margin-left: 5rem
  }

  .xl\:mt-11 {
    margin-top: 5.5rem
  }

  .xl\:mr-11 {
    margin-right: 5.5rem
  }

  .xl\:mb-11 {
    margin-bottom: 5.5rem
  }

  .xl\:ml-11 {
    margin-left: 5.5rem
  }

  .xl\:mt-12 {
    margin-top: 6rem
  }

  .xl\:mr-12 {
    margin-right: 6rem
  }

  .xl\:mb-12 {
    margin-bottom: 6rem
  }

  .xl\:ml-12 {
    margin-left: 6rem
  }

  .xl\:mt-13 {
    margin-top: 6.5rem
  }

  .xl\:mr-13 {
    margin-right: 6.5rem
  }

  .xl\:mb-13 {
    margin-bottom: 6.5rem
  }

  .xl\:ml-13 {
    margin-left: 6.5rem
  }

  .xl\:mt-14 {
    margin-top: 7rem
  }

  .xl\:mr-14 {
    margin-right: 7rem
  }

  .xl\:mb-14 {
    margin-bottom: 7rem
  }

  .xl\:ml-14 {
    margin-left: 7rem
  }

  .xl\:mt-15 {
    margin-top: 7.5rem
  }

  .xl\:mr-15 {
    margin-right: 7.5rem
  }

  .xl\:mb-15 {
    margin-bottom: 7.5rem
  }

  .xl\:ml-15 {
    margin-left: 7.5rem
  }

  .xl\:mt-16 {
    margin-top: 8rem
  }

  .xl\:mr-16 {
    margin-right: 8rem
  }

  .xl\:mb-16 {
    margin-bottom: 8rem
  }

  .xl\:ml-16 {
    margin-left: 8rem
  }

  .xl\:mt-17 {
    margin-top: 8.5rem
  }

  .xl\:mr-17 {
    margin-right: 8.5rem
  }

  .xl\:mb-17 {
    margin-bottom: 8.5rem
  }

  .xl\:ml-17 {
    margin-left: 8.5rem
  }

  .xl\:mt-19 {
    margin-top: 9rem
  }

  .xl\:mr-19 {
    margin-right: 9rem
  }

  .xl\:mb-19 {
    margin-bottom: 9rem
  }

  .xl\:ml-19 {
    margin-left: 9rem
  }

  .xl\:mt-20 {
    margin-top: 9.5rem
  }

  .xl\:mr-20 {
    margin-right: 9.5rem
  }

  .xl\:mb-20 {
    margin-bottom: 9.5rem
  }

  .xl\:ml-20 {
    margin-left: 9.5rem
  }

  .xl\:mt-22 {
    margin-top: 11rem
  }

  .xl\:mr-22 {
    margin-right: 11rem
  }

  .xl\:mb-22 {
    margin-bottom: 11rem
  }

  .xl\:ml-22 {
    margin-left: 11rem
  }

  .xl\:mt-24 {
    margin-top: 12rem
  }

  .xl\:mr-24 {
    margin-right: 12rem
  }

  .xl\:mb-24 {
    margin-bottom: 12rem
  }

  .xl\:ml-24 {
    margin-left: 12rem
  }

  .xl\:mt-32 {
    margin-top: 16rem
  }

  .xl\:mr-32 {
    margin-right: 16rem
  }

  .xl\:mb-32 {
    margin-bottom: 16rem
  }

  .xl\:ml-32 {
    margin-left: 16rem
  }

  .xl\:mt-40 {
    margin-top: 20rem
  }

  .xl\:mr-40 {
    margin-right: 20rem
  }

  .xl\:mb-40 {
    margin-bottom: 20rem
  }

  .xl\:ml-40 {
    margin-left: 20rem
  }

  .xl\:mt-auto {
    margin-top: auto
  }

  .xl\:mr-auto {
    margin-right: auto
  }

  .xl\:mb-auto {
    margin-bottom: auto
  }

  .xl\:ml-auto {
    margin-left: auto
  }

  .xl\:mt-px {
    margin-top: 1px
  }

  .xl\:mr-px {
    margin-right: 1px
  }

  .xl\:mb-px {
    margin-bottom: 1px
  }

  .xl\:ml-px {
    margin-left: 1px
  }

  .xl\:mt-half {
    margin-top: 0.25rem
  }

  .xl\:mr-half {
    margin-right: 0.25rem
  }

  .xl\:mb-half {
    margin-bottom: 0.25rem
  }

  .xl\:ml-half {
    margin-left: 0.25rem
  }

  .xl\:-mt-1 {
    margin-top: -0.5rem
  }

  .xl\:-mr-1 {
    margin-right: -0.5rem
  }

  .xl\:-mb-1 {
    margin-bottom: -0.5rem
  }

  .xl\:-ml-1 {
    margin-left: -0.5rem
  }

  .xl\:-mt-2 {
    margin-top: -1rem
  }

  .xl\:-mr-2 {
    margin-right: -1rem
  }

  .xl\:-mb-2 {
    margin-bottom: -1rem
  }

  .xl\:-ml-2 {
    margin-left: -1rem
  }

  .xl\:-mt-3 {
    margin-top: -1.5rem
  }

  .xl\:-mr-3 {
    margin-right: -1.5rem
  }

  .xl\:-mb-3 {
    margin-bottom: -1.5rem
  }

  .xl\:-ml-3 {
    margin-left: -1.5rem
  }

  .xl\:-mt-4 {
    margin-top: -2rem
  }

  .xl\:-mr-4 {
    margin-right: -2rem
  }

  .xl\:-mb-4 {
    margin-bottom: -2rem
  }

  .xl\:-ml-4 {
    margin-left: -2rem
  }

  .xl\:-mt-5 {
    margin-top: -2.5rem
  }

  .xl\:-mr-5 {
    margin-right: -2.5rem
  }

  .xl\:-mb-5 {
    margin-bottom: -2.5rem
  }

  .xl\:-ml-5 {
    margin-left: -2.5rem
  }

  .xl\:-mt-6 {
    margin-top: -3rem
  }

  .xl\:-mr-6 {
    margin-right: -3rem
  }

  .xl\:-mb-6 {
    margin-bottom: -3rem
  }

  .xl\:-ml-6 {
    margin-left: -3rem
  }

  .xl\:-mt-7 {
    margin-top: -3.5rem
  }

  .xl\:-mr-7 {
    margin-right: -3.5rem
  }

  .xl\:-mb-7 {
    margin-bottom: -3.5rem
  }

  .xl\:-ml-7 {
    margin-left: -3.5rem
  }

  .xl\:-mt-8 {
    margin-top: -4rem
  }

  .xl\:-mr-8 {
    margin-right: -4rem
  }

  .xl\:-mb-8 {
    margin-bottom: -4rem
  }

  .xl\:-ml-8 {
    margin-left: -4rem
  }

  .xl\:-mt-9 {
    margin-top: -4.5rem
  }

  .xl\:-mr-9 {
    margin-right: -4.5rem
  }

  .xl\:-mb-9 {
    margin-bottom: -4.5rem
  }

  .xl\:-ml-9 {
    margin-left: -4.5rem
  }

  .xl\:-mt-10 {
    margin-top: -5rem
  }

  .xl\:-mr-10 {
    margin-right: -5rem
  }

  .xl\:-mb-10 {
    margin-bottom: -5rem
  }

  .xl\:-ml-10 {
    margin-left: -5rem
  }

  .xl\:-mt-11 {
    margin-top: -5.5rem
  }

  .xl\:-mr-11 {
    margin-right: -5.5rem
  }

  .xl\:-mb-11 {
    margin-bottom: -5.5rem
  }

  .xl\:-ml-11 {
    margin-left: -5.5rem
  }

  .xl\:-mt-12 {
    margin-top: -6rem
  }

  .xl\:-mr-12 {
    margin-right: -6rem
  }

  .xl\:-mb-12 {
    margin-bottom: -6rem
  }

  .xl\:-ml-12 {
    margin-left: -6rem
  }

  .xl\:-mt-13 {
    margin-top: -6.5rem
  }

  .xl\:-mr-13 {
    margin-right: -6.5rem
  }

  .xl\:-mb-13 {
    margin-bottom: -6.5rem
  }

  .xl\:-ml-13 {
    margin-left: -6.5rem
  }

  .xl\:-mt-14 {
    margin-top: -7rem
  }

  .xl\:-mr-14 {
    margin-right: -7rem
  }

  .xl\:-mb-14 {
    margin-bottom: -7rem
  }

  .xl\:-ml-14 {
    margin-left: -7rem
  }

  .xl\:-mt-15 {
    margin-top: -7.5rem
  }

  .xl\:-mr-15 {
    margin-right: -7.5rem
  }

  .xl\:-mb-15 {
    margin-bottom: -7.5rem
  }

  .xl\:-ml-15 {
    margin-left: -7.5rem
  }

  .xl\:-mt-16 {
    margin-top: -8rem
  }

  .xl\:-mr-16 {
    margin-right: -8rem
  }

  .xl\:-mb-16 {
    margin-bottom: -8rem
  }

  .xl\:-ml-16 {
    margin-left: -8rem
  }

  .xl\:-mt-17 {
    margin-top: -8.5rem
  }

  .xl\:-mr-17 {
    margin-right: -8.5rem
  }

  .xl\:-mb-17 {
    margin-bottom: -8.5rem
  }

  .xl\:-ml-17 {
    margin-left: -8.5rem
  }

  .xl\:-mt-19 {
    margin-top: -9rem
  }

  .xl\:-mr-19 {
    margin-right: -9rem
  }

  .xl\:-mb-19 {
    margin-bottom: -9rem
  }

  .xl\:-ml-19 {
    margin-left: -9rem
  }

  .xl\:-mt-20 {
    margin-top: -9.5rem
  }

  .xl\:-mr-20 {
    margin-right: -9.5rem
  }

  .xl\:-mb-20 {
    margin-bottom: -9.5rem
  }

  .xl\:-ml-20 {
    margin-left: -9.5rem
  }

  .xl\:-mt-22 {
    margin-top: -11rem
  }

  .xl\:-mr-22 {
    margin-right: -11rem
  }

  .xl\:-mb-22 {
    margin-bottom: -11rem
  }

  .xl\:-ml-22 {
    margin-left: -11rem
  }

  .xl\:-mt-24 {
    margin-top: -12rem
  }

  .xl\:-mr-24 {
    margin-right: -12rem
  }

  .xl\:-mb-24 {
    margin-bottom: -12rem
  }

  .xl\:-ml-24 {
    margin-left: -12rem
  }

  .xl\:-mt-32 {
    margin-top: -16rem
  }

  .xl\:-mr-32 {
    margin-right: -16rem
  }

  .xl\:-mb-32 {
    margin-bottom: -16rem
  }

  .xl\:-ml-32 {
    margin-left: -16rem
  }

  .xl\:-mt-40 {
    margin-top: -20rem
  }

  .xl\:-mr-40 {
    margin-right: -20rem
  }

  .xl\:-mb-40 {
    margin-bottom: -20rem
  }

  .xl\:-ml-40 {
    margin-left: -20rem
  }

  .xl\:-mt-px {
    margin-top: -1px
  }

  .xl\:-mr-px {
    margin-right: -1px
  }

  .xl\:-mb-px {
    margin-bottom: -1px
  }

  .xl\:-ml-px {
    margin-left: -1px
  }

  .xl\:-mt-half {
    margin-top: -0.25rem
  }

  .xl\:-mr-half {
    margin-right: -0.25rem
  }

  .xl\:-mb-half {
    margin-bottom: -0.25rem
  }

  .xl\:-ml-half {
    margin-left: -0.25rem
  }

  .xl\:max-h-90 {
    max-height: 90%
  }

  .xl\:max-h-full {
    max-height: 100%
  }

  .xl\:max-h-screen {
    max-height: 100vh
  }

  .xl\:max-w-400 {
    max-width: 25rem
  }

  .xl\:max-w-500 {
    max-width: 31.25rem
  }

  .xl\:max-w-600 {
    max-width: 37.5rem
  }

  .xl\:max-w-700 {
    max-width: 43.75em
  }

  .xl\:max-w-800 {
    max-width: 50rem
  }

  .xl\:max-w-1000 {
    max-width: 62.5rem
  }

  .xl\:max-w-1200 {
    max-width: 75rem
  }

  .xl\:max-w-1280 {
    max-width: 80rem
  }

  .xl\:max-w-1400 {
    max-width: 87.5rem
  }

  .xl\:max-w-1600 {
    max-width: 100rem
  }

  .xl\:max-w-1800 {
    max-width: 112.5rem
  }

  .xl\:max-w-2000 {
    max-width: 125rem
  }

  .xl\:min-h-0 {
    min-height: 0
  }

  .xl\:min-h-full {
    min-height: 100%
  }

  .xl\:min-h-screen {
    min-height: 100vh
  }

  .xl\:min-w-0 {
    min-width: 0
  }

  .xl\:min-w-full {
    min-width: 100%
  }

  .xl\:object-contain {
    object-fit: contain
  }

  .xl\:object-cover {
    object-fit: cover
  }

  .xl\:object-fill {
    object-fit: fill
  }

  .xl\:object-none {
    object-fit: none
  }

  .xl\:object-scale-down {
    object-fit: scale-down
  }

  .xl\:object-bottom {
    object-position: bottom
  }

  .xl\:object-center {
    object-position: center
  }

  .xl\:object-left {
    object-position: left
  }

  .xl\:object-left-bottom {
    object-position: left bottom
  }

  .xl\:object-left-top {
    object-position: left top
  }

  .xl\:object-right {
    object-position: right
  }

  .xl\:object-right-bottom {
    object-position: right bottom
  }

  .xl\:object-right-top {
    object-position: right top
  }

  .xl\:object-top {
    object-position: top
  }

  .xl\:opacity-0 {
    opacity: 0
  }

  .xl\:opacity-20 {
    opacity: .2
  }

  .xl\:opacity-30 {
    opacity: .3
  }

  .xl\:opacity-40 {
    opacity: .4
  }

  .xl\:opacity-50 {
    opacity: .5
  }

  .xl\:opacity-60 {
    opacity: .6
  }

  .xl\:opacity-80 {
    opacity: .8
  }

  .xl\:opacity-100 {
    opacity: 1
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0
  }

  .xl\:hover\:opacity-20:hover {
    opacity: .2
  }

  .xl\:hover\:opacity-30:hover {
    opacity: .3
  }

  .xl\:hover\:opacity-40:hover {
    opacity: .4
  }

  .xl\:hover\:opacity-50:hover {
    opacity: .5
  }

  .xl\:hover\:opacity-60:hover {
    opacity: .6
  }

  .xl\:hover\:opacity-80:hover {
    opacity: .8
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0
  }

  .xl\:focus\:opacity-20:focus {
    opacity: .2
  }

  .xl\:focus\:opacity-30:focus {
    opacity: .3
  }

  .xl\:focus\:opacity-40:focus {
    opacity: .4
  }

  .xl\:focus\:opacity-50:focus {
    opacity: .5
  }

  .xl\:focus\:opacity-60:focus {
    opacity: .6
  }

  .xl\:focus\:opacity-80:focus {
    opacity: .8
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1
  }

  .xl\:outline-none {
    outline: 0
  }

  .xl\:focus\:outline-none:focus {
    outline: 0
  }

  .xl\:overflow-auto {
    overflow: auto
  }

  .xl\:overflow-hidden {
    overflow: hidden
  }

  .xl\:overflow-visible {
    overflow: visible
  }

  .xl\:overflow-scroll {
    overflow: scroll
  }

  .xl\:overflow-x-auto {
    overflow-x: auto
  }

  .xl\:overflow-y-auto {
    overflow-y: auto
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .xl\:overflow-x-visible {
    overflow-x: visible
  }

  .xl\:overflow-y-visible {
    overflow-y: visible
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .xl\:p-0 {
    padding: 0
  }

  .xl\:p-1 {
    padding: 0.5rem
  }

  .xl\:p-2 {
    padding: 1rem
  }

  .xl\:p-3 {
    padding: 1.5rem
  }

  .xl\:p-4 {
    padding: 2rem
  }

  .xl\:p-5 {
    padding: 2.5rem
  }

  .xl\:p-6 {
    padding: 3rem
  }

  .xl\:p-7 {
    padding: 3.5rem
  }

  .xl\:p-8 {
    padding: 4rem
  }

  .xl\:p-9 {
    padding: 4.5rem
  }

  .xl\:p-10 {
    padding: 5rem
  }

  .xl\:p-11 {
    padding: 5.5rem
  }

  .xl\:p-12 {
    padding: 6rem
  }

  .xl\:p-13 {
    padding: 6.5rem
  }

  .xl\:p-14 {
    padding: 7rem
  }

  .xl\:p-15 {
    padding: 7.5rem
  }

  .xl\:p-16 {
    padding: 8rem
  }

  .xl\:p-17 {
    padding: 8.5rem
  }

  .xl\:p-19 {
    padding: 9rem
  }

  .xl\:p-20 {
    padding: 9.5rem
  }

  .xl\:p-22 {
    padding: 11rem
  }

  .xl\:p-24 {
    padding: 12rem
  }

  .xl\:p-32 {
    padding: 16rem
  }

  .xl\:p-40 {
    padding: 20rem
  }

  .xl\:p-px {
    padding: 1px
  }

  .xl\:p-half {
    padding: 0.25rem
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xl\:py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xl\:px-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xl\:py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .xl\:px-2 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .xl\:py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .xl\:px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xl\:py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:px-4 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:py-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xl\:px-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .xl\:py-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .xl\:px-6 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .xl\:py-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .xl\:px-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .xl\:py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xl\:px-8 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xl\:py-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
  }

  .xl\:px-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
  }

  .xl\:py-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .xl\:px-10 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xl\:py-11 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem
  }

  .xl\:px-11 {
    padding-left: 5.5rem;
    padding-right: 5.5rem
  }

  .xl\:py-12 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xl\:px-12 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xl\:py-13 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem
  }

  .xl\:px-13 {
    padding-left: 6.5rem;
    padding-right: 6.5rem
  }

  .xl\:py-14 {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .xl\:px-14 {
    padding-left: 7rem;
    padding-right: 7rem
  }

  .xl\:py-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
  }

  .xl\:px-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .xl\:py-16 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xl\:px-16 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xl\:py-17 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem
  }

  .xl\:px-17 {
    padding-left: 8.5rem;
    padding-right: 8.5rem
  }

  .xl\:py-19 {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .xl\:px-19 {
    padding-left: 9rem;
    padding-right: 9rem
  }

  .xl\:py-20 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem
  }

  .xl\:px-20 {
    padding-left: 9.5rem;
    padding-right: 9.5rem
  }

  .xl\:py-22 {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .xl\:px-22 {
    padding-left: 11rem;
    padding-right: 11rem
  }

  .xl\:py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .xl\:px-24 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .xl\:py-32 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xl\:px-32 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xl\:py-40 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .xl\:px-40 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .xl\:py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xl\:px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xl\:pt-0 {
    padding-top: 0
  }

  .xl\:pr-0 {
    padding-right: 0
  }

  .xl\:pb-0 {
    padding-bottom: 0
  }

  .xl\:pl-0 {
    padding-left: 0
  }

  .xl\:pt-1 {
    padding-top: 0.5rem
  }

  .xl\:pr-1 {
    padding-right: 0.5rem
  }

  .xl\:pb-1 {
    padding-bottom: 0.5rem
  }

  .xl\:pl-1 {
    padding-left: 0.5rem
  }

  .xl\:pt-2 {
    padding-top: 1rem
  }

  .xl\:pr-2 {
    padding-right: 1rem
  }

  .xl\:pb-2 {
    padding-bottom: 1rem
  }

  .xl\:pl-2 {
    padding-left: 1rem
  }

  .xl\:pt-3 {
    padding-top: 1.5rem
  }

  .xl\:pr-3 {
    padding-right: 1.5rem
  }

  .xl\:pb-3 {
    padding-bottom: 1.5rem
  }

  .xl\:pl-3 {
    padding-left: 1.5rem
  }

  .xl\:pt-4 {
    padding-top: 2rem
  }

  .xl\:pr-4 {
    padding-right: 2rem
  }

  .xl\:pb-4 {
    padding-bottom: 2rem
  }

  .xl\:pl-4 {
    padding-left: 2rem
  }

  .xl\:pt-5 {
    padding-top: 2.5rem
  }

  .xl\:pr-5 {
    padding-right: 2.5rem
  }

  .xl\:pb-5 {
    padding-bottom: 2.5rem
  }

  .xl\:pl-5 {
    padding-left: 2.5rem
  }

  .xl\:pt-6 {
    padding-top: 3rem
  }

  .xl\:pr-6 {
    padding-right: 3rem
  }

  .xl\:pb-6 {
    padding-bottom: 3rem
  }

  .xl\:pl-6 {
    padding-left: 3rem
  }

  .xl\:pt-7 {
    padding-top: 3.5rem
  }

  .xl\:pr-7 {
    padding-right: 3.5rem
  }

  .xl\:pb-7 {
    padding-bottom: 3.5rem
  }

  .xl\:pl-7 {
    padding-left: 3.5rem
  }

  .xl\:pt-8 {
    padding-top: 4rem
  }

  .xl\:pr-8 {
    padding-right: 4rem
  }

  .xl\:pb-8 {
    padding-bottom: 4rem
  }

  .xl\:pl-8 {
    padding-left: 4rem
  }

  .xl\:pt-9 {
    padding-top: 4.5rem
  }

  .xl\:pr-9 {
    padding-right: 4.5rem
  }

  .xl\:pb-9 {
    padding-bottom: 4.5rem
  }

  .xl\:pl-9 {
    padding-left: 4.5rem
  }

  .xl\:pt-10 {
    padding-top: 5rem
  }

  .xl\:pr-10 {
    padding-right: 5rem
  }

  .xl\:pb-10 {
    padding-bottom: 5rem
  }

  .xl\:pl-10 {
    padding-left: 5rem
  }

  .xl\:pt-11 {
    padding-top: 5.5rem
  }

  .xl\:pr-11 {
    padding-right: 5.5rem
  }

  .xl\:pb-11 {
    padding-bottom: 5.5rem
  }

  .xl\:pl-11 {
    padding-left: 5.5rem
  }

  .xl\:pt-12 {
    padding-top: 6rem
  }

  .xl\:pr-12 {
    padding-right: 6rem
  }

  .xl\:pb-12 {
    padding-bottom: 6rem
  }

  .xl\:pl-12 {
    padding-left: 6rem
  }

  .xl\:pt-13 {
    padding-top: 6.5rem
  }

  .xl\:pr-13 {
    padding-right: 6.5rem
  }

  .xl\:pb-13 {
    padding-bottom: 6.5rem
  }

  .xl\:pl-13 {
    padding-left: 6.5rem
  }

  .xl\:pt-14 {
    padding-top: 7rem
  }

  .xl\:pr-14 {
    padding-right: 7rem
  }

  .xl\:pb-14 {
    padding-bottom: 7rem
  }

  .xl\:pl-14 {
    padding-left: 7rem
  }

  .xl\:pt-15 {
    padding-top: 7.5rem
  }

  .xl\:pr-15 {
    padding-right: 7.5rem
  }

  .xl\:pb-15 {
    padding-bottom: 7.5rem
  }

  .xl\:pl-15 {
    padding-left: 7.5rem
  }

  .xl\:pt-16 {
    padding-top: 8rem
  }

  .xl\:pr-16 {
    padding-right: 8rem
  }

  .xl\:pb-16 {
    padding-bottom: 8rem
  }

  .xl\:pl-16 {
    padding-left: 8rem
  }

  .xl\:pt-17 {
    padding-top: 8.5rem
  }

  .xl\:pr-17 {
    padding-right: 8.5rem
  }

  .xl\:pb-17 {
    padding-bottom: 8.5rem
  }

  .xl\:pl-17 {
    padding-left: 8.5rem
  }

  .xl\:pt-19 {
    padding-top: 9rem
  }

  .xl\:pr-19 {
    padding-right: 9rem
  }

  .xl\:pb-19 {
    padding-bottom: 9rem
  }

  .xl\:pl-19 {
    padding-left: 9rem
  }

  .xl\:pt-20 {
    padding-top: 9.5rem
  }

  .xl\:pr-20 {
    padding-right: 9.5rem
  }

  .xl\:pb-20 {
    padding-bottom: 9.5rem
  }

  .xl\:pl-20 {
    padding-left: 9.5rem
  }

  .xl\:pt-22 {
    padding-top: 11rem
  }

  .xl\:pr-22 {
    padding-right: 11rem
  }

  .xl\:pb-22 {
    padding-bottom: 11rem
  }

  .xl\:pl-22 {
    padding-left: 11rem
  }

  .xl\:pt-24 {
    padding-top: 12rem
  }

  .xl\:pr-24 {
    padding-right: 12rem
  }

  .xl\:pb-24 {
    padding-bottom: 12rem
  }

  .xl\:pl-24 {
    padding-left: 12rem
  }

  .xl\:pt-32 {
    padding-top: 16rem
  }

  .xl\:pr-32 {
    padding-right: 16rem
  }

  .xl\:pb-32 {
    padding-bottom: 16rem
  }

  .xl\:pl-32 {
    padding-left: 16rem
  }

  .xl\:pt-40 {
    padding-top: 20rem
  }

  .xl\:pr-40 {
    padding-right: 20rem
  }

  .xl\:pb-40 {
    padding-bottom: 20rem
  }

  .xl\:pl-40 {
    padding-left: 20rem
  }

  .xl\:pt-px {
    padding-top: 1px
  }

  .xl\:pr-px {
    padding-right: 1px
  }

  .xl\:pb-px {
    padding-bottom: 1px
  }

  .xl\:pl-px {
    padding-left: 1px
  }

  .xl\:pt-half {
    padding-top: 0.25rem
  }

  .xl\:pr-half {
    padding-right: 0.25rem
  }

  .xl\:pb-half {
    padding-bottom: 0.25rem
  }

  .xl\:pl-half {
    padding-left: 0.25rem
  }

  .xl\:placeholder-transparent::placeholder {
    color: transparent
  }

  .xl\:placeholder-black::placeholder {
    color: @color-black
  }

  .xl\:placeholder-white::placeholder {
    color: @color-white
  }

  .xl\:placeholder-grey-10::placeholder {
    color: fade(@color-grey, 10%)
  }

  .xl\:placeholder-grey-30::placeholder {
    color: fade(@color-grey, 30%)
  }

  .xl\:placeholder-grey-40::placeholder {
    color: fade(@color-grey, 40%)
  }

  .xl\:placeholder-grey-60::placeholder {
    color: fade(@color-grey, 60%)
  }

  .xl\:placeholder-grey-90::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xl\:placeholder-grey::placeholder {
    color: @color-grey
  }

  .xl\:placeholder-grey-metal::placeholder {
    color: @color-grey
  }

  .xl\:placeholder-grey-divider::placeholder {
    color: fade(@color-grey, 20%)
  }

  .xl\:placeholder-grey-light::placeholder {
    color: @color-grey-light
  }

  .xl\:placeholder-grey-header::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xl\:placeholder-body::placeholder {
    color: @color-body
  }

  .xl\:placeholder-yellow::placeholder {
    color: @color-yellow
  }

  .xl\:placeholder-orange::placeholder {
    color: @color-orange
  }

  .xl\:placeholder-stone::placeholder {
    color: @color-stone
  }

  .xl\:placeholder-khaki::placeholder {
    color: @color-khaki
  }

  .xl\:placeholder-green::placeholder {
    color: @color-green
  }

  .xl\:placeholder-blue::placeholder {
    color: @color-blue
  }

  .xl\:placeholder-red::placeholder {
    color: @color-red
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    color: @color-black
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    color: @color-white
  }

  .xl\:focus\:placeholder-grey-10:focus::placeholder {
    color: fade(@color-grey, 10%)
  }

  .xl\:focus\:placeholder-grey-30:focus::placeholder {
    color: fade(@color-grey, 30%)
  }

  .xl\:focus\:placeholder-grey-40:focus::placeholder {
    color: fade(@color-grey, 40%)
  }

  .xl\:focus\:placeholder-grey-60:focus::placeholder {
    color: fade(@color-grey, 60%)
  }

  .xl\:focus\:placeholder-grey-90:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xl\:focus\:placeholder-grey:focus::placeholder {
    color: @color-grey
  }

  .xl\:focus\:placeholder-grey-metal:focus::placeholder {
    color: @color-grey
  }

  .xl\:focus\:placeholder-grey-divider:focus::placeholder {
    color: fade(@color-grey, 20%)
  }

  .xl\:focus\:placeholder-grey-light:focus::placeholder {
    color: @color-grey-light
  }

  .xl\:focus\:placeholder-grey-header:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xl\:focus\:placeholder-body:focus::placeholder {
    color: @color-body
  }

  .xl\:focus\:placeholder-yellow:focus::placeholder {
    color: @color-yellow
  }

  .xl\:focus\:placeholder-orange:focus::placeholder {
    color: @color-orange
  }

  .xl\:focus\:placeholder-stone:focus::placeholder {
    color: @color-stone
  }

  .xl\:focus\:placeholder-khaki:focus::placeholder {
    color: @color-khaki
  }

  .xl\:focus\:placeholder-green:focus::placeholder {
    color: @color-green
  }

  .xl\:focus\:placeholder-blue:focus::placeholder {
    color: @color-blue
  }

  .xl\:focus\:placeholder-red:focus::placeholder {
    color: @color-red
  }

  .xl\:pointer-events-none {
    pointer-events: none
  }

  .xl\:pointer-events-auto {
    pointer-events: auto
  }

  .xl\:static {
    position: static
  }

  .xl\:fixed {
    position: fixed
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:relative {
    position: relative
  }

  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .xl\:inset-x-0 {
    right: 0;
    left: 0
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .xl\:inset-x-auto {
    right: auto;
    left: auto
  }

  .xl\:top-0 {
    top: 0
  }

  .xl\:right-0 {
    right: 0
  }

  .xl\:bottom-0 {
    bottom: 0
  }

  .xl\:left-0 {
    left: 0
  }

  .xl\:top-auto {
    top: auto
  }

  .xl\:right-auto {
    right: auto
  }

  .xl\:bottom-auto {
    bottom: auto
  }

  .xl\:left-auto {
    left: auto
  }

  .xl\:resize-none {
    resize: none
  }

  .xl\:resize-y {
    resize: vertical
  }

  .xl\:resize-x {
    resize: horizontal
  }

  .xl\:resize {
    resize: both
  }

  .xl\:shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow-none {
    box-shadow: none
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .xl\:fill-current {
    fill: currentColor
  }

  .xl\:stroke-current {
    stroke: currentColor
  }

  .xl\:table-auto {
    table-layout: auto
  }

  .xl\:table-fixed {
    table-layout: fixed
  }

  .xl\:text-left {
    text-align: left
  }

  .xl\:text-center {
    text-align: center
  }

  .xl\:text-right {
    text-align: right
  }

  .xl\:text-justify {
    text-align: justify
  }

  .xl\:text-transparent {
    color: transparent
  }

  .xl\:text-black {
    color: @color-black
  }

  .xl\:text-white {
    color: @color-white
  }

  .xl\:text-grey-10 {
    color: fade(@color-grey, 10%)
  }

  .xl\:text-grey-30 {
    color: fade(@color-grey, 30%)
  }

  .xl\:text-grey-40 {
    color: fade(@color-grey, 40%)
  }

  .xl\:text-grey-60 {
    color: fade(@color-grey, 60%)
  }

  .xl\:text-grey-90 {
    color: fade(@color-grey, 90%)
  }

  .xl\:text-grey {
    color: @color-grey
  }

  .xl\:text-grey-metal {
    color: @color-grey
  }

  .xl\:text-grey-divider {
    color: fade(@color-grey, 20%)
  }

  .xl\:text-grey-light {
    color: @color-grey-light
  }

  .xl\:text-grey-header {
    color: fade(@color-grey, 90%)
  }

  .xl\:text-body {
    color: @color-body
  }

  .xl\:text-yellow {
    color: @color-yellow
  }

  .xl\:text-orange {
    color: @color-orange
  }

  .xl\:text-stone {
    color: @color-stone
  }

  .xl\:text-khaki {
    color: @color-khaki
  }

  .xl\:text-green {
    color: @color-green
  }

  .xl\:text-blue {
    color: @color-blue
  }

  .xl\:text-red {
    color: @color-red
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent
  }

  .xl\:hover\:text-black:hover {
    color: @color-black
  }

  .xl\:hover\:text-white:hover {
    color: @color-white
  }

  .xl\:hover\:text-grey-10:hover {
    color: fade(@color-grey, 10%)
  }

  .xl\:hover\:text-grey-30:hover {
    color: fade(@color-grey, 30%)
  }

  .xl\:hover\:text-grey-40:hover {
    color: fade(@color-grey, 40%)
  }

  .xl\:hover\:text-grey-60:hover {
    color: fade(@color-grey, 60%)
  }

  .xl\:hover\:text-grey-90:hover {
    color: fade(@color-grey, 90%)
  }

  .xl\:hover\:text-grey:hover {
    color: @color-grey
  }

  .xl\:hover\:text-grey-metal:hover {
    color: @color-grey
  }

  .xl\:hover\:text-grey-divider:hover {
    color: fade(@color-grey, 20%)
  }

  .xl\:hover\:text-grey-light:hover {
    color: @color-grey-light
  }

  .xl\:hover\:text-grey-header:hover {
    color: fade(@color-grey, 90%)
  }

  .xl\:hover\:text-body:hover {
    color: @color-body
  }

  .xl\:hover\:text-yellow:hover {
    color: @color-yellow
  }

  .xl\:hover\:text-orange:hover {
    color: @color-orange
  }

  .xl\:hover\:text-stone:hover {
    color: @color-stone
  }

  .xl\:hover\:text-khaki:hover {
    color: @color-khaki
  }

  .xl\:hover\:text-green:hover {
    color: @color-green
  }

  .xl\:hover\:text-blue:hover {
    color: @color-blue
  }

  .xl\:hover\:text-red:hover {
    color: @color-red
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent
  }

  .xl\:focus\:text-black:focus {
    color: @color-black
  }

  .xl\:focus\:text-white:focus {
    color: @color-white
  }

  .xl\:focus\:text-grey-10:focus {
    color: fade(@color-grey, 10%)
  }

  .xl\:focus\:text-grey-30:focus {
    color: fade(@color-grey, 30%)
  }

  .xl\:focus\:text-grey-40:focus {
    color: fade(@color-grey, 40%)
  }

  .xl\:focus\:text-grey-60:focus {
    color: fade(@color-grey, 60%)
  }

  .xl\:focus\:text-grey-90:focus {
    color: fade(@color-grey, 90%)
  }

  .xl\:focus\:text-grey:focus {
    color: @color-grey
  }

  .xl\:focus\:text-grey-metal:focus {
    color: @color-grey
  }

  .xl\:focus\:text-grey-divider:focus {
    color: fade(@color-grey, 20%)
  }

  .xl\:focus\:text-grey-light:focus {
    color: @color-grey-light
  }

  .xl\:focus\:text-grey-header:focus {
    color: fade(@color-grey, 90%)
  }

  .xl\:focus\:text-body:focus {
    color: @color-body
  }

  .xl\:focus\:text-yellow:focus {
    color: @color-yellow
  }

  .xl\:focus\:text-orange:focus {
    color: @color-orange
  }

  .xl\:focus\:text-stone:focus {
    color: @color-stone
  }

  .xl\:focus\:text-khaki:focus {
    color: @color-khaki
  }

  .xl\:focus\:text-green:focus {
    color: @color-green
  }

  .xl\:focus\:text-blue:focus {
    color: @color-blue
  }

  .xl\:focus\:text-red:focus {
    color: @color-red
  }

  .xl\:text-base {
    font-size: .6875rem
  }

  .xl\:text-md {
    font-size: 0.9375rem
  }

  .xl\:text-lg {
    font-size: 1rem
  }

  .xl\:text-xl {
    font-size: 1.375rem
  }

  .xl\:text-2xl {
    font-size: 2.0625rem
  }

  .xl\:italic {
    font-style: italic
  }

  .xl\:not-italic {
    font-style: normal
  }

  .xl\:uppercase {
    text-transform: uppercase
  }

  .xl\:lowercase {
    text-transform: lowercase
  }

  .xl\:capitalize {
    text-transform: capitalize
  }

  .xl\:normal-case {
    text-transform: none
  }

  .xl\:underline {
    text-decoration: underline
  }

  .xl\:line-through {
    text-decoration: line-through
  }

  .xl\:no-underline {
    text-decoration: none
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .xl\:tracking-normal {
    letter-spacing: 0
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .xl\:align-baseline {
    vertical-align: baseline
  }

  .xl\:align-top {
    vertical-align: top
  }

  .xl\:align-middle {
    vertical-align: middle
  }

  .xl\:align-bottom {
    vertical-align: bottom
  }

  .xl\:align-text-top {
    vertical-align: text-top
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .xl\:visible {
    visibility: visible
  }

  .xl\:invisible {
    visibility: hidden
  }

  .xl\:whitespace-normal {
    white-space: normal
  }

  .xl\:whitespace-no-wrap {
    white-space: nowrap
  }

  .xl\:whitespace-pre {
    white-space: pre
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .xl\:w-0 {
    width: 0
  }

  .xl\:w-1 {
    width: 0.5rem
  }

  .xl\:w-2 {
    width: 1rem
  }

  .xl\:w-3 {
    width: 1.5rem
  }

  .xl\:w-4 {
    width: 2rem
  }

  .xl\:w-5 {
    width: 2.5rem
  }

  .xl\:w-6 {
    width: 3rem
  }

  .xl\:w-7 {
    width: 3.5rem
  }

  .xl\:w-8 {
    width: 4rem
  }

  .xl\:w-9 {
    width: 4.5rem
  }

  .xl\:w-10 {
    width: 5rem
  }

  .xl\:w-11 {
    width: 5.5rem
  }

  .xl\:w-12 {
    width: 6rem
  }

  .xl\:w-13 {
    width: 6.5rem
  }

  .xl\:w-14 {
    width: 7rem
  }

  .xl\:w-15 {
    width: 7.5rem
  }

  .xl\:w-16 {
    width: 8rem
  }

  .xl\:w-17 {
    width: 8.5rem
  }

  .xl\:w-19 {
    width: 9rem
  }

  .xl\:w-20 {
    width: 9.5rem
  }

  .xl\:w-22 {
    width: 11rem
  }

  .xl\:w-24 {
    width: 12rem
  }

  .xl\:w-32 {
    width: 16rem
  }

  .xl\:w-40 {
    width: 20rem
  }

  .xl\:w-auto {
    width: auto
  }

  .xl\:w-px {
    width: 1px
  }

  .xl\:w-half {
    width: 0.25rem
  }

  .xl\:w-1\/2 {
    width: 50%
  }

  .xl\:w-1\/3 {
    width: 33.333333%
  }

  .xl\:w-2\/3 {
    width: 66.666667%
  }

  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-2\/4 {
    width: 50%
  }

  .xl\:w-3\/4 {
    width: 75%
  }

  .xl\:w-1\/5 {
    width: 20%
  }

  .xl\:w-2\/5 {
    width: 40%
  }

  .xl\:w-3\/5 {
    width: 60%
  }

  .xl\:w-4\/5 {
    width: 80%
  }

  .xl\:w-1\/6 {
    width: 16.666667%
  }

  .xl\:w-2\/6 {
    width: 33.333333%
  }

  .xl\:w-3\/6 {
    width: 50%
  }

  .xl\:w-4\/6 {
    width: 66.666667%
  }

  .xl\:w-5\/6 {
    width: 83.333333%
  }

  .xl\:w-1\/12 {
    width: 8.333333%
  }

  .xl\:w-2\/12 {
    width: 16.666667%
  }

  .xl\:w-3\/12 {
    width: 25%
  }

  .xl\:w-4\/12 {
    width: 33.333333%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-6\/12 {
    width: 50%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-9\/12 {
    width: 75%
  }

  .xl\:w-10\/12 {
    width: 83.333333%
  }

  .xl\:w-11\/12 {
    width: 91.666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:w-screen {
    width: 100vw
  }

  .xl\:z-0 {
    z-index: 0
  }

  .xl\:z-10 {
    z-index: 10
  }

  .xl\:z-20 {
    z-index: 20
  }

  .xl\:z-30 {
    z-index: 30
  }

  .xl\:z-40 {
    z-index: 40
  }

  .xl\:z-50 {
    z-index: 50
  }

  .xl\:z-auto {
    z-index: auto
  }
}

@media (min-width: @xxl-break) {
  .xxl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xxl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xxl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xxl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xxl\:bg-transparent {
    background-color: transparent
  }

  .xxl\:bg-black {
    background-color: @color-black
  }

  .xxl\:bg-white {
    background-color: @color-white
  }

  .xxl\:bg-grey-10 {
    background-color: fade(@color-grey, 10%)
  }

  .xxl\:bg-grey-30 {
    background-color: fade(@color-grey, 30%)
  }

  .xxl\:bg-grey-40 {
    background-color: fade(@color-grey, 40%)
  }

  .xxl\:bg-grey-60 {
    background-color: fade(@color-grey, 60%)
  }

  .xxl\:bg-grey-90 {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:bg-grey {
    background-color: @color-grey
  }

  .xxl\:bg-grey-metal {
    background-color: @color-grey
  }

  .xxl\:bg-grey-divider {
    background-color: fade(@color-grey, 20%)
  }

  .xxl\:bg-grey-light {
    background-color: @color-grey-light
  }

  .xxl\:bg-grey-header {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:bg-body {
    background-color: @color-body
  }

  .xxl\:bg-yellow {
    background-color: @color-yellow
  }

  .xxl\:bg-orange {
    background-color: @color-orange
  }

  .xxl\:bg-stone {
    background-color: @color-stone
  }

  .xxl\:bg-khaki {
    background-color: @color-khaki
  }

  .xxl\:bg-green {
    background-color: @color-green
  }

  .xxl\:bg-blue {
    background-color: @color-blue
  }

  .xxl\:bg-red {
    background-color: @color-red
  }

  .xxl\:hover\:bg-transparent:hover {
    background-color: transparent
  }

  .xxl\:hover\:bg-black:hover {
    background-color: @color-black
  }

  .xxl\:hover\:bg-white:hover {
    background-color: @color-white
  }

  .xxl\:hover\:bg-grey-10:hover {
    background-color: fade(@color-grey, 10%)
  }

  .xxl\:hover\:bg-grey-30:hover {
    background-color: fade(@color-grey, 30%)
  }

  .xxl\:hover\:bg-grey-40:hover {
    background-color: fade(@color-grey, 40%)
  }

  .xxl\:hover\:bg-grey-60:hover {
    background-color: fade(@color-grey, 60%)
  }

  .xxl\:hover\:bg-grey-90:hover {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:bg-grey:hover {
    background-color: @color-grey
  }

  .xxl\:hover\:bg-grey-metal:hover {
    background-color: @color-grey
  }

  .xxl\:hover\:bg-grey-divider:hover {
    background-color: fade(@color-grey, 20%)
  }

  .xxl\:hover\:bg-grey-light:hover {
    background-color: @color-grey-light
  }

  .xxl\:hover\:bg-grey-header:hover {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:bg-body:hover {
    background-color: @color-body
  }

  .xxl\:hover\:bg-yellow:hover {
    background-color: @color-yellow
  }

  .xxl\:hover\:bg-orange:hover {
    background-color: @color-orange
  }

  .xxl\:hover\:bg-stone:hover {
    background-color: @color-stone
  }

  .xxl\:hover\:bg-khaki:hover {
    background-color: @color-khaki
  }

  .xxl\:hover\:bg-green:hover {
    background-color: @color-green
  }

  .xxl\:hover\:bg-blue:hover {
    background-color: @color-blue
  }

  .xxl\:hover\:bg-red:hover {
    background-color: @color-red
  }

  .xxl\:focus\:bg-transparent:focus {
    background-color: transparent
  }

  .xxl\:focus\:bg-black:focus {
    background-color: @color-black
  }

  .xxl\:focus\:bg-white:focus {
    background-color: @color-white
  }

  .xxl\:focus\:bg-grey-10:focus {
    background-color: fade(@color-grey, 10%)
  }

  .xxl\:focus\:bg-grey-30:focus {
    background-color: fade(@color-grey, 30%)
  }

  .xxl\:focus\:bg-grey-40:focus {
    background-color: fade(@color-grey, 40%)
  }

  .xxl\:focus\:bg-grey-60:focus {
    background-color: fade(@color-grey, 60%)
  }

  .xxl\:focus\:bg-grey-90:focus {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:bg-grey:focus {
    background-color: @color-grey
  }

  .xxl\:focus\:bg-grey-metal:focus {
    background-color: @color-grey
  }

  .xxl\:focus\:bg-grey-divider:focus {
    background-color: fade(@color-grey, 20%)
  }

  .xxl\:focus\:bg-grey-light:focus {
    background-color: @color-grey-light
  }

  .xxl\:focus\:bg-grey-header:focus {
    background-color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:bg-body:focus {
    background-color: @color-body
  }

  .xxl\:focus\:bg-yellow:focus {
    background-color: @color-yellow
  }

  .xxl\:focus\:bg-orange:focus {
    background-color: @color-orange
  }

  .xxl\:focus\:bg-stone:focus {
    background-color: @color-stone
  }

  .xxl\:focus\:bg-khaki:focus {
    background-color: @color-khaki
  }

  .xxl\:focus\:bg-green:focus {
    background-color: @color-green
  }

  .xxl\:focus\:bg-blue:focus {
    background-color: @color-blue
  }

  .xxl\:focus\:bg-red:focus {
    background-color: @color-red
  }

  .xxl\:bg-bottom {
    background-position: bottom
  }

  .xxl\:bg-center {
    background-position: center
  }

  .xxl\:bg-left {
    background-position: left
  }

  .xxl\:bg-left-bottom {
    background-position: left bottom
  }

  .xxl\:bg-left-top {
    background-position: left top
  }

  .xxl\:bg-right {
    background-position: right
  }

  .xxl\:bg-right-bottom {
    background-position: right bottom
  }

  .xxl\:bg-right-top {
    background-position: right top
  }

  .xxl\:bg-top {
    background-position: top
  }

  .xxl\:bg-repeat {
    background-repeat: repeat
  }

  .xxl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .xxl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .xxl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .xxl\:bg-repeat-round {
    background-repeat: round
  }

  .xxl\:bg-repeat-space {
    background-repeat: space
  }

  .xxl\:bg-auto {
    background-size: auto
  }

  .xxl\:bg-cover {
    background-size: cover
  }

  .xxl\:bg-contain {
    background-size: contain
  }

  .xxl\:border-collapse {
    border-collapse: collapse
  }

  .xxl\:border-separate {
    border-collapse: separate
  }

  .xxl\:border-transparent {
    border-color: transparent
  }

  .xxl\:border-black {
    border-color: @color-black
  }

  .xxl\:border-white {
    border-color: @color-white
  }

  .xxl\:border-grey-10 {
    border-color: fade(@color-grey, 10%)
  }

  .xxl\:border-grey-30 {
    border-color: fade(@color-grey, 30%)
  }

  .xxl\:border-grey-40 {
    border-color: fade(@color-grey, 40%)
  }

  .xxl\:border-grey-60 {
    border-color: fade(@color-grey, 60%)
  }

  .xxl\:border-grey-90 {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:border-grey {
    border-color: @color-grey
  }

  .xxl\:border-grey-metal {
    border-color: @color-grey
  }

  .xxl\:border-grey-divider {
    border-color: fade(@color-grey, 20%)
  }

  .xxl\:border-grey-light {
    border-color: @color-grey-light
  }

  .xxl\:border-grey-header {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:border-body {
    border-color: @color-body
  }

  .xxl\:border-yellow {
    border-color: @color-yellow
  }

  .xxl\:border-orange {
    border-color: @color-orange
  }

  .xxl\:border-stone {
    border-color: @color-stone
  }

  .xxl\:border-khaki {
    border-color: @color-khaki
  }

  .xxl\:border-green {
    border-color: @color-green
  }

  .xxl\:border-blue {
    border-color: @color-blue
  }

  .xxl\:border-red {
    border-color: @color-red
  }

  .xxl\:hover\:border-transparent:hover {
    border-color: transparent
  }

  .xxl\:hover\:border-black:hover {
    border-color: @color-black
  }

  .xxl\:hover\:border-white:hover {
    border-color: @color-white
  }

  .xxl\:hover\:border-grey-10:hover {
    border-color: fade(@color-grey, 10%)
  }

  .xxl\:hover\:border-grey-30:hover {
    border-color: fade(@color-grey, 30%)
  }

  .xxl\:hover\:border-grey-40:hover {
    border-color: fade(@color-grey, 40%)
  }

  .xxl\:hover\:border-grey-60:hover {
    border-color: fade(@color-grey, 60%)
  }

  .xxl\:hover\:border-grey-90:hover {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:border-grey:hover {
    border-color: @color-grey
  }

  .xxl\:hover\:border-grey-metal:hover {
    border-color: @color-grey
  }

  .xxl\:hover\:border-grey-divider:hover {
    border-color: fade(@color-grey, 20%)
  }

  .xxl\:hover\:border-grey-light:hover {
    border-color: @color-grey-light
  }

  .xxl\:hover\:border-grey-header:hover {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:border-body:hover {
    border-color: @color-body
  }

  .xxl\:hover\:border-yellow:hover {
    border-color: @color-yellow
  }

  .xxl\:hover\:border-orange:hover {
    border-color: @color-orange
  }

  .xxl\:hover\:border-stone:hover {
    border-color: @color-stone
  }

  .xxl\:hover\:border-khaki:hover {
    border-color: @color-khaki
  }

  .xxl\:hover\:border-green:hover {
    border-color: @color-green
  }

  .xxl\:hover\:border-blue:hover {
    border-color: @color-blue
  }

  .xxl\:hover\:border-red:hover {
    border-color: @color-red
  }

  .xxl\:focus\:border-transparent:focus {
    border-color: transparent
  }

  .xxl\:focus\:border-black:focus {
    border-color: @color-black
  }

  .xxl\:focus\:border-white:focus {
    border-color: @color-white
  }

  .xxl\:focus\:border-grey-10:focus {
    border-color: fade(@color-grey, 10%)
  }

  .xxl\:focus\:border-grey-30:focus {
    border-color: fade(@color-grey, 30%)
  }

  .xxl\:focus\:border-grey-40:focus {
    border-color: fade(@color-grey, 40%)
  }

  .xxl\:focus\:border-grey-60:focus {
    border-color: fade(@color-grey, 60%)
  }

  .xxl\:focus\:border-grey-90:focus {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:border-grey:focus {
    border-color: @color-grey
  }

  .xxl\:focus\:border-grey-metal:focus {
    border-color: @color-grey
  }

  .xxl\:focus\:border-grey-divider:focus {
    border-color: fade(@color-grey, 20%)
  }

  .xxl\:focus\:border-grey-light:focus {
    border-color: @color-grey-light
  }

  .xxl\:focus\:border-grey-header:focus {
    border-color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:border-body:focus {
    border-color: @color-body
  }

  .xxl\:focus\:border-yellow:focus {
    border-color: @color-yellow
  }

  .xxl\:focus\:border-orange:focus {
    border-color: @color-orange
  }

  .xxl\:focus\:border-stone:focus {
    border-color: @color-stone
  }

  .xxl\:focus\:border-khaki:focus {
    border-color: @color-khaki
  }

  .xxl\:focus\:border-green:focus {
    border-color: @color-green
  }

  .xxl\:focus\:border-blue:focus {
    border-color: @color-blue
  }

  .xxl\:focus\:border-red:focus {
    border-color: @color-red
  }

  .xxl\:rounded-none {
    border-radius: 0
  }

  .xxl\:rounded {
    border-radius: .125rem
  }

  .xxl\:rounded-lg {
    border-radius: .375rem
  }

  .xxl\:rounded-full {
    border-radius: 20em
  }

  .xxl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xxl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xxl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xxl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xxl\:rounded-t {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem
  }

  .xxl\:rounded-r {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
  }

  .xxl\:rounded-b {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .xxl\:rounded-l {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
  }

  .xxl\:rounded-t-lg {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
  }

  .xxl\:rounded-r-lg {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
  }

  .xxl\:rounded-b-lg {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .xxl\:rounded-l-lg {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
  }

  .xxl\:rounded-t-full {
    border-top-left-radius: 20em;
    border-top-right-radius: 20em
  }

  .xxl\:rounded-r-full {
    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em
  }

  .xxl\:rounded-b-full {
    border-bottom-right-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .xxl\:rounded-l-full {
    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em
  }

  .xxl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .xxl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .xxl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .xxl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .xxl\:rounded-tl {
    border-top-left-radius: .125rem
  }

  .xxl\:rounded-tr {
    border-top-right-radius: .125rem
  }

  .xxl\:rounded-br {
    border-bottom-right-radius: .125rem
  }

  .xxl\:rounded-bl {
    border-bottom-left-radius: .125rem
  }

  .xxl\:rounded-tl-lg {
    border-top-left-radius: .375rem
  }

  .xxl\:rounded-tr-lg {
    border-top-right-radius: .375rem
  }

  .xxl\:rounded-br-lg {
    border-bottom-right-radius: .375rem
  }

  .xxl\:rounded-bl-lg {
    border-bottom-left-radius: .375rem
  }

  .xxl\:rounded-tl-full {
    border-top-left-radius: 20em
  }

  .xxl\:rounded-tr-full {
    border-top-right-radius: 20em
  }

  .xxl\:rounded-br-full {
    border-bottom-right-radius: 20em
  }

  .xxl\:rounded-bl-full {
    border-bottom-left-radius: 20em
  }

  .xxl\:border-solid {
    border-style: solid
  }

  .xxl\:border-dashed {
    border-style: dashed
  }

  .xxl\:border-dotted {
    border-style: dotted
  }

  .xxl\:border-double {
    border-style: double
  }

  .xxl\:border-none {
    border-style: none
  }

  .xxl\:border-0 {
    border-width: 0
  }

  .xxl\:border-3 {
    border-width: .1875rem
  }

  .xxl\:border-6 {
    border-width: .375rem
  }

  .xxl\:border {
    border-width: 1px
  }

  .xxl\:border-t-0 {
    border-top-width: 0
  }

  .xxl\:border-r-0 {
    border-right-width: 0
  }

  .xxl\:border-b-0 {
    border-bottom-width: 0
  }

  .xxl\:border-l-0 {
    border-left-width: 0
  }

  .xxl\:border-t-3 {
    border-top-width: .1875rem
  }

  .xxl\:border-r-3 {
    border-right-width: .1875rem
  }

  .xxl\:border-b-3 {
    border-bottom-width: .1875rem
  }

  .xxl\:border-l-3 {
    border-left-width: .1875rem
  }

  .xxl\:border-t-6 {
    border-top-width: .375rem
  }

  .xxl\:border-r-6 {
    border-right-width: .375rem
  }

  .xxl\:border-b-6 {
    border-bottom-width: .375rem
  }

  .xxl\:border-l-6 {
    border-left-width: .375rem
  }

  .xxl\:border-t {
    border-top-width: 1px
  }

  .xxl\:border-r {
    border-right-width: 1px
  }

  .xxl\:border-b {
    border-bottom-width: 1px
  }

  .xxl\:border-l {
    border-left-width: 1px
  }

  .xxl\:cursor-auto {
    cursor: auto
  }

  .xxl\:cursor-default {
    cursor: default
  }

  .xxl\:cursor-pointer {
    cursor: pointer
  }

  .xxl\:cursor-wait {
    cursor: wait
  }

  .xxl\:cursor-text {
    cursor: text
  }

  .xxl\:cursor-move {
    cursor: move
  }

  .xxl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .xxl\:block {
    display: block
  }

  .xxl\:inline-block {
    display: inline-block
  }

  .xxl\:inline {
    display: inline
  }

  .xxl\:flex {
    display: flex
  }

  .xxl\:inline-flex {
    display: inline-flex
  }

  .xxl\:table {
    display: table
  }

  .xxl\:table-row {
    display: table-row
  }

  .xxl\:table-cell {
    display: table-cell
  }

  .xxl\:hidden {
    display: none
  }

  .xxl\:flex-row {
    flex-direction: row
  }

  .xxl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .xxl\:flex-col {
    flex-direction: column
  }

  .xxl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .xxl\:flex-wrap {
    flex-wrap: wrap
  }

  .xxl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .xxl\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .xxl\:items-start {
    align-items: flex-start
  }

  .xxl\:items-end {
    align-items: flex-end
  }

  .xxl\:items-center {
    align-items: center
  }

  .xxl\:items-baseline {
    align-items: baseline
  }

  .xxl\:items-stretch {
    align-items: stretch
  }

  .xxl\:self-auto {
    align-self: auto
  }

  .xxl\:self-start {
    align-self: flex-start
  }

  .xxl\:self-end {
    align-self: flex-end
  }

  .xxl\:self-center {
    align-self: center
  }

  .xxl\:self-stretch {
    align-self: stretch
  }

  .xxl\:justify-start {
    justify-content: flex-start
  }

  .xxl\:justify-end {
    justify-content: flex-end
  }

  .xxl\:justify-center {
    justify-content: center
  }

  .xxl\:justify-between {
    justify-content: space-between
  }

  .xxl\:justify-around {
    justify-content: space-around
  }

  .xxl\:content-center {
    align-content: center
  }

  .xxl\:content-start {
    align-content: flex-start
  }

  .xxl\:content-end {
    align-content: flex-end
  }

  .xxl\:content-between {
    align-content: space-between
  }

  .xxl\:content-around {
    align-content: space-around
  }

  .xxl\:flex-1 {
    flex: 1 1 0%
  }

  .xxl\:flex-auto {
    flex: 1 1 auto
  }

  .xxl\:flex-initial {
    flex: 0 1 auto
  }

  .xxl\:flex-none {
    flex: none
  }

  .xxl\:flex-grow-0 {
    flex-grow: 0
  }

  .xxl\:flex-grow {
    flex-grow: 1
  }

  .xxl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .xxl\:flex-shrink {
    flex-shrink: 1
  }

  .xxl\:order-1 {
    order: 1
  }

  .xxl\:order-2 {
    order: 2
  }

  .xxl\:order-3 {
    order: 3
  }

  .xxl\:order-4 {
    order: 4
  }

  .xxl\:order-5 {
    order: 5
  }

  .xxl\:order-6 {
    order: 6
  }

  .xxl\:order-7 {
    order: 7
  }

  .xxl\:order-8 {
    order: 8
  }

  .xxl\:order-9 {
    order: 9
  }

  .xxl\:order-10 {
    order: 10
  }

  .xxl\:order-11 {
    order: 11
  }

  .xxl\:order-12 {
    order: 12
  }

  .xxl\:order-first {
    order: -9999
  }

  .xxl\:order-last {
    order: 9999
  }

  .xxl\:order-none {
    order: 0
  }

  .xxl\:float-right {
    float: right
  }

  .xxl\:float-left {
    float: left
  }

  .xxl\:float-none {
    float: none
  }

  .xxl\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .xxl\:font-body {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif
  }

  .xxl\:font-normal {
    font-weight: 400
  }

  .xxl\:font-semibold {
    font-weight: 600
  }

  .xxl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .xxl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .xxl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .xxl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .xxl\:h-0 {
    height: 0
  }

  .xxl\:h-1 {
    height: 0.5rem
  }

  .xxl\:h-2 {
    height: 1rem
  }

  .xxl\:h-3 {
    height: 1.5rem
  }

  .xxl\:h-4 {
    height: 2rem
  }

  .xxl\:h-5 {
    height: 2.5rem
  }

  .xxl\:h-6 {
    height: 3rem
  }

  .xxl\:h-7 {
    height: 3.5rem
  }

  .xxl\:h-8 {
    height: 4rem
  }

  .xxl\:h-9 {
    height: 4.5rem
  }

  .xxl\:h-10 {
    height: 5rem
  }

  .xxl\:h-11 {
    height: 5.5rem
  }

  .xxl\:h-12 {
    height: 6rem
  }

  .xxl\:h-13 {
    height: 6.5rem
  }

  .xxl\:h-14 {
    height: 7rem
  }

  .xxl\:h-15 {
    height: 7.5rem
  }

  .xxl\:h-16 {
    height: 8rem
  }

  .xxl\:h-17 {
    height: 8.5rem
  }

  .xxl\:h-19 {
    height: 9rem
  }

  .xxl\:h-20 {
    height: 9.5rem
  }

  .xxl\:h-22 {
    height: 11rem
  }

  .xxl\:h-24 {
    height: 12rem
  }

  .xxl\:h-32 {
    height: 16rem
  }

  .xxl\:h-40 {
    height: 20rem
  }

  .xxl\:h-auto {
    height: auto
  }

  .xxl\:h-px {
    height: 1px
  }

  .xxl\:h-half {
    height: 0.25rem
  }

  .xxl\:h-full {
    height: 100%
  }

  .xxl\:h-screen {
    height: 100vh
  }

  .xxl\:leading-base {
    line-height: .75rem
  }

  .xxl\:leading-sm {
    line-height: 1.125rem
  }

  .xxl\:leading-lg {
    line-height: 1.5rem
  }

  .xxl\:leading-xl {
    line-height: 1.875rem
  }

  .xxl\:leading-2xl {
    line-height: 2.25rem
  }

  .xxl\:list-none {
    list-style-type: none
  }

  .xxl\:list-disc {
    list-style-type: disc
  }

  .xxl\:list-decimal {
    list-style-type: decimal
  }

  .xxl\:m-0 {
    margin: 0
  }

  .xxl\:m-1 {
    margin: 0.5rem
  }

  .xxl\:m-2 {
    margin: 1rem
  }

  .xxl\:m-3 {
    margin: 1.5rem
  }

  .xxl\:m-4 {
    margin: 2rem
  }

  .xxl\:m-5 {
    margin: 2.5rem
  }

  .xxl\:m-6 {
    margin: 3rem
  }

  .xxl\:m-7 {
    margin: 3.5rem
  }

  .xxl\:m-8 {
    margin: 4rem
  }

  .xxl\:m-9 {
    margin: 4.5rem
  }

  .xxl\:m-10 {
    margin: 5rem
  }

  .xxl\:m-11 {
    margin: 5.5rem
  }

  .xxl\:m-12 {
    margin: 6rem
  }

  .xxl\:m-13 {
    margin: 6.5rem
  }

  .xxl\:m-14 {
    margin: 7rem
  }

  .xxl\:m-15 {
    margin: 7.5rem
  }

  .xxl\:m-16 {
    margin: 8rem
  }

  .xxl\:m-17 {
    margin: 8.5rem
  }

  .xxl\:m-19 {
    margin: 9rem
  }

  .xxl\:m-20 {
    margin: 9.5rem
  }

  .xxl\:m-22 {
    margin: 11rem
  }

  .xxl\:m-24 {
    margin: 12rem
  }

  .xxl\:m-32 {
    margin: 16rem
  }

  .xxl\:m-40 {
    margin: 20rem
  }

  .xxl\:m-auto {
    margin: auto
  }

  .xxl\:m-px {
    margin: 1px
  }

  .xxl\:m-half {
    margin: 0.25rem
  }

  .xxl\:-m-1 {
    margin: -0.5rem
  }

  .xxl\:-m-2 {
    margin: -1rem
  }

  .xxl\:-m-3 {
    margin: -1.5rem
  }

  .xxl\:-m-4 {
    margin: -2rem
  }

  .xxl\:-m-5 {
    margin: -2.5rem
  }

  .xxl\:-m-6 {
    margin: -3rem
  }

  .xxl\:-m-7 {
    margin: -3.5rem
  }

  .xxl\:-m-8 {
    margin: -4rem
  }

  .xxl\:-m-9 {
    margin: -4.5rem
  }

  .xxl\:-m-10 {
    margin: -5rem
  }

  .xxl\:-m-11 {
    margin: -5.5rem
  }

  .xxl\:-m-12 {
    margin: -6rem
  }

  .xxl\:-m-13 {
    margin: -6.5rem
  }

  .xxl\:-m-14 {
    margin: -7rem
  }

  .xxl\:-m-15 {
    margin: -7.5rem
  }

  .xxl\:-m-16 {
    margin: -8rem
  }

  .xxl\:-m-17 {
    margin: -8.5rem
  }

  .xxl\:-m-19 {
    margin: -9rem
  }

  .xxl\:-m-20 {
    margin: -9.5rem
  }

  .xxl\:-m-22 {
    margin: -11rem
  }

  .xxl\:-m-24 {
    margin: -12rem
  }

  .xxl\:-m-32 {
    margin: -16rem
  }

  .xxl\:-m-40 {
    margin: -20rem
  }

  .xxl\:-m-px {
    margin: -1px
  }

  .xxl\:-m-half {
    margin: -0.25rem
  }

  .xxl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xxl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .xxl\:my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xxl\:mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xxl\:my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xxl\:mx-2 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .xxl\:my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .xxl\:mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .xxl\:my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xxl\:mx-4 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xxl\:my-5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .xxl\:mx-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .xxl\:my-6 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .xxl\:mx-6 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .xxl\:my-7 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  .xxl\:mx-7 {
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  .xxl\:my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xxl\:mx-8 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xxl\:my-9 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem
  }

  .xxl\:mx-9 {
    margin-left: 4.5rem;
    margin-right: 4.5rem
  }

  .xxl\:my-10 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .xxl\:mx-10 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .xxl\:my-11 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem
  }

  .xxl\:mx-11 {
    margin-left: 5.5rem;
    margin-right: 5.5rem
  }

  .xxl\:my-12 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xxl\:mx-12 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xxl\:my-13 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem
  }

  .xxl\:mx-13 {
    margin-left: 6.5rem;
    margin-right: 6.5rem
  }

  .xxl\:my-14 {
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  .xxl\:mx-14 {
    margin-left: 7rem;
    margin-right: 7rem
  }

  .xxl\:my-15 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem
  }

  .xxl\:mx-15 {
    margin-left: 7.5rem;
    margin-right: 7.5rem
  }

  .xxl\:my-16 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xxl\:mx-16 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xxl\:my-17 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem
  }

  .xxl\:mx-17 {
    margin-left: 8.5rem;
    margin-right: 8.5rem
  }

  .xxl\:my-19 {
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  .xxl\:mx-19 {
    margin-left: 9rem;
    margin-right: 9rem
  }

  .xxl\:my-20 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem
  }

  .xxl\:mx-20 {
    margin-left: 9.5rem;
    margin-right: 9.5rem
  }

  .xxl\:my-22 {
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  .xxl\:mx-22 {
    margin-left: 11rem;
    margin-right: 11rem
  }

  .xxl\:my-24 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .xxl\:mx-24 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .xxl\:my-32 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xxl\:mx-32 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xxl\:my-40 {
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  .xxl\:mx-40 {
    margin-left: 20rem;
    margin-right: 20rem
  }

  .xxl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xxl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xxl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xxl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .xxl\:my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xxl\:mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xxl\:-my-1 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xxl\:-mx-1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xxl\:-my-2 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xxl\:-mx-2 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xxl\:-my-3 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .xxl\:-mx-3 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .xxl\:-my-4 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xxl\:-mx-4 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xxl\:-my-5 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .xxl\:-mx-5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .xxl\:-my-6 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .xxl\:-mx-6 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .xxl\:-my-7 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  .xxl\:-mx-7 {
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  .xxl\:-my-8 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xxl\:-mx-8 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xxl\:-my-9 {
    margin-top: -4.5rem;
    margin-bottom: -4.5rem
  }

  .xxl\:-mx-9 {
    margin-left: -4.5rem;
    margin-right: -4.5rem
  }

  .xxl\:-my-10 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .xxl\:-mx-10 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .xxl\:-my-11 {
    margin-top: -5.5rem;
    margin-bottom: -5.5rem
  }

  .xxl\:-mx-11 {
    margin-left: -5.5rem;
    margin-right: -5.5rem
  }

  .xxl\:-my-12 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xxl\:-mx-12 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xxl\:-my-13 {
    margin-top: -6.5rem;
    margin-bottom: -6.5rem
  }

  .xxl\:-mx-13 {
    margin-left: -6.5rem;
    margin-right: -6.5rem
  }

  .xxl\:-my-14 {
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  .xxl\:-mx-14 {
    margin-left: -7rem;
    margin-right: -7rem
  }

  .xxl\:-my-15 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem
  }

  .xxl\:-mx-15 {
    margin-left: -7.5rem;
    margin-right: -7.5rem
  }

  .xxl\:-my-16 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xxl\:-mx-16 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xxl\:-my-17 {
    margin-top: -8.5rem;
    margin-bottom: -8.5rem
  }

  .xxl\:-mx-17 {
    margin-left: -8.5rem;
    margin-right: -8.5rem
  }

  .xxl\:-my-19 {
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  .xxl\:-mx-19 {
    margin-left: -9rem;
    margin-right: -9rem
  }

  .xxl\:-my-20 {
    margin-top: -9.5rem;
    margin-bottom: -9.5rem
  }

  .xxl\:-mx-20 {
    margin-left: -9.5rem;
    margin-right: -9.5rem
  }

  .xxl\:-my-22 {
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  .xxl\:-mx-22 {
    margin-left: -11rem;
    margin-right: -11rem
  }

  .xxl\:-my-24 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .xxl\:-mx-24 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .xxl\:-my-32 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xxl\:-mx-32 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xxl\:-my-40 {
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  .xxl\:-mx-40 {
    margin-left: -20rem;
    margin-right: -20rem
  }

  .xxl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xxl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .xxl\:-my-half {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xxl\:-mx-half {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xxl\:mt-0 {
    margin-top: 0
  }

  .xxl\:mr-0 {
    margin-right: 0
  }

  .xxl\:mb-0 {
    margin-bottom: 0
  }

  .xxl\:ml-0 {
    margin-left: 0
  }

  .xxl\:mt-1 {
    margin-top: 0.5rem
  }

  .xxl\:mr-1 {
    margin-right: 0.5rem
  }

  .xxl\:mb-1 {
    margin-bottom: 0.5rem
  }

  .xxl\:ml-1 {
    margin-left: 0.5rem
  }

  .xxl\:mt-2 {
    margin-top: 1rem
  }

  .xxl\:mr-2 {
    margin-right: 1rem
  }

  .xxl\:mb-2 {
    margin-bottom: 1rem
  }

  .xxl\:ml-2 {
    margin-left: 1rem
  }

  .xxl\:mt-3 {
    margin-top: 1.5rem
  }

  .xxl\:mr-3 {
    margin-right: 1.5rem
  }

  .xxl\:mb-3 {
    margin-bottom: 1.5rem
  }

  .xxl\:ml-3 {
    margin-left: 1.5rem
  }

  .xxl\:mt-4 {
    margin-top: 2rem
  }

  .xxl\:mr-4 {
    margin-right: 2rem
  }

  .xxl\:mb-4 {
    margin-bottom: 2rem
  }

  .xxl\:ml-4 {
    margin-left: 2rem
  }

  .xxl\:mt-5 {
    margin-top: 2.5rem
  }

  .xxl\:mr-5 {
    margin-right: 2.5rem
  }

  .xxl\:mb-5 {
    margin-bottom: 2.5rem
  }

  .xxl\:ml-5 {
    margin-left: 2.5rem
  }

  .xxl\:mt-6 {
    margin-top: 3rem
  }

  .xxl\:mr-6 {
    margin-right: 3rem
  }

  .xxl\:mb-6 {
    margin-bottom: 3rem
  }

  .xxl\:ml-6 {
    margin-left: 3rem
  }

  .xxl\:mt-7 {
    margin-top: 3.5rem
  }

  .xxl\:mr-7 {
    margin-right: 3.5rem
  }

  .xxl\:mb-7 {
    margin-bottom: 3.5rem
  }

  .xxl\:ml-7 {
    margin-left: 3.5rem
  }

  .xxl\:mt-8 {
    margin-top: 4rem
  }

  .xxl\:mr-8 {
    margin-right: 4rem
  }

  .xxl\:mb-8 {
    margin-bottom: 4rem
  }

  .xxl\:ml-8 {
    margin-left: 4rem
  }

  .xxl\:mt-9 {
    margin-top: 4.5rem
  }

  .xxl\:mr-9 {
    margin-right: 4.5rem
  }

  .xxl\:mb-9 {
    margin-bottom: 4.5rem
  }

  .xxl\:ml-9 {
    margin-left: 4.5rem
  }

  .xxl\:mt-10 {
    margin-top: 5rem
  }

  .xxl\:mr-10 {
    margin-right: 5rem
  }

  .xxl\:mb-10 {
    margin-bottom: 5rem
  }

  .xxl\:ml-10 {
    margin-left: 5rem
  }

  .xxl\:mt-11 {
    margin-top: 5.5rem
  }

  .xxl\:mr-11 {
    margin-right: 5.5rem
  }

  .xxl\:mb-11 {
    margin-bottom: 5.5rem
  }

  .xxl\:ml-11 {
    margin-left: 5.5rem
  }

  .xxl\:mt-12 {
    margin-top: 6rem
  }

  .xxl\:mr-12 {
    margin-right: 6rem
  }

  .xxl\:mb-12 {
    margin-bottom: 6rem
  }

  .xxl\:ml-12 {
    margin-left: 6rem
  }

  .xxl\:mt-13 {
    margin-top: 6.5rem
  }

  .xxl\:mr-13 {
    margin-right: 6.5rem
  }

  .xxl\:mb-13 {
    margin-bottom: 6.5rem
  }

  .xxl\:ml-13 {
    margin-left: 6.5rem
  }

  .xxl\:mt-14 {
    margin-top: 7rem
  }

  .xxl\:mr-14 {
    margin-right: 7rem
  }

  .xxl\:mb-14 {
    margin-bottom: 7rem
  }

  .xxl\:ml-14 {
    margin-left: 7rem
  }

  .xxl\:mt-15 {
    margin-top: 7.5rem
  }

  .xxl\:mr-15 {
    margin-right: 7.5rem
  }

  .xxl\:mb-15 {
    margin-bottom: 7.5rem
  }

  .xxl\:ml-15 {
    margin-left: 7.5rem
  }

  .xxl\:mt-16 {
    margin-top: 8rem
  }

  .xxl\:mr-16 {
    margin-right: 8rem
  }

  .xxl\:mb-16 {
    margin-bottom: 8rem
  }

  .xxl\:ml-16 {
    margin-left: 8rem
  }

  .xxl\:mt-17 {
    margin-top: 8.5rem
  }

  .xxl\:mr-17 {
    margin-right: 8.5rem
  }

  .xxl\:mb-17 {
    margin-bottom: 8.5rem
  }

  .xxl\:ml-17 {
    margin-left: 8.5rem
  }

  .xxl\:mt-19 {
    margin-top: 9rem
  }

  .xxl\:mr-19 {
    margin-right: 9rem
  }

  .xxl\:mb-19 {
    margin-bottom: 9rem
  }

  .xxl\:ml-19 {
    margin-left: 9rem
  }

  .xxl\:mt-20 {
    margin-top: 9.5rem
  }

  .xxl\:mr-20 {
    margin-right: 9.5rem
  }

  .xxl\:mb-20 {
    margin-bottom: 9.5rem
  }

  .xxl\:ml-20 {
    margin-left: 9.5rem
  }

  .xxl\:mt-22 {
    margin-top: 11rem
  }

  .xxl\:mr-22 {
    margin-right: 11rem
  }

  .xxl\:mb-22 {
    margin-bottom: 11rem
  }

  .xxl\:ml-22 {
    margin-left: 11rem
  }

  .xxl\:mt-24 {
    margin-top: 12rem
  }

  .xxl\:mr-24 {
    margin-right: 12rem
  }

  .xxl\:mb-24 {
    margin-bottom: 12rem
  }

  .xxl\:ml-24 {
    margin-left: 12rem
  }

  .xxl\:mt-32 {
    margin-top: 16rem
  }

  .xxl\:mr-32 {
    margin-right: 16rem
  }

  .xxl\:mb-32 {
    margin-bottom: 16rem
  }

  .xxl\:ml-32 {
    margin-left: 16rem
  }

  .xxl\:mt-40 {
    margin-top: 20rem
  }

  .xxl\:mr-40 {
    margin-right: 20rem
  }

  .xxl\:mb-40 {
    margin-bottom: 20rem
  }

  .xxl\:ml-40 {
    margin-left: 20rem
  }

  .xxl\:mt-auto {
    margin-top: auto
  }

  .xxl\:mr-auto {
    margin-right: auto
  }

  .xxl\:mb-auto {
    margin-bottom: auto
  }

  .xxl\:ml-auto {
    margin-left: auto
  }

  .xxl\:mt-px {
    margin-top: 1px
  }

  .xxl\:mr-px {
    margin-right: 1px
  }

  .xxl\:mb-px {
    margin-bottom: 1px
  }

  .xxl\:ml-px {
    margin-left: 1px
  }

  .xxl\:mt-half {
    margin-top: 0.25rem
  }

  .xxl\:mr-half {
    margin-right: 0.25rem
  }

  .xxl\:mb-half {
    margin-bottom: 0.25rem
  }

  .xxl\:ml-half {
    margin-left: 0.25rem
  }

  .xxl\:-mt-1 {
    margin-top: -0.5rem
  }

  .xxl\:-mr-1 {
    margin-right: -0.5rem
  }

  .xxl\:-mb-1 {
    margin-bottom: -0.5rem
  }

  .xxl\:-ml-1 {
    margin-left: -0.5rem
  }

  .xxl\:-mt-2 {
    margin-top: -1rem
  }

  .xxl\:-mr-2 {
    margin-right: -1rem
  }

  .xxl\:-mb-2 {
    margin-bottom: -1rem
  }

  .xxl\:-ml-2 {
    margin-left: -1rem
  }

  .xxl\:-mt-3 {
    margin-top: -1.5rem
  }

  .xxl\:-mr-3 {
    margin-right: -1.5rem
  }

  .xxl\:-mb-3 {
    margin-bottom: -1.5rem
  }

  .xxl\:-ml-3 {
    margin-left: -1.5rem
  }

  .xxl\:-mt-4 {
    margin-top: -2rem
  }

  .xxl\:-mr-4 {
    margin-right: -2rem
  }

  .xxl\:-mb-4 {
    margin-bottom: -2rem
  }

  .xxl\:-ml-4 {
    margin-left: -2rem
  }

  .xxl\:-mt-5 {
    margin-top: -2.5rem
  }

  .xxl\:-mr-5 {
    margin-right: -2.5rem
  }

  .xxl\:-mb-5 {
    margin-bottom: -2.5rem
  }

  .xxl\:-ml-5 {
    margin-left: -2.5rem
  }

  .xxl\:-mt-6 {
    margin-top: -3rem
  }

  .xxl\:-mr-6 {
    margin-right: -3rem
  }

  .xxl\:-mb-6 {
    margin-bottom: -3rem
  }

  .xxl\:-ml-6 {
    margin-left: -3rem
  }

  .xxl\:-mt-7 {
    margin-top: -3.5rem
  }

  .xxl\:-mr-7 {
    margin-right: -3.5rem
  }

  .xxl\:-mb-7 {
    margin-bottom: -3.5rem
  }

  .xxl\:-ml-7 {
    margin-left: -3.5rem
  }

  .xxl\:-mt-8 {
    margin-top: -4rem
  }

  .xxl\:-mr-8 {
    margin-right: -4rem
  }

  .xxl\:-mb-8 {
    margin-bottom: -4rem
  }

  .xxl\:-ml-8 {
    margin-left: -4rem
  }

  .xxl\:-mt-9 {
    margin-top: -4.5rem
  }

  .xxl\:-mr-9 {
    margin-right: -4.5rem
  }

  .xxl\:-mb-9 {
    margin-bottom: -4.5rem
  }

  .xxl\:-ml-9 {
    margin-left: -4.5rem
  }

  .xxl\:-mt-10 {
    margin-top: -5rem
  }

  .xxl\:-mr-10 {
    margin-right: -5rem
  }

  .xxl\:-mb-10 {
    margin-bottom: -5rem
  }

  .xxl\:-ml-10 {
    margin-left: -5rem
  }

  .xxl\:-mt-11 {
    margin-top: -5.5rem
  }

  .xxl\:-mr-11 {
    margin-right: -5.5rem
  }

  .xxl\:-mb-11 {
    margin-bottom: -5.5rem
  }

  .xxl\:-ml-11 {
    margin-left: -5.5rem
  }

  .xxl\:-mt-12 {
    margin-top: -6rem
  }

  .xxl\:-mr-12 {
    margin-right: -6rem
  }

  .xxl\:-mb-12 {
    margin-bottom: -6rem
  }

  .xxl\:-ml-12 {
    margin-left: -6rem
  }

  .xxl\:-mt-13 {
    margin-top: -6.5rem
  }

  .xxl\:-mr-13 {
    margin-right: -6.5rem
  }

  .xxl\:-mb-13 {
    margin-bottom: -6.5rem
  }

  .xxl\:-ml-13 {
    margin-left: -6.5rem
  }

  .xxl\:-mt-14 {
    margin-top: -7rem
  }

  .xxl\:-mr-14 {
    margin-right: -7rem
  }

  .xxl\:-mb-14 {
    margin-bottom: -7rem
  }

  .xxl\:-ml-14 {
    margin-left: -7rem
  }

  .xxl\:-mt-15 {
    margin-top: -7.5rem
  }

  .xxl\:-mr-15 {
    margin-right: -7.5rem
  }

  .xxl\:-mb-15 {
    margin-bottom: -7.5rem
  }

  .xxl\:-ml-15 {
    margin-left: -7.5rem
  }

  .xxl\:-mt-16 {
    margin-top: -8rem
  }

  .xxl\:-mr-16 {
    margin-right: -8rem
  }

  .xxl\:-mb-16 {
    margin-bottom: -8rem
  }

  .xxl\:-ml-16 {
    margin-left: -8rem
  }

  .xxl\:-mt-17 {
    margin-top: -8.5rem
  }

  .xxl\:-mr-17 {
    margin-right: -8.5rem
  }

  .xxl\:-mb-17 {
    margin-bottom: -8.5rem
  }

  .xxl\:-ml-17 {
    margin-left: -8.5rem
  }

  .xxl\:-mt-19 {
    margin-top: -9rem
  }

  .xxl\:-mr-19 {
    margin-right: -9rem
  }

  .xxl\:-mb-19 {
    margin-bottom: -9rem
  }

  .xxl\:-ml-19 {
    margin-left: -9rem
  }

  .xxl\:-mt-20 {
    margin-top: -9.5rem
  }

  .xxl\:-mr-20 {
    margin-right: -9.5rem
  }

  .xxl\:-mb-20 {
    margin-bottom: -9.5rem
  }

  .xxl\:-ml-20 {
    margin-left: -9.5rem
  }

  .xxl\:-mt-22 {
    margin-top: -11rem
  }

  .xxl\:-mr-22 {
    margin-right: -11rem
  }

  .xxl\:-mb-22 {
    margin-bottom: -11rem
  }

  .xxl\:-ml-22 {
    margin-left: -11rem
  }

  .xxl\:-mt-24 {
    margin-top: -12rem
  }

  .xxl\:-mr-24 {
    margin-right: -12rem
  }

  .xxl\:-mb-24 {
    margin-bottom: -12rem
  }

  .xxl\:-ml-24 {
    margin-left: -12rem
  }

  .xxl\:-mt-32 {
    margin-top: -16rem
  }

  .xxl\:-mr-32 {
    margin-right: -16rem
  }

  .xxl\:-mb-32 {
    margin-bottom: -16rem
  }

  .xxl\:-ml-32 {
    margin-left: -16rem
  }

  .xxl\:-mt-40 {
    margin-top: -20rem
  }

  .xxl\:-mr-40 {
    margin-right: -20rem
  }

  .xxl\:-mb-40 {
    margin-bottom: -20rem
  }

  .xxl\:-ml-40 {
    margin-left: -20rem
  }

  .xxl\:-mt-px {
    margin-top: -1px
  }

  .xxl\:-mr-px {
    margin-right: -1px
  }

  .xxl\:-mb-px {
    margin-bottom: -1px
  }

  .xxl\:-ml-px {
    margin-left: -1px
  }

  .xxl\:-mt-half {
    margin-top: -0.25rem
  }

  .xxl\:-mr-half {
    margin-right: -0.25rem
  }

  .xxl\:-mb-half {
    margin-bottom: -0.25rem
  }

  .xxl\:-ml-half {
    margin-left: -0.25rem
  }

  .xxl\:max-h-90 {
    max-height: 90%
  }

  .xxl\:max-h-full {
    max-height: 100%
  }

  .xxl\:max-h-screen {
    max-height: 100vh
  }

  .xxl\:max-w-400 {
    max-width: 25rem
  }

  .xxl\:max-w-500 {
    max-width: 31.25rem
  }

  .xxl\:max-w-600 {
    max-width: 37.5rem
  }

  .xxl\:max-w-700 {
    max-width: 43.75em
  }

  .xxl\:max-w-800 {
    max-width: 50rem
  }

  .xxl\:max-w-1000 {
    max-width: 62.5rem
  }

  .xxl\:max-w-1200 {
    max-width: 75rem
  }

  .xxl\:max-w-1280 {
    max-width: 80rem
  }

  .xxl\:max-w-1400 {
    max-width: 87.5rem
  }

  .xxl\:max-w-1600 {
    max-width: 100rem
  }

  .xxl\:max-w-1800 {
    max-width: 112.5rem
  }

  .xxl\:max-w-2000 {
    max-width: 125rem
  }

  .xxl\:min-h-0 {
    min-height: 0
  }

  .xxl\:min-h-full {
    min-height: 100%
  }

  .xxl\:min-h-screen {
    min-height: 100vh
  }

  .xxl\:min-w-0 {
    min-width: 0
  }

  .xxl\:min-w-full {
    min-width: 100%
  }

  .xxl\:object-contain {
    object-fit: contain
  }

  .xxl\:object-cover {
    object-fit: cover
  }

  .xxl\:object-fill {
    object-fit: fill
  }

  .xxl\:object-none {
    object-fit: none
  }

  .xxl\:object-scale-down {
    object-fit: scale-down
  }

  .xxl\:object-bottom {
    object-position: bottom
  }

  .xxl\:object-center {
    object-position: center
  }

  .xxl\:object-left {
    object-position: left
  }

  .xxl\:object-left-bottom {
    object-position: left bottom
  }

  .xxl\:object-left-top {
    object-position: left top
  }

  .xxl\:object-right {
    object-position: right
  }

  .xxl\:object-right-bottom {
    object-position: right bottom
  }

  .xxl\:object-right-top {
    object-position: right top
  }

  .xxl\:object-top {
    object-position: top
  }

  .xxl\:opacity-0 {
    opacity: 0
  }

  .xxl\:opacity-20 {
    opacity: .2
  }

  .xxl\:opacity-30 {
    opacity: .3
  }

  .xxl\:opacity-40 {
    opacity: .4
  }

  .xxl\:opacity-50 {
    opacity: .5
  }

  .xxl\:opacity-60 {
    opacity: .6
  }

  .xxl\:opacity-80 {
    opacity: .8
  }

  .xxl\:opacity-100 {
    opacity: 1
  }

  .xxl\:hover\:opacity-0:hover {
    opacity: 0
  }

  .xxl\:hover\:opacity-20:hover {
    opacity: .2
  }

  .xxl\:hover\:opacity-30:hover {
    opacity: .3
  }

  .xxl\:hover\:opacity-40:hover {
    opacity: .4
  }

  .xxl\:hover\:opacity-50:hover {
    opacity: .5
  }

  .xxl\:hover\:opacity-60:hover {
    opacity: .6
  }

  .xxl\:hover\:opacity-80:hover {
    opacity: .8
  }

  .xxl\:hover\:opacity-100:hover {
    opacity: 1
  }

  .xxl\:focus\:opacity-0:focus {
    opacity: 0
  }

  .xxl\:focus\:opacity-20:focus {
    opacity: .2
  }

  .xxl\:focus\:opacity-30:focus {
    opacity: .3
  }

  .xxl\:focus\:opacity-40:focus {
    opacity: .4
  }

  .xxl\:focus\:opacity-50:focus {
    opacity: .5
  }

  .xxl\:focus\:opacity-60:focus {
    opacity: .6
  }

  .xxl\:focus\:opacity-80:focus {
    opacity: .8
  }

  .xxl\:focus\:opacity-100:focus {
    opacity: 1
  }

  .xxl\:outline-none {
    outline: 0
  }

  .xxl\:focus\:outline-none:focus {
    outline: 0
  }

  .xxl\:overflow-auto {
    overflow: auto
  }

  .xxl\:overflow-hidden {
    overflow: hidden
  }

  .xxl\:overflow-visible {
    overflow: visible
  }

  .xxl\:overflow-scroll {
    overflow: scroll
  }

  .xxl\:overflow-x-auto {
    overflow-x: auto
  }

  .xxl\:overflow-y-auto {
    overflow-y: auto
  }

  .xxl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .xxl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .xxl\:overflow-x-visible {
    overflow-x: visible
  }

  .xxl\:overflow-y-visible {
    overflow-y: visible
  }

  .xxl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .xxl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .xxl\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .xxl\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .xxl\:p-0 {
    padding: 0
  }

  .xxl\:p-1 {
    padding: 0.5rem
  }

  .xxl\:p-2 {
    padding: 1rem
  }

  .xxl\:p-3 {
    padding: 1.5rem
  }

  .xxl\:p-4 {
    padding: 2rem
  }

  .xxl\:p-5 {
    padding: 2.5rem
  }

  .xxl\:p-6 {
    padding: 3rem
  }

  .xxl\:p-7 {
    padding: 3.5rem
  }

  .xxl\:p-8 {
    padding: 4rem
  }

  .xxl\:p-9 {
    padding: 4.5rem
  }

  .xxl\:p-10 {
    padding: 5rem
  }

  .xxl\:p-11 {
    padding: 5.5rem
  }

  .xxl\:p-12 {
    padding: 6rem
  }

  .xxl\:p-13 {
    padding: 6.5rem
  }

  .xxl\:p-14 {
    padding: 7rem
  }

  .xxl\:p-15 {
    padding: 7.5rem
  }

  .xxl\:p-16 {
    padding: 8rem
  }

  .xxl\:p-17 {
    padding: 8.5rem
  }

  .xxl\:p-19 {
    padding: 9rem
  }

  .xxl\:p-20 {
    padding: 9.5rem
  }

  .xxl\:p-22 {
    padding: 11rem
  }

  .xxl\:p-24 {
    padding: 12rem
  }

  .xxl\:p-32 {
    padding: 16rem
  }

  .xxl\:p-40 {
    padding: 20rem
  }

  .xxl\:p-px {
    padding: 1px
  }

  .xxl\:p-half {
    padding: 0.25rem
  }

  .xxl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xxl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xxl\:py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xxl\:px-1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xxl\:py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .xxl\:px-2 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .xxl\:py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .xxl\:px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xxl\:py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xxl\:px-4 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xxl\:py-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xxl\:px-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .xxl\:py-6 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .xxl\:px-6 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .xxl\:py-7 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  .xxl\:px-7 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .xxl\:py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xxl\:px-8 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xxl\:py-9 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem
  }

  .xxl\:px-9 {
    padding-left: 4.5rem;
    padding-right: 4.5rem
  }

  .xxl\:py-10 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .xxl\:px-10 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xxl\:py-11 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem
  }

  .xxl\:px-11 {
    padding-left: 5.5rem;
    padding-right: 5.5rem
  }

  .xxl\:py-12 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xxl\:px-12 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xxl\:py-13 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem
  }

  .xxl\:px-13 {
    padding-left: 6.5rem;
    padding-right: 6.5rem
  }

  .xxl\:py-14 {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .xxl\:px-14 {
    padding-left: 7rem;
    padding-right: 7rem
  }

  .xxl\:py-15 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
  }

  .xxl\:px-15 {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }

  .xxl\:py-16 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xxl\:px-16 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xxl\:py-17 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem
  }

  .xxl\:px-17 {
    padding-left: 8.5rem;
    padding-right: 8.5rem
  }

  .xxl\:py-19 {
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  .xxl\:px-19 {
    padding-left: 9rem;
    padding-right: 9rem
  }

  .xxl\:py-20 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem
  }

  .xxl\:px-20 {
    padding-left: 9.5rem;
    padding-right: 9.5rem
  }

  .xxl\:py-22 {
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  .xxl\:px-22 {
    padding-left: 11rem;
    padding-right: 11rem
  }

  .xxl\:py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .xxl\:px-24 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .xxl\:py-32 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xxl\:px-32 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xxl\:py-40 {
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  .xxl\:px-40 {
    padding-left: 20rem;
    padding-right: 20rem
  }

  .xxl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xxl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .xxl\:py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xxl\:px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xxl\:pt-0 {
    padding-top: 0
  }

  .xxl\:pr-0 {
    padding-right: 0
  }

  .xxl\:pb-0 {
    padding-bottom: 0
  }

  .xxl\:pl-0 {
    padding-left: 0
  }

  .xxl\:pt-1 {
    padding-top: 0.5rem
  }

  .xxl\:pr-1 {
    padding-right: 0.5rem
  }

  .xxl\:pb-1 {
    padding-bottom: 0.5rem
  }

  .xxl\:pl-1 {
    padding-left: 0.5rem
  }

  .xxl\:pt-2 {
    padding-top: 1rem
  }

  .xxl\:pr-2 {
    padding-right: 1rem
  }

  .xxl\:pb-2 {
    padding-bottom: 1rem
  }

  .xxl\:pl-2 {
    padding-left: 1rem
  }

  .xxl\:pt-3 {
    padding-top: 1.5rem
  }

  .xxl\:pr-3 {
    padding-right: 1.5rem
  }

  .xxl\:pb-3 {
    padding-bottom: 1.5rem
  }

  .xxl\:pl-3 {
    padding-left: 1.5rem
  }

  .xxl\:pt-4 {
    padding-top: 2rem
  }

  .xxl\:pr-4 {
    padding-right: 2rem
  }

  .xxl\:pb-4 {
    padding-bottom: 2rem
  }

  .xxl\:pl-4 {
    padding-left: 2rem
  }

  .xxl\:pt-5 {
    padding-top: 2.5rem
  }

  .xxl\:pr-5 {
    padding-right: 2.5rem
  }

  .xxl\:pb-5 {
    padding-bottom: 2.5rem
  }

  .xxl\:pl-5 {
    padding-left: 2.5rem
  }

  .xxl\:pt-6 {
    padding-top: 3rem
  }

  .xxl\:pr-6 {
    padding-right: 3rem
  }

  .xxl\:pb-6 {
    padding-bottom: 3rem
  }

  .xxl\:pl-6 {
    padding-left: 3rem
  }

  .xxl\:pt-7 {
    padding-top: 3.5rem
  }

  .xxl\:pr-7 {
    padding-right: 3.5rem
  }

  .xxl\:pb-7 {
    padding-bottom: 3.5rem
  }

  .xxl\:pl-7 {
    padding-left: 3.5rem
  }

  .xxl\:pt-8 {
    padding-top: 4rem
  }

  .xxl\:pr-8 {
    padding-right: 4rem
  }

  .xxl\:pb-8 {
    padding-bottom: 4rem
  }

  .xxl\:pl-8 {
    padding-left: 4rem
  }

  .xxl\:pt-9 {
    padding-top: 4.5rem
  }

  .xxl\:pr-9 {
    padding-right: 4.5rem
  }

  .xxl\:pb-9 {
    padding-bottom: 4.5rem
  }

  .xxl\:pl-9 {
    padding-left: 4.5rem
  }

  .xxl\:pt-10 {
    padding-top: 5rem
  }

  .xxl\:pr-10 {
    padding-right: 5rem
  }

  .xxl\:pb-10 {
    padding-bottom: 5rem
  }

  .xxl\:pl-10 {
    padding-left: 5rem
  }

  .xxl\:pt-11 {
    padding-top: 5.5rem
  }

  .xxl\:pr-11 {
    padding-right: 5.5rem
  }

  .xxl\:pb-11 {
    padding-bottom: 5.5rem
  }

  .xxl\:pl-11 {
    padding-left: 5.5rem
  }

  .xxl\:pt-12 {
    padding-top: 6rem
  }

  .xxl\:pr-12 {
    padding-right: 6rem
  }

  .xxl\:pb-12 {
    padding-bottom: 6rem
  }

  .xxl\:pl-12 {
    padding-left: 6rem
  }

  .xxl\:pt-13 {
    padding-top: 6.5rem
  }

  .xxl\:pr-13 {
    padding-right: 6.5rem
  }

  .xxl\:pb-13 {
    padding-bottom: 6.5rem
  }

  .xxl\:pl-13 {
    padding-left: 6.5rem
  }

  .xxl\:pt-14 {
    padding-top: 7rem
  }

  .xxl\:pr-14 {
    padding-right: 7rem
  }

  .xxl\:pb-14 {
    padding-bottom: 7rem
  }

  .xxl\:pl-14 {
    padding-left: 7rem
  }

  .xxl\:pt-15 {
    padding-top: 7.5rem
  }

  .xxl\:pr-15 {
    padding-right: 7.5rem
  }

  .xxl\:pb-15 {
    padding-bottom: 7.5rem
  }

  .xxl\:pl-15 {
    padding-left: 7.5rem
  }

  .xxl\:pt-16 {
    padding-top: 8rem
  }

  .xxl\:pr-16 {
    padding-right: 8rem
  }

  .xxl\:pb-16 {
    padding-bottom: 8rem
  }

  .xxl\:pl-16 {
    padding-left: 8rem
  }

  .xxl\:pt-17 {
    padding-top: 8.5rem
  }

  .xxl\:pr-17 {
    padding-right: 8.5rem
  }

  .xxl\:pb-17 {
    padding-bottom: 8.5rem
  }

  .xxl\:pl-17 {
    padding-left: 8.5rem
  }

  .xxl\:pt-19 {
    padding-top: 9rem
  }

  .xxl\:pr-19 {
    padding-right: 9rem
  }

  .xxl\:pb-19 {
    padding-bottom: 9rem
  }

  .xxl\:pl-19 {
    padding-left: 9rem
  }

  .xxl\:pt-20 {
    padding-top: 9.5rem
  }

  .xxl\:pr-20 {
    padding-right: 9.5rem
  }

  .xxl\:pb-20 {
    padding-bottom: 9.5rem
  }

  .xxl\:pl-20 {
    padding-left: 9.5rem
  }

  .xxl\:pt-22 {
    padding-top: 11rem
  }

  .xxl\:pr-22 {
    padding-right: 11rem
  }

  .xxl\:pb-22 {
    padding-bottom: 11rem
  }

  .xxl\:pl-22 {
    padding-left: 11rem
  }

  .xxl\:pt-24 {
    padding-top: 12rem
  }

  .xxl\:pr-24 {
    padding-right: 12rem
  }

  .xxl\:pb-24 {
    padding-bottom: 12rem
  }

  .xxl\:pl-24 {
    padding-left: 12rem
  }

  .xxl\:pt-32 {
    padding-top: 16rem
  }

  .xxl\:pr-32 {
    padding-right: 16rem
  }

  .xxl\:pb-32 {
    padding-bottom: 16rem
  }

  .xxl\:pl-32 {
    padding-left: 16rem
  }

  .xxl\:pt-40 {
    padding-top: 20rem
  }

  .xxl\:pr-40 {
    padding-right: 20rem
  }

  .xxl\:pb-40 {
    padding-bottom: 20rem
  }

  .xxl\:pl-40 {
    padding-left: 20rem
  }

  .xxl\:pt-px {
    padding-top: 1px
  }

  .xxl\:pr-px {
    padding-right: 1px
  }

  .xxl\:pb-px {
    padding-bottom: 1px
  }

  .xxl\:pl-px {
    padding-left: 1px
  }

  .xxl\:pt-half {
    padding-top: 0.25rem
  }

  .xxl\:pr-half {
    padding-right: 0.25rem
  }

  .xxl\:pb-half {
    padding-bottom: 0.25rem
  }

  .xxl\:pl-half {
    padding-left: 0.25rem
  }

  .xxl\:placeholder-transparent::placeholder {
    color: transparent
  }

  .xxl\:placeholder-black::placeholder {
    color: @color-black
  }

  .xxl\:placeholder-white::placeholder {
    color: @color-white
  }

  .xxl\:placeholder-grey-10::placeholder {
    color: fade(@color-grey, 10%)
  }

  .xxl\:placeholder-grey-30::placeholder {
    color: fade(@color-grey, 30%)
  }

  .xxl\:placeholder-grey-40::placeholder {
    color: fade(@color-grey, 40%)
  }

  .xxl\:placeholder-grey-60::placeholder {
    color: fade(@color-grey, 60%)
  }

  .xxl\:placeholder-grey-90::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xxl\:placeholder-grey::placeholder {
    color: @color-grey
  }

  .xxl\:placeholder-grey-metal::placeholder {
    color: @color-grey
  }

  .xxl\:placeholder-grey-divider::placeholder {
    color: fade(@color-grey, 20%)
  }

  .xxl\:placeholder-grey-light::placeholder {
    color: @color-grey-light
  }

  .xxl\:placeholder-grey-header::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xxl\:placeholder-body::placeholder {
    color: @color-body
  }

  .xxl\:placeholder-yellow::placeholder {
    color: @color-yellow
  }

  .xxl\:placeholder-orange::placeholder {
    color: @color-orange
  }

  .xxl\:placeholder-stone::placeholder {
    color: @color-stone
  }

  .xxl\:placeholder-khaki::placeholder {
    color: @color-khaki
  }

  .xxl\:placeholder-green::placeholder {
    color: @color-green
  }

  .xxl\:placeholder-blue::placeholder {
    color: @color-blue
  }

  .xxl\:placeholder-red::placeholder {
    color: @color-red
  }

  .xxl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent
  }

  .xxl\:focus\:placeholder-black:focus::placeholder {
    color: @color-black
  }

  .xxl\:focus\:placeholder-white:focus::placeholder {
    color: @color-white
  }

  .xxl\:focus\:placeholder-grey-10:focus::placeholder {
    color: fade(@color-grey, 10%)
  }

  .xxl\:focus\:placeholder-grey-30:focus::placeholder {
    color: fade(@color-grey, 30%)
  }

  .xxl\:focus\:placeholder-grey-40:focus::placeholder {
    color: fade(@color-grey, 40%)
  }

  .xxl\:focus\:placeholder-grey-60:focus::placeholder {
    color: fade(@color-grey, 60%)
  }

  .xxl\:focus\:placeholder-grey-90:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:placeholder-grey:focus::placeholder {
    color: @color-grey
  }

  .xxl\:focus\:placeholder-grey-metal:focus::placeholder {
    color: @color-grey
  }

  .xxl\:focus\:placeholder-grey-divider:focus::placeholder {
    color: fade(@color-grey, 20%)
  }

  .xxl\:focus\:placeholder-grey-light:focus::placeholder {
    color: @color-grey-light
  }

  .xxl\:focus\:placeholder-grey-header:focus::placeholder {
    color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:placeholder-body:focus::placeholder {
    color: @color-body
  }

  .xxl\:focus\:placeholder-yellow:focus::placeholder {
    color: @color-yellow
  }

  .xxl\:focus\:placeholder-orange:focus::placeholder {
    color: @color-orange
  }

  .xxl\:focus\:placeholder-stone:focus::placeholder {
    color: @color-stone
  }

  .xxl\:focus\:placeholder-khaki:focus::placeholder {
    color: @color-khaki
  }

  .xxl\:focus\:placeholder-green:focus::placeholder {
    color: @color-green
  }

  .xxl\:focus\:placeholder-blue:focus::placeholder {
    color: @color-blue
  }

  .xxl\:focus\:placeholder-red:focus::placeholder {
    color: @color-red
  }

  .xxl\:pointer-events-none {
    pointer-events: none
  }

  .xxl\:pointer-events-auto {
    pointer-events: auto
  }

  .xxl\:static {
    position: static
  }

  .xxl\:fixed {
    position: fixed
  }

  .xxl\:absolute {
    position: absolute
  }

  .xxl\:relative {
    position: relative
  }

  .xxl\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .xxl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xxl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xxl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .xxl\:inset-x-0 {
    right: 0;
    left: 0
  }

  .xxl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .xxl\:inset-x-auto {
    right: auto;
    left: auto
  }

  .xxl\:top-0 {
    top: 0
  }

  .xxl\:right-0 {
    right: 0
  }

  .xxl\:bottom-0 {
    bottom: 0
  }

  .xxl\:left-0 {
    left: 0
  }

  .xxl\:top-auto {
    top: auto
  }

  .xxl\:right-auto {
    right: auto
  }

  .xxl\:bottom-auto {
    bottom: auto
  }

  .xxl\:left-auto {
    left: auto
  }

  .xxl\:resize-none {
    resize: none
  }

  .xxl\:resize-y {
    resize: vertical
  }

  .xxl\:resize-x {
    resize: horizontal
  }

  .xxl\:resize {
    resize: both
  }

  .xxl\:shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xxl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xxl\:shadow-none {
    box-shadow: none
  }

  .xxl\:hover\:shadow:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xxl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xxl\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .xxl\:focus\:shadow:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1)
  }

  .xxl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xxl\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .xxl\:fill-current {
    fill: currentColor
  }

  .xxl\:stroke-current {
    stroke: currentColor
  }

  .xxl\:table-auto {
    table-layout: auto
  }

  .xxl\:table-fixed {
    table-layout: fixed
  }

  .xxl\:text-left {
    text-align: left
  }

  .xxl\:text-center {
    text-align: center
  }

  .xxl\:text-right {
    text-align: right
  }

  .xxl\:text-justify {
    text-align: justify
  }

  .xxl\:text-transparent {
    color: transparent
  }

  .xxl\:text-black {
    color: @color-black
  }

  .xxl\:text-white {
    color: @color-white
  }

  .xxl\:text-grey-10 {
    color: fade(@color-grey, 10%)
  }

  .xxl\:text-grey-30 {
    color: fade(@color-grey, 30%)
  }

  .xxl\:text-grey-40 {
    color: fade(@color-grey, 40%)
  }

  .xxl\:text-grey-60 {
    color: fade(@color-grey, 60%)
  }

  .xxl\:text-grey-90 {
    color: fade(@color-grey, 90%)
  }

  .xxl\:text-grey {
    color: @color-grey
  }

  .xxl\:text-grey-metal {
    color: @color-grey
  }

  .xxl\:text-grey-divider {
    color: fade(@color-grey, 20%)
  }

  .xxl\:text-grey-light {
    color: @color-grey-light
  }

  .xxl\:text-grey-header {
    color: fade(@color-grey, 90%)
  }

  .xxl\:text-body {
    color: @color-body
  }

  .xxl\:text-yellow {
    color: @color-yellow
  }

  .xxl\:text-orange {
    color: @color-orange
  }

  .xxl\:text-stone {
    color: @color-stone
  }

  .xxl\:text-khaki {
    color: @color-khaki
  }

  .xxl\:text-green {
    color: @color-green
  }

  .xxl\:text-blue {
    color: @color-blue
  }

  .xxl\:text-red {
    color: @color-red
  }

  .xxl\:hover\:text-transparent:hover {
    color: transparent
  }

  .xxl\:hover\:text-black:hover {
    color: @color-black
  }

  .xxl\:hover\:text-white:hover {
    color: @color-white
  }

  .xxl\:hover\:text-grey-10:hover {
    color: fade(@color-grey, 10%)
  }

  .xxl\:hover\:text-grey-30:hover {
    color: fade(@color-grey, 30%)
  }

  .xxl\:hover\:text-grey-40:hover {
    color: fade(@color-grey, 40%)
  }

  .xxl\:hover\:text-grey-60:hover {
    color: fade(@color-grey, 60%)
  }

  .xxl\:hover\:text-grey-90:hover {
    color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:text-grey:hover {
    color: @color-grey
  }

  .xxl\:hover\:text-grey-metal:hover {
    color: @color-grey
  }

  .xxl\:hover\:text-grey-divider:hover {
    color: fade(@color-grey, 20%)
  }

  .xxl\:hover\:text-grey-light:hover {
    color: @color-grey-light
  }

  .xxl\:hover\:text-grey-header:hover {
    color: fade(@color-grey, 90%)
  }

  .xxl\:hover\:text-body:hover {
    color: @color-body
  }

  .xxl\:hover\:text-yellow:hover {
    color: @color-yellow
  }

  .xxl\:hover\:text-orange:hover {
    color: @color-orange
  }

  .xxl\:hover\:text-stone:hover {
    color: @color-stone
  }

  .xxl\:hover\:text-khaki:hover {
    color: @color-khaki
  }

  .xxl\:hover\:text-green:hover {
    color: @color-green
  }

  .xxl\:hover\:text-blue:hover {
    color: @color-blue
  }

  .xxl\:hover\:text-red:hover {
    color: @color-red
  }

  .xxl\:focus\:text-transparent:focus {
    color: transparent
  }

  .xxl\:focus\:text-black:focus {
    color: @color-black
  }

  .xxl\:focus\:text-white:focus {
    color: @color-white
  }

  .xxl\:focus\:text-grey-10:focus {
    color: fade(@color-grey, 10%)
  }

  .xxl\:focus\:text-grey-30:focus {
    color: fade(@color-grey, 30%)
  }

  .xxl\:focus\:text-grey-40:focus {
    color: fade(@color-grey, 40%)
  }

  .xxl\:focus\:text-grey-60:focus {
    color: fade(@color-grey, 60%)
  }

  .xxl\:focus\:text-grey-90:focus {
    color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:text-grey:focus {
    color: @color-grey
  }

  .xxl\:focus\:text-grey-metal:focus {
    color: @color-grey
  }

  .xxl\:focus\:text-grey-divider:focus {
    color: fade(@color-grey, 20%)
  }

  .xxl\:focus\:text-grey-light:focus {
    color: @color-grey-light
  }

  .xxl\:focus\:text-grey-header:focus {
    color: fade(@color-grey, 90%)
  }

  .xxl\:focus\:text-body:focus {
    color: @color-body
  }

  .xxl\:focus\:text-yellow:focus {
    color: @color-yellow
  }

  .xxl\:focus\:text-orange:focus {
    color: @color-orange
  }

  .xxl\:focus\:text-stone:focus {
    color: @color-stone
  }

  .xxl\:focus\:text-khaki:focus {
    color: @color-khaki
  }

  .xxl\:focus\:text-green:focus {
    color: @color-green
  }

  .xxl\:focus\:text-blue:focus {
    color: @color-blue
  }

  .xxl\:focus\:text-red:focus {
    color: @color-red
  }

  .xxl\:text-base {
    font-size: .6875rem
  }

  .xxl\:text-md {
    font-size: 0.9375rem
  }

  .xxl\:text-lg {
    font-size: 1rem
  }

  .xxl\:text-xl {
    font-size: 1.375rem
  }

  .xxl\:text-2xl {
    font-size: 2.0625rem
  }

  .xxl\:italic {
    font-style: italic
  }

  .xxl\:not-italic {
    font-style: normal
  }

  .xxl\:uppercase {
    text-transform: uppercase
  }

  .xxl\:lowercase {
    text-transform: lowercase
  }

  .xxl\:capitalize {
    text-transform: capitalize
  }

  .xxl\:normal-case {
    text-transform: none
  }

  .xxl\:underline {
    text-decoration: underline
  }

  .xxl\:line-through {
    text-decoration: line-through
  }

  .xxl\:no-underline {
    text-decoration: none
  }

  .xxl\:hover\:underline:hover {
    text-decoration: underline
  }

  .xxl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .xxl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .xxl\:focus\:underline:focus {
    text-decoration: underline
  }

  .xxl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .xxl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .xxl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .xxl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .xxl\:tracking-normal {
    letter-spacing: 0
  }

  .xxl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .xxl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .xxl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .xxl\:align-baseline {
    vertical-align: baseline
  }

  .xxl\:align-top {
    vertical-align: top
  }

  .xxl\:align-middle {
    vertical-align: middle
  }

  .xxl\:align-bottom {
    vertical-align: bottom
  }

  .xxl\:align-text-top {
    vertical-align: text-top
  }

  .xxl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .xxl\:visible {
    visibility: visible
  }

  .xxl\:invisible {
    visibility: hidden
  }

  .xxl\:whitespace-normal {
    white-space: normal
  }

  .xxl\:whitespace-no-wrap {
    white-space: nowrap
  }

  .xxl\:whitespace-pre {
    white-space: pre
  }

  .xxl\:whitespace-pre-line {
    white-space: pre-line
  }

  .xxl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .xxl\:w-0 {
    width: 0
  }

  .xxl\:w-1 {
    width: 0.5rem
  }

  .xxl\:w-2 {
    width: 1rem
  }

  .xxl\:w-3 {
    width: 1.5rem
  }

  .xxl\:w-4 {
    width: 2rem
  }

  .xxl\:w-5 {
    width: 2.5rem
  }

  .xxl\:w-6 {
    width: 3rem
  }

  .xxl\:w-7 {
    width: 3.5rem
  }

  .xxl\:w-8 {
    width: 4rem
  }

  .xxl\:w-9 {
    width: 4.5rem
  }

  .xxl\:w-10 {
    width: 5rem
  }

  .xxl\:w-11 {
    width: 5.5rem
  }

  .xxl\:w-12 {
    width: 6rem
  }

  .xxl\:w-13 {
    width: 6.5rem
  }

  .xxl\:w-14 {
    width: 7rem
  }

  .xxl\:w-15 {
    width: 7.5rem
  }

  .xxl\:w-16 {
    width: 8rem
  }

  .xxl\:w-17 {
    width: 8.5rem
  }

  .xxl\:w-19 {
    width: 9rem
  }

  .xxl\:w-20 {
    width: 9.5rem
  }

  .xxl\:w-22 {
    width: 11rem
  }

  .xxl\:w-24 {
    width: 12rem
  }

  .xxl\:w-32 {
    width: 16rem
  }

  .xxl\:w-40 {
    width: 20rem
  }

  .xxl\:w-auto {
    width: auto
  }

  .xxl\:w-px {
    width: 1px
  }

  .xxl\:w-half {
    width: 0.25rem
  }

  .xxl\:w-1\/2 {
    width: 50%
  }

  .xxl\:w-1\/3 {
    width: 33.333333%
  }

  .xxl\:w-2\/3 {
    width: 66.666667%
  }

  .xxl\:w-1\/4 {
    width: 25%
  }

  .xxl\:w-2\/4 {
    width: 50%
  }

  .xxl\:w-3\/4 {
    width: 75%
  }

  .xxl\:w-1\/5 {
    width: 20%
  }

  .xxl\:w-2\/5 {
    width: 40%
  }

  .xxl\:w-3\/5 {
    width: 60%
  }

  .xxl\:w-4\/5 {
    width: 80%
  }

  .xxl\:w-1\/6 {
    width: 16.666667%
  }

  .xxl\:w-2\/6 {
    width: 33.333333%
  }

  .xxl\:w-3\/6 {
    width: 50%
  }

  .xxl\:w-4\/6 {
    width: 66.666667%
  }

  .xxl\:w-5\/6 {
    width: 83.333333%
  }

  .xxl\:w-1\/12 {
    width: 8.333333%
  }

  .xxl\:w-2\/12 {
    width: 16.666667%
  }

  .xxl\:w-3\/12 {
    width: 25%
  }

  .xxl\:w-4\/12 {
    width: 33.333333%
  }

  .xxl\:w-5\/12 {
    width: 41.666667%
  }

  .xxl\:w-6\/12 {
    width: 50%
  }

  .xxl\:w-7\/12 {
    width: 58.333333%
  }

  .xxl\:w-8\/12 {
    width: 66.666667%
  }

  .xxl\:w-9\/12 {
    width: 75%
  }

  .xxl\:w-10\/12 {
    width: 83.333333%
  }

  .xxl\:w-11\/12 {
    width: 91.666667%
  }

  .xxl\:w-full {
    width: 100%
  }

  .xxl\:w-screen {
    width: 100vw
  }

  .xxl\:z-0 {
    z-index: 0
  }

  .xxl\:z-10 {
    z-index: 10
  }

  .xxl\:z-20 {
    z-index: 20
  }

  .xxl\:z-30 {
    z-index: 30
  }

  .xxl\:z-40 {
    z-index: 40
  }

  .xxl\:z-50 {
    z-index: 50
  }

  .xxl\:z-auto {
    z-index: auto
  }
}