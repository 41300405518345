//@font-face {
//    font-family: 'InterHinted';
//    font-style:  normal;
//    font-weight: 400;
//    src: url("/fonts/hinted/Inter-Regular.woff2?v=3.11") format("woff2");
//}
//@font-face {
//    font-family: 'InterHinted';
//    font-style:  normal;
//    font-weight: 600;
//    src: url("/fonts/hinted/Inter-SemiBold.woff2?v=3.11") format("woff2");
//}


/*  variables import
	---------------------------------------------------------------------------- */
	@import (reference) "init.less";
    @import (reference) "mixins.less";
    @import (reference) "reset.less";
    @import (reference) "global.less";
    @import (reference) "tailwind.less";




/*  ----------------------------------------------------------------------------
   	/Base
       ---------------------------------------------------------------------------- */
@page {
//  size: A4;
}

html,
body {
    @media print {
//        height: 99%;
        width: 210mm;
//        height: 297mm;
      height: 210mm;
    }
}

body,
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: Inter, -apple-system, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.page-print{
    background: #fff;

    @media print{

        background: white;
        color: #000;

        .app-header,
        .portal-header{ display: none; }

        main {
            margin: 0;
        }
    }
}

.print-page{
    @media screen{
        max-width: 74.375rem;
        margin: 1.5rem auto;
        // padding: 3.75rem 2.5rem 5.3125rem 2.5rem;
        .bg-white;
        color: @color-black;
    }
    @media print {
        margin: 7mm;
    }
}

.print-page--barcodes {
  background-color: white;
  @media print {
    margin: 0;
  }
}

@results-header-height: 3.1cm;
@results-details-height: 3.45cm;

.print-page--dieline {
}

.print-barcodes-list {
    margin-left: -0.2645833cm;
    height: 16.1cm;
    align-content: flex-start;
}

/*  ----------------------------------------------------------------------------
   	/Header
   	---------------------------------------------------------------------------- */

.print-header{

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bg-yellow;
        padding: 0.635cm 0.3cm;
    }

    &__logo{
        position: relative;

        &.humm{
            width: 3.386666cm;
        }
    }
    &__meta {
        text-align: left;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        > div {
            margin-left: 0.7468cm;
            font-size: 10pt;
            line-height: 1.2;
        }
    }
}











/*  ----------------------------------------------------------------------------
/Headings
---------------------------------------------------------------------------- */

.print-headings{


    @media screen{
        padding: .3125rem .9375rem;
        background-color: @color-black;
    }

    @media print{
        padding: .1cm .3cm;
        background: @color-black !important;
    }

    &__title{
        margin-bottom: 0;
        .font-semibold;
        .font-size-height(18,20);
        color: @color-white;

        @media print{
            font-size: 10pt;
            line-height: 1.5;
        }
    }
    &__title--main {
        color: @color-yellow;
    }

    &__subtitle {
        color: @color-yellow;
        font-size: 9pt;
        line-height: 1;
    }
}











/*  ----------------------------------------------------------------------------
   	/Informations
   	---------------------------------------------------------------------------- */

.print-info{
    display: flex;
    margin: 0 0 1.0583332cm;
    .font-size-height(14,20);

    @media print{
        font-size: 8pt;
        line-height: 1.4;
        //break-inside: avoid;
    }

    + .print-info {
        margin: 1.0583332cm 0 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__col {
        padding: 0 0.2645833cm;
    }

    &__col-1,
    &__col-2{
        width: 50%;
    }

    &__col-1 {
        padding-left: 0;
    }
    &__col-2 {
        padding-right: 0;
    }

    &__heading{
        padding: 0.24cm 0;
        margin-top: 0.3766cm;
        margin-bottom: 0;
        border-bottom: 1px solid @color-black;
        .font-semibold;
        font-size: 10pt;
        line-height: inherit;
    }

    &__content{
        display: flex;
        margin: 1rem -0.625rem 0;
        @media print {
            margin-top: 0.25cm;
            margin: 0.25cm -0.2645833cm 0;
        }
    }

    &__block{
        width: 50%;
        flex-grow: 1;
        padding: 0 0.2645833cm;
    }

    &--stickers {
        margin-bottom: 0.7085cm;
        justify-content: space-between;
        .print-info__col {
            padding: 0;
        }
        .print-info__col + .print-info__col {
            padding-left: 0.2645833cm;
        }
    }

    &__lab-address {
        .print-info__heading {
            border: 0;
            margin-top: 0;
        }
        .print-info__content {
            margin-top: 0;
        }
    }

    &__sender {
        .print-info__heading {
            border: 0;
            margin-top: 0;
        }
        .print-info__content {
            margin-top: 0;
        }
    }


    &__table {
        font-size: inherit;
        margin: 0;
        th,
        td {
            padding: 0;
            border: 0;
            color: inherit;
        }
    }

    &.print-info--barcode-row {
        margin: 0;
    }

    &__code-detail {
        padding-top: @s-3;
        padding-left: 0;
        border-top: 1px solid;
        @media print {
            flex: 1;
            width: auto;
            max-width: none;
            height: 3.2cm;
            padding-top: 0.5cm;
        }
    }
    &__code-detail-pottle-name {
        display: flex;
        @media print {
            margin-top: -0.2cm;
        }
        .pottle-head__sample-name {
            @media print {
                font-size: 12pt;
                line-height: 1.5;
            }
        }
        .pottle-head__num {
            margin-top: 0.15cm;
        }
    }
    &__code-detail-list {
        columns: 3;
        column-gap: 2rem;
    }
    &__barcode {
        flex: none;
        width: auto;
        max-width: none;
        .print-sticker {
            width: 5cm;
            height: 3.2cm;
            min-height: 0;
            display: flex;
            padding: 4mm;
            position: relative;
        }
        .print-sticker__logo {
            position: absolute;
            top: 4mm;
            left: 4mm;
            width: 5mm;
            height: 5mm;
        }
    }
}


.print-info--details {
    margin: 0 0 0.3cm;
    .print-info__heading {
        margin-top: 0.06cm;
    }
}


.print-sticker {
    border: 1px solid fade(@color-grey, 30%);
    border-radius: 4px;
    min-height: 37mm;
    padding: 5mm;

    &__barcode_details {
        margin-left: 14mm;
        height: auto;
        border-left: 1px solid fade(@color-grey, 15%);
        padding-left: 0.4cm;
    }

    &__barcode_details-inner {
        transform: rotate(-90deg);
        transform-origin: 0 0;
        margin-top: 2.375cm;
    }

    &__barcode {
        width: 15mm;
        height: 15mm;
        flex: none;
        align-self: flex-end;
        margin: 0;
        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    &__barcode-id {
        .text-lg;
        line-height: 1;
        @media print {
            font-size: 10pt;
        }
    }
    &__barcode-pottle-name {
        display: flex;

        @media print {
            font-size: 8pt;
            margin-top: -0.1cm;
        }

        .pottle-head__sample-name {
            font-size: 0.875rem;
            padding: 0;
            flex: none;
            @media print {
                font-size: 8pt;
            }
        }

        .pottle-head__num {
            flex: none;
            margin-top: 1.5mm;
            margin-left: 1.5mm;
        }
    }

    &--address {
        line-height: 1.5;
        width: 13cm;
        height: 8.8cm;
        font-size: 16pt;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .print-sticker__text {
            padding-bottom: 5mm;
        }
        .print-sticker__logo {
            margin-top: auto;
            width: 30mm;
        }
    }

    &--sender {
        width: 8.8cm;
        height: 5.8cm;
        font-size: 11pt;
        transform: rotate(-90deg);
        transform-origin: 0 0;
        top: 8.8cm;
        .print-info__heading {
            padding-top: 0;
        }
    }

}








/*  ----------------------------------------------------------------------------
   	/Table - Results
   	---------------------------------------------------------------------------- */

.print-result{
    font-size: 8pt;
    color: @color-black;
    //page-break-inside: avoid;
    padding-bottom: 0.8cm;
    position: relative;

    &:last-of-type {
        padding-bottom: 0;
    }

    &__header {
        border-top: 4px solid @color-black;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 3mm 0 2mm;
    }
    &__header-title {
        display: flex;
        align-items: flex-end;
    }
    &__header-label {
        text-transform: uppercase;
        font-size: 8pt;
        .font-semibold;
        line-height: 1.5;
    }
    &__header-heading {
        margin: 0;
        padding: 0;
        line-height: 1;
        font-size: 1.375rem;
        .font-semibold;
    }

    &__header-icon {
        margin-right: 4.2mm;
        margin-bottom: 2mm;
    }



	&__header + &__section{
        border-top: 1px solid @color-black;
        padding-bottom: 0.5cm;
        margin-left: 0.85cm;
        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &__section-header {
        padding: 0.3cm 0 0;
        display: flex;
        justify-content: space-between;
        h3 {
            .font-semibold;
            font-size: 12pt;
            line-height: 1.3;
            margin: 0;
            padding: 0;
        }
        h3 .sub {
            font-size: 10.5pt;
            .font-normal;
            .text-body;
        }
    }

    &__dates {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        > div {
            margin-left: 1.375rem;
        }
        strong {
            text-transform: uppercase;
        }
    }

    &__table{
		font-size: inherit;
		line-height: inherit;
        color: inherit;
        margin: 0.3cm 0;
        .border-0;
        .border-t;
        .border-solid;
        border-color: #dedede;

        th,
        td {
            .border-l;
            .border-solid;
            border-color: #dedede;
            color: black;
            &:first-child {
                .border-l-0;
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        th {
            text-transform: uppercase;
            font-size: 8pt;
            padding: 0.9375rem 1.5625rem;
            line-height: normal;
            @media print {
                padding: 0.2673cm 0.3856cm;
            }
        }

        td{
			vertical-align: top;
            border-bottom: 0;
            padding: 0.5rem 1.5625rem;
            font-size: 8pt;
            line-height: 1.3;
            @media print {
                padding: 0.13cm 0.3856cm;
            }
        }
    }

    &__th-test,
    &__th-value,
    &__th-ref {
        width: 26%;
    }

    &__th-tech {
        text-align: right;
    }

    &__td-test{

    }

	&__td-value{
		.font-semibold;
	}

    &__th-unit,
	&__td-unit{
		.font-semibold;
		.font-size(11);
        opacity: .5;
        text-transform: none;
	}

	&__td-date{
		.font-semibold;
		.font-size(11);
	}

	&__td-tech{
        text-align: right;
        // width: 42%;
    }
    &__signature {
        display: inline-block;
        height: 1cm;
        width: 100%;
        background-position: 100% 0;
        .mt-1;
    }
    &__pass-fail {
        border-radius: 20em;
        padding: 0.1875rem 0.625rem 0.125rem;
        text-transform: uppercase;
        .ml-2;
        .font-semibold;
        .text-base;
        .bg-black;
        .text-white;
    }
}



.range-gauge{
	position: relative;
	overflow: hidden;
	display: inline-flex;
	border-radius: 20em;
	.font-semibold;
	.font-size-height(11,15);

	&__min,
	&__max{
		padding: .1875rem .625rem .125rem;
		width: 3.125rem;
		background-color: fade(@color-black, 10%);
		color: fade(@color-black, 50%);
	}

	&__min{
		border-right: 1px solid fade(@color-black, 10%);
	}

	&__infinite{
		padding: .1875rem .625rem .125rem;
		width: 3.125rem;
		color: fade(@color-black, 50%);
	}

	&__max{
		text-align: right;
	}
}


.not-accredited {
    width: 1.3mm;
    height: 1.3mm;
    font-size: 0;
    color: transparent;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 1mm;
    margin-top: -0.6mm;
}







/*  ----------------------------------------------------------------------------
/Table - Forecast
---------------------------------------------------------------------------- */
.print-result--forecast {
    .print-result__table {
        th,
        td {
            border: 1px solid #dedede;
            &:first-child {
                .border-l-0;
            }
            &:last-child {
                .border-r-0;
            }
        }
        th {
            padding: 0.2673cm 0.1cm;
            font-size: 7.5pt;
        }
        td {
            padding: 0.13cm 0.1cm;
        }
        &__tr-sub {
            th {
                text-align: center;
                font-size: 5pt;
            }
        }
    }
    .print-result__table--12-months {
        .print-result__th-type {
            width: 13.25%;
        }
        .print-result__th-units {
            width: 12%;
        }
        .print-result__th-result {
            width: 16%;
        }
    }
    .print-result__table--maximum {
        max-width: 200mm;
        .print-result__th-label {
            width: 25%;
        }
    }
    .print-result__signed {
        margin: 0 0 10mm;
        .print-result__signature {
            display: block;
            background-position: 0 0;
            width: 2cm;
        }
        .print-result__tech {
            margin: 3mm 0;
        }
    }
}
.print-forecast-model {
    font-size: 10.5pt;
    padding: 2cm 0.85cm;
    line-height: 1.5;
    p {
        max-width: none;
        .text-body;
    }
}


/*  ----------------------------------------------------------------------------
/Note
---------------------------------------------------------------------------- */
.print-notes {
    margin: 0.45cm 0;
}
.print-note {
    display: flex;
    padding: 0.5cm 0;
    border-bottom: 1px solid;

    > div {
        font-size: 8pt;
        line-height: 1.5;
    }
    &__title {
        width: 28.57142857142857%;
        .font-semibold;
        padding-left: 0.3cm;
        padding-right: 0.3cm;
        padding-top: 0.1cm;
        padding-bottom: 0.1cm;

    }
    &__text {
        width: 71.42857142857143%;
    }
}



/*  ----------------------------------------------------------------------------
   	/Footer
   	---------------------------------------------------------------------------- */

.print-footer{
    margin-top: 0.3cm;
    padding-top: 0.3cm;
    border-top: 4px solid @color-black;
    //page-break-inside: avoid;

    .print-footer__inner{
        display: flex;
    }

    .print-footer__info{
        flex: 1;
        padding: 0 .265cm 0 0;
    }

    .print-footer__terms{
        font-size: 7pt;
        line-height: 1.2;

        p{
            margin-top: 0;
            margin-bottom: 0.5rem;
            max-width: none;
        }
      p:last-child {
        margin-top: 0;
        margin-bottom: 0;
        max-width: none;
      }
    }

    .print-footer__logos{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .print-footer__logo-list{
        display: flex;
        align-items: center;
    }

    .print-footer__logo-item{
        margin: 0 0 0 0.65cm;
        img {
            max-height: 0.935cm;
        }
    }
}

.print-barcodes-footer {
    padding-top: 0.655cm;
    margin-top: auto;
}


body.sample .print-result:after {
  align-items: center;
  justify-content: center;
  content: "Sample";
  display: flex;
  font-family: Impact;
  font-size: 15em;
  transform: rotate(45deg);
  color: black;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: center;
}

/*  ----------------------------------------------------------------------------
/Utilities
---------------------------------------------------------------------------- */
@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    .no-break {
        page-break-inside: avoid;
    }
    .page-break-before {
        page-break-before: always;
    }
    .page-break-after {
        page-break-after: always;
    }
}
